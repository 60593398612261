//src/components/ImageCrop.jsx

import { useState } from 'react';
import user1 from '../../assets/imgs/dummyImg.jpg';
import Modal from '../customImageEditor/editorModal';
import { readFile } from '../customImageEditor/cropImage';
import ImageCropModalContent from './ImageCropModalContent';
import { useImageCropContext } from './ImageCropProvider';
import { allApiUrl } from '../../api/apiRoutes';
import { apiPutMethod } from '../../api/rest';
import toastMessages from '../toastMessage';

const ImageCrop = (props) => {
    const [openModal, setOpenModal] = useState(false);
    const [preview, setPreviewImage] = useState('');
    const { getProcessedImage, setImage, resetStates } = useImageCropContext();
    const handleDone = async () => {
        const avatar = await getProcessedImage();
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        // props.setImage(avatar)
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!props.recruiter) {
            try {
                let formData = new FormData();
                formData.append(props.type, avatar);
                formData.append("accountId",
                    userInfo._id);
                let res = await apiPutMethod(
                    allApiUrl.updateProfileCandidate,
                    formData,
                    header
                );
                if (res.status == 200) {
                    toastMessages('success', "Profile updated successfully")
                    props.setRefresh(!props.refresh)
                    props.setDismissModal(true)
                    document.getElementById('avatarInput').value = null
                }
            } catch (err) {
                // setIsLoading(false);
                console.log("err", err);
            }
        }
        else {
            props.setForm({ ...props.form, firmLogo: avatar })
            props.setIsNewImageUploaded(true)
            props.setPreview(window.URL.createObjectURL(avatar))
        }
        setPreviewImage(window.URL.createObjectURL(avatar));
        resetStates();
        setOpenModal(false);
    };

    const handleDoneOnboardingRecruiter = async () => {
        const avatar = await getProcessedImage();
        const header = {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        props.setImage(avatar)
        if (props.page == "companyLogoModal") {
            props.setPreview({ ...props.preview, companyImage: window.URL.createObjectURL(avatar) })
        } else {
            props.setPreview({ ...props.preview, headshotImage: window.URL.createObjectURL(avatar) })
        }
        setPreviewImage(window.URL.createObjectURL(avatar));
        resetStates();
        setOpenModal(false);
    };
    const handleFileChange = async ({ target: { files } }) => {
        const file = files && files[0];
        const imageDataUrl = await readFile(file);
        setImage(imageDataUrl);
        setOpenModal(true);
    };
    return (
        <div className="bg-gray-100 h-screen flex justify-center items-center">
            {/* <input
                type="file"
                onChange={handleFileChange}
                className="hidden"
                id="avatarInput"
                accept="image/*"
            /> */}
            {/* <label htmlFor="avatarInput" className="cursor-pointer">
                <img
                    src={preview}
                    height={192}
                    width={192}
                    className="object-cover rounded-full h-48 w-48"
                    alt=""
                />
            </label> */}
            <Modal open={openModal} handleClose={() => setOpenModal(false)}>
                <ImageCropModalContent
                    page={props.page}
                    handleDone={handleDone}
                    handleClose={() => setOpenModal(false)}
                    dismissModal={props.dismissModal}
                    setDismissModal={props.setDismissModal}
                    recruiter={props.recruiter}
                    onBoardingRecruiter={props.onBoardingRecruiter}
                    handleDoneOnboardingRecruiter={handleDoneOnboardingRecruiter}
                />
            </Modal>
        </div>
    );
};

export default ImageCrop;