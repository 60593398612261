import Sidebar from "../components/sidebar";
import Topbar from "../components/topBar";
import Footer from "../components/footer";
import Modals from "./modal";
import jobIcon from "../assets/imgs/jobImg.png";
import locationIcon from "../assets/imgs/locationIconGreen.svg";
import editIcon from "../assets/imgs/editIcon.svg";
import deleteIcon from "../assets/imgs/iconDelete.svg";

function JobsPosted() {
  return (
    <>
      {/* <Modals />
      <Sidebar /> */}
      <div className="mainWrapper">
        {/* <Topbar /> */}
        <section className="jobPosted sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12">
                <div className="commonHeading">
                  <h2>Jobs Posted</h2>
                </div>

                <div className="jobPosted__List">
                  <div className="postHeader">
                    <div className="postImg">
                      <img src={jobIcon} />
                    </div>
                    <div className="postText">
                      <h4 className="white">
                        General Manager{" "}
                        <span className="green">
                          <img src={locationIcon} />
                          Lufkin, TX
                        </span>
                      </h4>
                      <p>10 min. ago</p>
                    </div>

                    <div className="headBtns">
                      <button className="btn">
                        <img src={editIcon} />
                      </button>

                      <button className="btn">
                        <img src={deleteIcon} />
                      </button>
                    </div>
                  </div>

                  <div className="postBodyContent">
                    <h4>
                      $65,547 - $83,279/ <span>Annually</span>
                    </h4>
                    <p>
                      Responsible for overseeing all store operations and
                      ensuring the highest quality of customer service. You must
                      have strong leadership and communication skills to
                      effectively manage a team of employees, control inventory
                      and costs, and exceed sales goals.
                    </p>
                  </div>
                  <div className="postFooter">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="tags">
                          <span>Full-Time</span>
                          <span>Texas</span>
                          <span>Management</span>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="btnSection">
                          <a className="btn btn-save" href="">
                            View Details
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default JobsPosted;
