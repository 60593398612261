import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import logo from './logo.svg';
// import "./App.css";
import "./assets/css/custom.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Login from "./view/login";
import SelectRole from "./view/select-role";
import CreateAccount from "./view/create-account";
import CreatePassword from "./view/create-password";
import ForgotPassword from "./view/forgot-password";
import ResetPassword from "./view/reset-passowrd";
import ResetSuccessful from "./view/password-reset-successful";
import AffilateUrl from "./view/affilate-url";
import TermsService from "./view/terms-of-service";
import PrivacyPolicy from "./view/privacy-policy";
import ContactUs from "./view/contact-us";
import Profile from "./view/my-profile";
import CreateJob from "./view/create-job";
import EditJob from "./view/edit-job";
import CompanyMyProfile from "./view/company-my-profile";
import Welcome from "./view/welcome";
import Landing from "./view/landing";
import WelcomeLanding from "./view/welcome-landing";
import EmployerLanding from "./view/employer-landing";
import OnBoardingProcess from "./view/onboarding-process";
import OnBoardingProcessRecruiter from "./view/onboarding-process-recruiter";
import NoReferralFound from "./view/noreferralfound";
import PublicQr from "./view/public-qr";
import ImageUpload from "./components/customImageEditor";
import Eror from "./view/404";
import Linkedin from "./view/linkedin";
//---------------------------------------------- Admin Routes-------------------------------------------------------
import AdminAnalytics from "./view/admin/adminAnalytics";
import AdminDashboard from "./view/admin/adminDashboard";
import AdminTickets from "./view/admin/adminTickets";
import AdminUsers from "./view/admin/adminUser";
import AdminTeam from "./view/admin/adminTeam";
import AdminLogin from "./view/admin/adminLogin";
/* ---------------------------------------------Routes not used Currently start---------------------------------------------------------------------------- */
import Dashboard from "./view/dashboard";
import SearchJobDetail from "./view/search-job-detail";
import ViewJobDetail from "./view/view-job-detail";
import JobsPosted from "./view/jobs-posted";
import Applicants from "./view/applicants";
import ShareJob from "./view/shareJob";
import EmployeeLeaderBoard from "./view/admin/employeeLeaderboard";
import EmployeeCRM from "./view/admin/employeeCrm";
import EmployeeProduction from "./view/admin/employeeProduction";
// import Analytics from "./view/analytics";
// import NoPageFound from "./view/nopagefound";
// import Plans from "./view/plans";
// import Pricing from "./view/pricing";
// import CompanyProfile from "./view/company-profile";
// import CandidateProfile from "./view/candidate-profile";
// import CandidateJobList from "./view/candidate-job-list";
// import CandidateJobGrid from "./view/candidate-job-grid";
// import SearchResultList from "./view/search-result-list";
// import SearchResultGrid from "./view/search-result-grid";
// import Messages from "./view/messages";
// import NoResult from "./view/no-result";
// import Settings from "./view/settings";
// import PremiumJobDetail from "./view/premium-job-detail";
// import CreateJobStepTwo from "./view/create-job-step2";
// import CreateJobStepThree from "./view/create-job-step3";
// import CreateJobStepFour from "./view/create-job-step4";
// import Budget from "./view/set-budget";
// import ForYou from "./view/for-you";
// import EmployerSettings from "./view/employer-setting";
/* ---------------------------------------------Routes not used Currently end---------------------------------------------------------------------------- */

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const isAdmin = window.location.hostname.includes("admin");
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="App">
        <ToastContainer
          // closeOnClick={false}
          position="top-right"
          hideProgressBar={false}
          newestOnTop={false}
          autoClose={2000}
          bodyClassName="toastBody"
        />
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/*" element={<Eror />} />
            <Route path="/404" element={<Eror />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-successful" element={<ResetSuccessful />} />
            <Route path="/home-welcome" element={<WelcomeLanding />} />
            <Route path="/home" element={<Landing />} />
            <Route path="/employer-home" element={<EmployerLanding />} />
            <Route path="/terms-of-service" element={<TermsService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/public-qr" element={<PublicQr />} />
            <Route path="/noreferral" element={<NoReferralFound />} />
            <Route path="/search-job-detail" element={<SearchJobDetail />} />
            <Route path="/view-job/:id" element={<ViewJobDetail />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/jobs-posted" element={<JobsPosted />} />
            <Route path="/applicants" element={<Applicants />} />
            <Route path="/onboarding-process" element={<OnBoardingProcess />} />
            <Route path="/linkedin" element={<Linkedin />} />
            <Route
              path="/onboarding-recruiter"
              element={<OnBoardingProcessRecruiter />}
            />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/my-profile" element={<Profile />} />
            <Route path="/profile" element={<CompanyMyProfile />} />
            <Route path="/create-job" element={<CreateJob />} />
            <Route path="/edit-job/:id" element={<EditJob />} />
            <Route path="/select-role" element={<SelectRole />} />
            <Route path="/affiliates" element={<AffilateUrl />} />
            <Route path="/imageUpload" element={<ImageUpload />} />
            {/* ---------------------------------------------Routes not used Currently start---------------------------------------------------------------------------- */}
            {/*
            <Route path="/plans" element={<Plans />} /> 
            <Route path="/create-password" element={<CreatePassword />} />
            <Route path="/*" element={<NoResult />} />
            <Route path="/pricing" element={<Pricing />} />
           <Route path="/company-profile" element={<CompanyProfile />} />
           <Route path="/candidate-profile" element={<CandidateProfile />} />
           <Route path="/candidate-list" element={<CandidateJobList />} />
           <Route path="/candidate-job-grid" element={<CandidateJobGrid />} />
           <Route path="/search-result-list" element={<SearchResultList />} />
           <Route path="/search-result-grid" element={<SearchResultGrid />} />
           <Route path="/premium-job-detail" element={<PremiumJobDetail />} />
           <Route path="/settings" element={<Settings />} /> 
           <Route path="/create-job/step2" element={<CreateJobStepTwo />} />
           <Route path="/create-job/step3" element={<CreateJobStepThree />} />
           <Route path="/create-job/step4" element={<CreateJobStepFour />} /> 
           <Route path="/budget" element={<Budget />} /> 
           <Route path="/for-you" element={<ForYou />} /> 
           <Route path="/employer-settings" element={<EmployerSettings />} /> 
           <Route path="/messages" element={<Messages />} /> 
            <Route path="/analytics" element={<Analytics />} />
            {/* ---------------------------------------------Routes not used Currently end---------------------------------------------------------------------------- */}
            {/* {isAdmin ? (
              <> */}
            {/* Admin Routes Start*/}
            <Route path="/share/:team/:project/:id" element={<ShareJob />} />
            <Route path="/adminDashboard" element={<AdminDashboard />} />
            <Route path="/adminAnalytics" element={<AdminAnalytics />} />
            <Route path="/adminTickets" element={<AdminTickets />} />
            <Route path="/adminUsers" element={<AdminUsers />} />
            <Route path="/adminTeam" element={<AdminTeam />} />
            <Route path="/adminLogin" element={<AdminLogin />} />
            <Route
              path="/employee-leaderboard"
              element={<EmployeeLeaderBoard />}
            />
            <Route path="/employee-crm" element={<EmployeeCRM />} />
            <Route
              path="/employee-production"
              element={<EmployeeProduction />}
            />
            {/* Admin Routes End*/}
            {/* </>
            ) : (
          ''
            )} */}
          </Routes>
        </Router>
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
