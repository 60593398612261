import { BrowserRouter, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import contactImg from "../assets/imgs/contactImg.svg";
import facebook from "../assets/imgs/facebook.svg";
import instagram from "../assets/imgs/instagram.svg";
import jobPost_full from "../assets/imgs/jobPost_full.gif";
import twitter from "../assets/imgs/twitter.svg";
import linkedIn from "../assets/imgs/linkedIn.svg";
import toastMessages from "../components/toastMessage";
import { apiPostMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import FullPageLoader from "../components/fullPageLoader";
import checkMark from "../assets/imgs/checkMark.gif";
import { useTheme } from "styled-components";

// Register ScrollTrigger with GSAP
const ContactUsModal = () => {
  return (
    <div className="mainWrapper">
      {/* full launch modal Starts */}
      <div
        class="modal fade commonModal editEducationModal confirmationModal"
        id="fullLaunchModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header"></div>
            <div class="modal-body pt-0">
              <div className="commonModalContent px-0">
                <img src={checkMark} />
                <p className="mb-0">
                  Query submitted. ! <br />
                  <small>
                    Now sit back and relax as we get back to you, or maybe
                    create a hirebird account to see what the hype is all about!
                  </small>
                  !
                </p>
                <div className="formBtns mt-3">
                  <button
                    className="btn inviteBtn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* full launch Modal Ends */}
    </div>
  );
};
function ContactUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setform] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setform((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  // useEffect(() => {
  //   const uploadImageModal = new window.bootstrap.Modal(
  //     document.getElementById("fullLaunchModal")
  //   );
  //   uploadImageModal.show();
  // }, []);

  useEffect(() => {
    document.title = "Contact | Join our waitlist!";
    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content =
        "Full release in April 2024. Sign up for our pre-access beta for sneak peaks, new information and product discounts. Send us a message.";
    }
  }, []);
  async function contactUsHandler(e) {
    setIsLoading(true);
    e.preventDefault();
    try {
      let res = await apiPostMethod(allApiUrl.contactUs, form, "");
      if (res.status == 200) {
        const uploadImageModal = await new window.bootstrap.Modal(
          document.getElementById("fullLaunchModal")
        );
        uploadImageModal.show();
        setform({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
      setIsLoading(false);
    } catch (err) {
      toastMessages("error", err.data.message);
      setIsLoading(false);
    }
  }
  return (
    <>
      <Navbar />
      <ContactUsModal />
      <section className="contactSection">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="contactSection__Img wow zoomIn">
                <img src={contactImg} alt="Contact Us" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="contactSection__Form wow fadeInUp">
                <form onSubmit={(e) => contactUsHandler(e)}>
                  <h3>Contact Us</h3>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6">
                      <div className="form-group">
                        <input
                          required
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Full name *"
                          name="name"
                          value={form.name}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6">
                      <div className="form-group">
                        <input
                          required
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Email *"
                          name="email"
                          value={form.email}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <div className="form-group">
                        <input
                          required
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Subject *"
                          name="subject"
                          value={form.subject}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <div className="form-group">
                        <textarea
                          required
                          autoComplete="off"
                          className="form-control"
                          placeholder="Message *"
                          name="message"
                          value={form.message}
                          onChange={onChangeHandler}
                        ></textarea>
                      </div>

                      <div className="form-group text-center">
                        <button className="btn btn-send">
                          Send Message
                          {isLoading && (
                            <div
                              style={{ marginLeft: "21px" }}
                              class="spinner-border spinner-border-sm"
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="newFooter wow fadeInUp">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="newFooter__Content">
                <ul>
                  <li>
                    <Link to="/home">Candidates</Link>
                  </li>
                  <li>
                    <Link to="/employer-home">Employer</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-service">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="newFooter__Content">
                <p>Hirebird.me © 2023</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="newFooter__Content">
                <ul className="socialIcon">
                  {/* <li className="wow zoomIn">
                    <Link to="" target="_blank">
                      <img src={facebook} />
                    </Link>
                  </li> */}

                  <li className="wow zoomIn">
                    <Link
                      to="https://www.instagram.com/hirebird.app/"
                      target="_blank"
                    >
                      <img src={instagram} />
                    </Link>
                  </li>

                  {/* <li className="wow zoomIn">
                    <Link to="" target="_blank">
                      <img src={twitter} />
                    </Link>
                  </li> */}

                  <li className="wow zoomIn">
                    <Link
                      to="https://www.linkedin.com/company/hirebird-me"
                      target="_blank"
                    >
                      <img src={linkedIn} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
