import noResult from "../assets/imgs/no-result.svg";

function NoReferralFound() {
  return (
    <>
      <div className="mainWrapper">
        <div className="jobListing">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="jobListing__noResult">
                  <img src={noResult} />
                  <h4>No ! Referral Found </h4>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoReferralFound;
