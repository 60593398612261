import { BrowserRouter, Link, useNavigate } from "react-router-dom";

import Logo from "../../assets/imgs/headerAdmin.svg";
import DashboardIcon from "../../assets/imgs/dashboardIcon.svg";
import jobIcon from "../../assets/imgs/jobIcon.svg";
import applicantsIcon from "../../assets/imgs/applicantsIcon.svg";
import messageIcon from "../../assets/imgs/messageIcon.svg";
import settingIcon from "../../assets/imgs/settingIcon.svg";
import whiteSearchIcon from "../../assets/imgs/whiteSearchIcon.svg";
import rocketIcon from "../../assets/imgs/rocketIcon.svg";
import inviteCharacterImg from "../../assets/imgs/inviteCharacterImg.svg";
import employerInviteCharacter from "../../assets/imgs/employerInviteCharacter.svg";
import forYouIcon from "../../assets/imgs/forYouIcon.svg";
import profileIcon from "../../assets/imgs/profileIcon.svg";
import match from "../../assets/imgs/match.svg";
import backArrow from "../../assets/imgs/backArrow.svg";
import { useState } from "react";
import Modal from "../../components/modals/editProfileModlas";
import waitImg from "../../assets/imgs/waitImg.svg";
import analyticsAdmin from "../../assets/imgs/analyticsAdmin.svg";
import userAdmin from "../../assets/imgs/simple-icons_googledocs.svg";
import teamView from "../../assets/imgs/Hirebird_WhiteIcon.svg";
import { current } from "@reduxjs/toolkit";
function Sidebar({
  activePage,
  accountsDetail,
  sidebarOpen,
  setSidebarOpen,
  referralpoints,
}) {
  const [currentPage, setCurrentPage] = useState(activePage);
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let navigate = useNavigate();
  return (
    <>
      <div className={`sidebar ${true ? "active" : ""}`}>
        <Link>
          <img src={Logo} />
        </Link>
        <div className="close" onClick={() => setSidebarOpen("")}></div>
        <div className="sidebar__Inner">
          <>
            <ul>
              <li
                className={`${currentPage == "adminDashboard" ? "active" : ""}`}
              >
                <Link to="/adminDashboard">
                  <img src={DashboardIcon} alt="Dashboard Icon" />
                  Dashboard
                </Link>
              </li>

              <li
                className={`${currentPage == "adminAnalytics" ? "active" : ""}`}
              >
                <Link to="/adminAnalytics">
                  <img src={analyticsAdmin} alt="Job Icon" />
                  Analytics
                </Link>
              </li>

              <li
                className="disabled"
                style={{
                  pointerEvents: "none",
                }}
                // className={`${currentPage == "adminTickets" ? "active" : ""}`}
              >
                <Link to="/adminTickets">
                  <img src={applicantsIcon} alt="Applicants Icon" />
                  Tickets
                </Link>
              </li>

              <li className={`${currentPage == "adminUsers" ? "active" : ""}`}>
                <Link to="/adminUsers">
                  <img src={userAdmin} alt="Messages Icon" />
                  Users
                </Link>
              </li>

              <li className={`${currentPage == "adminTeam" ? "active" : ""}`}>
                <Link to="/adminTeam">
                  <img src={teamView} alt="Settings Icon" />
                  Team View
                </Link>
              </li>
            </ul>
          </>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
