import Sidebar from "./adminSidebar";
import Topbar from "./adminTopbar";
import deleteTickets from "../../assets/imgs/deleteTickets.svg";
import closeTickets from "../../assets/imgs/closeTickets.svg";
import pendingTickets from "../../assets/imgs/pendingTickets.svg";
import totalTickets from "../../assets/imgs/totalTickets.svg";
import candidateImg from "../../assets/imgs/candidateImg.png";
import messageBtn from "../../assets/imgs/messageBtn.svg";
import sortingArrow from "../../assets/imgs/sortingArrow.svg";
import dottedIcon from "../../assets/imgs/dottedIcon.svg";
function AdminTickets() {
  return (
    <>
      <div className="mainWrapper">
        <Sidebar activePage="adminTickets" />
        <Topbar />
        <section className="analytics sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6">
                <div className="commonHeading">
                  <h2>Tickets</h2>
                </div>
              </div>
              <div className="ticketOuter">
                <div className="row">
                  {/* <div className="col-xl-12 ticketMain"> */}
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ticketBox">
                      <div className="ticketBoxBody">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <img src={totalTickets} />
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="ticketData">
                              <h3>897</h3>
                              <p>Total Tickets</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ticketBox">
                      <div className="ticketBoxBody">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <img src={pendingTickets} />
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="ticketData">
                              <h3>453</h3>
                              <p>Pending Tickets</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ticketBox">
                      <div className="ticketBoxBody">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <img src={closeTickets} />
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="ticketData">
                              <h3>453</h3>
                              <p>Close Tickets</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6 col-md-6">
                    <div className="ticketBox">
                      <div className="ticketBoxBody">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <img src={deleteTickets} />
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="ticketData">
                              <h3>453</h3>
                              <p>Delete Tickets</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="row">
                  {/* <div className="row"> */}
                  <div className="col-lg-12 dashboard">
                    <div className="card">
                      <div className="card-body dashboardCardBody">
                        <div className="table-resposnive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>
                                  ID{" "}
                                  <span>
                                    <img src={sortingArrow} />
                                  </span>
                                </th>
                                <th>
                                  Requested by{" "}
                                  <span>
                                    <img src={sortingArrow} />
                                  </span>
                                </th>
                                <th>
                                  Subject{" "}
                                  <span>
                                    <img src={sortingArrow} />
                                  </span>
                                </th>
                                <th>
                                  Assignee{" "}
                                  <span>
                                    <img src={sortingArrow} />
                                  </span>
                                </th>
                                <th>
                                  Priority{" "}
                                  <span>
                                    <img src={sortingArrow} />
                                  </span>
                                </th>
                                <th>
                                  Status{" "}
                                  <span>
                                    <img src={sortingArrow} />
                                  </span>
                                </th>
                                <th>
                                  Created Date{" "}
                                  <span>
                                    <img src={sortingArrow} />
                                  </span>
                                </th>
                                <th>
                                  Due Date
                                  <span>
                                    <img src={sortingArrow} />
                                  </span>
                                </th>
                                <th>
                                  <img src={dottedIcon} />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>#00001</td>
                                <td>
                                  {" "}
                                  <span>
                                    <img src={candidateImg} />{" "}
                                  </span>
                                  Cameron A.
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>#00001</td>
                                <td>
                                  {" "}
                                  <span>
                                    <img src={candidateImg} />{" "}
                                  </span>
                                  Cameron A.
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>#00001</td>
                                <td>
                                  {" "}
                                  <span>
                                    <img src={candidateImg} />{" "}
                                  </span>
                                  Cameron A.
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>#0001</td>
                                <td>
                                  {" "}
                                  <span>
                                    <img src={candidateImg} />{" "}
                                  </span>
                                  Cameron A.
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>#0003</td>
                                <td>
                                  {" "}
                                  <span>
                                    <img src={candidateImg} />{" "}
                                  </span>
                                  Cameron A.
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>
                                  #0001
                                </td>
                                <td>
                                  {" "}
                                  <span>
                                    <img src={candidateImg} />{" "}
                                  </span>
                                  Cameron A.
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>
                                  #0001
                                </td>
                                <td>
                                  {" "}
                                  <span>
                                    <img src={candidateImg} />{" "}
                                  </span>
                                  Cameron A.
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>
                                  #0001
                                </td>
                                <td>
                                  {" "}
                                  <span>
                                    <img src={candidateImg} />{" "}
                                  </span>
                                  Cameron A.
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default AdminTickets;
