import { useState } from "react";
import StepFormProgress from "../../view/stepForm-progress";
import dummyImg from "../../assets/imgs/dummyImg.jpg";
import { getYear } from "../../utils/helper";
import iconModalDelete from "../../assets/imgs/iconModalDelete.svg";
function AdminUserModal({
  currModalPreviewData,
  setCurrModalPreviewData,
  setDeleteJobId,
  onJobDeleteHandler,
}) {
  // let {}
  let data = [];
  return (
    <div className="mainWrapper">
      {/* View Account start*/}
      <div
        class="modal fade commonModal editEducationModal candidateOnboarding"
        id="adminUserAccount"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0"></div>
            <div class="modal-body pt-0 px-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setCurrModalPreviewData({})}
              ></button>
              <div className="commonModalContent px-4">
                <h3 className="ModalHeading">Account Information</h3>
                <div className="teamInfo text-start">
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <span>First Name:</span>{" "}
                        {currModalPreviewData.firstName}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Last Name:</span> {currModalPreviewData.lastName}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Email:</span> {currModalPreviewData.email}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Phone Number:</span>{" "}
                        {currModalPreviewData.phoneNumber}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Profile Completed</span>{" "}
                        {currModalPreviewData.profileCompleted
                          ? currModalPreviewData.profileCompleted.toString()
                          : "false"}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Account Type:</span>{" "}
                        {currModalPreviewData.accountType}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Created At:</span>{" "}
                        {new Date(
                          currModalPreviewData.createdAt
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* View Account end*/}
      {/* View Candidate start*/}
      <div
        class="modal fade commonModal editEducationModal candidateOnboarding"
        id="adminUserCandidate"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0"></div>
            <div class="modal-body pt-0 px-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setCurrModalPreviewData({})}
              ></button>
              <div className="commonModalContent px-4">
                <h3 className="ModalHeading">Candidate Information</h3>
                <div className="teamInfo text-start">
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <span>Email:</span> {currModalPreviewData.email}
                      </p>
                    </div>

                    {currModalPreviewData &&
                      currModalPreviewData.qualification &&
                      currModalPreviewData?.qualification.length > 0 && (
                        <div className="col-lg-6">
                          <p>
                            {" "}
                            <span>Qualification:</span>
                          </p>
                          {currModalPreviewData &&
                            currModalPreviewData &&
                            currModalPreviewData?.qualification &&
                            currModalPreviewData?.qualification?.map((item) => {
                              return (
                                <>
                                  <div className="ActEduDtls">
                                    <div className="eduDyImg">
                                      <img
                                        src={
                                          item.universityLogo
                                            ? item.universityLogo
                                            : dummyImg
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="eduDyText">
                                      <h3>{item.university}</h3>
                                      <p>
                                        {item.degreeType} (
                                        {getYear(item.startDate)} -{" "}
                                        {/* {getYear(item.endDate)}) */}
                                        {!item.isPresent
                                          ? getYear(item.endDate)
                                          : "Present"}
                                        )
                                        {/* {item.endDate ? getYear(item.endDate) : "Present"}) */}
                                      </p>
                                    </div>
                                  </div>
                                  <p className="description">
                                    {item.description}
                                  </p>
                                  <div className="divider"></div>
                                </>
                              );
                            })}
                        </div>
                      )}
                    <div className="col-lg-6">
                      <p>
                        <span>Created At:</span>{" "}
                        {new Date(
                          currModalPreviewData.createdAt
                        ).toLocaleDateString()}
                      </p>
                    </div>
                    {currModalPreviewData &&
                      currModalPreviewData?.skills &&
                      currModalPreviewData?.skills.length > 0 && (
                        <div className="col-lg-6">
                          <p>
                            <span>Skills:</span>
                          </p>
                          <div
                            className="tags"
                            // style={{ textTransform: "capitalize" }}
                          >
                            {currModalPreviewData &&
                              currModalPreviewData &&
                              currModalPreviewData?.skills &&
                              currModalPreviewData?.skills?.map((item) => {
                                return <span>{item.skillSet}</span>;
                              })}
                          </div>
                        </div>
                      )}
                    {currModalPreviewData &&
                      currModalPreviewData.customValuePrompt &&
                      currModalPreviewData.customValuePrompt.length > 0 && (
                        <div className="col-lg-6">
                          <p className="customPrompt">
                            <span>Custom Value Prompt:</span>{" "}
                            {currModalPreviewData &&
                              currModalPreviewData.customValuePrompt &&
                              currModalPreviewData.customValuePrompt.find(
                                (item) => item.a !== ""
                              ).a}
                          </p>
                        </div>
                      )}
                    {currModalPreviewData &&
                      currModalPreviewData.workExperience &&
                      currModalPreviewData.workExperience.length > 0 && (
                        <div className="col-lg-6">
                          <p>
                            <span>Experience:</span>
                          </p>
                          {currModalPreviewData &&
                            currModalPreviewData &&
                            currModalPreviewData?.workExperience &&
                            currModalPreviewData?.workExperience.map((item) => {
                              return (
                                <>
                                  <div className="eduDetail">
                                    <p
                                      className="mb-0 experienceHead"
                                      // style={{ textTransform: "capitalize" }}
                                    >
                                      {item.designation}
                                    </p>
                                    <p>
                                      {item.companyName} (
                                      {getYear(item.startDate)} -{" "}
                                      {/* {getYear(item.endDate)}) */}
                                      {!item.isPresent
                                        ? getYear(item.endDate)
                                        : "Present"}
                                      )
                                      {/* {item.endDate ? getYear(item.endDate) : "Present"}) */}
                                    </p>
                                    <p className="description">
                                      {item.description}
                                    </p>
                                  </div>
                                  <div className="divider"></div>
                                </>
                              );
                            })}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* View Candidate end*/}
      {/* View Account start*/}
      <div
        class="modal fade commonModal editEducationModal candidateOnboarding"
        id="adminUserFirm"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0"></div>
            <div class="modal-body pt-0 px-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setCurrModalPreviewData({})}
              ></button>
              <div className="commonModalContent px-4">
                <h3 className="ModalHeading">Company Information</h3>
                <div className="teamInfo text-start">
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <span>Firm Name:</span> {currModalPreviewData.firmName}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Admin:</span> {currModalPreviewData.admin}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Address:</span> {currModalPreviewData.address}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Website Link:</span>{" "}
                        {currModalPreviewData.websiteLink}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Created At:</span>{" "}
                        {currModalPreviewData.createdAt
                          ? new Date(
                              currModalPreviewData.createdAt
                            ).toLocaleDateString()
                          : new Date(
                              currModalPreviewData.createdOn
                            ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* View Account end*/}
      {/* View Job start*/}
      <div
        class="modal fade commonModal editEducationModal candidateOnboarding"
        id="adminUserJob"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0"></div>
            <div class="modal-body pt-0 px-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setCurrModalPreviewData({})}
              ></button>
              <div className="commonModalContent px-4">
                <h3 className="ModalHeading">Job Information</h3>
                <div className="teamInfo text-start">
                  <div className="row">
                    <div className="col-lg-6">
                      <p>
                        <span>Title:</span> {currModalPreviewData.title}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Job Type:</span> {currModalPreviewData.jobType}
                      </p>
                    </div>
                    {currModalPreviewData.location && (
                      <div className="col-lg-6">
                        <p>
                          <span>Location:</span> {currModalPreviewData.location}
                        </p>
                      </div>
                    )}
                    <div className="col-lg-6">
                      <p>
                        <span>Salary:</span> {currModalPreviewData.salary}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Position Type:</span>{" "}
                        {currModalPreviewData.positionType}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Time Period:</span>{" "}
                        {currModalPreviewData.timeperiod}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Key Skills:</span>{" "}
                      </p>
                      <ul className="skillListing">
                        {currModalPreviewData.keySkills &&
                          currModalPreviewData.keySkills.length > 0 &&
                          currModalPreviewData.keySkills.map((item) => {
                            return <li key={item}>{item}</li>;
                          })}
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Created At:</span>{" "}
                        {currModalPreviewData.createdAt
                          ? new Date(
                              currModalPreviewData.createdAt
                            ).toLocaleDateString()
                          : new Date(
                              currModalPreviewData.createdOn
                            ).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        <span>Posted by:</span>{" "}
                        {currModalPreviewData.firmId &&
                          currModalPreviewData.firmId.firmName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* View Job end*/}
      {/* Delete Job Modal start*/}
      <div
        class="modal fade commonModal addEducationModal"
        id="deleteJobModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setDeleteJobId("")}
              ></button>
            </div>
            <div class="modal-body pt-0">
              <div className="commonModalContent px-0">
                <h3 className="ModalHeading">Delete Job</h3>
                <img className="deleteJobImg" src={iconModalDelete} />
                <p>
                  Are you sure you want to delete this <br /> Job ?
                </p>

                <div className="formBtns">
                  <button
                    className="btn cancelBtn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setDeleteJobId("")}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn inviteBtn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={onJobDeleteHandler}
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Job Modal end*/}
    </div>
  );
}

export default AdminUserModal;
