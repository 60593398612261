import { useNavigate } from "react-router-dom";
import rightArrow from "../assets/imgs/rightarrows.svg";
import ResetGif from "../assets/imgs/sucess-reset.gif";

function ResetSuccessful() {
  let navigate = useNavigate();
  return (
    <>
      <div className="successpasswordouter">
        <div className="successpasswordsection">
          <div className="container">
            <div>
              <img className="topImg" src={ResetGif} />
              <h1>Successful password reset!</h1>
              <p>
                You can now use your new password to log in to your account!
              </p>
              <a
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login <img src={rightArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetSuccessful;
