import { useEffect, useState } from "react";
import RecruiterModal from "../components/modals/recruiterModal";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import { useNavigate } from "react-router-dom";

const OnBoardingProcessRecruiter = () => {
  // //Constant
  const navigate = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let defaultForm = {
    accountId: userInfo._id,
    firmName: "",
    designation: "",
  };
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  // //useState
  const [form, setForm] = useState({});
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  //header image
  const [previewImage, setPreviewImage] = useState({
    headshotImage: "",
  });
  const [headshotImage, setHeadshotImage] = useState("");
  const [isHeadshotImageDeleted, setIsHeadshotImageDeleted] = useState(false);

  //for company logo
  const [previewImageCompany, setPreviewImageCompany] = useState({
    companyImage: "",
  });
  const [companyImage, setCompanyImage] = useState("");
  const [isCompanyImageDeleted, setIsCompanyImageDeleted] = useState(false);

  //function
  useEffect(() => {
    const locationModal = new window.bootstrap.Modal(
      document.getElementById("setCompanyModal")
    );
    locationModal.show();
  }, []);
  // useEffect(() => {
  // getRecruiterDetails();
  // }, [isProfileUpdated]);

  // async function getRecruiterDetails() {
  //   try {
  //     const response = await apiGetMethod(allApiUrl.profile, "", header);
  //     if (response.status === 200) {
  //       // setAccountsDetails(response.data.data);
  //     }
  //   } catch (err) {
  //     //  toastMessage('error',err.data.message)
  //     console.log("err", err.data.message);
  //   }
  // }
  async function onSubmit(e, nextModal, formData, field) {
    e.preventDefault();
    try {
      let updatedData = {
        accountId: userInfo._id,
        [field]: form[field],
      };
      if (field == "firmName") {
        updatedData["employerProfileId"] = form.employerProfileId;
      }
      let res = await apiPutMethod(
        allApiUrl.updateprofile,
        updatedData,
        header
      );
      if (res.status == 200) {
      }
    } catch (err) {
      console.log("err", err.message);
    }
  }
  async function onCompanyHandler(value) {
    try {
      const response = await apiPostMethod(
        allApiUrl.searchFirm,
        { text: value },
        header
      );
      if (response.status === 200) {
        let data = [];
        response.data.data.map((item) => {
          data.push({ firmName: item.firmName, firmId: item._id });
        });
        setCompanyOptions(data);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  let props = {
    form,
    setForm,
    onSubmit,
    onCompanyHandler,
    companyOptions,
    setCompanyOptions,
    isCompanySelected,
    setIsCompanySelected,
    userInfo,
    header,
    previewImageCompany,
    setPreviewImageCompany,
    companyImage,
    setCompanyImage,
    isCompanyImageDeleted,
    setIsCompanyImageDeleted,
    previewImage,
    setPreviewImage,
    headshotImage,
    setHeadshotImage,
    isHeadshotImageDeleted,
    setIsHeadshotImageDeleted,
  };
  return (
    <>
      <RecruiterModal {...props} />
    </>
  );
};
export default OnBoardingProcessRecruiter;
