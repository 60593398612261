import editLight from "../../../assets/imgs/editLight.svg";
import viewIcon from "../../../assets/imgs/viewIcon.svg";
const CandidateTable = (props) => {
  return (
    <tbody>
      {props.userData &&
        props.userData.length > 0 &&
        props.userData.map((item, index) => {
          return (
            <tr>
              <td>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    checked={props.csvData.some(
                      (csvItem) => csvItem.id === item._id
                    )}
                    type="checkbox"
                    onClick={() => {
                      props.setCsvData((prev) => {
                        const existingIndex = prev.findIndex(
                          (csvItem) => csvItem.id === item._id
                        );
                        if (existingIndex !== -1) {
                          const updatedData = [
                            ...prev.slice(0, existingIndex),
                            ...prev.slice(existingIndex + 1),
                          ];
                          return updatedData;
                        } else {
                          let updatedItem = {
                            id: item._id,
                            "Email ": item.email,
                            "Qualification ": item.qualification.length,
                            "Work Experience": item.workExperience.length,
                            "Skills ": item.skills.length,
                            "Custom Value Prompt":
                              item.customValuePrompt.length,
                            "Created At": new Date(
                              item.createdAt
                            ).toLocaleDateString(),
                          };
                          return [...prev, updatedItem];
                        }
                      });
                    }}
                  />
                </div>
              </td>
              <td>{index + 1}</td>
              <td>{item.email}</td>
              <td>{item?.qualification?.length}</td>
              <td>{item?.workExperience?.length}</td>
              <td>{item?.skills?.length}</td>
              <td>{item?.customValuePrompt?.length}</td>
              <td>{new Date(item.createdAt).toLocaleDateString()}</td>

              <td>
                <div className="actionBtns">
                  {/* <button className="btn">
                    <img src={editLight} />
                  </button> */}
                  <button
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#adminUserCandidate"
                    onClick={() => props.setCurrModalPreviewData(item)}
                  >
                    <img src={viewIcon} />
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};
export default CandidateTable;
