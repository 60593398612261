import Sidebar from "./adminSidebar";
import Topbar from "./adminTopbar";
import Linkedin from "../../assets/imgs/linkedin2.svg";

//
// import Linkedin from "../../assets/imgs/linkedin2.svg";
import Location from "../../assets/imgs/location.svg";
import phonelogowhite from "../../assets/imgs/phonelogowhite.svg";
import usericon from "../../assets/imgs/usericon.svg";
import jobpositionimg from "../../assets/imgs/jobPositionImg.svg";
import locationicongreen from "../../assets/imgs/locationIconGreen.svg";
import star from "../../assets/imgs/star.svg";
import pauljason from "../../assets/imgs/pauljason.svg";
import calender from "../../assets/imgs/calender.svg";
import inviteRecruiter from "../../assets/imgs/inviteRecruiter.svg";
import timer from "../../assets/imgs/timer.svg";
import website from "../../assets/imgs/website.svg";
import dummyImg from "../../assets/imgs/dummyImg.jpg";
import uploadIcon from "../../assets/imgs/uploadIcon.svg";
import whiteCross from "../../assets/imgs/whiteCross.svg";
import addWhiteIcon from "../../assets/imgs/addWhiteIcon.svg";
import { useEffect, useState } from "react";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoutes";
import toastMessages from "../../components/toastMessage";
import { toast, useToast } from "react-toastify";
import contacticon from "../../assets/imgs/contacticon.svg";
import location2 from "../../assets/imgs/location2.svg";
import phonelogo from "../../assets/imgs/phonelogo.svg";
import addIcon from "../../assets/imgs/addIcon.svg";
import editIcon from "../../assets/imgs/editIcon.svg";
import iconDelete from "../../assets/imgs/iconDelete.svg";
import peoplesIcon from "../../assets/imgs/peoplesIcon.svg";
import briefcase from "../../assets/imgs/briefcase.svg";
import { useNavigate } from "react-router-dom";
import FullPageLoader from "../../components/fullPageLoader";
import DOMPurify from "dompurify";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TimeAgo from "../../components/daysAgoComponent";
import { calculateTimeAgo } from "../../utils/helper";
import { Greeting } from "../my-profile";
import topArrow from "../../assets/imgs/right.svg";
import newUser from "../../assets/imgs/newUserIcon.svg";
import newJobPost from "../../assets/imgs/newJobPost.svg";
import totaljobPosted from "../../assets/imgs/totaljobPosted.svg";

// import Sidebar from '../components/sidebar';
// import Topbar from '../components/topBar';
import Footer from "../../components/footer";
import Modals from "../modal";
// import ReactApexChart from 'react-apexcharts';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import Approve from "../../assets/imgs/approve.svg";
import Reject from "../../assets/imgs/reject.svg";
import editRoundIcon from "../../assets/imgs/editRoundIcon.svg";

// Images
import applicationInviteSend from "../../assets/imgs/applicationSend.svg";
import newContactsMade from "../../assets/imgs/newContractMade.svg";
import InterviewSchedule from "../../assets/imgs/interviewScheduled.svg";
import jobOfferSend from "../../assets/imgs/jobOfferSend.svg";
import joOfferAccepted from "../../assets/imgs/jobOfferAcceptedO.svg";
import Rectangle_1394 from "../../assets/imgs/Rectangle_1394.svg";
import offerAcceptIcon from "../../assets/imgs/offerAcceptIcon.svg";
import { AdminLineChart } from "./adminDashboard";
//
// line chart
const data = [
  { name: "Jan", value: 30 },
  { name: "Feb", value: 40 },
  { name: "Mar", value: 25 },
  { name: "Apr", value: 50 },
  { name: "May", value: 49 },
  { name: "Jun", value: 21 },
  { name: "Jul", value: 70 },
  { name: "Aug", value: 51 },
  { name: "Sep", value: 60 },
  { name: "Oct", value: 90 },
  { name: "Nov", value: 45 },
  { name: "Dec", value: 30 },
];

function AdminAnalytics() {
  let navigate = useNavigate();
  const [analyticsData, setAnalyticsData] = useState({});
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDashboardData();
    getUserData();
  }, []);
  const getUserData = async () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      let data = {
        currentPage: 1,
        limit: 5,
        type: "accounts",
      };
      const response = await apiPostMethod(allApiUrl.getAllUsers, data, header);
      if (response.status == 200) {
        setUserData(response.data.data);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.data.message);
    }
  };
  const getDashboardData = async () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.adminAnalytics, "", header);
      if (response.status == 200) {
        setAnalyticsData(response.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.data.message);
    }
  };
  if (isLoading) return <FullPageLoader />;
  // {"users":145,"jobs":18,"candidate":69,"recruiter":54,"uncategorised":22}
  return (
    <>
      <div className="mainWrapper">
        <Sidebar activePage="adminAnalytics" />
        <Topbar />
        <section className="analytics sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6">
                <div className="commonHeading">
                  <h2>Analytics</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="invitation commonRadius8 mb-5">
                <div className="row">
                  <div className="col-xl-3 col-lg-6">
                    <div className="invitationCard commonRadius8">
                      <div className="inviteHeader">
                        <span>
                          <img src={newUser} />
                        </span>

                        <p>Total Users</p>
                      </div>
                      <div className="inviteBody">
                        <h4 className="green">{analyticsData.users}</h4>
                        <p>
                          Today <br />
                          <span>
                            {analyticsData.todayAccounts}
                            {analyticsData.todayAccounts !== 0 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_1510_281)">
                                  <path
                                    d="M5.73444 0.859734L1.94278 4.6514C1.74544 4.85572 1.63624 5.12937 1.63871 5.41342C1.64118 5.69747 1.75511 5.96918 1.95597 6.17004C2.15683 6.3709 2.42854 6.48483 2.71259 6.4873C2.99664 6.48977 3.27029 6.38057 3.47461 6.18323L5.41703 4.24082L5.41703 11.3757C5.41703 11.663 5.53116 11.9385 5.73433 12.1417C5.93749 12.3448 6.21304 12.459 6.50036 12.459C6.78768 12.459 7.06323 12.3448 7.26639 12.1417C7.46956 11.9385 7.58369 11.663 7.58369 11.3757L7.58369 4.24082L9.52611 6.18323C9.62604 6.2867 9.74558 6.36923 9.87775 6.42601C10.0099 6.48279 10.1521 6.51267 10.2959 6.51392C10.4398 6.51517 10.5824 6.48776 10.7156 6.43329C10.8487 6.37882 10.9697 6.29838 11.0714 6.19666C11.1731 6.09494 11.2535 5.97399 11.308 5.84085C11.3625 5.70771 11.3899 5.56506 11.3886 5.42122C11.3874 5.27737 11.3575 5.13522 11.3007 5.00305C11.2439 4.87087 11.1614 4.75133 11.0579 4.6514L7.26628 0.859734C7.06312 0.656641 6.78762 0.542549 6.50036 0.542549C6.2131 0.542549 5.9376 0.656641 5.73444 0.859734Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1510_281">
                                    <rect
                                      width="13"
                                      height="13"
                                      fill="white"
                                      transform="translate(0 13) rotate(-90)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-6">
                    <div className="invitationCard commonRadius8">
                      <div className="inviteHeader">
                        <span>
                          <img src={totaljobPosted} alt="" />
                        </span>

                        <p>Total Job Post</p>
                      </div>

                      <div className="inviteBody">
                        <h4 className="green">{analyticsData.jobs}</h4>
                        <p>
                          Today <br />
                          <span>
                            {analyticsData.todayJobs}
                            {analyticsData.todayJobs !== 0 && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_1510_281)">
                                  <path
                                    d="M5.73444 0.859734L1.94278 4.6514C1.74544 4.85572 1.63624 5.12937 1.63871 5.41342C1.64118 5.69747 1.75511 5.96918 1.95597 6.17004C2.15683 6.3709 2.42854 6.48483 2.71259 6.4873C2.99664 6.48977 3.27029 6.38057 3.47461 6.18323L5.41703 4.24082L5.41703 11.3757C5.41703 11.663 5.53116 11.9385 5.73433 12.1417C5.93749 12.3448 6.21304 12.459 6.50036 12.459C6.78768 12.459 7.06323 12.3448 7.26639 12.1417C7.46956 11.9385 7.58369 11.663 7.58369 11.3757L7.58369 4.24082L9.52611 6.18323C9.62604 6.2867 9.74558 6.36923 9.87775 6.42601C10.0099 6.48279 10.1521 6.51267 10.2959 6.51392C10.4398 6.51517 10.5824 6.48776 10.7156 6.43329C10.8487 6.37882 10.9697 6.29838 11.0714 6.19666C11.1731 6.09494 11.2535 5.97399 11.308 5.84085C11.3625 5.70771 11.3899 5.56506 11.3886 5.42122C11.3874 5.27737 11.3575 5.13522 11.3007 5.00305C11.2439 4.87087 11.1614 4.75133 11.0579 4.6514L7.26628 0.859734C7.06312 0.656641 6.78762 0.542549 6.50036 0.542549C6.2131 0.542549 5.9376 0.656641 5.73444 0.859734Z"
                                    fill="white"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_1510_281">
                                    <rect
                                      width="13"
                                      height="13"
                                      fill="white"
                                      transform="translate(0 13) rotate(-90)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                            )}

                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1510_289)">
                                <path
                                  d="M5.73444 12.1403L1.94278 8.3486C1.74544 8.14428 1.63624 7.87063 1.63871 7.58658C1.64118 7.30253 1.75511 7.03082 1.95597 6.82996C2.15683 6.6291 2.42854 6.51517 2.71259 6.5127C2.99664 6.51023 3.27029 6.61943 3.47461 6.81677L5.41703 8.75918L5.41703 1.62435C5.41703 1.33703 5.53116 1.06148 5.73433 0.858317C5.93749 0.655152 6.21304 0.541016 6.50036 0.541016C6.78768 0.541016 7.06323 0.655152 7.26639 0.858317C7.46956 1.06148 7.58369 1.33703 7.58369 1.62435L7.58369 8.75918L9.52611 6.81677C9.62604 6.7133 9.74558 6.63077 9.87775 6.57399C10.0099 6.51721 10.1521 6.48733 10.2959 6.48608C10.4398 6.48483 10.5824 6.51224 10.7156 6.56671C10.8487 6.62118 10.9697 6.70162 11.0714 6.80334C11.1731 6.90506 11.2535 7.02601 11.308 7.15915C11.3625 7.29229 11.3899 7.43494 11.3886 7.57878C11.3874 7.72263 11.3575 7.86478 11.3007 7.99695C11.2439 8.12913 11.1614 8.24867 11.0579 8.3486L7.26628 12.1403C7.06312 12.3434 6.78762 12.4575 6.50036 12.4575C6.2131 12.4575 5.9376 12.3434 5.73444 12.1403Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1510_289">
                                  <rect
                                    width="13"
                                    height="13"
                                    fill="white"
                                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
                                  />
                                </clipPath>
                              </defs>
                            </svg> */}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12">
                    <div className="invitationCard commonRadius8">
                      <div className="inviteHeader employerHeader">
                        <h3 className="white">New Users</h3>
                        <div className="dropdown">
                          <button
                            className="btn viewAllButton"
                            type="button"
                            // data-bs-toggle="dropdown"
                            // aria-expanded="false"
                            onClick={() => navigate("/adminUsers")}
                          >
                            View All
                          </button>
                          {/* <button
                            className="btn dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            June 2023
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                          </ul> */}
                        </div>
                      </div>

                      {/* <div className="card">
                        <div className="card-header">
                        </div>
                      </div> */}

                      {/* <div className="inviteHeader">
                        <h3 style={{ color: "white" }}>Manage Team</h3>
                      </div> */}
                      <div className="inviteBody">
                        <div className="employeeCardOuter">
                          {userData.splice(0, 2).map((item) => {
                            return (
                              <div className="newEmployeeCard">
                                <div className="candidateImg">
                                  <img src={dummyImg} alt="User Image" />
                                </div>
                                <div className="candidateuserleft2">
                                  <div>
                                    <h2>
                                      {item.firstName + " " + item.lastName}
                                      <span
                                        style={{ marginLeft: "12px" }}
                                      ></span>
                                      <TimeAgo timestamp={item.createdAt} />
                                      {/* <span>12 hours ago</span> */}
                                    </h2>
                                  </div>

                                  <p>email:{item.email}</p>
                                  {item.phoneNumber && (
                                    <p>phone Number:{item.phoneNumber}</p>
                                  )}
                                </div>
                              </div>
                            );
                          })}

                          {/* <div className="newEmployeeCard">
                            <div className="candidateImg">
                              <img src={dummyImg} alt="User Image" />
                            </div>
                            <div className="candidateuserleft2">
                              <div>
                                <h2>
                                  Alex Khan
                                  <span>12 hours ago</span>
                                </h2>
                              </div>

                              <p>
                                {
                                  "Lorem ipsum dolor sit amet consectetur. In euismod a neque volutpat..."
                                }
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-xl-12">
                <div class="card mb-5">
                  <div class="card-header">
                    <h3>New Users</h3>
                    <div className="dropdown">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        June 2023
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            June 2023
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            June 2023
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            June 2023
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card-body">
                    <div className="chart-container">
                      <LineChart width={1100} height={680} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="value"
                          stroke="#8884d8"
                        />
                      </LineChart>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <ReactApexChart options={options} series={series} type="line" height={350} /> */}
              <AdminLineChart />
              {false && (
                <div className="col-xl-4">
                  <div className="invitation commonRadius8 mb-5">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="white">Hiring Statistics</h3>
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            June 2023
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="hiringStatisticsBody">
                        <div className="hiringStatistics">
                          <div className="hiringStatisticsInner">
                            <img src={applicationInviteSend} />
                            <p>Application Invites Sent</p>
                          </div>
                          <h6 className="count">1,226</h6>
                        </div>

                        <div className="divider"></div>

                        <div className="hiringStatistics">
                          <div className="hiringStatisticsInner">
                            <img src={newContactsMade} />
                            <p>New Contacts Made</p>
                          </div>
                          <h6 className="count">751</h6>
                        </div>

                        <div className="divider"></div>

                        <div className="hiringStatistics">
                          <div className="hiringStatisticsInner">
                            <img src={InterviewSchedule} />
                            <p>Interviews Scheduled</p>
                          </div>
                          <h6 className="count">102</h6>
                        </div>

                        <div className="divider"></div>

                        <div className="hiringStatistics">
                          <div className="hiringStatisticsInner">
                            <img src={jobOfferSend} />
                            <p>Job Offers Sent</p>
                          </div>
                          <h6 className="count">51</h6>
                        </div>

                        <div className="divider"></div>

                        <div className="hiringStatistics">
                          <div className="hiringStatisticsInner">
                            <img src={offerAcceptIcon} />
                            <p>Job Offer Accepted</p>
                          </div>
                          <h6 className="__count">21</h6>
                        </div>
                        <div className="divider"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* <Footer /> */}
      </div>
    </>
    // <>
    //   <Sidebar />
    //   <Topbar />
    //   <div className="mainWrapper">
    //     <section className="profile sectionPadding">
    //       <div className="container-fluid">
    //         <div className="row">
    //           <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
    //             <div className="commonHeading candidateHeading">
    //               <h2>Dashboard</h2>
    //             </div>
    //           </div>
    //           {/* <div className="row">
    //             <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-8">
    //               <div className="commonHeading candidateHeading">
    //                 <div className="candidatemidleft">
    //                   <div className="candidatemidright">
    //                     <div className="adminTopRowSetting mb-3">
    //                       <img src={newUser} alt="" />
    //                       <div>
    //                         <h4>New Users</h4>
    //                         <span className="newUserData">124</span>
    //                       </div>
    //                     </div>
    //                     <div className="adminTopRowSetting mb-3">
    //                       <div>
    //                         <p>Today</p>
    //                         <span>
    //                           <small className="todayData">13</small>
    //                           <img src={topArrow} className="topArrow" />
    //                         </span>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="candidatemidleft">
    //                   <div className="candidatemidright">
    //                     <div className="adminTopRowSetting mb-3">
    //                       <img src={newJobPost} alt="" />
    //                       <div>
    //                         <h4>New Job Post</h4>
    //                         <span className="newUserData">31</span>
    //                       </div>
    //                     </div>
    //                     <div className="adminTopRowSetting mb-3">
    //                       <div>
    //                         <p>Today</p>
    //                         <span>
    //                           <small className="todayData">4</small>
    //                           <img src={topArrow} className="topArrow" />
    //                         </span>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-xxl-6 col-xl-4 col-lg-4 col-md-4">
    //               <div className="commonHeading candidateHeading">
    //                 <div className="candidatemidleft">
    //                   <div className="candidatemidright">
    //                     <h4>Manage Team</h4>
    //                     <div className="candidatemidright2 mb-3">
    //                       <div className="imageGroupTeamMember">
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                         <img src={dummyImg} className="manageTeamImage" />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div> */}
    //           <div className="row">
    //             <div className="col-xl-8">
    //               <div class="card mb-5">
    //                 <div class="card-header">
    //                   <h3>Impressions</h3>
    //                   <div className="dropdown">
    //                     <button
    //                       className="btn dropdown-toggle"
    //                       type="button"
    //                       data-bs-toggle="dropdown"
    //                       aria-expanded="false"
    //                     >
    //                       June 2023
    //                     </button>
    //                     <ul className="dropdown-menu">
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                     </ul>
    //                   </div>
    //                 </div>
    //                 <div class="card-body">
    //                   <div className="chart-container">
    //                     {/* <ReactApexChart options={options} series={series} type="line" height={350} /> */}

    //                     <LineChart width={600} height={350} data={data}>
    //                       <CartesianGrid strokeDasharray="3 3" />
    //                       <XAxis dataKey="name" />
    //                       <YAxis />
    //                       <Tooltip />
    //                       <Legend />
    //                       <Line
    //                         type="monotone"
    //                         dataKey="value"
    //                         stroke="#8884d8"
    //                       />
    //                     </LineChart>
    //                   </div>
    //                 </div>
    //               </div>

    //               <div className="row">
    //                 <div className="col-lg-7">
    //                   <div className="invitation commonRadius8 mb-5">
    //                     <h3 className="white">Invitations</h3>
    //                     <div className="row">
    //                       <div className="col-lg-6">
    //                         <div className="invitationCard commonRadius8">
    //                           <div className="inviteHeader">
    //                             <span>
    //                               <svg
    //                                 xmlns="http://www.w3.org/2000/svg"
    //                                 width="20"
    //                                 height="20"
    //                                 viewBox="0 0 20 20"
    //                                 fill="none"
    //                               >
    //                                 <path
    //                                   d="M3.50977 8.04256L4.61281 8.98602V7.25586L3.50977 8.04256Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M3.33301 8.63086V16.8169L8.12596 12.7239L3.33301 8.63086Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M9.98035 3.43521L9.04102 4.10405H11.5635L10.6113 3.43356C10.422 3.3002 10.169 3.30085 9.98035 3.43521Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M5.17188 5.21V9.46541L10.3316 13.872L15.4913 9.46541V5.21C15.4913 4.90849 15.2468 4.66406 14.9453 4.66406H5.71781C5.4163 4.66406 5.17188 4.90849 5.17188 5.21ZM12.1177 9.89654H8.54822C8.39144 9.89654 8.26825 9.77057 8.26825 9.6166C8.26825 9.45982 8.39144 9.33663 8.54822 9.33663H12.1177C12.2717 9.33663 12.3977 9.45982 12.3977 9.6166C12.3977 9.77057 12.2717 9.89654 12.1177 9.89654ZM7.05882 5.84833H13.6043C13.7611 5.84833 13.8843 5.97428 13.8843 6.12828C13.8843 6.28225 13.7611 6.40824 13.6043 6.40824H7.05882C6.90485 6.40824 6.77885 6.28225 6.77885 6.12828C6.77885 5.97428 6.90485 5.84833 7.05882 5.84833ZM7.05882 7.59247H13.6043C13.7611 7.59247 13.8843 7.71847 13.8843 7.87244C13.8843 8.02641 13.7611 8.1524 13.6043 8.1524H7.05882C6.90485 8.1524 6.77885 8.02641 6.77885 7.87244C6.77885 7.71847 6.90485 7.59247 7.05882 7.59247Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M12.1068 13.0938L10.5138 14.4544C10.4634 14.4991 10.3962 14.5215 10.3318 14.5215C10.2674 14.5215 10.203 14.4991 10.1498 14.4544L8.55686 13.0938L3.81152 17.1448H16.8521L12.1068 13.0938Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M16.0518 8.98653L17.1548 8.04586L16.0518 7.26758V8.98653Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M17.331 16.8169V8.63086L12.5381 12.7239L17.331 16.8169Z"
    //                                   fill="white"
    //                                 />
    //                               </svg>
    //                             </span>

    //                             <p>Accepted Invitations </p>
    //                           </div>

    //                           <div className="inviteBody">
    //                             <h4 className="green">124</h4>
    //                             <p>
    //                               This Week <br />
    //                               <span>
    //                                 13
    //                                 <svg
    //                                   xmlns="http://www.w3.org/2000/svg"
    //                                   width="13"
    //                                   height="13"
    //                                   viewBox="0 0 13 13"
    //                                   fill="none"
    //                                 >
    //                                   <g clip-path="url(#clip0_1510_281)">
    //                                     <path
    //                                       d="M5.73444 0.859734L1.94278 4.6514C1.74544 4.85572 1.63624 5.12937 1.63871 5.41342C1.64118 5.69747 1.75511 5.96918 1.95597 6.17004C2.15683 6.3709 2.42854 6.48483 2.71259 6.4873C2.99664 6.48977 3.27029 6.38057 3.47461 6.18323L5.41703 4.24082L5.41703 11.3757C5.41703 11.663 5.53116 11.9385 5.73433 12.1417C5.93749 12.3448 6.21304 12.459 6.50036 12.459C6.78768 12.459 7.06323 12.3448 7.26639 12.1417C7.46956 11.9385 7.58369 11.663 7.58369 11.3757L7.58369 4.24082L9.52611 6.18323C9.62604 6.2867 9.74558 6.36923 9.87775 6.42601C10.0099 6.48279 10.1521 6.51267 10.2959 6.51392C10.4398 6.51517 10.5824 6.48776 10.7156 6.43329C10.8487 6.37882 10.9697 6.29838 11.0714 6.19666C11.1731 6.09494 11.2535 5.97399 11.308 5.84085C11.3625 5.70771 11.3899 5.56506 11.3886 5.42122C11.3874 5.27737 11.3575 5.13522 11.3007 5.00305C11.2439 4.87087 11.1614 4.75133 11.0579 4.6514L7.26628 0.859734C7.06312 0.656641 6.78762 0.542549 6.50036 0.542549C6.2131 0.542549 5.9376 0.656641 5.73444 0.859734Z"
    //                                       fill="white"
    //                                     />
    //                                   </g>
    //                                   <defs>
    //                                     <clipPath id="clip0_1510_281">
    //                                       <rect
    //                                         width="13"
    //                                         height="13"
    //                                         fill="white"
    //                                         transform="translate(0 13) rotate(-90)"
    //                                       />
    //                                     </clipPath>
    //                                   </defs>
    //                                 </svg>
    //                               </span>
    //                             </p>
    //                           </div>
    //                         </div>
    //                       </div>
    //                       <div className="col-lg-6">
    //                         <div className="invitationCard commonRadius8">
    //                           <div className="inviteHeader">
    //                             <span>
    //                               <svg
    //                                 xmlns="http://www.w3.org/2000/svg"
    //                                 width="20"
    //                                 height="20"
    //                                 viewBox="0 0 20 20"
    //                                 fill="none"
    //                               >
    //                                 <path
    //                                   d="M3.50977 8.04256L4.61281 8.98602V7.25586L3.50977 8.04256Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M3.33301 8.63086V16.8169L8.12596 12.7239L3.33301 8.63086Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M9.98035 3.43521L9.04102 4.10405H11.5635L10.6113 3.43356C10.422 3.3002 10.169 3.30085 9.98035 3.43521Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M5.17188 5.21V9.46541L10.3316 13.872L15.4913 9.46541V5.21C15.4913 4.90849 15.2468 4.66406 14.9453 4.66406H5.71781C5.4163 4.66406 5.17188 4.90849 5.17188 5.21ZM12.1177 9.89654H8.54822C8.39144 9.89654 8.26825 9.77057 8.26825 9.6166C8.26825 9.45982 8.39144 9.33663 8.54822 9.33663H12.1177C12.2717 9.33663 12.3977 9.45982 12.3977 9.6166C12.3977 9.77057 12.2717 9.89654 12.1177 9.89654ZM7.05882 5.84833H13.6043C13.7611 5.84833 13.8843 5.97428 13.8843 6.12828C13.8843 6.28225 13.7611 6.40824 13.6043 6.40824H7.05882C6.90485 6.40824 6.77885 6.28225 6.77885 6.12828C6.77885 5.97428 6.90485 5.84833 7.05882 5.84833ZM7.05882 7.59247H13.6043C13.7611 7.59247 13.8843 7.71847 13.8843 7.87244C13.8843 8.02641 13.7611 8.1524 13.6043 8.1524H7.05882C6.90485 8.1524 6.77885 8.02641 6.77885 7.87244C6.77885 7.71847 6.90485 7.59247 7.05882 7.59247Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M12.1068 13.0938L10.5138 14.4544C10.4634 14.4991 10.3962 14.5215 10.3318 14.5215C10.2674 14.5215 10.203 14.4991 10.1498 14.4544L8.55686 13.0938L3.81152 17.1448H16.8521L12.1068 13.0938Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M16.0518 8.98653L17.1548 8.04586L16.0518 7.26758V8.98653Z"
    //                                   fill="white"
    //                                 />
    //                                 <path
    //                                   d="M17.331 16.8169V8.63086L12.5381 12.7239L17.331 16.8169Z"
    //                                   fill="white"
    //                                 />
    //                               </svg>
    //                             </span>

    //                             <p>Declined Invitations</p>
    //                           </div>

    //                           <div className="inviteBody">
    //                             <h4 className="green">104</h4>
    //                             <p>
    //                               This Week <br />
    //                               <span>
    //                                 10
    //                                 <svg
    //                                   xmlns="http://www.w3.org/2000/svg"
    //                                   width="13"
    //                                   height="13"
    //                                   viewBox="0 0 13 13"
    //                                   fill="none"
    //                                 >
    //                                   <g clip-path="url(#clip0_1510_289)">
    //                                     <path
    //                                       d="M5.73444 12.1403L1.94278 8.3486C1.74544 8.14428 1.63624 7.87063 1.63871 7.58658C1.64118 7.30253 1.75511 7.03082 1.95597 6.82996C2.15683 6.6291 2.42854 6.51517 2.71259 6.5127C2.99664 6.51023 3.27029 6.61943 3.47461 6.81677L5.41703 8.75918L5.41703 1.62435C5.41703 1.33703 5.53116 1.06148 5.73433 0.858317C5.93749 0.655152 6.21304 0.541016 6.50036 0.541016C6.78768 0.541016 7.06323 0.655152 7.26639 0.858317C7.46956 1.06148 7.58369 1.33703 7.58369 1.62435L7.58369 8.75918L9.52611 6.81677C9.62604 6.7133 9.74558 6.63077 9.87775 6.57399C10.0099 6.51721 10.1521 6.48733 10.2959 6.48608C10.4398 6.48483 10.5824 6.51224 10.7156 6.56671C10.8487 6.62118 10.9697 6.70162 11.0714 6.80334C11.1731 6.90506 11.2535 7.02601 11.308 7.15915C11.3625 7.29229 11.3899 7.43494 11.3886 7.57878C11.3874 7.72263 11.3575 7.86478 11.3007 7.99695C11.2439 8.12913 11.1614 8.24867 11.0579 8.3486L7.26628 12.1403C7.06312 12.3434 6.78762 12.4575 6.50036 12.4575C6.2131 12.4575 5.9376 12.3434 5.73444 12.1403Z"
    //                                       fill="white"
    //                                     />
    //                                   </g>
    //                                   <defs>
    //                                     <clipPath id="clip0_1510_289">
    //                                       <rect
    //                                         width="13"
    //                                         height="13"
    //                                         fill="white"
    //                                         transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
    //                                       />
    //                                     </clipPath>
    //                                   </defs>
    //                                 </svg>
    //                               </span>
    //                             </p>
    //                           </div>
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-lg-5">
    //                   <div className="invitation commonRadius8 mb-5">
    //                     <h3 className="white">Job Listing</h3>

    //                     <div className="listing commonRadius8">
    //                       <div>
    //                         <span className="green">
    //                           <img src={Approve} />
    //                         </span>
    //                         <h5>19</h5>
    //                       </div>

    //                       <p className="green">Accepted</p>
    //                     </div>

    //                     <div className="listing commonRadius8">
    //                       <div>
    //                         <span className="red">
    //                           <img src={Reject} />
    //                         </span>
    //                         <h5>13</h5>
    //                       </div>

    //                       <p className="red">Rejected</p>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>

    //               <div class="card mb-5">
    //                 <div class="card-header">
    //                   <h3>Job Listing Impressions</h3>
    //                   <div className="dropdown">
    //                     <button
    //                       className="btn dropdown-toggle"
    //                       type="button"
    //                       data-bs-toggle="dropdown"
    //                       aria-expanded="false"
    //                     >
    //                       June 2023
    //                     </button>
    //                     <ul className="dropdown-menu">
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                     </ul>
    //                   </div>
    //                 </div>
    //                 <div class="card-body">
    //                   <div className="chart-container">
    //                     {/* <ReactApexChart options={options} series={series} type="line" height={350} /> */}
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-xl-4">
    //               <div class="card mb-5">
    //                 <div class="card-header">
    //                   <h3>Impressions</h3>
    //                   <div className="dropdown">
    //                     <button
    //                       className="btn dropdown-toggle"
    //                       type="button"
    //                       data-bs-toggle="dropdown"
    //                       aria-expanded="false"
    //                     >
    //                       June 2023
    //                     </button>
    //                     <ul className="dropdown-menu">
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                       <li>
    //                         <a className="dropdown-item" href="#">
    //                           June 2023
    //                         </a>
    //                       </li>
    //                     </ul>
    //                   </div>
    //                 </div>
    //                 <div class="card-body">
    //                   <div className="chart-container">
    //                     {/* <ReactApexChart options={options} series={series} type="line" height={350} /> */}
    //                   </div>
    //                 </div>
    //               </div>

    //               <div className="invitation commonRadius8 mb-5">
    //                 <h3 className="white">Information</h3>

    //                 <div className="listing commonRadius8">
    //                   <div>
    //                     <span className="green">
    //                       <img src={Approve} />
    //                     </span>
    //                     <h5>19</h5>
    //                   </div>

    //                   <p className="green">Accepted</p>
    //                 </div>

    //                 <div className="listing commonRadius8">
    //                   <div>
    //                     <span className="red">
    //                       <img src={Reject} />
    //                     </span>
    //                     <h5>13</h5>
    //                   </div>

    //                   <p className="red">Rejected</p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    // </>
  );
}
export default AdminAnalytics;
