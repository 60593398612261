import { useState } from "react";
// import { Button, Spinner } from "react-bootstrap";
import { CSVLink } from "react-csv";
import toastMessages from "../toastMessage";

export const ExportCsv = ({
  filename,
  csvData,
  setCsvData,
  setAllCheck,
  allCheck,
}) => {
  const [isExported, setIsExported] = useState(false);
  return (
    <div className="exportBtn">
      <p>
        {csvData.length} {csvData.length == 1 ? "File" : "Files"} Selected
      </p>
      <CSVLink
        onClick={() => {
          toastMessages("success", "Report Downloaded Successfully");
          setTimeout(() => {
            setCsvData([]);
            setAllCheck({
              accounts: false,
              candidates: false,
              companies: false,
              jobs: false,
            });
          }, 1000);
        }}
        className=""
        filename={`${filename}(${new Date().toLocaleDateString()}).csv`}
        data={csvData}
      >
        <i className="fa-solid fa-cloud-arrow-down"></i> Export
      </CSVLink>
    </div>
  );
};
