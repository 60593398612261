import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/imgs/HireBIRD.png";
import { useState } from "react";
import { apiPostMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import queryString from "query-string";
import toastMessages from "../components/toastMessage";

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  let { token } = queryString.parse(location.search);
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });
  const [viewPassword, setViewPassword] = useState({
    new: true,
    confirm: true,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  };
  async function onSubmit(e) {
    e.preventDefault();
    if (!form.password)
      return toastMessages("error", "Please enter new password");
    if (!form.confirmPassword)
      return toastMessages("error", "Please enter confirm password");
    if (form.password !== form.confirmPassword) {
      toastMessages(
        "error",
        "Confirm Password and New Password does not match.Please check again."
      );
    } else {
      let header = {
        Authorization: `Bearer ${token}`,
      };
      try {
        const response = await apiPostMethod(
          allApiUrl.changepassword,
          form,
          header
        );
        if (response.status == 200) {
          navigate("/reset-successful");
          toastMessages("success", response.data.message);
        }
      } catch (err) {
        console.log("err", err.data.message);
        toastMessages("error", err.data.message);
      }
    }
  }
  return (
    <>
      <section className="formSection roleSelectorMain">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="formSection__content">
                <div className="text-center mb-4">
                  <a href="#">
                    <img className="logoImg" src={Logo} alt="HireBird Logo" />
                  </a>
                </div>
                <form onSubmit={onSubmit}>
                  <h2 className="mb-4">Reset Your Password</h2>

                  <div className="form-group">
                    <input
                      name="password"
                      type={`${!viewPassword.new ? "text" : "password"}`}
                      className="form-control"
                      placeholder="New Password"
                      value={form.password}
                      onChange={handleChange}
                      autocomplete="off"
                    />
                    <i
                      className={`toggle-password fa fa-fw fa-eye${
                        viewPassword.new ? "-slash" : ""
                      }`}
                      onClick={() =>
                        setViewPassword({
                          ...viewPassword,
                          new: !viewPassword.new,
                        })
                      }
                    ></i>
                    <span>
                      <i className="fa-solid fa-lock"></i>
                    </span>
                  </div>

                  <div className="form-group mb-2">
                    <input
                      type={`${!viewPassword.confirm ? "text" : "password"}`}
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Confirm New Password"
                      value={form.confirmPassword}
                      onChange={handleChange}
                      autocomplete="off"
                    />
                    <i
                      className={`toggle-password fa fa-fw fa-eye${
                        viewPassword.confirm ? "-slash" : ""
                      }`}
                      onClick={() =>
                        setViewPassword({
                          ...viewPassword,
                          confirm: !viewPassword.confirm,
                        })
                      }
                    ></i>
                    <span>
                      <i className="fa-solid fa-lock"></i>
                    </span>
                  </div>

                  <button
                    className="btn commonBtb"
                    disabled={!form.password && !form.confirmPassword}
                  >
                    Reset password <i className="fa-solid fa-angles-right"></i>
                  </button>
                  <p className="mt-3 mb-0">
                    <Link to="/login">Back to sign in</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
