import { useNavigate } from "react-router-dom";
import editLight from "../../../assets/imgs/editLight.svg";
import viewIcon from "../../../assets/imgs/viewIcon.svg";
import DeleteIcon from "../../../assets/imgs/deleteIcon.svg";
import AdminModal from "../../../components/modals/adminModal";
import deleteIcon from "../../../assets/imgs/iconDelete.svg";

const JobTable = (props) => {
  let navigate = useNavigate();
  return (
    <>
      <tbody>
        {props.userData &&
          props.userData.length > 0 &&
          props.userData.map((item, index) => {
            return (
              <tr>
                <td>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      checked={props.csvData.some(
                        (csvItem) => csvItem.id === item._id
                      )}
                      type="checkbox"
                      onClick={() => {
                        props.setCsvData((prev) => {
                          const existingIndex = prev.findIndex(
                            (csvItem) => csvItem.id === item._id
                          );
                          if (existingIndex !== -1) {
                            const updatedData = [
                              ...prev.slice(0, existingIndex),
                              ...prev.slice(existingIndex + 1),
                            ];
                            return updatedData;
                          } else {
                            let updatedItem = {
                              id: item._id,
                              "Title ": item.title,
                              "Job Type": item.jobType,
                              "Location ": item.location,
                              "Salary ": item.salary,
                              "Salary Upto": item.salryUpto,
                              "Posted By": item?.firmId?.firmName,
                              "Keys Skills": item.keySkills.map((item) => item),
                              "Time Period": item.timeperiod,
                              "Position Type": item.positionType,
                              "Created At": new Date(
                                item.createdAt
                              ).toLocaleDateString(),
                            };
                            return [...prev, updatedItem];
                          }
                        });
                      }}
                    />
                  </div>
                </td>
                <td>{index + 1}</td>
                <td>{item.title}</td>
                <td>{item.jobType}</td>
                <td>{item.location}</td>
                <td>{item.salary}</td>
                <td>{item?.firmId?.firmName}</td>
                <td>{item.positionType}</td>
                <td>{item.timeperiod}</td>
                <td>
                  <ul className="skillListing">
                    {item.keySkills &&
                      item.keySkills.length > 0 &&
                      item.keySkills.map((item) => {
                        return <li key={item}>{item}</li>;
                      })}
                  </ul>
                </td>
                <td>
                  {item.createdAt
                    ? new Date(item.createdAt).toLocaleDateString()
                    : new Date(item.postedOn).toLocaleDateString()}
                </td>
                <td>
                  <div className="actionBtns">
                    {/* <button
                      className="btn"
                      onClick={() =>
                        navigate(`/edit-job/${item._id}&admin=true`)
                      }
                      data-bs-target="#educationhistoryModal"
                      data-bs-toggle="modal"
                    >
                      <img src={editLight} />
                    </button> */}
                    <button
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#adminUserJob"
                      onClick={() => props.setCurrModalPreviewData(item)}
                    >
                      <img src={viewIcon} />
                    </button>
                    {/* <button
                      
                    > */}
                    <svg
                      cursor="pointer"
                      onClick={() => props.setCurrModalPreviewData(item)}
                      data-bs-toggle="modal"
                      data-bs-target="#deleteJobModal"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="red"
                      className="w-6 h-6"
                      width="30" // Change the width here
                      // height="16" // Change the height here
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                    {/* </button> */}
                  </div>
                </td>
              </tr>
            );
          })}
      </tbody>
    </>
  );
};
export default JobTable;
