import Sidebar from "../components/sidebar";
import Topbar from "../components/topBar";
import Footer from "../components/footer";
import Modals from "./modal";
import normalSearch from "../assets/imgs/normalSearch.svg";
import editLight from "../assets/imgs/editLight.svg";
import viewIcon from "../assets/imgs/viewIcon.svg";
import { useEffect, useState } from "react";
import { apiGetMethod, apiPostMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";

function Applicants() {
  const [applicantList, setApplicantList] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);

  useEffect(() => {
    const visiblePages = 5;
    const halfVisiblePages = Math.floor(visiblePages / 2);
    let start = currentPage - halfVisiblePages;
    let end = currentPage + halfVisiblePages;

    if (start < 1) {
      start = 1;
      end = Math.min(totalPages, visiblePages);
    } else if (end > totalPages) {
      end = totalPages;
      start = Math.max(1, totalPages - visiblePages + 1);
    }

    setStartIndex(start);
    setEndIndex(end);
  }, [currentPage, totalPages]);

  useEffect(() => {
    getApplicants();
  }, [currentPage]);
  const getApplicants = async () => {
    let header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      let data = {
        currentPage,
        limit: 10,
      };
      const response = await apiPostMethod(
        allApiUrl.viewAllJobApplicants,
        data,
        header
      );
      if (response.status == 200) {
        setApplicantList(response.data.job);
        setTotalPages(response.data.pages);
        setTotalCount(response.data.totalCount);
      }
    } catch (err) {
      console.log("err", err.data.message);
    }
  };
  function nextHandler(e) {
    e.preventDefault();
    setCurrentPage((prev) => prev + 1);
  }
  function prevHandler(e) {
    e.preventDefault();
    setCurrentPage((prev) => prev - 1);
  }
  return (
    <>
      <Sidebar
        activePage="applicants"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className="mainWrapper">
        <Topbar />
        <section className="commonTable sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-9">
                <div className="commonHeading">
                  <h2>Applicants</h2>
                </div>
              </div>

              <div className="col-xl-3">
                <div className="searchBar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                  />
                  <span>
                    <img src={normalSearch} />
                  </span>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="tableOuter">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Position Applied For</th>
                          <th>University</th>
                          <th>Compatibility</th>
                          <th>Status</th>
                          <th>Operations</th>
                        </tr>
                      </thead>

                      <tbody>
                        {applicantList &&
                          applicantList.length > 0 &&
                          applicantList.map((item, index) => {
                            let universityName = "-";
                            if (
                              item.applicantId.candidateProfileId.qualification
                                .length > 0
                            ) {
                              universityName =
                                item.applicantId.candidateProfileId
                                  .qualification[0].university;
                            }
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>
                                  {item.applicantId.firstName +
                                    " " +
                                    item.applicantId.lastName}
                                </td>
                                <td>{item.jobId.title}</td>
                                <td>{universityName}</td>
                                <td>
                                  <span className="compatibe green">100%</span>
                                </td>
                                <td>
                                  <span className="status hire">Hired</span>
                                </td>
                                <td>
                                  <div className="actionBtns">
                                    <button className="btn">
                                      <img src={editLight} />
                                    </button>
                                    <button className="btn">
                                      <img src={viewIcon} />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {totalCount > 10 && (
                    <div className="pagination">
                      <div className="col-xl-6">
                        <div className="enteries">
                          <p>
                            Showing{" "}
                            <span className="green">
                              {(currentPage - 1) * 10 + 1} to {currentPage * 10}
                            </span>{" "}
                            of {totalCount} entries
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="cppagination">
                          <a
                            style={{
                              pointerEvents: currentPage <= 1 ? "none" : "",
                            }}
                            href=""
                            className="paginationarrowleft"
                            onClick={(e) => prevHandler(e)}
                          >
                            <i
                              className="fa-solid fa-angles-left"
                              style={{
                                color: currentPage <= 1 ? "grey" : "",
                              }}
                            ></i>
                          </a>
                          {/* totalPages */}
                          {[...Array(endIndex - startIndex + 1)].map(
                            (_, index) => {
                              const pageNumber = startIndex + index;
                              return (
                                <a
                                  key={pageNumber}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentPage(pageNumber);
                                  }}
                                  className={`${
                                    currentPage === pageNumber ? "active" : ""
                                  }`}
                                >
                                  {pageNumber}
                                </a>
                              );
                            }
                          )}
                          <a
                            style={{
                              pointerEvents:
                                currentPage >= totalPages ? "none" : "",
                            }}
                            // href=""
                            className="paginationarrowright"
                            onClick={(e) => nextHandler(e)}
                          >
                            <i
                              className="fa-solid fa-angles-right"
                              style={{
                                color: currentPage >= totalPages ? "grey" : "",
                              }}
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Applicants;
