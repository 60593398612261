import Sidebar from "./adminSidebar";
import Topbar from "./adminTopbar";
import dummyImg from "../../assets/imgs/dummyImg.jpg";
import { useEffect, useState } from "react";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoutes";
import newUser from "../../assets/imgs/newUserIcon.svg";
import { Tooltip as ReactTooltip } from "react-tooltip";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import FullPageLoader from "../../components/fullPageLoader";
import { CustomDatePickerInput } from "../modal";
import DatePicker from "react-datepicker";
import { monthNames } from "../../utils/helper";
import AdminModal from "../../components/modals/adminModal";
import { useNavigate } from "react-router-dom";
import totaljobPosted from "../../assets/imgs/totaljobPosted.svg";
import newPosted123 from "../../assets/imgs/newPosted123.png";

// line chart
const data = [
  { name: "Jul", value: 70 },
  { name: "Aug", value: 51 },
  { name: "Sep", value: 60 },
  { name: "Oct", value: 90 },
  { name: "Nov", value: 45 },
  { name: "Dec", value: 30 },
];
export const TeamMember = ({ currTeam, setCurrTeam }) => {
  const [teamMember, setTeamMember] = useState([]);
  let navigate = useNavigate();
  useEffect(() => {
    getTeam();
  }, []);
  const getTeam = async () => {
    let header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.getTeam, "", header);
      if (response.status == 200) {
        setTeamMember(response.data.employees);
      }
    } catch (err) {
      console.log("err", err.data.message);
    }
  };
  return (
    <>
      <ReactTooltip id="my-tooltip" />
      <div className="col-lg-6 col-md-12">
        <div className="invitationCard commonRadius8">
          <div className="inviteHeader" style={{ cursor: "pointer" }}>
            <h3
              onClick={() => {
                // alert("testing");
                navigate("/adminDashboard");
              }}
            >
              Manage Team
            </h3>
          </div>
          <div className="inviteBody">
            {teamMember &&
              teamMember.length > 0 &&
              teamMember.map((item) => {
                return (
                  <>
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        item.firstName + " " + item.lastName
                      }
                    >
                      <img
                        src={dummyImg}
                        className="manageTeamImage"
                        data-bs-target="#viewTeamMember"
                        data-bs-toggle="modal"
                        onClick={() => setCurrTeam(item)}
                      />
                    </a>
                  </>
                );
              })}
            {/* <img src={dummyImg} className="manageTeamImage" />
          <img src={dummyImg} className="manageTeamImage" />
          <img src={dummyImg} className="manageTeamImage" />
          <img src={dummyImg} className="manageTeamImage" />
          <img src={dummyImg} className="manageTeamImage" />
          <img src={dummyImg} className="manageTeamImage" />
          <img src={dummyImg} className="manageTeamImage" /> */}
            {/* <img src={dummyImg} className="manageTeamImage" /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export const AdminLineChart = () => {
  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthIndex = currentDate.getMonth();
    const monthYearArray = monthNames
      .slice(0, currentMonthIndex + 1)
      .map((month) => `${month} ${currentYear}`);
    monthYearArray.push("Custom Date");
    handleMonthSelect(monthYearArray[0]);
    setCurrDateFilter(monthYearArray[0]);
    setDateFilter(monthYearArray);
  }, []);

  const [datefilter, setDateFilter] = useState([]);
  const [currDateFilter, setCurrDateFilter] = useState("");

  const [currStartDate, setCurrStartDate] = useState(null);
  const [currEndDate, setCurrEndDate] = useState(null);

  const [graphData, setGraphData] = useState([]);
  async function filterHandler(startDate, endDate) {
    try {
      const header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      let updatedData = {
        startDate,
        endDate,
      };
      const response = await apiPostMethod(
        allApiUrl.getCustomData,
        updatedData,
        header
      );
      if (response.status === 200) {
        let data = [];
        if (response.data.updated) {
          Object.keys(response.data.updated).map((item) => {
            data.push({ name: item, value: response.data.updated[item] });
          });
        }
        setGraphData(data);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  function handleMonthSelect(monthYear) {
    const [month, year] = monthYear.split(" ");
    const monthIndex = monthNames.indexOf(month);
    const startDate = new Date(year, monthIndex, 1).getTime();
    const endDate = new Date(year, monthIndex + 1, 0).getTime(); // Last day of the month
    setCurrStartDate(startDate);
    setCurrEndDate(endDate);
    filterHandler(startDate, endDate);
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log("payload", payload);
      return (
        <div
          className="custom-tooltip"
          style={{ background: "white", padding: "12px", borderRadius: "28px" }}
        >
          <p
            style={{
              fontSize: "24px",
              fontWeight: "semibold",
              marginLeft: "32px",
            }}
            className="desc"
          >
            {payload[0].value}
          </p>
          <p className="label">{`${label}`}</p>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
        </div>
      );
    }

    return null;
  };
  return (
    <div className="col-xl-12">
      <div class="card mb-5">
        <div class="card-header">
          <div className="col-lg-6">
            <h3>New Users</h3>
          </div>
          <div className="col-lg-6">
            <div className="filterOuter">
              {currDateFilter == "Custom Date" && (
                <div className="customDate">
                  <DatePicker
                    showYearDropdown
                    // showMonthYearPicker
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    placeholderText="Start Date*"
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <CustomDatePickerInput placeholder="Start Date*" />
                    }
                    selected={parseInt(currStartDate)}
                    onChange={(data) => {
                      let updatedStartDate = data.getTime().toString();
                      setCurrStartDate(updatedStartDate);
                    }}
                  />
                  <DatePicker
                    showYearDropdown
                    // showMonthYearPicker
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={15}
                    placeholderText="End Date*"
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <CustomDatePickerInput placeholder="End Date*" />
                    }
                    selected={parseInt(currEndDate)}
                    onChange={(data) => {
                      let updatedendDate = data.getTime().toString();
                      setCurrEndDate(updatedendDate);
                    }}
                  />
                  <button
                    className="btn"
                    type="button"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                    onClick={() => filterHandler(currStartDate, currEndDate)}
                  >
                    Show
                  </button>
                  <div className="dropdown"></div>
                </div>
              )}
              <div className="dropdown newUserDropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {currDateFilter}
                </button>
                <ul className="dropdown-menu">
                  {datefilter.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          if (item !== "Custom Date") {
                            handleMonthSelect(item);
                          }
                          setCurrDateFilter(item);
                        }}
                      >
                        <a className="dropdown-item" href="#">
                          {item}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            {/* ) : ( */}
          </div>
          {/* )} */}
        </div>
        <div class="card-body">
          {/* <ResponsiveContainer width="99%" aspect={3}> */}
          <div className="chart-container">
            {/* <ReactApexChart options={options} series={series} type="line" height={350} /> */}
            <LineChart width={1300} height={680} data={graphData}>
              <CartesianGrid strokeDasharray="4 4" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Line
                activeDot={{ r: 8 }}
                type="monotone"
                dataKey="value"
                stroke="#61D180"
                strokeWidth={3}
              />
            </LineChart>
          </div>
          {/* </ResponsiveContainer> */}
        </div>
      </div>
    </div>
  );
};
function AdminDashboard() {
  const [currTeam, setCurrTeam] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getDashboardData();
  }, []);
  const getDashboardData = async () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      const response = await apiGetMethod(allApiUrl.adminDashboard, "", header);
      if (response.status == 200) {
        setDashboardData(response.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.data.message);
    }
  };

  if (isLoading) return <FullPageLoader />;
  return (
    <>
      <AdminModal currTeam={currTeam} setCurrTeam={setCurrTeam} />
      <div className="mainWrapper">
        <Sidebar activePage="adminDashboard" />
        <Topbar />
        <section className="analytics profile sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6">
                <div className="commonHeading">
                  <h2>Dashboard</h2>
                </div>
              </div>
            </div>
            <div className="invitation commonRadius8 mb-5">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="invitationCard commonRadius8">
                    <div className="inviteHeader">
                      <span>
                        <img src={newUser} />
                      </span>

                      <p>New Users</p>
                    </div>
                    <div className="inviteBody">
                      <h4 className="green">{dashboardData.newUsers}</h4>
                      <p>
                        Today <br />
                        <span>
                          {dashboardData.todayUsers}
                          {dashboardData.todayUsers !== 0 && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1510_281)">
                                <path
                                  d="M5.73444 0.859734L1.94278 4.6514C1.74544 4.85572 1.63624 5.12937 1.63871 5.41342C1.64118 5.69747 1.75511 5.96918 1.95597 6.17004C2.15683 6.3709 2.42854 6.48483 2.71259 6.4873C2.99664 6.48977 3.27029 6.38057 3.47461 6.18323L5.41703 4.24082L5.41703 11.3757C5.41703 11.663 5.53116 11.9385 5.73433 12.1417C5.93749 12.3448 6.21304 12.459 6.50036 12.459C6.78768 12.459 7.06323 12.3448 7.26639 12.1417C7.46956 11.9385 7.58369 11.663 7.58369 11.3757L7.58369 4.24082L9.52611 6.18323C9.62604 6.2867 9.74558 6.36923 9.87775 6.42601C10.0099 6.48279 10.1521 6.51267 10.2959 6.51392C10.4398 6.51517 10.5824 6.48776 10.7156 6.43329C10.8487 6.37882 10.9697 6.29838 11.0714 6.19666C11.1731 6.09494 11.2535 5.97399 11.308 5.84085C11.3625 5.70771 11.3899 5.56506 11.3886 5.42122C11.3874 5.27737 11.3575 5.13522 11.3007 5.00305C11.2439 4.87087 11.1614 4.75133 11.0579 4.6514L7.26628 0.859734C7.06312 0.656641 6.78762 0.542549 6.50036 0.542549C6.2131 0.542549 5.9376 0.656641 5.73444 0.859734Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1510_281">
                                  <rect
                                    width="13"
                                    height="13"
                                    fill="white"
                                    transform="translate(0 13) rotate(-90)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="invitationCard commonRadius8">
                    <div className="inviteHeader">
                      <span>
                        <img src={totaljobPosted} alt="" />
                      </span>

                      <p>New Job Post</p>
                    </div>

                    <div className="inviteBody">
                      <h4 className="green">{dashboardData.newJobs}</h4>
                      <p>
                        Today <br />
                        <span>
                          {dashboardData.todayJobs}
                          {dashboardData.todayJobs !== 0 && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1510_289)">
                                <path
                                  d="M5.73444 12.1403L1.94278 8.3486C1.74544 8.14428 1.63624 7.87063 1.63871 7.58658C1.64118 7.30253 1.75511 7.03082 1.95597 6.82996C2.15683 6.6291 2.42854 6.51517 2.71259 6.5127C2.99664 6.51023 3.27029 6.61943 3.47461 6.81677L5.41703 8.75918L5.41703 1.62435C5.41703 1.33703 5.53116 1.06148 5.73433 0.858317C5.93749 0.655152 6.21304 0.541016 6.50036 0.541016C6.78768 0.541016 7.06323 0.655152 7.26639 0.858317C7.46956 1.06148 7.58369 1.33703 7.58369 1.62435L7.58369 8.75918L9.52611 6.81677C9.62604 6.7133 9.74558 6.63077 9.87775 6.57399C10.0099 6.51721 10.1521 6.48733 10.2959 6.48608C10.4398 6.48483 10.5824 6.51224 10.7156 6.56671C10.8487 6.62118 10.9697 6.70162 11.0714 6.80334C11.1731 6.90506 11.2535 7.02601 11.308 7.15915C11.3625 7.29229 11.3899 7.43494 11.3886 7.57878C11.3874 7.72263 11.3575 7.86478 11.3007 7.99695C11.2439 8.12913 11.1614 8.24867 11.0579 8.3486L7.26628 12.1403C7.06312 12.3434 6.78762 12.4575 6.50036 12.4575C6.2131 12.4575 5.9376 12.3434 5.73444 12.1403Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1510_289">
                                  <rect
                                    width="13"
                                    height="13"
                                    fill="white"
                                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <TeamMember setCurrTeam={setCurrTeam} currTeam={currTeam} />
              </div>
            </div>
            <div className="row">
              <AdminLineChart />
              {false && (
                <div className="col-xl-4">
                  <div
                    className="invitation commonRadius8 mb-5"
                    style={{ padding: "6px" }}
                  >
                    <div className="card">
                      <div className="card-header">
                        <h3 className="white">New Tickets</h3>
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            June 2023
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                June 2023
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body ticketsCard-body">
                        <div className="ticketList">
                          <div className="candidateImg">
                            <img src={dummyImg} alt="User Image" />
                          </div>
                          <div className="candidateuserleft2">
                            <div className="name">
                              <h2>
                                Alex Khan <span>12 hours ago</span>
                              </h2>
                            </div>

                            <p>
                              {
                                "Lorem ipsum dolor sit amet consectetur. In euismod a neque volutpat..."
                              }
                            </p>
                          </div>
                        </div>

                        <div className="ticketList">
                          <div className="candidateImg">
                            <img src={dummyImg} alt="User Image" />
                          </div>
                          <div className="candidateuserleft2">
                            <div className="name">
                              <h2>
                                Alex Khan <span>12 hours ago</span>
                              </h2>
                            </div>

                            <p>
                              {
                                "Lorem ipsum dolor sit amet consectetur. In euismod a neque volutpat..."
                              }
                            </p>
                          </div>
                        </div>

                        <div className="ticketList">
                          <div className="candidateImg">
                            <img src={dummyImg} alt="User Image" />
                          </div>
                          <div className="candidateuserleft2">
                            <div className="name">
                              <h2>
                                Alex Khan <span>12 hours ago</span>
                              </h2>
                            </div>

                            <p>
                              {
                                "Lorem ipsum dolor sit amet consectetur. In euismod a neque volutpat..."
                              }
                            </p>
                          </div>
                        </div>

                        <div className="ticketList">
                          <div className="candidateImg">
                            <img src={dummyImg} alt="User Image" />
                          </div>
                          <div className="candidateuserleft2">
                            <div className="name">
                              <h2>
                                Alex Khan <span>12 hours ago</span>
                              </h2>
                            </div>

                            <p>
                              {
                                "Lorem ipsum dolor sit amet consectetur. In euismod a neque volutpat..."
                              }
                            </p>
                          </div>
                        </div>

                        <div className="ticketList">
                          <div className="candidateImg">
                            <img src={dummyImg} alt="User Image" />
                          </div>
                          <div className="candidateuserleft2">
                            <div className="name">
                              <h2>
                                Alex Khan <span>12 hours ago</span>
                              </h2>
                            </div>

                            <p>
                              {
                                "Lorem ipsum dolor sit amet consectetur. In euismod a neque volutpat..."
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* <Footer /> */}
      </div>
    </>
  );
}
export default AdminDashboard;
