import Sidebar from "./adminSidebar";
import Topbar from "./adminTopbar";
import normalSearch from "../../assets/imgs/normalSearch.svg";
import editLight from "../../assets/imgs/editLight.svg";
import viewIcon from "../../assets/imgs/viewIcon.svg";
import { useEffect, useState } from "react";
import FullPageLoader from "../../components/fullPageLoader";
import { allApiUrl } from "../../api/apiRoutes";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../../api/rest";
import AccountsTable from "./adminTables/accountsTable";
import CandidatesTable from "./adminTables/candidatesTable";
import FirmTable from "./adminTables/firmsTable";
import JobTable from "./adminTables/JobsTable";
import AdminModal from "../../components/modals/adminModal";
import { ExportCsv } from "../../components/button/export";
import AdminUserModal from "../../components/modals/adminUserModal";
import toastMessages from "../../components/toastMessage";

const AdminTables = (props) => {
  if (props.currentType == "Accounts") {
    return (
      <AccountsTable
        setCurrModalPreviewData={props.setCurrModalPreviewData}
        userData={props.userData}
        setCsvData={props.setCsvData}
        csvData={props.csvData}
      />
    );
  } else if (props.currentType == "Candidates") {
    return (
      <CandidatesTable
        setCurrModalPreviewData={props.setCurrModalPreviewData}
        userData={props.userData}
        setCsvData={props.setCsvData}
        csvData={props.csvData}
      />
    );
  } else if (props.currentType == "Companies") {
    return (
      <FirmTable
        setCurrModalPreviewData={props.setCurrModalPreviewData}
        userData={props.userData}
        setCsvData={props.setCsvData}
        csvData={props.csvData}
      />
    );
  } else {
    return (
      <JobTable
        setCurrModalPreviewData={props.setCurrModalPreviewData}
        userData={props.userData}
        setCsvData={props.setCsvData}
        csvData={props.csvData}
      />
    );
  }
};
const TableHeader = (props) => {
  let data = "";
  if (props.currentType == "Accounts") {
    data = (
      <thead>
        <tr>
          <th>
            <div className="form-check">
              <input
                className="form-check-input"
                checked={props.allCheck.accounts}
                type="checkbox"
                onClick={(e) => {
                  if (e) {
                    let updatedItem = [];
                    if (e.target.checked) {
                      props.userData.map((item) => {
                        updatedItem.push({
                          id: item._id,
                          "First name": item.firstName,
                          "Last name": item.lastName,
                          "Email ": item.email,
                          "Phone number": item.phoneNumber,
                          "Account type": item.accountType,
                          "Created at": new Date(
                            item.createdAt
                          ).toLocaleDateString(),
                        });
                      });
                    } else {
                      updatedItem = [];
                    }
                    props.setAllCheck({
                      ...props.allCheck,
                      accounts: e.target.checked,
                    });
                    props.setCsvData(updatedItem);
                  }
                }}
              />
            </div>
          </th>
          <th>S.No</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>Profile Completed</th>
          <th>Account Type</th>
          <th>Created At</th>
          <th>Operation</th>
        </tr>
      </thead>
    );
  } else if (props.currentType == "Candidates") {
    data = (
      <thead>
        <tr>
          <th>
            <div className="form-check">
              <input
                className="form-check-input"
                checked={props.allCheck.candidates}
                type="checkbox"
                onClick={(e) => {
                  if (e) {
                    let updatedItem = [];
                    if (e.target.checked) {
                      props.userData.map((item) => {
                        updatedItem.push({
                          id: item._id,
                          "Email ": item.email,
                          "Qualification ": item.qualification.length,
                          "Work Experience": item.workExperience.length,
                          "Skills ": item.skills.length,
                          "Custom Value Prompt": item.customValuePrompt.length,
                          "Created At": new Date(
                            item.createdAt
                          ).toLocaleDateString(),
                        });
                      });
                    } else {
                      updatedItem = [];
                    }
                    props.setAllCheck({
                      ...props.allCheck,
                      candidates: e.target.checked,
                    });
                    props.setCsvData(updatedItem);
                  }
                }}
              />
            </div>
          </th>
          <th>S.No</th>
          <th>Email</th>
          <th>Qualification</th>
          <th>Work Experience</th>
          <th>Skills</th>
          <th>Custom Value Prompt</th>
          <th>Created At</th>
          <th>Operation</th>
        </tr>
      </thead>
    );
  } else if (props.currentType == "Companies") {
    data = (
      <thead>
        <tr>
          <th>
            <div className="form-check">
              <input
                className="form-check-input"
                checked={props.allCheck.companies}
                type="checkbox"
                onClick={(e) => {
                  if (e) {
                    let updatedItem = [];
                    if (e.target.checked) {
                      props.userData.map((item) => {
                        updatedItem.push({
                          id: item._id,
                          "Firm Name ": item.firmName,
                          "Admin ": item.admin,
                          "Address ": item.address,
                          "Website Link": item.websiteLink,
                          "Created At": new Date(
                            item.createdAt
                          ).toLocaleDateString(),
                        });
                      });
                    } else {
                      updatedItem = [];
                    }
                    props.setAllCheck({
                      ...props.allCheck,
                      companies: e.target.checked,
                    });
                    props.setCsvData(updatedItem);
                  }
                }}
              />
            </div>
          </th>
          <th>S.No</th>
          <th>Company Name</th>
          <th>Admin</th>
          <th>Address</th>
          <th>Website Link</th>
          <th>Created At</th>
          <th>Operation</th>
        </tr>
      </thead>
    );
  } else {
    data = (
      <thead>
        <tr>
          <th>
            <div className="form-check">
              <input
                className="form-check-input"
                checked={props.allCheck.jobs}
                type="checkbox"
                onClick={(e) => {
                  if (e) {
                    let updatedItem = [];
                    if (e.target.checked) {
                      props.userData.map((item) => {
                        updatedItem.push({
                          id: item._id,
                          "Title ": item.title,
                          "Job Type": item.jobType,
                          "Location ": item.location,
                          "Salary ": item.salary,
                          "Salary Upto": item.salryUpto,
                          "Posted By": item?.firmId?.firmName,
                          "Keys Skills":
                            item.keySkills &&
                            item.keySkills.length > 0 &&
                            item.keySkills.map((item) => item),
                          "Time Period": item.timeperiod,
                          "Position Type": item.positionType,
                          "Created At": new Date(
                            item.createdAt
                          ).toLocaleDateString(),
                        });
                      });
                    } else {
                      updatedItem = [];
                    }
                    props.setAllCheck({
                      ...props.allCheck,
                      jobs: e.target.checked,
                    });
                    props.setCsvData(updatedItem);
                  }
                }}
              />
            </div>
          </th>
          <th>S.No</th>
          <th>Title</th>
          <th>Position Type</th>
          <th>Location</th>
          <th>Salary</th>
          <th>Posted By</th>
          <th>Job Type</th>
          <th>Time Period</th>
          <th>Key Skills</th>
          <th>Created At</th>
          <th>Operations</th>
        </tr>
      </thead>
    );
  }
  return data;
};
function AdminUsers() {
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentType, setCurrentType] = useState("Accounts");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [currModalPreviewData, setCurrModalPreviewData] = useState({});
  const [allCheck, setAllCheck] = useState({
    accounts: false,
    candidates: false,
    companies: false,
    jobs: false,
  });
  const [deleteJobId, setDeleteJobId] = useState("");
  // jobId:''
  useEffect(() => {
    getUserData();
  }, [currentPage, currentType, isProfileUpdated]);
  useEffect(() => {
    const visiblePages = 5;
    const halfVisiblePages = Math.floor(visiblePages / 2);
    let start = currentPage - halfVisiblePages;
    let end = currentPage + halfVisiblePages;

    if (start < 1) {
      start = 1;
      end = Math.min(totalPages, visiblePages);
    } else if (end > totalPages) {
      end = totalPages;
      start = Math.max(1, totalPages - visiblePages + 1);
    }

    setStartIndex(start);
    setEndIndex(end);
  }, [currentPage, totalPages]);

  const getUserData = async () => {
    setIsLoading(true);
    let header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };
    try {
      let data = {
        currentPage,
        limit: 5,
        type: currentType,
      };
      const response = await apiPostMethod(allApiUrl.getAllUsers, data, header);
      if (response.status == 200) {
        setUserData(JSON.parse(JSON.stringify(response.data.data)));
        setTotalPages(response.data.pages);
        setTotalCount(response.data.totalCount);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err", err.data.message);
    }
  };
  if (isLoading) return <FullPageLoader />;

  function nextHandler(e) {
    e.preventDefault();
    setCurrentPage((prev) => prev + 1);
  }
  function prevHandler(e) {
    e.preventDefault();
    setCurrentPage((prev) => prev - 1);
  }
  async function onJobDeleteHandler() {
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      let data = {
        jobId: currModalPreviewData._id,
      };
      let res = await apiPutMethod(`${allApiUrl.deleteJob}`, data, header);
      if (res.status == 200) {
        // setDeleteJobId("");
        setCurrModalPreviewData({});
        toastMessages("success", "Job deleted successfully");
        setIsProfileUpdated(!isProfileUpdated);
        setCurrentType("Jobs");
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  }
  return (
    <>
      <AdminUserModal
        onJobDeleteHandler={onJobDeleteHandler}
        setDeleteJobId={setDeleteJobId}
        setCurrModalPreviewData={setCurrModalPreviewData}
        currModalPreviewData={currModalPreviewData}
      />
      <div className="mainWrapper">
        <Topbar />
        <Sidebar activePage="adminUsers" />
        <section className="users commonTable sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-9">
                <div className="commonHeading">
                  <h2>{currentType}</h2>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        {/* <h2>{currentType}</h2> */}
                        {csvData && csvData.length > 0 && (
                          <ExportCsv
                            filename={currentType}
                            csvData={csvData}
                            setCsvData={setCsvData}
                            setAllCheck={setAllCheck}
                            allCheck={allCheck}
                          />
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="dropdown">
                          <button
                            className="btn userButton"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {currentType}
                          </button>
                          <ul className="dropdown-menu">
                            {[
                              "Accounts",
                              "Candidates",
                              "Companies",
                              "Jobs",
                            ].map((item) => {
                              return (
                                <li
                                  onClick={() => {
                                    setCurrentType(item);
                                    setCurrentPage(1);
                                  }}
                                >
                                  <a className="dropdown-item">{item}</a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="tableOuter">
                      <div className="table-responsive">
                        <table className="table">
                          <TableHeader
                            currentType={currentType}
                            userData={userData}
                            setCsvData={setCsvData}
                            setAllCheck={setAllCheck}
                            allCheck={allCheck}
                          />
                          <AdminTables
                            setCurrModalPreviewData={setCurrModalPreviewData}
                            setCsvData={setCsvData}
                            csvData={csvData}
                            currentType={currentType}
                            userData={userData}
                          />
                        </table>
                      </div>
                      <div className="pagination">
                        <div className="col-xl-6">
                          <div className="enteries">
                            <p>
                              Showing{" "}
                              <span className="green">
                                {(currentPage - 1) * 5 + 1} to{" "}
                                {currentPage * 5 >= totalCount
                                  ? totalCount
                                  : currentPage * 5}
                              </span>{" "}
                              of {totalCount} entries
                            </p>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="cppagination">
                            <a
                              style={{
                                pointerEvents: currentPage <= 1 ? "none" : "",
                              }}
                              href=""
                              className="paginationarrowleft"
                              onClick={(e) => prevHandler(e)}
                            >
                              <i
                                className="fa-solid fa-angles-left"
                                style={{
                                  color: currentPage <= 1 ? "grey" : "",
                                }}
                              ></i>
                            </a>
                            {/* totalPages */}
                            {[...Array(endIndex - startIndex + 1)].map(
                              (_, index) => {
                                const pageNumber = startIndex + index;
                                return (
                                  <a
                                    key={pageNumber}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setCurrentPage(pageNumber);
                                    }}
                                    className={`${
                                      currentPage === pageNumber ? "active" : ""
                                    }`}
                                  >
                                    {pageNumber}
                                  </a>
                                );
                              }
                            )}
                            <a
                              style={{
                                pointerEvents:
                                  currentPage >= totalPages ? "none" : "",
                              }}
                              // href=""
                              className="paginationarrowright"
                              onClick={(e) => nextHandler(e)}
                            >
                              <i
                                className="fa-solid fa-angles-right"
                                style={{
                                  color:
                                    currentPage >= totalPages ? "grey" : "",
                                }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default AdminUsers;
