import Sidebar from '../components/sidebar';
import Topbar from '../components/topBar';
import Footer from '../components/footer';
import searchIcon from '../assets/imgs/searchIcon.svg';
import jobImg from '../assets/imgs/jobImgNew.png';
import LocationIcon from '../assets/imgs/locationBlack.svg';
import timerIcon from '../assets/imgs/timerBlack.svg';
import calenderIcon from '../assets/imgs/calender.svg';
import chartIcon from '../assets/imgs/chart.svg';
import heartGrey from '../assets/imgs/heartGrey.svg';
import rejectIcon from '../assets/imgs/rejectIcon.svg';
import reportIcon from '../assets/imgs/reportIcon.svg';


function SearchJobDetail() {
  return (<>
  <Sidebar />

<div className="mainWrapper">
    <Topbar />

    <section className="jobListing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="jobListing__form">
                <form>
                  <div className="row">
                    <div className="col-xl-5 col-lg-5">
                      <div className="form-group first">
                        <label
                          ><i className="fa-solid fa-magnifying-glass"></i>
                          What</label
                        >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Junior Software Developer"
                        />
                        <span><i className="fa-solid fa-circle-xmark"></i></span>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5">
                      <div className="form-group">
                        <label
                          ><i className="fa-solid fa-location-dot"></i> Where</label
                        >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="New York, USA"
                        />
                        <span><i className="fa-solid fa-circle-xmark"></i></span>
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2">
                      <button className="btn btn-search">
                        Search <img src={searchIcon} />
                      </button>
                    </div>
                  </div>
                </form>

                <div className="filters">
                  <select className="">
                    <option>Full Time</option>
                    <option>Part Time</option>
                  </select>
                  <select className="">
                    <option>Pay</option>
                    <option>Pay</option>
                  </select>
                  <select className="">
                    <option>Social Media Marketing Intern</option>
                    <option>Digital Marketer</option>
                  </select>

                  <select className="">
                    <option>Location</option>
                    <option>Location</option>
                  </select>

                  <select className="">
                    <option>In Person</option>
                    <option>In Person</option>
                  </select>

                  <select className="">
                    <option>University/College</option>
                    <option>University/College</option>
                  </select>

                  <select className="">
                    <option>Education</option>
                    <option>Education</option>
                  </select>

                  <button className="btn btn-clear">Clear all</button>
                </div>
              </div>

              <div className="jobListing__List">
                <h3 className="preferencesHead">
                    <span>Search Results</span>
                </h3>
              </div>

              <div className='jobDetails'>
                <div className='jobDetailsInner'>
                    <div className='row'>
                        <div className='col-xl-9'>
                            <div className='jobDetails__Header'>
                                <div className='jobImg'>
                                    <img src={jobImg} />
                                </div>
                                <div className='heading'>
                                    <h2>K-12 School Teacher, Woods Cross High School</h2>
                                    <p className='preference'><span><img src={LocationIcon} /> Bountiful, UT</span> <span><img src={timerIcon} /> Full Time</span> <span><img src={calenderIcon} /> 10 min. ago</span> <span><img src={chartIcon} /> 93 % match</span></p>
                                    <h4>$4,675 - $5,547/ <span>Monthly</span></h4>
                                    <p>Woods Cross High School is a public school located in Bountiful, Utah that aims to provide quality education to its students. It has a diverse student population and offers a wide array of academic and extracurricular programs such as sports, music, and theater that nurture the students' talents and interests</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-3'>
                            <div className='jobBtns'>
                                <button className='btn btn-save'>Apply Now</button>
                                <button className='btn btn-cancel'>
                                    <img src={heartGrey} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className='detailsContent'>
                    <h3 className='detail'>Job Details</h3>
                    <p>We are currently inviting application for Primary, Secondary and Higher Secondary teachers for the academic year 2023-24. Please read below for more details:</p>

                    <ul>
                        <li>Teach as a full-time, paid faculty member at a primary or secondary school</li>
                        <li>Share language, culture, and knowledge with students, colleagues, and the surrounding community</li>
                        <li>Assist students with additional academic needs (tutoring, discussion groups).</li>
                        <li>Fulfill professional development requirements.</li>
                        <li>Complete the cross-cultural requirement</li>
                        <li>Perform other assigned tasks as designated by supervisors and school administration</li>
                    </ul>
                    <h5>Job Benefits & Requirements</h5>
                    <p>Working in the US on a teacher visa is extremely valuable work experience.</p>

                    <ul>
                        <li>You can build your skills in a unique environment, learn new teaching methods, and grow comfortable with teaching diverse groups of students.</
                        li>
                        <li>Attractive pay package</li>
                        <li>Excellent benefits.</li>
                        <li>Experience as a teacher is beneficial for your career and adds to your personal experience as well. You will get to try new activities, visit new places, meet different people, and generally broaden your horizons.</li>

                        <li>Sharpen your professional skills and participate in cross-cultural activities in schools and communities.</li>
                    </ul>

                    <div className='btns mt-5'>
                        <button className='btn'>Report job <img src={reportIcon} /></button>
                        <button className='btn'><img src={rejectIcon} /></button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
<Footer />
</div>

    
    </>
  );
}

export default SearchJobDetail;