import { useEffect, useState } from "react";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Topbar from "../components/topBar";
import Footer from "../components/footer";
import ProfileModal from "../components/modals/profileModals";
import Location from "../assets/imgs/location.svg";
import Download from "../assets/imgs/download.svg";
import Usericon from "../assets/imgs/usericon.svg";
import candidateImg from "../assets/imgs/candidateImg.png";
import dummyImg from "../assets/imgs/dummyImg.jpg";
import Anthropology from "../assets/imgs/anthropology.svg";
import experiencepeople from "../assets/imgs/experiencepeople.svg";
import starImg from "../assets/imgs/starImg.svg";
import education from "../assets/imgs/education.svg";
import customPromptIcon from "../assets/imgs/customPromptIcon.svg";
import location2 from "../assets/imgs/location2.svg";
import contacticon from "../assets/imgs/contacticon.svg";
import userimg from "../assets/imgs/userimg.jpg";
import editRoundIcon from "../assets/imgs/editRoundIcon.svg";
import editCameraIcon from "../assets/imgs/editCameraIcon.svg";
import editIcon from "../assets/imgs/editIcon.svg";
import addIcon from "../assets/imgs/addIcon.svg";
import uploadIcon from "../assets/imgs/uploadIcon.svg";
import inviteImg from "../assets/imgs/inviteImg.svg";
import whiteCross from "../assets/imgs/whiteCross.svg";
import toastMessages from "../components/toastMessage";
import { getYear, isCandidateFilledCompleted } from "../utils/helper";
import FullPageLoader from "../components/fullPageLoader";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTheme } from "styled-components";
// import ImageCrop from "../components/customImageEditor/ImageCrop";
// import ImageCropProvider from "../components/customImageEditor/ImageCropProvider";

export const Greeting = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const name = userInfo && userInfo.firstName;
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [greeting, setGreeting] = useState("");
  let isLastloginPresent;
  isLastloginPresent = userInfo && userInfo.lastLogout;
  useEffect(() => {
    const currentTime = new Date().getHours();
    if (currentTime >= 5 && currentTime < 12) {
      setGreeting(`Good Morning, ${name}!`);
    } else if (currentTime >= 12 && currentTime < 15) {
      setGreeting(`Good Afternoon, ${name}!`);
    } else {
      setGreeting(`Good Evening, ${name}!`);
    }

    const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");

    if (hasVisitedBefore) {
      setIsFirstTime(false);
    } else {
      localStorage.setItem("hasVisitedBefore", "true");
    }
  }, [name]);
  const renderGreeting = () => {
    if (!isLastloginPresent) {
      return `Nice to meet you, ${name}!`;
    } else if (isFirstTime) {
      // Check if last visit was more than a week ago
      const lastVisitTimestamp = userInfo && userInfo.lastLogout;
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      if (lastVisitTimestamp && new Date(lastVisitTimestamp) < oneWeekAgo) {
        return `We missed you , ${name}!`;
      } else {
        return `Welcome back, ${name}!`;
      }
    } else {
      return greeting;
    }
  };

  return <h2>{renderGreeting()}</h2>;
};

const percentage = 66;
const BasicProfile = ({
  accountsDetail,
  edit,
  onSubmit,
  setPreviewImage,
  previewImage,
  displayImage,
  setDisplayImage,
  isDisplayImageDeleted,
  setIsDisplayImageDeleted,
}) => {
  return (
    <div className="candidateuserleft">
      <div className="candidateImg">
        <img
          src={
            accountsDetail && accountsDetail?.profileImage
              ? accountsDetail?.profileImage
              : dummyImg
          }
          alt="User Image"
        />
        {edit && (
          <button className="btn uploadImgBtn">
            <img
              //temporary changes
              data-bs-target="#customImageUpload1"
              data-bs-toggle="modal"
              src={editCameraIcon}
            />

            {/* <input
              type="file"
              onChange={(e) => {
                if (e) {
                  const file = e.target.files;
                  if (
                    file &&
                    file[0] &&
                    file[0].type &&
                    file[0].type.split("/")[0] == "image"
                  ) {
                    setPreviewImage({
                      ...previewImage,
                      displayImage: URL.createObjectURL(file[0]),
                    });
                    setDisplayImage(file[0]);
                    onSubmit(e, file[0], true);
                    const uploadImageModal = new window.bootstrap.Modal(
                      document.getElementById("profileImageModal")
                    );
                    uploadImageModal.show();
                    setIsDisplayImageDeleted(false);
                  } else {
                    toastMessages("error", "The image format is not correct");
                  }
                }
              }}
            /> */}
          </button>
        )}
      </div>
      <div className="candidateuserleft2">
        <div>
          <h2
          // style={{ textTransform: "capitalize" }}
          >
            {accountsDetail?.firstName +
              " " +
              (accountsDetail?.lastName ? accountsDetail?.lastName : "")}
            <span>
              {accountsDetail &&
                accountsDetail?.candidateProfileId &&
                accountsDetail?.candidateProfileId?.qualification &&
                accountsDetail?.candidateProfileId?.qualification[0] &&
                accountsDetail?.candidateProfileId?.qualification[0].university}
            </span>
            {edit && (
              <button
                data-bs-target="#locationModal"
                data-bs-toggle="modal"
                className="btn"
              >
                <img src={editRoundIcon} />
              </button>
            )}
          </h2>
        </div>
        {accountsDetail &&
        accountsDetail?.candidateProfileId &&
        accountsDetail?.candidateProfileId?.location ? (
          <p style={{ textTransform: "capitalize" }}>
            <img src={Location} alt="Location Icon" />
            {/* <span>City :</span>{" "} */}
            {accountsDetail &&
              accountsDetail?.candidateProfileId &&
              accountsDetail?.candidateProfileId?.location &&
              accountsDetail?.candidateProfileId?.location}
            {/* <span style={{ marginLeft: "8px" }}> State : </span> */}
            {/* {accountsDetail &&
              accountsDetail?.candidateProfileId &&
              accountsDetail?.candidateProfileId?.locations &&
              accountsDetail?.candidateProfileId?.locations &&
              accountsDetail?.candidateProfileId?.locations?.state} */}
          </p>
        ) : (
          ""
        )}
        {/* {(accountsDetail &&
          accountsDetail?.candidateProfileId &&
          accountsDetail?.candidateProfileId?.locations &&
          accountsDetail?.candidateProfileId?.locations &&
          accountsDetail?.candidateProfileId?.locations?.city) ||
        (accountsDetail &&
          accountsDetail?.candidateProfileId &&
          accountsDetail?.candidateProfileId?.locations &&
          accountsDetail?.candidateProfileId?.locations &&
          accountsDetail?.candidateProfileId?.locations?.state) ? (
          <p style={{ textTransform: "capitalize" }}>
            <img src={Location} alt="Location Icon" />
            <span>City :</span>{" "}
            {accountsDetail &&
              accountsDetail?.candidateProfileId &&
              accountsDetail?.candidateProfileId?.locations &&
              accountsDetail?.candidateProfileId?.locations &&
              accountsDetail?.candidateProfileId?.locations?.city}
            <span style={{ marginLeft: "8px" }}> State : </span>
            {accountsDetail &&
              accountsDetail?.candidateProfileId &&
              accountsDetail?.candidateProfileId?.locations &&
              accountsDetail?.candidateProfileId?.locations &&
              accountsDetail?.candidateProfileId?.locations?.state}
          </p>
        ) : (
          ""
        )} */}
      </div>
    </div>
  );
};
const AboutSection = ({ accountsDetail, edit }) => {
  return (
    <div
      className={`candidatemidleft aboutSection ${
        accountsDetail.candidateProfileId?.about ? "" : "empty"
      }`}
    >
      <div className="candidatemidabout">
        <div className="candidatemidabout1">
          <h3>
            <img src={Usericon} alt="User Icon" /> About Me
          </h3>
          {edit && (
            <div className="actionBtns">
              <button
                className="btn"
                data-bs-target="#addExperienceModal"
                data-bs-toggle="modal"
              >
                <img src={editIcon} />
              </button>
            </div>
          )}
        </div>
        {accountsDetail?.candidateProfileId?.about && (
          <p className="mb-0">{accountsDetail?.candidateProfileId?.about}</p>
        )}
      </div>
    </div>
  );
};
const EducationSection = ({ accountsDetail, edit }) => {
  return (
    <div
      className={`candidatemidleft experienceSection ${
        accountsDetail.candidateProfileId?.qualification.length > 0
          ? ""
          : "empty"
      }`}
    >
      <div className="candidatemidabout">
        <div className="candidatemidabout1">
          <h3>
            <img src={education} alt="" /> Education
          </h3>
          {edit && (
            <div className="actionBtns">
              <button
                className="btn"
                data-bs-toggle="modal"
                data-bs-target="#addEducationModal"
              >
                <img src={addIcon} />
              </button>
              {accountsDetail &&
                accountsDetail?.candidateProfileId &&
                accountsDetail?.candidateProfileId?.qualification &&
                accountsDetail?.candidateProfileId?.qualification.length >
                  0 && (
                  <button
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#editEducationModal"
                  >
                    <img src={editIcon} />
                  </button>
                )}
            </div>
          )}
        </div>
        {accountsDetail &&
          accountsDetail?.candidateProfileId &&
          accountsDetail?.candidateProfileId?.qualification &&
          accountsDetail?.candidateProfileId?.qualification?.map((item) => {
            return (
              <>
                <div className="eduDetail">
                  <div
                    className="row"
                    // style={{ textTransform: "capitalize" }}
                  >
                    <div className="col-xxl-1 col-xl-2 col-lg-1 col-md-2">
                      <span className="eduDyImg">
                        <img
                          src={
                            item.universityLogo
                              ? item.universityLogo
                              : Anthropology
                          }
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10">
                      <div className="eduDyText">
                        <h3>{item.university}</h3>
                        <p>
                          {item.degreeType} ({getYear(item.startDate)} -{" "}
                          {/* {getYear(item.endDate)}) */}
                          {!item.isPresent ? getYear(item.endDate) : "Present"})
                          {/* {item.endDate ? getYear(item.endDate) : "Present"}) */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="description">{item.description}</p>
                </div>
                <div className="divider"></div>
              </>
            );
          })}
        {/* <div className="eduDetail">
        <div className="row">
          <div className="col-xxl-2 col-xl-2 col-lg-3">
            <img src={Anthropology} alt="" />
          </div>
          <div className="col-xxl-10 col-xl-10 col-lg-9">
            <h3>Bachelor of Science, Marketing</h3>
            <p>Utah Valley University - (2017 - 2021)</p>
          </div>
        </div>
        <p>
          I am proud to boast a Bachelor of Science in
          Marketing from Utah Valley University. With a
          3.79 GPA, I have proven myself to be a dedicated
          and persistent student with a 3.79 GPA, I have
          proven myself to be a dedicated.....
        </p>
      </div> */}
      </div>
    </div>
  );
};
const ExperienceSection = ({ accountsDetail, edit }) => {
  return (
    <div
      className={`candidatemidleft experienceSection ${
        accountsDetail.candidateProfileId?.workExperience.length > 0
          ? ""
          : "empty"
      }`}
    >
      <div className="candidatemidabout">
        <div className="candidatemidabout1">
          <h3>
            <img src={experiencepeople} alt="" />
            Experience
          </h3>
          {edit && (
            <div className="actionBtns">
              <button
                className="btn"
                data-bs-toggle="modal"
                data-bs-target="#addExperienceModalSingle"
              >
                <img src={addIcon} />
              </button>
              {accountsDetail &&
                accountsDetail?.candidateProfileId &&
                accountsDetail?.candidateProfileId?.workExperience &&
                accountsDetail?.candidateProfileId?.workExperience.length >
                  0 && (
                  <button
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#editExperienceModal"
                  >
                    <img src={editIcon} />
                  </button>
                )}
            </div>
          )}
        </div>
        {
          accountsDetail &&
            accountsDetail?.candidateProfileId &&
            accountsDetail?.candidateProfileId?.workExperience &&
            accountsDetail?.candidateProfileId?.workExperience.map((item) => {
              return (
                <>
                  <div className="eduDetail">
                    <p
                      className="mb-0 experienceHead"
                      // style={{ textTransform: "capitalize" }}
                    >
                      {item.designation}
                    </p>
                    <p>
                      {item.companyName} ({getYear(item.startDate)} -{" "}
                      {/* {getYear(item.endDate)}) */}
                      {!item.isPresent ? getYear(item.endDate) : "Present"})
                      {/* {item.endDate ? getYear(item.endDate) : "Present"}) */}
                    </p>
                    <p className="description">{item.description}</p>
                  </div>
                  <div className="divider"></div>
                </>
              );
            })
          // return (
          // )
        }

        {/* <div className="eduDetail">
        <p className="mb-0 experienceHead">
          Sales Manager
        </p>
        <p>Lumio - (2021 - 2023)</p>
        <p>
          As a highly motivated and accomplished solar
          sales manager at Lumio, I brought years of
          experience in developing and implementing
          effective sales and management strategies for
          renewable energy products.
        </p>
      </div> */}
      </div>
    </div>
  );
};
const CustomValuePrompt = ({
  edit,
  accountsDetail,
  currentCustomPrompt,
  setCurrentCustomPrompt,
}) => {
  let custom =
    accountsDetail &&
    accountsDetail.candidateProfileId &&
    accountsDetail.candidateProfileId.customValuePrompt &&
    accountsDetail.candidateProfileId.customValuePrompt.length > 0 &&
    accountsDetail.candidateProfileId.customValuePrompt.find(
      (item) => item.q === currentCustomPrompt
    );

  useEffect(() => {
    if (
      accountsDetail &&
      accountsDetail.candidateProfileId &&
      accountsDetail.candidateProfileId.customValuePrompt &&
      accountsDetail.candidateProfileId.customValuePrompt.length > 0
    ) {
      const firstItemWithAnswer =
        accountsDetail.candidateProfileId.customValuePrompt.find(
          (item) => item.a
        );

      if (firstItemWithAnswer) {
        setCurrentCustomPrompt(firstItemWithAnswer.q);
      }
    }
  }, [accountsDetail]);

  return (
    <>
      {edit ? (
        <div className="setupProfile mb-4">
          <h3>
            <img src={customPromptIcon} />
            Custom Value Prompt
          </h3>
          <button data-bs-toggle="modal" data-bs-target="#questionModal">
            <img src={editIcon} />
          </button>
        </div>
      ) : (
        <div
          className={`candidatemidleft mb-0 customPromptSection ${
            accountsDetail &&
            accountsDetail.candidateProfileId &&
            accountsDetail.candidateProfileId.customValuePrompt &&
            accountsDetail.candidateProfileId.customValuePrompt.length > 0
              ? ""
              : "empty"
          }`}
        >
          <div className="candidatemidabout">
            <div className="candidatemidabout1">
              <h3>
                <img src={customPromptIcon} alt="" /> Custom Value Prompt
              </h3>
              {edit && (
                <div className="actionBtns">
                  <button className="btn">
                    <img src={editIcon} />
                  </button>
                </div>
              )}
            </div>
            {accountsDetail &&
            accountsDetail.candidateProfileId &&
            accountsDetail.candidateProfileId.customValuePrompt &&
            accountsDetail.candidateProfileId.customValuePrompt.length > 0 ? (
              <div className="eduDetail">
                {accountsDetail &&
                accountsDetail.candidateProfileId &&
                accountsDetail.candidateProfileId.customValuePrompt &&
                accountsDetail.candidateProfileId.customValuePrompt.length >
                  0 ? (
                  <h5 class="promtHead">{custom && custom.q}</h5>
                ) : (
                  ""
                )}
                <p className="promptAnswer">{custom && custom.a}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
};
const SkillSection = ({ accountsDetail, edit }) => {
  return (
    <div className="candidatemidleft profileSkills">
      <div className="candidatemidabout">
        <div className="candidatemidabout1">
          <h3>
            <img src={starImg} alt="" /> Skills
            {edit && (
              <div className="actionBtns">
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#skillsModal"
                >
                  <img src={addIcon} />
                </button>
                {accountsDetail &&
                  accountsDetail?.candidateProfileId &&
                  accountsDetail?.candidateProfileId?.skills &&
                  accountsDetail?.candidateProfileId?.skills.length > 0 && (
                    <button
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#additionalSkillModal"
                    >
                      <img src={editIcon} />
                    </button>
                  )}
              </div>
            )}
          </h3>
        </div>
        {/* {accountsDetail &&
          accountsDetail?.candidateProfileId &&
          accountsDetail?.candidateProfileId?.skills &&
          accountsDetail?.candidateProfileId?.skills?.map((item) => { */}
        {/* return ( */}
        <div className="jobssectionbottom mt-4">
          <div
            className="tags"
            // style={{ textTransform: "capitalize" }}
          >
            {accountsDetail &&
              accountsDetail?.candidateProfileId &&
              accountsDetail?.candidateProfileId?.skills &&
              accountsDetail?.candidateProfileId?.skills?.map((item) => {
                return <span>{item.skillSet}</span>;
              })}
          </div>
        </div>
        {/* // <div className="progressOuter">
              //   <div>
              //     {item.skillSet} <span>{item.proficiencyGrade}%</span>
              //   </div> */}
        {/* //   <div
              //     className="progress"
              //     role="progressbar"
              //     aria-label="Basic example"
              //     aria-valuenow={item.proficiencyGrade}
              //     aria-valuemin="0"
              //     aria-valuemax="100"
              //   >
              //     <div
              //       className="progress-bar"
              //       style={{
              //         width: `${item.proficiencyGrade}%`,
              //       }}
              //     ></div>
              //   </div> */}

        {/* <div className="progressOuter">
        <p>
          Presenting <span>80%</span>
        </p>
        <div
          className="progress"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="80"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar"
            style={{ width: "80%" }}
          ></div>
        </div>
      </div> */}

        {/* <div className="progressOuter">
        <p>
          Management <span>85%</span>
        </p>
        <div
          className="progress"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="85"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar"
            style={{ width: "85%" }}
          ></div>
        </div>
      </div> */}

        {/* <div className="progressOuter">
        <p>
          Direct Sales <span>90%</span>
        </p>
        <div
          className="progress"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="90"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar"
            style={{ width: "90%" }}
          ></div>
        </div>
      </div> */}

        {/* <div className="progressOuter">
        <p>
          Critical Thinking <span>85%</span>
        </p>
        <div
          className="progress"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="85"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar"
            style={{ width: "85%" }}
          ></div>
        </div>
      </div> */}

        {/* <div className="progressOuter">
        <p>
          Microsoft Excel <span>90%</span>
        </p>
        <div
          className="progress"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="90"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className="progress-bar"
            style={{ width: "90%" }}
          ></div>
        </div>
      </div> */}
      </div>
    </div>
  );
};
const InviteStudent = ({ navigate }) => {
  return (
    <div className="col-xxl-12">
      <div className="sectionInvite commonRadius8 mb-4">
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
            <div className="sectionInvite__Content">
              {/* <h4>
                Invite your friends!{" "}
                <span>
                  Win tuition for a year or pay off your student loans!
                </span>
              </h4> */}

              <h4>
                Invite your friends! Earn an entry <br /> for a Fall 2024 full
                scholarship!
              </h4>
              <button
                className="btn commonRadius8"
                onClick={() => {
                  navigate("/affiliates");
                }}
              >
                Share my link!
              </button>
            </div>
          </div>
          {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
            <div className="sectionInvite__Img">
              <img src={inviteImg} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
// const ImageUploadComponent = () => {
//   return (
//     <ImageCropProvider>
//       <ImageCrop />
//     </ImageCropProvider>
//   );
// };
function Profile() {
  // useEffect(() => {
  //   let isFilled = false;
  //   if (form) {
  //     if (
  //       form.locations &&
  //       form.locations.state &&
  //       form.locations.city &&
  //       form.email &&
  //       form.about &&
  //       form.about.trim() !== "" && // Check if about is not an empty string
  //       form.qualification &&
  //       form.qualification.length > 0 &&
  //       form.workExperience &&
  //       form.workExperience.length > 0 &&
  //       form.skills &&
  //       form.skills.length > 0 &&
  //       form.customValuePrompt &&
  //       form.customValuePrompt.length > 0 &&
  //       form.displayImage
  //     ) {
  //       isFilled = true;
  //     }
  //   }
  // }, []);
  let navigate = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let defaultForm = {
    accountId: userInfo && userInfo._id,
    locations: {
      state: "",
      city: "",
    },
    about: "",
    qualification: [
      {
        university: "",
        degreeType: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ],
    workExperience: [
      {
        companyName: "",
        designation: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ],
    skills: [
      {
        skillSet: "",
        // proficiencyGrade: "",
      },
    ],
  };
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };

  //useState
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [form, setForm] = useState(defaultForm);
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [previewImage, setPreviewImage] = useState({
    displayImage: "",
  });
  const [displayImage, setDisplayImage] = useState("");
  const [isDisplayImageDeleted, setIsDisplayImageDeleted] = useState(false);
  const [accountsDetail, setAccountsDetail] = useState({});
  const [referralpoints, setReferralpoints] = useState("");
  const [currentCustomPrompt, setCurrentCustomPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialLoading, setInitaiLoading] = useState(true);
  const [currPage, setCurrPage] = useState("view");
  const [dismissModal, setDismissModal] = useState(false);
  // university state
  const [universityList, setUniversityList] = useState([]);
  const [isUniverSelected, setIsUniversitySelected] = useState(false);

  //company state
  const [companyList, setCompanyList] = useState([]);
  const [isCompanySelected, setIsCompanySelected] = useState(false);

  //function
  useEffect(() => {
    setIsLoading(true);
    // toastMessages("success", "This is testing text");
    getAccountDetail();
  }, [isProfileUpdated]);
  async function getAccountDetail() {
    setIsLoading(true);
    try {
      const response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status === 200) {
        setPercentageCompleted(response.data.total);
        // setAccountsDetail(json(response.data.data));
        setAccountsDetail(JSON.parse(JSON.stringify(response.data.data)));
        if (response && response.data && response.data.referralpoints) {
          setReferralpoints(response.data.referralpoints);
        }
        delete response.data.data._id;
        delete response.data.data._v;
        setForm({
          ...response.data.data,
          accountId: userInfo._id,
          about: response.data.data.about || "",
          customValuePrompt: response.data.data.candidateProfileId
            .customValuePrompt.length
            ? response.data.data.candidateProfileId.customValuePrompt
            : [
                {
                  q: "Something that sets me apart from the crowd",
                  a: "",
                },
                {
                  q: "A time I added measurable valuable for a client.",
                  a: "",
                },
                {
                  q: "A time I exceeded expectations.",
                  a: "",
                },
                {
                  q: "A time I elevated a company, culture, or workplace.",
                  a: "",
                },
                {
                  q: "Here's why I have the skills to thrive in the work place.",
                  a: "",
                },
                {
                  q: "A valuable lesson I learned from a mistake.",
                  a: "",
                },
                {
                  q: "Something I'm passionate about.",
                  a: "",
                },
                {
                  q: "Why my profile should stand out.",
                  a: "",
                },
                {
                  q: "Why you shouldn't judge a book by it's cover.",
                  a: "",
                },
              ],
        });

        // for showing all filled modal
        // let showModal = false;
        if (
          response &&
          response.data.data &&
          response.data.data.profileCompleted == false
        ) {
          // if (response.data.total == 100) {
          //   const uploadImageModal = new window.bootstrap.Modal(
          //     document.getElementById("postingModal")
          //   );
          //   uploadImageModal.show();
          // }
          // if (
          //   response &&
          //   response.data &&
          //   response.data.data &&
          //   response.data.data.candidateProfileId
          // ) {
          //   let data =
          //     response &&
          //     response.data &&
          //     response.data.data &&
          //     response.data.data.candidateProfileId;
          //   if (
          //     data.customValuePrompt &&
          //     data.customValuePrompt.length > 0 &&
          //     data.displayImage &&
          //     data.about
          //   ) {
          // }
          // else {
          // }
          // }
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  async function onSubmit(e, data, formData) {
    setIsLoading(true);
    e.preventDefault();
    try {
      if (formData) {
        let formData = new FormData();
        formData.append("file", data);
        formData.append("accountId", userInfo._id);
        let res = await apiPutMethod(
          allApiUrl.updateProfileCandidate,
          formData,
          header
        );
        if (res.status == 200) {
          setIsLoading(false);
          toastMessages("success", "Profile updated successfully");
          setCurrPage("edit");
          setIsProfileUpdated(!isProfileUpdated);
        }
      } else {
        let res = await apiPutMethod(
          allApiUrl.updateProfileCandidate,
          data,
          header
        );
        if (res.status == 200) {
          setIsLoading(false);
          setCurrPage("edit");
          toastMessages("success", "Profile updated successfully");
          setIsProfileUpdated(!isProfileUpdated);
        }
      }
    } catch (err) {
      // window.location.reload();
      setIsLoading(false);
      toastMessages("error", err.data.message);
      console.log("err", err.message);
    }
  }
  async function onUniversitySearchHandler(value) {
    try {
      const response = await apiPostMethod(
        allApiUrl.search,
        { text: value },
        header
      );
      if (response.status === 200) {
        setUniversityList(response.data.universities);
      }
    } catch (err) {
      console.log("err", err.data.message);
    }
  }
  async function onCompanyHandler(value) {
    try {
      const response = await apiPostMethod(
        allApiUrl.searchFirm,
        { text: value },
        header
      );
      if (response.status === 200) {
        let data = [];
        response.data.data.map((item) => {
          data.push({ firmName: item.firmName, firmId: item._id });
        });
        setCompanyList(data);
      }
    } catch (err) {}
  }
  let props = {
    form,
    setForm,
    onSubmit,
    userInfo,
    navigate,
    previewImage,
    setPreviewImage,
    setDisplayImage,
    setIsProfileUpdated,
    isProfileUpdated,
    dismissModal,
    setDismissModal,
    universityList,
    isUniverSelected,
    setIsUniversitySelected,
    onUniversitySearchHandler,
    isCompanySelected,
    setIsCompanySelected,
    onCompanyHandler,
    companyList,
  };

  if (isLoading) return <FullPageLoader />;
  // if (initialLoading) return <FullPageLoader />;
  return (
    <>
      <ProfileModal {...props} />
      <Sidebar
        activePage="myProfile"
        referralpoints={referralpoints}
        accountsDetail={accountsDetail}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Topbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="mainWrapper">
        <section className="profile sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                <div className="commonHeading candidateHeading">
                  {/* <h2>My Profile</h2> */}
                  <Greeting />
                  {/* <ImageUploadComponent /> */}
                  {/* <button
                    class="btn btn-primary"
                    data-bs-target="#locationModal"
                    data-bs-toggle="modal"
                  >
                    Open first modal
                  </button> */}
                  {/* <div className="release commonRadius8">
                    <p>
                      <span className="commonRadius8">
                        Full Release March 2024
                      </span>
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12">
                <div className="commonTabs">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        class={`nav-link ${currPage == "view" ? "active" : ""}`}
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        View
                      </button>
                      <button
                        class={`nav-link ${currPage == "edit" ? "active" : ""}`}
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Edit
                      </button>
                    </div>
                  </nav>
                </div>
              </div>

              <div class="tab-content" id="nav-tabContent">
                <div
                  class={`tab-pane fade ${
                    currPage == "view" ? "show active" : ""
                  }`}
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                  tabindex="0"
                >
                  <div className="row">
                    <div className="col-xxl-9 col-xl-8">
                      <InviteStudent navigate={navigate} />
                      <div className="pb-4">
                        <div className="candidateuser mt-0 mb-4">
                          <div className="row">
                            <div className="col-lg-9 col-md-9">
                              <BasicProfile
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                accountsDetail={accountsDetail}
                                edit={false}
                              />
                            </div>
                            <div className="col-lg-3 col-md-3">
                              {percentageCompleted !== 100 && (
                                <div className="profileCompletion">
                                  <CircularProgressbar
                                    value={percentageCompleted}
                                    text={`${percentageCompleted}%`}
                                  />
                                  <p>Completed</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <AboutSection
                          edit={false}
                          accountsDetail={accountsDetail}
                        />
                        <EducationSection
                          edit={false}
                          accountsDetail={accountsDetail}
                        />
                        <ExperienceSection
                          edit={false}
                          accountsDetail={accountsDetail}
                        />
                        <CustomValuePrompt
                          edit={false}
                          currentCustomPrompt={currentCustomPrompt}
                          setCurrentCustomPrompt={setCurrentCustomPrompt}
                          accountsDetail={accountsDetail}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-4">
                      <div className="profileRightOuter">
                        {accountsDetail &&
                          accountsDetail.candidateProfileId &&
                          accountsDetail.candidateProfileId.displayImage && (
                            <div className="candidateFullImg mb-4">
                              <img
                                src={
                                  accountsDetail.candidateProfileId
                                    .displayImage || dummyImg
                                }
                                alt=""
                                className="img-fluid maskimg"
                              />
                            </div>
                          )}
                        {/* <div className="candidateProfileRight">
                          <div className="candidatemidright1">
                            <h3 className="contactHeading">
                              <img src={contacticon} alt="" /> Contact Cameron
                            </h3>
                          </div>
                          <div className="candidatemidright2">
                            <a
                              href=""
                              className="sendmessage w-100 text-center"
                            >
                              Send message
                            </a>
                          </div>
                        </div> */}
                        <SkillSection
                          accountsDetail={accountsDetail}
                          edit={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class={`tab-pane fade ${
                    currPage == "edit" ? "show active" : ""
                  }`}
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                  tabindex="0"
                >
                  <div className="row">
                    <div className="col-xxl-9 col-xl-8">
                      <InviteStudent navigate={navigate} />
                      <div className="pb-4">
                        <div className="candidateuser mt-0 mb-4">
                          <BasicProfile
                            accountsDetail={accountsDetail}
                            edit={true}
                            onSubmit={onSubmit}
                            previewImage={previewImage}
                            setPreviewImage={setPreviewImage}
                            displayImage={displayImage}
                            setDisplayImage={setDisplayImage}
                            isDisplayImageDeleted={isDisplayImageDeleted}
                            setIsDisplayImageDeleted={setIsDisplayImageDeleted}
                          />
                        </div>
                        <AboutSection
                          edit={true}
                          accountsDetail={accountsDetail}
                        />
                        <EducationSection
                          edit={true}
                          accountsDetail={accountsDetail}
                        />
                        <ExperienceSection
                          edit={true}
                          accountsDetail={accountsDetail}
                        />
                        <CustomValuePrompt
                          edit={true}
                          accountsDetail={accountsDetail}
                          currentCustomPrompt={currentCustomPrompt}
                          setCurrentCustomPrompt={setCurrentCustomPrompt}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-4">
                      <div className="profileRightOuter">
                        <div className="photoUploadSection">
                          <p>
                            Add an extra picture to showcase more of who you
                            are!
                          </p>

                          <div
                            className="dragDrop"
                            //temporary changes
                            data-bs-target="#customImageUpload"
                            data-bs-toggle="modal"
                          >
                            {/* <input
                              type="file"
                              id="displayImage"
                              onChange={async (e) => {
                                if (e) {
                                  const file = e.target.files;
                                  if (
                                    file &&
                                    file[0] &&
                                    file[0].type &&
                                    file[0].type.split("/")[0] == "image"
                                  ) {
                                    // setIsLoading(true);
                                    setPreviewImage({
                                      ...previewImage,
                                      displayImage: URL.createObjectURL(
                                        file[0]
                                      ),
                                    });
                                    setDisplayImage(file[0]);
                                    setIsDisplayImageDeleted(false);
                                    try {
                                      let formData = new FormData();
                                      formData.append("displayImage", file[0]);
                                      formData.append(
                                        "accountId",
                                        userInfo._id
                                      );
                                      let res = await apiPutMethod(
                                        allApiUrl.updateProfileCandidate,
                                        formData,
                                        header
                                      );
                                      if (res.status == 200) {
                                        // setIsLoading(false);
                                        const uploadImageModal =
                                          new window.bootstrap.Modal(
                                            document.getElementById(
                                              "confirmationModal"
                                            )
                                          );
                                        uploadImageModal.show();
                                        setIsProfileUpdated(!isProfileUpdated);
                                      }
                                    } catch (err) {
                                      // setIsLoading(false);
                                      console.log("err", err);
                                    }
                                  } else {
                                    // setIsLoading(false);
                                    toastMessages(
                                      "error",
                                      "The image format is not correct"
                                    );
                                  }
                                }
                              }}
                            /> */}
                            <img src={uploadIcon} />
                            <p>
                              Drop your file here{" "}
                              <span className="green">or Browser</span>
                            </p>
                            <p>
                              We Support <span className="white">JPG, PNG</span>{" "}
                              files. Make sure that your file is no more than 500
                              kb.{" "}
                            </p>
                          </div>
                          {form.candidateProfileId &&
                            form.candidateProfileId.displayImage && (
                              <span className="uploadPreview">
                                {form.candidateProfileId &&
                                  form.candidateProfileId.displayImage && (
                                    <img
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "65px",
                                      }}
                                      src={form.candidateProfileId.displayImage}
                                      alt="headhot-image"
                                    />
                                  )}

                                {form.candidateProfileId &&
                                  form.candidateProfileId.displayImage && (
                                    <img
                                      className="crossIcon"
                                      src={whiteCross}
                                      // className="fa-solid fa-trash-can red"
                                      onClick={async () => {
                                        try {
                                          let formData = new FormData();
                                          formData.append("displayImage", "");
                                          formData.append(
                                            "accountId",
                                            userInfo._id
                                          );
                                          let res = await apiPutMethod(
                                            allApiUrl.updateProfileCandidate,
                                            formData,
                                            header
                                          );
                                          if (res.status == 200) {
                                            // setIsLoading(false);
                                            const uploadImageModal =
                                              new window.bootstrap.Modal(
                                                document.getElementById(
                                                  "deleteconfirmationModal"
                                                )
                                              );
                                            uploadImageModal.show();
                                            setIsProfileUpdated(
                                              !isProfileUpdated
                                            );
                                            setIsDisplayImageDeleted(true);
                                            setDisplayImage("");
                                            setPreviewImage({
                                              displayImage: "",
                                            });
                                            document.getElementById(
                                              "displayImage"
                                            ).value = null;
                                          }
                                        } catch (err) {
                                          // setIsLoading(false);
                                          console.log("err", err.message);
                                        }
                                      }}
                                    />
                                  )}
                              </span>
                            )}
                        </div>
                        <SkillSection
                          accountsDetail={accountsDetail}
                          edit={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Profile;
