import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const TextEditor = ({ form, setForm }) => {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setForm((prev) => {
      prev.description = data;
      return { ...prev };
    });
  };
  return (
    <div className="editorOuter">
      <CKEditor
        config={{ placeholder: "Job Description *" }}
        editor={ClassicEditor}
        data={form.description}
        onChange={handleEditorChange}
      />
    </div>
  );
};
