import editLight from "../../../assets/imgs/editLight.svg";
import viewIcon from "../../../assets/imgs/viewIcon.svg";
const AccountsTable = (props) => {
  return (
    <tbody>
      {props.userData &&
        props.userData.length > 0 &&
        props.userData.map((item, index) => {
          return (
            <tr key={item.id}>
              <td>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    checked={props.csvData.some(
                      (csvItem) => csvItem.id === item._id
                    )}
                    type="checkbox"
                    onClick={() => {
                      props.setCsvData((prev) => {
                        const existingIndex = prev.findIndex(
                          (csvItem) => csvItem.id === item._id
                        );
                        if (existingIndex !== -1) {
                          const updatedData = [
                            ...prev.slice(0, existingIndex),
                            ...prev.slice(existingIndex + 1),
                          ];
                          return updatedData;
                        } else {
                          let updatedItem = {
                            id: item._id,
                            "First name": item.firstName,
                            "Last name": item.lastName,
                            "Email ": item.email,
                            "Phone number": item.phoneNumber,
                            "Account type": item.accountType,
                            "Created at": new Date(
                              item.createdAt
                            ).toLocaleDateString(),
                          };
                          return [...prev, updatedItem];
                        }
                      });
                    }}
                  />
                </div>
              </td>
              <td>{index + 1}</td>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{item.email}</td>
              <td>{item.phoneNumber}</td>
              <td>
                {item.profileCompleted
                  ? item.profileCompleted.toString()
                  : "false"}
              </td>
              <td>{item.accountType}</td>
              <td>{new Date(item.createdAt).toLocaleDateString()}</td>
              <td>
                <div className="actionBtns">
                  {/* <button className="btn">
                    <img src={editLight} />
                  </button> */}
                  <button
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#adminUserAccount"
                    onClick={() => props.setCurrModalPreviewData(item)}
                  >
                    <img src={viewIcon} />
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};
export default AccountsTable;
