// -----------------------------------------------Auth---------------------------------------------------------
const signUp = "/auth/signup";
const login = "/auth/login";
const signinwithgoogle = "/auth/google";
const success = "/auth/success";
const forgotpassword = "/auth/forgot-password";
const changepassword = "/auth/change-password";
const googleAuth = "/auth/google-auth";
const logout = "/auth/logout";
const signinWithLinkedin = "/auth/linkedin-auth";
// ----------------------------------------------Pages----------------------------------------------------------
const profile = "/auth/profile";
const updateprofile = "/auth/update-account";
const searchFirm = "/firm/search-firm";
const updateProfileCandidate = "/candidate/update-profile";
const updateFirm = "/firm/update-firm";
//  ---------------------------------------------Job-------------------------------------------------------------
const createJob = "/job/create-job";
const viewfirmjobs = "/job/view-firm-jobs";
const specificJob = "/job/view-job";
const updateJob = "/job/update-job";
const jobUrl = "/job/view-job-by-url";
const applyJob = "/job/apply-for-job";
//-----------------------------------------------Testimonial-----------------------------------------------------
const createTestimonial = "/firm/create-testimonial";
const viewTestimonial = "/firm/view-firm-testimonials";
const viewSpecificTestimonial = "/firm/view-testimonial";
const updateTestimonial = "/firm/update-testimonial";
//--------------------------------------- -------Contact-Us-------------------------------------------------------
const contactUs = "/contact-us";
//-----------------------------------------------Department------------------------------------------------------
const department = "/list-departments";
const createDepartment = "/create-department";
//-----------------------------------------------University------------------------------------------------------
const universities = "/list-universities";
const search = "/search-university";
// ----------------------------------------------Admin-----------------------------------------------------------
const adminLogin = "/admin/login";
const adminDashboard = "/admin/dashboard-data";
const adminAnalytics = "/admin/get-all-data";
const createTeam = "/admin/create-team";
const getTeam = "/admin/get-team";
const listTeam = "/admin/list-team";
const getAllUsers = "/admin/get-user-data";
const getCustomData = "/admin/get-custom-data";
const updateEmployee = "/admin/update-employee";
const deleteJob = "/admin/delete-job";
// -------------------------Dashboard--------------------
const viewAllJobApplicants = "/job/view-all-applicants";

export const allApiUrl = {
  viewAllJobApplicants,
  applyJob,
  updateEmployee,
  deleteJob,
  listTeam,
  getAllUsers,
  jobUrl,
  getCustomData,
  createTeam,
  getTeam,
  signinWithLinkedin,
  adminDashboard,
  adminAnalytics,
  universities,
  adminLogin,
  search,
  specificJob,
  logout,
  updateJob,
  createDepartment,
  department,
  login,
  signUp,
  signinwithgoogle,
  success,
  forgotpassword,
  changepassword,
  profile,
  updateprofile,
  searchFirm,
  updateProfileCandidate,
  updateFirm,
  createJob,
  viewfirmjobs,
  createTestimonial,
  viewTestimonial,
  viewSpecificTestimonial,
  updateTestimonial,
  contactUs,
  googleAuth,
};
