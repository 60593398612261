import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import signinImg from "../assets/imgs/signinImg-cropped.svg";
import Logo from "../assets/imgs/HireBIRD.png";

import googleIcon from "../assets/imgs/googleIcon.svg";
import { useEffect, useState } from "react";
import { apiGetMethod, apiPostMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import toastMessages from "../components/toastMessage";
import { useToast } from "react-toastify";
import axios from "axios";
import FullPageLoader from "../components/fullPageLoader";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleOAuth,
  useGoogleLogin,
} from "@react-oauth/google";

export const GoogleAuth = () => {
  const { logout } = useGoogleOAuth();
  let navigate = useNavigate();
  const [data, setData] = useState({});
  const [isGoogleApiCall, setIsGoogleApiCalled] = useState(false);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  // useEffect(() => {
  //   if (isGoogleApiCall) {
  //     googleAuthHandler(data);
  //   }
  // }, [isGoogleApiCall]);
  // useEffect(() => {
  //   logout();
  // }, []);
  async function googleAuthHandler(data) {
    try {
      let res = await apiPostMethod(allApiUrl.googleAuth, data, "");
      if (res.status === 200) {
        navigate(`/welcome?token=${res.data.token}`);
      }
    } catch (err) {
      navigate("/noreferral");
      console.log("Error", err);
    }
  }
  const handleCustomButtonClick = () => {
    // Handle custom button click logic
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          setData(credentialResponse);
          setIsGoogleApiCalled(true);
        }}
        onError={() => {}}
      />
      <button onClick={handleCustomButtonClick}>Custom Button</button>
    </GoogleOAuthProvider>
  );
};

function Login() {
  const LINKEDIN_CLIENT_SECRET = "jrJPQiY8QcSeQ92e";
  const YOUR_CLIENT_ID = "862r923hyyd6je";
  const YOUR_REDIRECT_URI = "http://localhost:3000/welcome";
  const handleLinkedInLogin = () => {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${YOUR_CLIENT_ID}&redirect_uri=${YOUR_REDIRECT_URI}&state=STATE&scope=email,profile`;
    // window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=YOUR_CLIENT_ID&redirect_uri=YOUR_REDIRECT_URI&state=STATE&scope=r_liteprofile%20r_emailaddress`;
  };
  const navigate = useNavigate();
  let isUserLoggedIn = localStorage.getItem("isLoggedIn");
  let accountType = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    setisLoading(true);
    if (isUserLoggedIn) {
      if (accountType.accountType == "recruiter") {
        navigate("/profile");
      } else if (accountType.accountType == "jobseeker") {
        navigate("/my-profile");
      } else {
        localStorage.clear();
        navigate("/login");
      }
    } else {
      setisLoading(false);
    }
  }, [isUserLoggedIn]);

  const [form, setForm] = useState({
    email: "",
    password: "",
    // rememberMe: false,
  });
  const [user, setUser] = useState(null);
  const [viewPassword, setViewPassword] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  async function onSubmit(e) {
    setisLoading(true);
    e.preventDefault();
    try {
      const response = await apiPostMethod(allApiUrl.login, form, "");
      if (response.status === 200) {
        toastMessages(
          "success",
          `Hey ${response.data.data.firstName}! Nice to see you`,
          "Login Successfully"
          // {`Hey ${first}! Nice to see you`}
          // response.data.message + " " + response.data.data.firstName
        );
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userInfo", JSON.stringify(response.data.data));
        if (response.data.data.accountType) {
          if (response.data.data.accountType == "recruiter") {
            if (
              response &&
              response.data &&
              response.data.data &&
              response.data.data.employerProfileId
              // response.data.data.employerProfileId.firmName ||
              // !!response.data.data.employerProfileId.description ||
              // !!response.data.data.employerProfileId.aboutus ||
              // !!response.data.data.employerProfileId.firmLogo
            ) {
              navigate("/profile");
            } else {
              navigate("/onboarding-recruiter");
            }
          } else {
            if (
              response.data.data.candidateProfileId.location ||
              // response.data.data.candidateProfileId.locations.state ||
              // response.data.data.candidateProfileId.locations.city ||
              !!response.data.data.candidateProfileId.qualification.length ||
              !!response.data.data.candidateProfileId.workExperience.length ||
              !!response.data.data.candidateProfileId.skills.length
            ) {
              navigate("/my-profile");
            } else {
              navigate("/onboarding-process");
            }
          }
        } else {
          navigate("/welcome");
        }
        setisLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setisLoading(false);
      toastMessages("error", err.data.message);
    }
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  };
  async function signInGoogleHandler(e) {
    e.preventDefault();

    // window.open(
    //   `${process.env.REACT_APP_API_BASE_URL}/auth/google?referredBy`,
    //   "_self"
    // );
  }
  async function getUser() {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/auth/success`;
      const { data } = await axios.get(url, { withCredentials: true });
      setUser(data.user._json);
      // const response= apiGetMethod(allApiUrl.success,)
    } catch (err) {}
  }
  // useEffect(() => {
  //   getUser();
  // }, []);
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      googleAuthHandler(codeResponse.access_token);
    }, // flow: 'auth-code',
  });
  async function googleAuthHandler(data) {
    setisLoading(true);
    try {
      let res = await apiPostMethod(
        allApiUrl.googleAuth,
        { access_token: data },
        ""
      );
      if (res.status === 200) {
        setisLoading(false);
        navigate(`/welcome?token=${res.data.token}`);
      }
    } catch (err) {
      setisLoading(false);
      navigate("/noreferral");
    }
  }
  if (isLoading) return <FullPageLoader />;
  return (
    <>
      <section className="formSection roleSelectorMain">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="formSection__Img">
                {/* <button onClick={handleLinkedInLogin}>
                  Login with LinkedIn
                </button> */}
                <img src={signinImg} alt="" />
              </div>
            </div>
            <div className="col-lg-5 ms-auto">
              <div className="formSection__content">
                <div className="text-center mb-4">
                  <p
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    <img className="logoImg" src={Logo} alt="HireBird Logo" />
                  </p>
                </div>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      onSubmit(e);
                    }
                  }}
                >
                  <h2>Sign in</h2>
                  <p>Sign in here to continue.</p>
                  <div className="googleLogin">
                    {/* <button
                      className="btn btn-default"
                      onClick={(e) => signInGoogleHandler(e)}
                    >
                      <img src={googleIcon} /> Sign in with Google
                    </button> */}
                    {/* <GoogleAuth /> */}
                    <button onClick={() => login()} className="btn btn-default">
                      Sign in with Google
                    </button>
                  </div>
                  <div className="divider">
                    <p>Or Sign in with Email</p>
                  </div>

                  <div className="form-group">
                    <input
                      required
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      value={form.email}
                      onChange={handleChange}
                      autocomplete="off"
                    />
                    <span>
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                  </div>

                  <div className="form-group">
                    <input
                      required
                      name="password"
                      type={`${!viewPassword ? "text" : "password"}`}
                      className="form-control"
                      id="password"
                      placeholder="Enter Password"
                      value={form.password}
                      onChange={handleChange}
                      autocomplete="off"
                    />
                    <i
                      onClick={() => setViewPassword(!viewPassword)}
                      className={`toggle-password fa fa-fw fa-eye${
                        viewPassword ? "-slash" : ""
                      }`}
                    ></i>
                    <span>
                      <i className="fa-solid fa-lock"></i>
                    </span>
                  </div>

                  <div className="passwordRemember">
                    <div className="form-check">
                      {/* <input
                        className="form-check-input"
                        type="checkbox"
                        // checked={form.rememberMe}
                        // onChange={(e) => {
                        //   if (e.target.checked) {
                        //     setForm({ ...form, rememberMe: true });
                        //   } else {
                        //     setForm({ ...form, rememberMe: false });
                        //   }
                        // }}
                        id="flexCheckDefault"
                      /> */}
                      {/* <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Remember Me
                      </label> */}
                    </div>
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>

                  <button
                    className="btn commonBtb"
                    onClick={onSubmit}
                    disabled={!form.email || !form.password ? true : false}
                  >
                    Sign in <i className="fa-solid fa-angles-right"></i>
                  </button>
                  <p className="mt-3 mb-0">
                    Don't have an account?{" "}
                    <Link to="/create-account">Sign up</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
