import classNames from "classnames";
const EditorModal = ({ open, children }) => {
    return (
        <div
            className={classNames('fixed top-0 w-full left-0', {
                hidden: !open
            })}
            id="modal"
        >
            <div className="uploaderOuter">
                <div className="fixed ">
                    {/* <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen">​</span> */}
                    <div
                        className="uploaderInner"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        <div className="">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditorModal;