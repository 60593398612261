import Sidebar from "../components/sidebar";
import Topbar from "../components/topBar";
import Footer from "../components/footer";
// import Modals from "../view/modal";
import jobIcon from "../assets/imgs/jobImg.png";
import locationIcon from "../assets/imgs/locationIconGreen.svg";
import editIcon from "../assets/imgs/editIcon.svg";
import deleteIcon from "../assets/imgs/iconDelete.svg";
import { useEffect, useState } from "react";
import FullPageLoader from "../components/fullPageLoader";
import { allApiUrl } from "../api/apiRoutes";
import { apiGetMethod, apiPostMethod } from "../api/rest";
import TimeAgo from "../components/daysAgoComponent";
import DOMPurify from "dompurify";
import DashboardModal from "../components/modals/dashboardModal";
import toastMessages from "../components/toastMessage";
import { useLocation, useNavigate } from "react-router-dom";
import dummyImg from "../assets/imgs/dummyImg.jpg";
import totaljobPosted from "../assets/imgs/totaljobPosted.svg";
function Dashboard() {
  let location = useLocation();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [firmJobs, setFirmJobs] = useState([]);
  const [deleteJobId, setDeleteJobId] = useState("");
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [jobData, setJobData] = useState({});
  const [step, setStep] = useState(33.33);

  // const [showFullDescription, setShowFullDescription] = useState(false);
  // const maxLength = 200; // Maximum length of description to show initially

  // const toggleDescription = () => {
  //   setShowFullDescription(!showFullDescription);
  // };

  let header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  useEffect(() => {
    getfirmjobs();
  }, [isProfileUpdated]);
  async function getfirmjobs() {
    setIsLoading(true);
    try {
      const response = await apiGetMethod(allApiUrl.viewfirmjobs, "", header);
      if (response.status === 200) {
        setFirmJobs(response.data.post);
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err.data.message);
      setIsLoading(false);
    }
  }
  async function onJobDeleteHandler() {
    try {
      let data = {
        firmId: deleteJobId,
        isDeleted: true,
      };
      let res = await apiPostMethod(
        `${allApiUrl.updateJob}/${deleteJobId}`,
        data,
        header
      );
      if (res.status == 200) {
        setDeleteJobId("");
        toastMessages("success", "Job deleted successfully");
        setIsProfileUpdated(!isProfileUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  }
  async function onJobDataHandler(e, value) {
    e.preventDefault();
    console.log("value", value);
    let resultString;
    let formattedData = parseFloat(
      value.salary.replace(/[^\d.-]/g, "")
    ).toFixed(2);
    resultString = `$${formattedData.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    let resultStringUpto;
    if (value.salaryType == "WithinRange" && value.salaryUpto) {
      let formattedDataUpto = parseFloat(
        value.salaryUpto.replace(/[^\d.-]/g, "")
      ).toFixed(2);
      resultStringUpto = `$${formattedDataUpto.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      )}`;
      resultString = resultString + "-" + resultStringUpto;
    }
    const sanitizedHTML = DOMPurify.sanitize(value.description);
    setJobData({
      ...value,
      updatedSalary: resultString,
      sanitizedHTML: sanitizedHTML,
    });
  }
  if (isLoading) return <FullPageLoader />;
  return (
    <>
      <DashboardModal
        onJobDeleteHandler={onJobDeleteHandler}
        setDeleteJobId={setDeleteJobId}
        isLoading={isLoading}
        jobData={jobData}
        setStep={setStep}
        step={step}
      />
      <Sidebar
        activePage="dashboard"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Topbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="mainWrapper">
        <section className="jobPosted sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12">
                <div className="commonHeading">
                  <h2>Jobs Posted</h2>
                </div>
                {firmJobs &&
                  firmJobs.length > 0 &&
                  firmJobs.map((item) => {
                    let resultString;
                    let formattedData = parseFloat(
                      item.salary.replace(/[^\d.-]/g, "")
                    ).toFixed(2);
                    resultString = `$${formattedData.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}`;
                    let resultStringUpto;
                    if (item.salaryType == "WithinRange" && item.salaryUpto) {
                      let formattedDataUpto = parseFloat(
                        item.salaryUpto.replace(/[^\d.-]/g, "")
                      ).toFixed(2);
                      resultStringUpto = `$${formattedDataUpto.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}`;
                      resultString = resultString + "-" + resultStringUpto;
                    }
                    // item.description.slice(0, maxLength) + "..." : item.description; // Show truncated description if it's longer than 'maxLength'
                    const sanitizedHTML = DOMPurify.sanitize(
                      item.description.slice(0, 300)
                    );
                    //  +
                    // (
                    //   <button
                    //     className="btn btn-link"
                    //     style={{ color: "#61D180" }}
                    //     onClick={() => navigate(`/view-job/${item._id}`)}
                    //   >
                    //     See More
                    //   </button>
                    // );
                    // const sanitizedHTML = DOMPurify.sanitize(item.description);
                    // console.log("sanitizedHTML", sanitizedHTML);
                    return (
                      <div className="jobPosted__List">
                        <div className="postHeader">
                          <div className="postImg">
                            <img
                              src={
                                item && item.firmId && item.firmId.firmLogo
                                  ? item.firmId.firmLogo
                                  : dummyImg
                              }
                            />
                          </div>
                          <div className="postText">
                            <h4 className="white">
                              {item.title}{" "}
                              <span className="green">
                                <img src={locationIcon} />
                                {item.location}
                              </span>
                            </h4>
                            <TimeAgo timestamp={item.createdAt} />
                            {/* <p>10 min. ago</p> */}
                          </div>

                          <div className="headBtns">
                            <button
                              className="btn"
                              onClick={() => navigate(`/edit-job/${item._id}`)}
                            >
                              <img src={editIcon} />
                            </button>

                            <button
                              className="btn"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteTestimonialJob"
                              onClick={() => setDeleteJobId(item._id)}
                            >
                              <img src={deleteIcon} />
                            </button>
                          </div>
                        </div>

                        <div className="postBodyContent">
                          <h4>
                            {resultString}/ <span>{item.timeperiod}</span>
                          </h4>
                          <div
                            className="detailText"
                            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                          />
                          <button
                            className="btn btn-link"
                            style={{ color: "#61D180" }}
                            onClick={() => navigate(`/view-job/${item._id}`)}
                          >
                            See More
                          </button>
                        </div>
                        <div className="postFooter">
                          <div className="row">
                            <div className="col-xl-6">
                              <div className="jobssectionbottom">
                                <div
                                  className="tags"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <span>{item.timeperiod}</span>
                                  <span>{item.jobType}</span>
                                  {item.location ? (
                                    <span>
                                      {item.location &&
                                        item.location.split(",")[0]}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <span>{item.positionType}</span>
                                </div>
                                {/* <a>Apply Now</a> */}
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="btnSection">
                                <a
                                  className="btn btn-share"
                                  data-bs-toggle="modal"
                                  data-bs-target="#shareJobModal"
                                  onClick={(e) => onJobDataHandler(e, item)}
                                >
                                  Share my Job Posting
                                </a>
                                <a
                                  className="btn btn-save"
                                  onClick={() =>
                                    navigate(`/view-job/${item._id}`)
                                  }
                                >
                                  View Details
                                </a>
                              </div>
                              {/* <div className="btnSection">
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="col-xl-12">
                  <div className="cppagination">
                    <a href="" className="paginationarrowleft">
                      <i className="fa-solid fa-angles-left"></i>
                    </a>
                    <a href="" className="">
                      1
                    </a>
                    <a href="" className="">
                      2
                    </a>
                    <a href="" className="">
                      3
                    </a>
                    <a href="" className="">
                      4
                    </a>
                    <a href="" className="paginationarrowright">
                      <i className="fa-solid fa-angles-right"></i>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
// import Sidebar from "../components/sidebar";
// import Topbar from "../components/topBar";
// import Footer from "../components/footer";
// import briefcaseIcon from "../assets/imgs/briefcaseIcon.svg";
// import analyticsIcons from "../assets/imgs/analyticsIcons.svg";
// import applicantIcon from "../assets/imgs/applicantIcon.svg";
// import filterIcon from "../assets/imgs/filterIcon.svg";
// import candidateImg from "../assets/imgs/candidateImg.png";
// import messageBtn from "../assets/imgs/messageBtn.svg";
// import contactIcon from "../assets/imgs/contactIcon.svg";
// import message from "../assets/imgs/message.svg";
// import allApplicantsIcon from "../assets/imgs/allApplicantsIcon.svg";
// import shortlistedIcon from "../assets/imgs/shortlistedIcon.svg";
// import timerYellow from "../assets/imgs/timerYellow.svg";
// import rejectedIcon from "../assets/imgs/rejectedIcon.svg";
// import moreIcon from "../assets/imgs/moreIcon.svg";
// import jobIcon from "../assets/imgs/jobImg.png";
// import locationIcon from "../assets/imgs/locationIconGreen.svg";
// import editIcon from "../assets/imgs/editIcon.svg";
// import deleteIcon from "../assets/imgs/iconDelete.svg";
// import { useEffect, useState } from "react";
// import FullPageLoader from "../components/fullPageLoader";
// import { allApiUrl } from "../api/apiRoutes";
// import { apiGetMethod, apiPostMethod } from "../api/rest";
// import TimeAgo from "../components/daysAgoComponent";
// import DOMPurify from "dompurify";
// import DashboardModal from "../components/modals/dashboardModal";
// import toastMessages from "../components/toastMessage";
// import { useLocation, useNavigate } from "react-router-dom";
// import dummyImg from "../assets/imgs/dummyImg.jpg";
// import totaljobPosted from "../assets/imgs/totaljobPosted.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { addTodo } from "../store/authslice";
// const JobPosted = ({
//   firmJobs,
//   setJobData,
//   navigate,
//   setDeleteJobId,
//   deleteJobId,
//   // firmJobs,
//   setFirmJobs,
// }) => {
//   async function onJobDataHandler(e, value) {
//     e.preventDefault();
//     let resultString;
//     let formattedData = parseFloat(
//       value.salary.replace(/[^\d.-]/g, "")
//     ).toFixed(2);
//     resultString = `$${formattedData.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
//     let resultStringUpto;
//     if (value.salaryType == "WithinRange" && value.salaryUpto) {
//       let formattedDataUpto = parseFloat(
//         value.salaryUpto.replace(/[^\d.-]/g, "")
//       ).toFixed(2);
//       resultStringUpto = `$${formattedDataUpto.replace(
//         /\B(?=(\d{3})+(?!\d))/g,
//         ","
//       )}`;
//       resultString = resultString + "-" + resultStringUpto;
//     }
//     const sanitizedHTML = DOMPurify.sanitize(value.description);
//     setJobData({
//       ...value,
//       updatedSalary: resultString,
//       sanitizedHTML: sanitizedHTML,
//     });
//   }
//   console.log("firmJobs", firmJobs);
//   return (
//     <section className="jobPosted sectionPadding">
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-xxl-12">
//             <div className="commonHeading">
//               <h2>Jobs Posted</h2>
//             </div>
//             {firmJobs &&
//               firmJobs.length > 0 &&
//               firmJobs.map((item) => {
//                 let resultString;
//                 let formattedData = parseFloat(
//                   item.salary.replace(/[^\d.-]/g, "")
//                 ).toFixed(2);
//                 resultString = `$${formattedData.replace(
//                   /\B(?=(\d{3})+(?!\d))/g,
//                   ","
//                 )}`;
//                 let resultStringUpto;
//                 if (item.salaryType == "WithinRange" && item.salaryUpto) {
//                   let formattedDataUpto = parseFloat(
//                     item.salaryUpto.replace(/[^\d.-]/g, "")
//                   ).toFixed(2);
//                   resultStringUpto = `$${formattedDataUpto.replace(
//                     /\B(?=(\d{3})+(?!\d))/g,
//                     ","
//                   )}`;
//                   resultString = resultString + "-" + resultStringUpto;
//                 }
//                 // item.description.slice(0, maxLength) + "..." : item.description; // Show truncated description if it's longer than 'maxLength'
//                 const sanitizedHTML = DOMPurify.sanitize(
//                   item.description.slice(0, 300)
//                 );
//                 //  +
//                 // (
//                 //   <button
//                 //     className="btn btn-link"
//                 //     style={{ color: "#61D180" }}
//                 //     onClick={() => navigate(`/view-job/${item._id}`)}
//                 //   >
//                 //     See More
//                 //   </button>
//                 // );
//                 // const sanitizedHTML = DOMPurify.sanitize(item.description);
//                 return (
//                   <div className="jobPosted__List">
//                     <div className="postHeader">
//                       <div className="postImg">
//                         <img
//                           src={
//                             item && item.firmId && item.firmId.firmLogo
//                               ? item.firmId.firmLogo
//                               : dummyImg
//                           }
//                         />
//                       </div>
//                       <div className="postText">
//                         <h4 className="white">
//                           {item.title}{" "}
//                           <span className="green">
//                             <img src={locationIcon} />
//                             {item.location}
//                           </span>
//                         </h4>
//                         <TimeAgo timestamp={item.createdAt} />
//                         {/* <p>10 min. ago</p> */}
//                       </div>

//                       <div className="headBtns">
//                         <button
//                           className="btn"
//                           onClick={() => navigate(`/edit-job/${item._id}`)}
//                         >
//                           <img src={editIcon} />
//                         </button>

//                         <button
//                           className="btn"
//                           data-bs-toggle="modal"
//                           data-bs-target="#deleteTestimonialJob"
//                           onClick={() => setDeleteJobId(item._id)}
//                         >
//                           <img src={deleteIcon} />
//                         </button>
//                       </div>
//                     </div>

//                     <div className="postBodyContent">
//                       <h4>
//                         {resultString}/ <span>{item.timeperiod}</span>
//                       </h4>
//                       <div
//                         className="detailText"
//                         dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
//                       />
//                       <button
//                         className="btn btn-link"
//                         style={{ color: "#61D180" }}
//                         onClick={() => navigate(`/view-job/${item._id}`)}
//                       >
//                         See More
//                       </button>
//                     </div>
//                     <div className="postFooter">
//                       <div className="row">
//                         <div className="col-xl-6">
//                           <div className="jobssectionbottom">
//                             <div
//                               className="tags"
//                               style={{ textTransform: "capitalize" }}
//                             >
//                               <span>{item.timeperiod}</span>
//                               <span>{item.jobType}</span>
//                               {item.location ? (
//                                 <span>
//                                   {item.location && item.location.split(",")[0]}
//                                 </span>
//                               ) : (
//                                 ""
//                               )}
//                               <span>{item.positionType}</span>
//                             </div>
//                             {/* <a>Apply Now</a> */}
//                           </div>
//                         </div>
//                         <div className="col-xl-6">
//                           <div className="btnSection">
//                             <a
//                               className="btn btn-share"
//                               data-bs-toggle="modal"
//                               data-bs-target="#shareJobModal"
//                               onClick={(e) => onJobDataHandler(e, item)}
//                             >
//                               Share my Job Posting
//                             </a>
//                             <a
//                               className="btn btn-save"
//                               onClick={() => navigate(`/view-job/${item._id}`)}
//                             >
//                               View Details
//                             </a>
//                           </div>
//                           {/* <div className="btnSection">
//                         </div> */}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//             {/* <div className="col-xl-12">
//             <div className="cppagination">
//               <a href="" className="paginationarrowleft">
//                 <i className="fa-solid fa-angles-left"></i>
//               </a>
//               <a href="" className="">
//                 1
//               </a>
//               <a href="" className="">
//                 2
//               </a>
//               <a href="" className="">
//                 3
//               </a>
//               <a href="" className="">
//                 4
//               </a>
//               <a href="" className="paginationarrowright">
//                 <i className="fa-solid fa-angles-right"></i>
//               </a>
//             </div>
//           </div> */}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };
// function Dashboard() {
//   let location = useLocation();
//   let navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(false);
//   const [sidebarOpen, setSidebarOpen] = useState(false);
//   const [firmJobs, setFirmJobs] = useState([]);
//   const [deleteJobId, setDeleteJobId] = useState("");
//   const [isProfileUpdated, setIsProfileUpdated] = useState(false);
//   const [jobData, setJobData] = useState({});
//   const [step, setStep] = useState(33.33);
//   const [newApplicantsList, setNewApplicantsList] = useState([]);
//   const [currentTab, setCurrentTab] = useState("dashboard");
//   let header = {
//     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//   };
//   useEffect(() => {
//     getfirmjobs();
//   }, [isProfileUpdated]);
//   async function getfirmjobs() {
//     setIsLoading(true);
//     try {
//       const response = await apiGetMethod(allApiUrl.viewfirmjobs, "", header);
//       if (response.status === 200) {
//         setFirmJobs(response.data.post);
//         // apiGetMethod(allApiUrl.viewAllJobApplicants, "", header).then(
//         //   (values) => {
//         //     setNewApplicantsList(values.data.job);
//         //   }
//         // );
//         setIsLoading(false);
//       }
//     } catch (err) {
//       console.log("err", err.data.message);
//       setIsLoading(false);
//     }
//   }
//   async function onJobDeleteHandler() {
//     try {
//       let data = {
//         firmId: deleteJobId,
//         isDeleted: true,
//       };
//       let res = await apiPostMethod(
//         `${allApiUrl.updateJob}/${deleteJobId}`,
//         data,
//         header
//       );
//       if (res.status == 200) {
//         setDeleteJobId("");
//         toastMessages("success", "Job deleted successfully");
//         setIsProfileUpdated(!isProfileUpdated);
//       }
//     } catch (err) {
//       toastMessages("error", err.data.message);
//     }
//   }
//   // initial the dispatch here
//   const dispatch = useDispatch();
//   if (isLoading) return <FullPageLoader />;
//   return (
//     <>
//       <DashboardModal
//         onJobDeleteHandler={onJobDeleteHandler}
//         setDeleteJobId={setDeleteJobId}
//         isLoading={isLoading}
//         jobData={jobData}
//         setStep={setStep}
//         step={step}
//       />
//       <Topbar />
//       <div className="mainWrapper">
//         <Sidebar
//           activePage="dashboard"
//           sidebarOpen={sidebarOpen}
//           setSidebarOpen={setSidebarOpen}
//         />
//         {currentTab == "dashboard" ? (
//           <div className="dashboard py-4">
//             <div className="container-fluid">
//               <div className="row">
//                 <div className="col-xl-6 col-lg-6 col-md-6">
//                   <div className="commonHeading">
//                     <h2>Dashboard</h2>
//                   </div>
//                 </div>
//                 <div className="col-xl-6 col-lg-6 col-md-6">
//                   <div className="text-end">
//                     <button
//                       className="btn commonBtn"
//                       onClick={() => dispatch(addTodo("hello"))}
//                     >
//                       Manage job posts
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-lg-4 col-md-4">
//                   <div
//                     className="commonCard"
//                     onClick={() => setCurrentTab("jobPosted")}
//                   >
//                     <a>
//                       <span>
//                         <img src={briefcaseIcon} />
//                       </span>

//                       <h4>Jobs Posted</h4>
//                     </a>
//                   </div>
//                 </div>

//                 <div className="col-lg-4 col-md-4">
//                   <div
//                     className="commonCard"
//                     onClick={() => setCurrentTab("viewAnalytics")}
//                   >
//                     <a href="">
//                       <span>
//                         <img src={analyticsIcons} />
//                       </span>

//                       <h4>View Analytics</h4>
//                     </a>
//                   </div>
//                 </div>

//                 <div className="col-lg-4 col-md-4">
//                   <div
//                     className="commonCard"
//                     onClick={() => navigate("/applicants")}
//                   >
//                     <a>
//                       <span>
//                         <img src={applicantIcon} />
//                       </span>

//                       <h4>Applicants</h4>
//                     </a>
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-lg-8">
//                   <div className="card">
//                     <div className="card-header">
//                       <h3>New Applicants </h3>
//                       <div class="dropdown">
//                         <button
//                           class="btn btn-secondary dropdown-toggle"
//                           type="button"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                         >
//                           <img src={filterIcon} />
//                         </button>
//                         <ul class="dropdown-menu">
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="Position"
//                               />
//                               <label class="form-check-label" for="Position">
//                                 Position
//                               </label>
//                             </div>
//                           </li>
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="profilePhoto"
//                               />
//                               <label
//                                 class="form-check-label"
//                                 for="profilePhoto"
//                               >
//                                 Name with profile photo
//                               </label>
//                             </div>
//                           </li>
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="Location"
//                               />
//                               <label class="form-check-label" for="Location">
//                                 Location
//                               </label>
//                             </div>
//                           </li>
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="University"
//                               />
//                               <label class="form-check-label" for="University">
//                                 University
//                               </label>
//                             </div>
//                           </li>
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="Experience"
//                               />
//                               <label class="form-check-label" for="Experience">
//                                 Experience
//                               </label>
//                             </div>
//                           </li>
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="Messages"
//                               />
//                               <label class="form-check-label" for="Messages">
//                                 Messages
//                               </label>
//                             </div>
//                           </li>
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="Action"
//                               />
//                               <label class="form-check-label" for="Action">
//                                 Action
//                               </label>
//                             </div>
//                           </li>
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="ScheduleInterview"
//                               />
//                               <label
//                                 class="form-check-label"
//                                 for="ScheduleInterview"
//                               >
//                                 Schedule Interview
//                               </label>
//                             </div>
//                           </li>
//                           <li>
//                             <div class="form-check">
//                               <input
//                                 class="form-check-input"
//                                 type="checkbox"
//                                 value=""
//                                 id="Capabilities"
//                               />
//                               <label
//                                 class="form-check-label"
//                                 for="Capabilities"
//                               >
//                                 Capabilities
//                               </label>
//                             </div>
//                           </li>
//                         </ul>
//                       </div>
//                     </div>
//                     <div className="card-body dashboardCardBody">
//                       <div className="table-resposnive">
//                         <table className="table">
//                           <thead>
//                             <tr>
//                               <th>Name</th>
//                               <th>Location</th>
//                               <th>University</th>
//                               <th>Message</th>
//                               <th>Action</th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {newApplicantsList &&
//                             newApplicantsList.length > 0 ? (
//                               newApplicantsList.map((item) => {
//                                 let universityName = "-";
//                                 if (
//                                   item.applicantId.candidateProfileId
//                                     .qualification.length > 0
//                                 ) {
//                                   universityName =
//                                     item.applicantId.candidateProfileId
//                                       .qualification[0].university;
//                                 }
//                                 return (
//                                   <tr>
//                                     <td>
//                                       <span>
//                                         <img
//                                           src={
//                                             item.applicantId.profileImage ||
//                                             dummyImg
//                                           }
//                                         />{" "}
//                                       </span>
//                                       {item.applicantId.firstName +
//                                         " " +
//                                         item.applicantId.lastName}
//                                     </td>
//                                     <td>Arizona</td>
//                                     <td>{universityName}</td>
//                                     <td>
//                                       <button>
//                                         <img src={messageBtn} />
//                                       </button>
//                                     </td>
//                                     <td>
//                                       <div className="btns">
//                                         <a href="">View</a>
//                                         <a href="">Schedule Interview</a>
//                                       </div>
//                                     </td>
//                                   </tr>
//                                 );
//                               })
//                             ) : (
//                               <tr>
//                                 <td>No Data Found</td>
//                               </tr>
//                             )}
//                           </tbody>
//                         </table>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-lg-4">
//                   <div className="activity">
//                     <div className="card activityCard">
//                       <div className="card-header">
//                         <h3>Recent activities</h3>
//                       </div>
//                       <div className="card-body dashboardCardBody">
//                         <ul>
//                           <li>
//                             <a href="">
//                               <span className="userImg">
//                                 <img src={contactIcon} />
//                               </span>
//                               <p>
//                                 {" "}
//                                 Cameron A. <span className="yellow">
//                                   (94%)
//                                 </span>{" "}
//                                 applied to{" "}
//                                 <span className="green">
//                                   Social Media Marketing Manager.
//                                 </span>{" "}
//                                 <br />
//                                 12 min ago.
//                               </p>
//                             </a>
//                           </li>

//                           <li>
//                             <a href="">
//                               <span className="userImg">
//                                 <img src={contactIcon} />
//                               </span>
//                               <p>
//                                 {" "}
//                                 Cameron A. <span className="yellow">
//                                   (94%)
//                                 </span>{" "}
//                                 applied to{" "}
//                                 <span className="green">
//                                   Social Media Marketing Manager.
//                                 </span>{" "}
//                                 <br />
//                                 12 min ago.
//                               </p>
//                             </a>
//                           </li>

//                           <li>
//                             <a href="">
//                               <span className="userImg">
//                                 <img src={contactIcon} />
//                               </span>
//                               <p>
//                                 {" "}
//                                 Cameron A. <span className="yellow">
//                                   (94%)
//                                 </span>{" "}
//                                 applied to{" "}
//                                 <span className="green">
//                                   Social Media Marketing Manager.
//                                 </span>{" "}
//                                 <br />
//                                 12 min ago.
//                               </p>
//                             </a>
//                           </li>

//                           <li>
//                             <a href="">
//                               <span className="userImg">
//                                 <img src={contactIcon} />
//                               </span>
//                               <p>
//                                 {" "}
//                                 Cameron A. <span className="yellow">
//                                   (94%)
//                                 </span>{" "}
//                                 applied to{" "}
//                                 <span className="green">
//                                   Social Media Marketing Manager.
//                                 </span>{" "}
//                                 <br />
//                                 12 min ago.
//                               </p>
//                             </a>
//                           </li>

//                           <li>
//                             <a href="">
//                               <span className="userImg">
//                                 <img src={contactIcon} />
//                               </span>
//                               <p>
//                                 {" "}
//                                 Cameron A. <span className="yellow">
//                                   (94%)
//                                 </span>{" "}
//                                 applied to{" "}
//                                 <span className="green">
//                                   Social Media Marketing Manager.
//                                 </span>{" "}
//                                 <br />
//                                 12 min ago.
//                               </p>
//                             </a>
//                           </li>

//                           <li>
//                             <a href="">
//                               <span className="userImg">
//                                 <img src={contactIcon} />
//                               </span>
//                               <p>
//                                 {" "}
//                                 Cameron A. <span className="yellow">
//                                   (94%)
//                                 </span>{" "}
//                                 applied to{" "}
//                                 <span className="green">
//                                   Social Media Marketing Manager.
//                                 </span>{" "}
//                                 <br />
//                                 12 min ago.
//                               </p>
//                             </a>
//                           </li>

//                           <li>
//                             <a href="">
//                               <span className="userImg">
//                                 <img src={contactIcon} />
//                               </span>
//                               <p>
//                                 {" "}
//                                 Cameron A. <span className="yellow">
//                                   (94%)
//                                 </span>{" "}
//                                 applied to{" "}
//                                 <span className="green">
//                                   Social Media Marketing Manager.
//                                 </span>{" "}
//                                 <br />
//                                 12 min ago.
//                               </p>
//                             </a>
//                           </li>

//                           <li>
//                             <a href="">
//                               <span className="userImg">
//                                 <img src={contactIcon} />
//                               </span>
//                               <p>
//                                 {" "}
//                                 Cameron A. <span className="yellow">
//                                   (94%)
//                                 </span>{" "}
//                                 applied to{" "}
//                                 <span className="green">
//                                   Social Media Marketing Manager.
//                                 </span>{" "}
//                                 <br />
//                                 12 min ago.
//                               </p>
//                             </a>
//                           </li>
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-xl-5 col-lg-5 col-md-6">
//                   <div className="messageSection">
//                     <h3>Latest Messages</h3>
//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="img">
//                               {" "}
//                               <img src={candidateImg} />
//                             </span>
//                             <p>
//                               Cameron Allen <br /> <span>Active now</span>
//                             </p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <img src={message} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="img">
//                               {" "}
//                               <img src={candidateImg} />
//                             </span>
//                             <p>
//                               Cameron Allen <br /> <span>Active now</span>
//                             </p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <img src={message} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="img">
//                               {" "}
//                               <img src={candidateImg} />
//                             </span>
//                             <p>
//                               Cameron Allen <br /> <span>Active now</span>
//                             </p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <img src={message} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="img">
//                               {" "}
//                               <img src={candidateImg} />
//                             </span>
//                             <p>
//                               Cameron Allen <br /> <span>Active now</span>
//                             </p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <img src={message} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-xl-3 col-lg-3 col-md-6">
//                   <div className="messageSection h-100">
//                     <h3>Total Applicants</h3>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="listIcons">
//                               {" "}
//                               <img src={allApplicantsIcon} />
//                             </span>
//                             <p>Applications</p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <p className="purple">33</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="listIcons">
//                               {" "}
//                               <img src={shortlistedIcon} />
//                             </span>
//                             <p>Shortlisted</p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <p className="green">8</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="listIcons">
//                               {" "}
//                               <img src={timerYellow} />
//                             </span>
//                             <p>On-hold</p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <p className="yellow">5</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="listIcons">
//                               {" "}
//                               <img src={rejectedIcon} />
//                             </span>
//                             <p>Rejected</p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <p className="red">2</p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-4 col-md-6">
//                   <div className="messageSection">
//                     <h3>Scheduled Interview</h3>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="listIcons text">
//                               {" "}
//                               07 <br />
//                               <span className="green">Nov</span>
//                             </span>
//                             <p>
//                               Cameron Allen <br /> <span>Active now</span>
//                             </p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <img src={moreIcon} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="listIcons text">
//                               {" "}
//                               07 <br />
//                               <span className="green">Nov</span>
//                             </span>
//                             <p>
//                               Cameron Allen <br /> <span>Active now</span>
//                             </p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <img src={moreIcon} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="listIcons text">
//                               {" "}
//                               07 <br />
//                               <span className="green">Nov</span>
//                             </span>
//                             <p>
//                               Cameron Allen <br /> <span>Active now</span>
//                             </p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <img src={moreIcon} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="messages">
//                       <div className="row">
//                         <div className="col-lg-8">
//                           <div className="messageInner">
//                             <span className="listIcons text">
//                               {" "}
//                               07 <br />
//                               <span className="green">Nov</span>
//                             </span>
//                             <p>
//                               Cameron Allen <br /> <span>Active now</span>
//                             </p>
//                           </div>
//                         </div>
//                         <div className="col-lg-4">
//                           <div className="text-end">
//                             <img src={moreIcon} />
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ) : currentTab == "jobPosted" ? (
//           <JobPosted
//             setFirmJobs={setFirmJobs}
//             firmJobs={firmJobs}
//             setJobData={setJobData}
//             jobData={jobData}
//             navigate={navigate}
//             setDeleteJobId={setDeleteJobId}
//             deleteJobId={deleteJobId}
//           />
//         ) : (
//           ""
//         )}
//         <Footer />
//       </div>
//     </>
//   );
// }

// export default Dashboard;
