import { toast } from "react-toastify";
import toastCrossIcon from "../../assets/imgs/toastCrossIcon.svg";
import toastIconGreen from "../../assets/imgs/toastIconGreen.svg";
const customId = "custom-id-yes";

function toastMessages(
  messageType,
  message,
  messsageHeader = "Saved Successfully"
) {
  let toastData;
  if (messageType == "error") {
    toastData = (
      <>
        <div className="toastOuter">
          <div className="toastImg">
            <img src={toastCrossIcon} />
          </div>
          <div>
            <h2 className="toastHead">Error Occurred</h2>
            <p className="toastText">{message}</p>
          </div>
        </div>
      </>
    );
  } else if (messageType == "warn") {
    toastData = (
      <>
        <div className="toastOuter">
          <div className="toastImg">
            <img src={toastIconGreen} />
          </div>
          <div>
            <h2 className="toastHead">Action Required</h2>
            <p className="toastText">{message}</p>
          </div>
        </div>
      </>
    );
  } else {
    toastData = (
      <>
        <div className="toastOuter">
          <div className="toastImg">
            <img src={toastIconGreen} />
          </div>
          <div>
            <h2 className="toastHead">{messsageHeader}</h2>
            <p className="toastText">{message}</p>
          </div>
        </div>
      </>
    );
  }
  if (toastData) {
    if (messageType === "success") {
      toast.dismiss();
      toast.success(toastData, {});
    } else if (messageType == "warn") {
      toast.dismiss();
      toast.warning(toastData, {});
    } else {
      toast.error(toastData, {});
    }
  }
}

export default toastMessages;
