import { Link } from "react-router-dom";
import Logo from "../assets/imgs/HireBIRD.png";

function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link
            className="navbar-brand d-xxl-none d-xl-none d-lg-none d-md-block"
            to="/home"
          >
            <img src={Logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
              <li
                className={`nav-item ${
                  window.location.pathname == "/" ||
                  window.location.pathname == "/home"
                    ? "active"
                    : ""
                }`}
              >
                <Link className="nav-link" aria-current="page" to="/home">
                  For Candidates
                </Link>
              </li>
              <li
                className={`nav-item ${
                  window.location.pathname == "/employer-home" ? "active" : ""
                }`}
              >
                <Link className="nav-link me-0" to="/employer-home">
                  For Employers
                </Link>
              </li>
            </ul>

            <Link className="navbar-brand navbar-brand-center me-0" to="/home">
              <img src={Logo} />
            </Link>

            <ul className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
              {/* <li className="nav-item">
                <Link to="/contact-us" className="nav-link" aria-current="page">
                  Contact Us
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/login" className="nav-link" aria-current="page">
                  Sign In
                </Link>
              </li>
              <Link
                to="/create-account"
                className="btn btn-createAcount commonRadius10"
              >
                Create an account!
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
