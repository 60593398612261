import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import Logo from "../assets/imgs/HireBIRD.png";
import forgotImg from "../assets/imgs/forgotImg.svg";
import { useState } from "react";
import toastMessages from "../components/toastMessage";
import { apiPostMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";

function ForgotPassword() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  async function onSubmit(e) {
    e.preventDefault();
    try {
      const response = await apiPostMethod(
        allApiUrl.forgotpassword,
        { email: email },
        ""
      );
      if (response.status === 200) {
        toastMessages(
          "success",
          "Email Sent Successfully.Please Check Your Email."
        );
        setEmail("");
        navigate("/login");
      }
    } catch (err) {
      toastMessages("err", "No account found.");
    }
  }
  return (
    <>
      <section className="formSection roleSelectorMain">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="formSection__Img">
                <img className="forgotImg" src={forgotImg} alt="" />
              </div>
            </div>
            <div className="col-lg-6 ms-auto">
              <div className="formSection__content mt-5">
                <div className="text-center mb-4">
                  <a href="#">
                    <img className="logoImg" src={Logo} alt="HireBird Logo" />
                  </a>
                </div>
                <form onSubmit={onSubmit}>
                  <h2 className="mb-2">Forgot your password?</h2>
                  <p>
                    Enter the email associated with your account and we'll send
                    an email with instructions to reset your password.{" "}
                  </p>

                  <div className="form-group">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      autocomplete="off"
                    />
                    <span>
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                  </div>

                  {/* <div className="passwordRemember">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" for="flexCheckDefault">
                                    Remember Me
                                </label>
                                </div>
                            <a href="">Forgot Password?</a>
                        </div> */}

                  <button className="btn commonBtb">
                    Reset password <i className="fa-solid fa-angles-right"></i>
                  </button>
                  <p className="mt-3 mb-0">
                    <Link to="/login">Back to sign in</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgotPassword;
