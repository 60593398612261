import Sidebar from "../components/sidebar";
import Topbar from "../components/topBar";
import Footer from "../components/footer";
import NewModals from "../components/modals/editProfileModlas";
import Linkedin from "../assets/imgs/linkedin2.svg";
import Location from "../assets/imgs/location.svg";
import phonelogowhite from "../assets/imgs/phonelogowhite.svg";
import usericon from "../assets/imgs/usericon.svg";
import jobpositionimg from "../assets/imgs/jobPositionImg.svg";
import locationicongreen from "../assets/imgs/locationIconGreen.svg";
import star from "../assets/imgs/star.svg";
import pauljason from "../assets/imgs/pauljason.svg";
import calender from "../assets/imgs/calender.svg";
import inviteRecruiter from "../assets/imgs/inviteRecruiter.svg";
import timer from "../assets/imgs/timer.svg";
import website from "../assets/imgs/website.svg";
import dummyImg from "../assets/imgs/dummyImg.jpg";
import uploadIcon from "../assets/imgs/uploadIcon.svg";
import whiteCross from "../assets/imgs/whiteCross.svg";
import addWhiteIcon from "../assets/imgs/addWhiteIcon.svg";
import { useEffect, useState } from "react";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import toastMessages from "../components/toastMessage";
import { toast, useToast } from "react-toastify";
import contacticon from "../assets/imgs/contacticon.svg";
import location2 from "../assets/imgs/location2.svg";
import phonelogo from "../assets/imgs/phonelogo.svg";
import addIcon from "../assets/imgs/addIcon.svg";
import editIcon from "../assets/imgs/editIcon.svg";
import iconDelete from "../assets/imgs/iconDelete.svg";
import peoplesIcon from "../assets/imgs/peoplesIcon.svg";
import briefcase from "../assets/imgs/briefcase.svg";
import { useNavigate } from "react-router-dom";
import FullPageLoader from "../components/fullPageLoader";
import DOMPurify from "dompurify";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TimeAgo from "../components/daysAgoComponent";
import { calculateTimeAgo } from "../utils/helper";
import { Greeting } from "./my-profile";
// const InviteStudent = ({ navigate }) => {
//   return (

//   );
// };
import toastWarningYellow from "../assets/imgs/toastIconWarning.svg";
const percentage = 66;
function CompanyMyProfile() {
  const [profileCompletePercentage, setProfileCompletePercentage] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("view");
  const [companyId, setCompanyId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let navigate = useNavigate();
  let header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  useEffect(() => {
    // let demo = (
    //   <>
    //     <div className="toastOuter">
    //       <div className="toastImg">
    //         <img src={toastWarningYellow} />
    //       </div>
    //       <div>
    //         <h2 className="toastHead">Action Required</h2>
    //         <p className="toastText">
    //           Incomplete fields.Please fill in all the required information now.
    //         </p>
    //       </div>
    //     </div>
    //   </>
    // );
    // toastMessages("warn", demo);
  }, []);
  const [form, setForm] = useState({
    firmId: "",
    firmName: "",
    websiteLink: "",
    firmNumber: "",
    foundationYear: "",
    address: "",
    aboutus: "",
    description: "",
    firmLogo: "",
    hiringAlert: {
      alertTitle: "",
      alertDescription: "",
      alertImage: "",
    },
    employeeTestimonials: {
      employeeName: "",
      employeeDescription: "",
      employeeRating: "",
      employeeImage: "",
    },
  });
  const [companyDetails, setCompanyDetails] = useState({});
  const [previewImage, setPreviewImage] = useState("");
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [firmJobs, setFirmJobs] = useState([]);
  const [previewHiringAlertImage, setPreviewHiriingAlertImage] = useState("");
  const [isHiringImageUploaded, setIsHiringImageUploaded] = useState(false);
  //Employee states
  const [previewEmployeeTestimonialImage, setPreviewEmployeeTestimonialImage] =
    useState("");
  const [isEmployeeUploaded, setIsEmployeeUploaded] = useState(false);
  const [testimonialObj, setTestimonialObj] = useState({
    name: "",
    description: "",
    rating: null,
    testimonialImage: "",
  });
  // const [accountDetails, setAccountDetails] = useState({});
  const [testimonialList, setTestimonialList] = useState([]);
  const [deleteJobId, setDeleteJobId] = useState("");
  const [isNewImageUploaded, setIsNewImageUploaded] = useState(false);

  async function addTestimonalHandler() {
    try {
      let formData = new FormData();
      formData.append("file", testimonialObj.testimonialImage);
      formData.append("description", testimonialObj.description);
      formData.append("rating", testimonialObj.rating);
      formData.append("name", testimonialObj.name);
      let res = await apiPostMethod(
        allApiUrl.createTestimonial,
        formData,
        header
      );
      if (res.status == 200) {
        setPreviewEmployeeTestimonialImage("");
        setIsEmployeeUploaded(false);
        setTestimonialObj({
          name: "",
          description: "",
          rating: null,
          testimonialImage: "",
        });
        setIsProfileUpdated(!isProfileUpdated);
        toastMessages("success", "Testimonal added successfully");
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  }
  async function getTestimonials() {
    try {
      let res = await apiGetMethod(allApiUrl.viewTestimonial, "", header);
      if (res.status == 200) {
        setTestimonialList(res.data.testimonials);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  }
  async function editTestimonalHandler() {
    try {
      let formData = new FormData();
      if (isEmployeeUploaded) {
        formData.append("file", testimonialObj.testimonialImage);
      }
      formData.append(
        "testimonialImage",
        isEmployeeUploaded
          ? ""
          : testimonialObj.testimonialImage !== undefined
          ? testimonialObj.testimonialImage
          : ""
      );
      formData.append("description", testimonialObj.description);
      formData.append("rating", testimonialObj.rating);
      formData.append("name", testimonialObj.name);
      let res = await apiPutMethod(
        `${allApiUrl.updateTestimonial}/${testimonialObj._id}`,
        formData,
        header
      );
      if (res.status == 200) {
        setPreviewEmployeeTestimonialImage("");
        setIsEmployeeUploaded(false);
        setTestimonialObj({
          name: "",
          description: "",
          rating: null,
          testimonialImage: "",
        });
        toastMessages("success", "Changes saved");
        setIsProfileUpdated(!isProfileUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  }
  async function onDeleteHandler() {
    try {
      let formData = new FormData();
      formData.append("isDeleted", true);
      let res = await apiPutMethod(
        `${allApiUrl.updateTestimonial}/${testimonialObj._id}`,
        formData,
        header
      );
      if (res.status == 200) {
        setIsEmployeeUploaded(false);
        setTestimonialObj({
          name: "",
          description: "",
          rating: null,
          testimonialImage: "",
        });
        toastMessages("success", "Testimonal deleted successfully");
        setIsProfileUpdated(!isProfileUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  }
  async function onJobDeleteHandler() {
    try {
      let data = {
        firmId: deleteJobId,
        isDeleted: true,
      };
      let res = await apiPostMethod(
        `${allApiUrl.updateJob}/${deleteJobId}`,
        data,
        header
      );
      if (res.status == 200) {
        setDeleteJobId("");
        toastMessages("success", "Job deleted successfully");
        setIsProfileUpdated(!isProfileUpdated);
      }
    } catch (err) {
      toastMessages("error", err.data.message);
    }
  }

  useEffect(() => {
    getAccountDetail();
    getTestimonials();
  }, [isProfileUpdated]);
  async function getAccountDetail() {
    // setIsLoading(true);
    try {
      const response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status === 200) {
        if (
          response &&
          response.data &&
          response.data.data &&
          !response.data.data.employerProfileId
        ) {
          navigate("/onboarding-recruiter");
        } else {
          //complete modal handle///////////////
          if (
            response &&
            response.data.data &&
            response.data.data.profileCompleted == false
          ) {
            if (response.data.total == 100) {
              const uploadImageModal = new window.bootstrap.Modal(
                document.getElementById("recruiterCompleteModal")
              );
              uploadImageModal.show();
            }
            // if (
            //   response &&
            //   response.data &&
            //   response.data.data &&
            //   response.data.data.employerProfileId
            // ) {
            //   let data =
            //     response &&
            //     response.data &&
            //     response.data.data &&
            //     response.data.data.employerProfileId;

            //   if (
            //     data.websiteLink &&
            //     data.firmNumber &&
            //     data.foundationYear &&
            //     data.address
            //   )
            {
            }
            // }
          }
          //////////////////////////////
          setProfileCompletePercentage(parseInt(response.data.total));
          // setAccountDetails(response.data.data);
          setForm({
            firmId: response.data.data.employerProfileId._id,
            firmName: response.data.data.employerProfileId.firmName || "",
            websiteLink: response.data.data.employerProfileId.websiteLink || "",
            firmNumber: response.data.data.employerProfileId.firmNumber || "",
            foundationYear:
              response.data.data.employerProfileId.foundationYear || "",
            address: response.data.data.employerProfileId.address || "",
            aboutus: response.data.data.employerProfileId.aboutus || "",
            description: response.data.data.employerProfileId.description || "",
            firmLogo: response.data.data.employerProfileId.firmLogo || "",
            hiringAlert: response.data.data.employerProfileId.hiringAlert,
          });
          setPreviewImage(response.data.data.employerProfileId.firmLogo);
          setPreviewHiriingAlertImage(
            response.data.data?.employerProfileId?.hiringAlert?.alertImage
          );
          setCompanyDetails(response.data.data);
          getfirmjobs();
        }
      }
    } catch (err) {
      console.log("err", err.data.message);
    }
  }
  const [lastJobPostedDate, setLastJobPostedDate] = useState(null);
  async function getfirmjobs() {
    try {
      const response = await apiGetMethod(allApiUrl.viewfirmjobs, "", header);
      if (response.status === 200) {
        let lastJob;
        if (response.data.post.length > 0) {
          lastJob = calculateTimeAgo(
            response.data.post[response.data.post.length - 1].createdAt
          );
        }
        setLastJobPostedDate(lastJob);
        setFirmJobs(response.data.post);
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err.data.message);
      setIsLoading(false);
    }
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  };
  let props = {
    setPreviewImage,
    previewImage,
    handleChange,
    form,
    setForm,
    isProfileUpdated,
    setIsProfileUpdated,
    userInfo,
    setActiveTab,
    header,
    isLoading,
    setIsLoading,
    activeTab,
    setIsNewImageUploaded,
    isNewImageUploaded,
  };
  async function createHiringAlertHandler(e) {
    e.preventDefault();
    try {
      if (isHiringImageUploaded) {
        let objToAppend = {
          alertTitle: form.hiringAlert.alertTitle,
          alertDescription: form.hiringAlert.alertDescription,
        };
        const formData = new FormData();
        formData.append("firmId", form.firmId);
        formData.append("alertImage", form.hiringAlert.alertImage);
        formData.append("hiringAlert", JSON.stringify(objToAppend));
        let res = await apiPostMethod(allApiUrl.updateFirm, formData, header);
        if (res.status == 200) {
          toastMessages("success", "Hiring alert created successfully.");
          setIsHiringImageUploaded(false);
        }
      } else {
        let data = {
          alertImage: form.hiringAlert.alertImage
            ? form.hiringAlert.alertImage
            : "",
          alertTitle: form.hiringAlert.alertTitle,
          alertDescription: form.hiringAlert.alertDescription,
        };
        let res = await apiPostMethod(
          allApiUrl.updateFirm,
          { hiringAlert: data, firmId: form.firmId },
          header
        );
        if (res.status == 200) {
          toastMessages("success", "Hiring alert created successfully.");
          setIsHiringImageUploaded(false);
        }
      }
      setIsProfileUpdated(!isProfileUpdated);
    } catch (err) {
      console.log("err", err.message);
    }
  }
  async function createHiringAlertHandler(e) {
    e.preventDefault();
    try {
      if (isHiringImageUploaded) {
        let objToAppend = {
          alertTitle: form.hiringAlert.alertTitle,
          alertDescription: form.hiringAlert.alertDescription,
        };
        const formData = new FormData();
        formData.append("firmId", form.firmId);
        formData.append("alertImage", form.hiringAlert.alertImage);
        formData.append("hiringAlert", JSON.stringify(objToAppend));
        let res = await apiPostMethod(allApiUrl.updateFirm, formData, header);
        if (res.status == 200) {
          toastMessages("success", "Hiring alert created successfully.");
          setIsHiringImageUploaded(false);
        }
      } else {
        let data = {
          alertImage: form.hiringAlert.alertImage
            ? form.hiringAlert.alertImage
            : "",
          alertTitle: form.hiringAlert.alertTitle,
          alertDescription: form.hiringAlert.alertDescription,
        };
        let res = await apiPostMethod(
          allApiUrl.updateFirm,
          { hiringAlert: data, firmId: form.firmId },
          header
        );
        if (res.status == 200) {
          toastMessages("success", "Hiring alert created successfully.");
          setIsHiringImageUploaded(false);
        }
      }
      setIsProfileUpdated(!isProfileUpdated);
    } catch (err) {
      console.log("err", err.message);
    }
  }
  async function profileCompleteHandler(e, redirect) {
    e.preventDefault();
    try {
      let data = {
        accountId: companyDetails._id,
        profileCompleted: true,
      };
      let res = await apiPutMethod(allApiUrl.updateprofile, data, header);
      if (res.status == 200) {
        setIsProfileUpdated(!isProfileUpdated);
        if (redirect) {
          navigate("/create-job");
        }
      }
    } catch (err) {
      toastMessages("error", err.data.message);
      console.log("err", err.message);
    }
  }
  let propsModal = {
    createHiringAlertHandler,
    form,
    setForm,
    previewHiringAlertImage,
    setPreviewHiriingAlertImage,
    isHiringImageUploaded,
    setIsHiringImageUploaded,
    previewEmployeeTestimonialImage,
    setPreviewEmployeeTestimonialImage,
    isEmployeeUploaded,
    setIsEmployeeUploaded,
    setTestimonialObj,
    testimonialObj,
    addTestimonalHandler,
    testimonialList,
    editTestimonalHandler,
    onDeleteHandler,
    profileCompleteHandler,
    deleteJobId,
    setDeleteJobId,
    onJobDeleteHandler,
    testimonialList,
    isLoading,
    setIsLoading,
    setIsNewImageUploaded,
    isNewImageUploaded,
    setPreviewImage,
  };
  if (isLoading) return <FullPageLoader />;
  return (
    <>
      <NewModals {...propsModal} />
      <Sidebar
        activePage="profile"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Topbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="mainWrapper">
        <section className="profile sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                <div className="commonHeading candidateHeading">
                  {/* <h2>Profile</h2> */}
                  <Greeting />{" "}
                  {/* <button
                    class="btn btn-primary"
                    data-bs-target="#locationModal"
                    data-bs-toggle="modal"
                  >
                    Open first modal
                  </button> */}
                  {/* <div className="release commonRadius8">
                    <p>
                      <span className="commonRadius8">
                        Full Release March 2024
                      </span>
                    </p>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-xxl-4 col-xl-4 col-md-4 col-7">
                <div className="text-end">
                  
                </div>
              </div> */}
              <div className="col-xxl-12 col-xl-12">
                <div className="commonTabs">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        onClick={() => setActiveTab("view")}
                        class={`nav-link ${
                          activeTab == "view" ? "active" : ""
                        }`}
                        // class="nav-link active"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        View
                      </button>
                      <button
                        onClick={() => setActiveTab("edit")}
                        class={`nav-link ${
                          activeTab == "edit" ? "active" : ""
                        }`}
                        // class="nav-link"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Edit
                      </button>
                    </div>
                  </nav>
                </div>
              </div>

              <div class="tab-content" id="nav-tabContent">
                <ViewCompanyProfile
                  lastJobPostedDate={lastJobPostedDate}
                  navigate={navigate}
                  setDeleteJobId={setDeleteJobId}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  companyDetails={companyDetails}
                  firmJobs={firmJobs}
                  setTestimonialObj={setTestimonialObj}
                  testimonialList={testimonialList}
                  setPreviewEmployeeTestimonialImage={
                    setPreviewEmployeeTestimonialImage
                  }
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  profileCompletePercentage={profileCompletePercentage}
                />
                <EditCompanyProfile {...props} />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
const ViewCompanyProfile = ({
  lastJobPostedDate,
  profileCompletePercentage,
  companyDetails,
  firmJobs,
  testimonialList,
  setTestimonialObj,
  setPreviewEmployeeTestimonialImage,
  activeTab,
  setDeleteJobId,
  isLoading,
}) => {
  let navigate = useNavigate();
  if (!companyDetails) {
    return <div>Loading...</div>; // Display a loading state
  }

  if (!companyDetails.employerProfileId) {
    return <div>No profile data available</div>; // Display a message for no data
  }
  if (isLoading) return <FullPageLoader />;
  return (
    <div
      class={`tab-pane fade ${activeTab == "view" ? "show active" : ""}`}
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
      tabindex="0"
    >
      <div className="candidateOuter pt-5 pb-4">
        {/* <div className="container-fluid"> */}
        <div className="row">
          <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 order2">
            {/* <InviteStudent navigate={navigate} /> */}
            <div className="sectionInvite commonRadius8 mt-0 mb-4">
              <div className="row">
                <div className="col-xxl-12">
                  <div className="sectionInvite__Content">
                    <h4>
                      Invite your friends/collegues, get a free month of
                      Hirebird Advanced when we launch!
                    </h4>
                    <button
                      className="btn commonRadius8"
                      onClick={() => {
                        navigate("/affiliates");
                      }}
                    >
                      Share my link!
                    </button>
                  </div>
                </div>
                {/* <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                          <div className="sectionInvite__Img">
                            <img src={inviteRecruiter} />
                          </div>
                        </div> */}
              </div>
            </div>
            <div className="candidateuser mt-0 mb-4">
              <div className="row">
                <div className="col-xxl-9 col-xl-8 col-md-9">
                  <div className="candidateuserleft">
                    <div className="candidateImg">
                      <img
                        src={
                          companyDetails.employerProfileId.firmLogo || dummyImg
                        }
                        alt=""
                      />
                    </div>
                    <div className="candidateuserleft2">
                      <div>
                        <h2>{companyDetails.employerProfileId.firmName}</h2>
                        {companyDetails.employerProfileId.address ? (
                          <h4>
                            <span>
                              <img src={Location} alt="" />
                            </span>
                            {companyDetails.employerProfileId.address}{" "}
                          </h4>
                        ) : (
                          ""
                        )}
                      </div>
                      <p>{companyDetails.employerProfileId.aboutus}</p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-3">
                  {/* <div className="candidateuserright text-end">
                      <button className="btn btn-download">
                        Contact Us
                        <img src={phonelogowhite} alt="" />
                      </button>
                    </div> */}
                  {profileCompletePercentage !== 100 && (
                    <div className="profileCompletion">
                      <CircularProgressbar
                        value={profileCompletePercentage}
                        text={`${profileCompletePercentage}%`}
                      />
                      <p>Completed</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="candidatemidleft">
              <div className="candidatemidabout">
                <div className="candidatemidabout1">
                  <h3>
                    <img src={usericon} alt="" /> Welcome to{" "}
                    {companyDetails.employerProfileId.firmName}!
                  </h3>
                </div>
                <p>{companyDetails.employerProfileId.description}</p>
                {/* <div>{sanitizedHTML}</div> */}
                {/* <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} /> */}
              </div>
            </div>
            <LatestJob
              setDeleteJobId={setDeleteJobId}
              firmJobs={firmJobs}
              companyDetails={companyDetails}
              navigate={navigate}
            />
            <CompanyReviews
              testimonialList={testimonialList}
              companyDetails={companyDetails}
              setTestimonialObj={setTestimonialObj}
              setPreviewEmployeeTestimonialImage={
                setPreviewEmployeeTestimonialImage
              }
            />
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 order1">
            {companyDetails.employerProfileId &&
              (companyDetails.employerProfileId.foundationYear ||
                companyDetails.employerProfileId.websiteLink) && (
                <div className="candidatemidleft">
                  <div className="candidatemidright">
                    <div className="candidatemidright1">
                      <h3>
                        {companyDetails.employerProfileId.firmName}
                        {/* <div>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star checked"></span>
                        <span className="fa fa-star"></span>
                        <span className="fa fa-star"></span>
                      </div> */}
                      </h3>
                    </div>
                    {companyDetails.employerProfileId.foundationYear ? (
                      <div className="candidatemidright2 mb-3">
                        <img src={calender} alt="" />
                        <div>
                          <p>Year founded</p>
                          <span>
                            {companyDetails.employerProfileId.foundationYear}
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {companyDetails.employerProfileId.websiteLink ? (
                      <div className="candidatemidright2 mb-3">
                        <img src={website} alt="" />
                        <div>
                          <p>Website</p>
                          <span>
                            <a
                              target="_blank"
                              href={
                                companyDetails &&
                                companyDetails.employerProfileId &&
                                companyDetails.employerProfileId.websiteLink &&
                                companyDetails.employerProfileId.websiteLink.includes(
                                  "https://"
                                )
                                  ? companyDetails.employerProfileId.websiteLink
                                  : `https://${companyDetails.employerProfileId.websiteLink}`
                              }
                            >
                              {companyDetails.employerProfileId.websiteLink}
                            </a>
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {lastJobPostedDate && (
                      <div className="candidatemidright2">
                        <img src={timer} alt="" />
                        <div>
                          <p>Last Jobs Posted</p>
                          <span>{lastJobPostedDate}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            {/* <div className="candidatemidleft commonRadius8">
                <div className="candidatemidright">
                  <div className="candidatemidright3">
                    <h3 className="mb-4">
                      <img src={contacticon} alt="" /> Contact Us
                    </h3>
                  </div>
                  <div className="candidatemidright4 mb-3">
                    <img src={location2} alt="" />
                    <p>{companyDetails.employerProfileId.address}</p>
                  </div>
                  {companyDetails &&
                    companyDetails.employerProfileId &&
                    companyDetails.employerProfileId.firmNumber && (
                      <div className="candidatemidright4">
                        <img src={phonelogo} alt="" />
                        <p>{companyDetails.employerProfileId.firmNumber}</p>
                      </div>
                    )}
                </div>
              </div> */}
            {companyDetails &&
              companyDetails.employerProfileId &&
              companyDetails.employerProfileId.hiringAlert &&
              companyDetails.employerProfileId.hiringAlert.alertImage && (
                <div
                  className="companyHiring"
                  style={{
                    color: "red",
                    backgroundImage: `url("${
                      companyDetails &&
                      companyDetails.employerProfileId.hiringAlert.alertImage &&
                      companyDetails.employerProfileId.hiringAlert.alertImage
                    }")`,
                  }}
                >
                  {/* <a href="" className="sendmessage">Send Maximillia a message</a> */}
                  <div className="maskimgcontainer">
                    <h3>
                      {companyDetails.employerProfileId.hiringAlert.alertTitle}
                    </h3>
                    <p>
                      {
                        companyDetails.employerProfileId.hiringAlert
                          .alertDescription
                      }
                      {/* {companyDetails.employerProfileId.firmName} is the place to
                    be. Apply to join our team today to join a fast-past work
                    environment. Did we mention the free cookies? */}
                    </p>
                    {/* <a href="">Learn more!</a> */}
                    {/* <img src="assets/imgs/userimg.jpg" alt="" className="img-fluid maskimg"> */}
                  </div>
                </div>
              )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};
const EditCompanyProfile = (props) => {
  let {
    handleChange,
    form,
    setForm,
    setPreviewImage,
    previewImage,
    userInfo,
    header,
    setIsProfileUpdated,
    isProfileUpdated,
    setActiveTab,
    activeTab,
    setIsLoading,
    isLoading,
    setIsNewImageUploaded,
    isNewImageUploaded,
  } = props;
  async function onSubmit(e) {
    setIsLoading(true);
    e.preventDefault();
    try {
      if (isNewImageUploaded) {
        const formData = new FormData();
        formData.append("firmLogo", form.firmLogo);
        formData.append("firmId", form.firmId);
        formData.append("websiteLink", form.websiteLink);
        formData.append("firmNumber", form.firmNumber);
        formData.append("foundationYear", form.foundationYear);
        formData.append("address", form.address);
        formData.append("aboutus", form.aboutus);
        formData.append("description", form.description);
        let res = await apiPostMethod(allApiUrl.updateFirm, formData, header);
        if (res.status == 200) {
          toastMessages("success", "Profile updated successfully");
          setIsLoading(false);
          setActiveTab("view");
          setIsProfileUpdated(!isProfileUpdated);
        }
      } else {
        let res = await apiPostMethod(allApiUrl.updateFirm, form, header);
        if (res.status == 200) {
          toastMessages("success", "Profile updated successfully");
          setIsLoading(false);
          setActiveTab("view");
          setIsProfileUpdated(!isProfileUpdated);
        }
      }
    } catch (err) {
      toastMessages("error", err.data.message);
      setIsProfileUpdated(!isProfileUpdated);
      setIsLoading(false);
      console.log("err", err.message);
    }
  }
  if (isLoading) return <FullPageLoader />;
  return (
    <div
      class={`tab-pane fade ${activeTab == "edit" ? "show active" : ""}`}
      id="nav-profile"
      role="tabpanel"
      aria-labelledby="nav-profile-tab"
      tabindex="0"
    >
      <div className="profileForm">
        <div className="row">
          <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="row">
                <div className="col-xl-6">
                  <div className="form-group">
                    <label>Company Name</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      name="firmName"
                      onChange={handleChange}
                      value={form.firmName}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="form-group">
                    <label>Website</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      name="websiteLink"
                      onChange={handleChange}
                      value={form.websiteLink}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      name="firmNumber"
                      onChange={(e) => {
                        setForm((prev) => {
                          const value = e.target.value.replace(/\D/g, "");
                          prev.firmNumber = value;
                          return { ...prev };
                        });
                      }}
                      value={form.firmNumber}
                    />
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="form-group">
                    <label>Year Founded</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      name="foundationYear"
                      // onChange={handleChange}
                      onChange={(e) => {
                        setForm((prev) => {
                          const value = e.target.value.replace(/\D/g, "");
                          prev.foundationYear = value;
                          return { ...prev };
                        });
                      }}
                      value={form.foundationYear}
                    />
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      name="address"
                      onChange={handleChange}
                      value={form.address}
                    />
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>Subheader</label>
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control about"
                      name="aboutus"
                      onChange={handleChange}
                      value={form.aboutus}
                    ></input>
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="form-group">
                    <label>Company Description</label>
                    <textarea
                      type="text"
                      autoComplete="off"
                      className="form-control description"
                      name="description"
                      onChange={handleChange}
                      value={form.description}
                    ></textarea>
                  </div>
                </div>
                {/* Changes  */}
                <div className="col-xl-6">
                  <div className="form-group">
                    <label>Add company logo image</label>
                    <div
                      className="dragDrop"
                      //temproray changes
                      data-bs-target="#customImageUpload2"
                      data-bs-toggle="modal"
                    >
                      {/* <input
                        id="companyData"
                        type="file"
                        onChange={(e) => {
                          if (e) {
                            const file = e.target.files;
                            if (
                              file &&
                              file[0] &&
                              file[0].type &&
                              file[0].type.split("/")[0] == "image"
                            ) {
                              setForm({ ...form, firmLogo: file[0] });
                              setPreviewImage(URL.createObjectURL(file[0]));
                              setIsNewImageUploaded(true);
                            } else {
                              toastMessages(
                                "error",
                                "The image format is not correct"
                              );
                            }
                          }
                        }}
                      /> */}
                      <img src={uploadIcon} />
                      <p>
                        Drop your file here{" "}
                        <span className="green">or Browser</span>
                      </p>
                      <p>
                        We Support <span className="white">JPG, PNG</span>{" "}
                        files. Make sure that your file is no more than 1 mb.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="preview">
                    {previewImage && (
                      <img
                        className="uploadImg"
                        src={previewImage ? previewImage : dummyImg}
                      />
                    )}
                    {previewImage && (
                      <span
                        onClick={() => {
                          //temporary changes
                          document.getElementById("companyData").value = null;
                          setIsNewImageUploaded(false);
                          setPreviewImage("");
                          setForm((prev) => {
                            prev.firmLogo = "";
                            return { ...prev };
                          });
                        }}
                      >
                        <img src={whiteCross} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="btnSection my-5">
                <button className="btn btn-cancel">Cancel</button>
                <button className="btn btn-save">Save Changes</button>
              </div>
            </form>
          </div>
          <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4">
            <div className="hiringAlert">
              <h4>{`Add/Edit Banner Image/Hiring Alert`}</h4>
              <div className="text-center">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#hiringAlertModal"
                >
                  {`${
                    form && form.hiringAlert.alertTitle ? "Edit" : "Create"
                  } Hiring Alert`}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const LatestJob = (props) => {
  // const sanitizedHTML = DOMPurify.sanitize(companyDetails.employerProfileId.description);
  let image;
  image =
    props.companyDetails &&
    props.companyDetails &&
    props.companyDetails.employerProfileId &&
    props.companyDetails.employerProfileId.firmLogo;
  return (
    <>
      {props.firmJobs && props.firmJobs.length > 0 ? (
        <>
          <div className="jobsHead">
            <h2 className="outerHeading">Latest Jobs</h2>
            <button>
              <img
                src={addIcon}
                onClick={() => {
                  props.navigate("/create-job");
                }}
              />
            </button>
          </div>

          {props.firmJobs &&
            props.firmJobs &&
            props.firmJobs.map((item) => {
              //salary
              let resultString;
              let formattedData = parseFloat(
                item.salary.replace(/[^\d.-]/g, "")
              ).toFixed(2);
              resultString = `$${formattedData.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}`;
              //salary upto
              let resultStringUpto;
              if (item.salaryType == "WithinRange" && item.salaryUpto) {
                // const numericValueUpto = parseFloat(
                //   item.salaryUpto.replace("$", "")
                // );
                // const formattedValueUpto = numericValueUpto.toFixed(2);
                // resultStringUpto = `$${formattedValueUpto}`;
                let formattedDataUpto = parseFloat(
                  item.salaryUpto.replace(/[^\d.-]/g, "")
                ).toFixed(2);
                resultStringUpto = `$${formattedDataUpto.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}`;

                resultString = resultString + "-" + resultStringUpto;
              }
              const sanitizedHTML = DOMPurify.sanitize(item.description);
              return (
                <div className="candidatemidleft">
                  <div className="jobActions">
                    <button
                      onClick={() =>
                        props.navigate(`/edit-job/${item._id}&admin=false`)
                      }
                    >
                      <img src={editIcon} />
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#deleteTestimonialJob"
                      onClick={() => props.setDeleteJobId(item._id)}
                    >
                      <img src={iconDelete} />
                    </button>
                  </div>
                  <div className="jobssection">
                    <div className="jobssectiontop">
                      <div className="jobssectiontop1">
                        {image && (
                          <img src={image} alt="" className="jobImgs" />
                        )}
                        {/* <img
                          src={image}
                          alt=""
                          style={{ width: " 50px" }}
                        /> */}
                        <div>
                          <h4>
                            {item.title}
                            {item.positionType == "in-person" ? (
                              <span>
                                <img src={locationicongreen} />
                                {item.location}
                              </span>
                            ) : (
                              ""
                            )}
                          </h4>
                          {item.createdAt && (
                            <TimeAgo timestamp={item.createdAt} />
                          )}

                          {/* <p>10 min. ago</p> */}
                        </div>
                      </div>
                    </div>
                    <p className="pricingplans">
                      {resultString}/ <span>{item.timeperiod}</span>
                    </p>
                    {/* {sanitizedHTML} */}
                    <div
                      className="detailText"
                      // style={{
                      //   color: "#fff",
                      //   fontSize: " 20px",
                      //   fontWeight: "700",
                      //   margin: "0px 13px",
                      // }}
                      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                    />
                    {/* <p>{item.description}</p> */}
                    <div className="jobssectionbottom">
                      <div
                        className="tags"
                        style={{ textTransform: "capitalize" }}
                      >
                        <span>{item.timeperiod}</span>
                        <span>{item.jobType}</span>
                        {item.location ? (
                          <span>
                            {item.location && item.location.split(",")[0]}
                          </span>
                        ) : (
                          ""
                        )}
                        <span>{item.positionType}</span>
                      </div>
                      {/* <a>Apply Now</a> */}
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div className="cppagination">
            <a href="" className="paginationarrowleft">
              <i className="fa-solid fa-angles-left"></i>
            </a>
            <a href="" className="">
              1
            </a>
            <a href="" className="">
              2
            </a>
            <a href="" className="">
              3
            </a>
            <a href="" className="">
              4
            </a>
            <a href="" className="paginationarrowright">
              <i className="fa-solid fa-angles-right"></i>
            </a>
          </div> */}
        </>
      ) : (
        <CreatePost />
      )}
    </>
  );
};
const CreatePost = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="setupProfile mb-4 empty">
        <h3>
          <img src={briefcase} />
          Create a Job Posting
        </h3>
        <button>
          <img
            src={addIcon}
            onClick={() => {
              navigate("/create-job");
            }}
          />
        </button>
      </div>
    </>
  );
};
const EmployeeTestimonial = () => {
  return (
    <div className="setupProfile mb-4 empty">
      <h3>
        <img src={peoplesIcon} /> Add Employee Testimonials
      </h3>
      <button data-bs-toggle="modal" data-bs-target="#testimonial">
        <img src={addIcon} />
      </button>
    </div>
  );
};
const CompanyReviews = ({
  testimonialList,
  companyDetails,
  setTestimonialObj,
  setPreviewEmployeeTestimonialImage,
}) => {
  return (
    <>
      {testimonialList && testimonialList.length > 0 ? (
        <div className="candidatemidleft reviewsSection">
          <div className="candidatereviews">
            <div className="candidatereviews1">
              <h3 style={{ textTransform: "capitalize" }}>
                <img src={star} alt="" />{" "}
                {companyDetails &&
                  companyDetails.employerProfileId &&
                  companyDetails.employerProfileId.firmName}{" "}
                Reviews
              </h3>
              <div className="addTestimonialBtn">
                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#testimonial"
                  onClick={() => {
                    setTestimonialObj({
                      name: "",
                      description: "",
                      rating: null,
                      testimonialImage: "",
                    });
                  }}
                >
                  <img src={addIcon} />
                </button>

                <button
                  className="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#editReviewsModal"
                >
                  <img src={editIcon} />
                </button>
              </div>
            </div>

            {testimonialList &&
              testimonialList.map((item) => {
                return (
                  <>
                    <div className="candidatereviews2">
                      <div className="row">
                        <div className="col-xl-8">
                          <div className="reviewOuter">
                            <div className="reviewImg">
                              <img
                                // style={{ borderRadius: "24px" }}
                                src={item.testimonialImage || dummyImg}
                                alt=""
                              />
                            </div>
                            <div className="reviewName">
                              {item.createdAt && (
                                <h4>
                                  {item.name} <br />
                                  <TimeAgo timestamp={item.createdAt} />
                                  {/* <span>2 days ago</span> */}
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                        {item.rating !== "null" && (
                          <div className="col-xl-4">
                            <div className="rating">
                              {[...Array(5)].map((_, index) => (
                                <span
                                  key={index}
                                  className={`fa fa-star ${
                                    index < item.rating ? "checked" : ""
                                  }`}
                                ></span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <div></div>
                      <div className="reviewContent">
                        {/* <button className="editTestimonialBtn">
                          <img
                            onClick={() => {
                              setTestimonialObj(item);
                              setPreviewEmployeeTestimonialImage(
                                item.testimonialImage
                              );
                            }}
                            src={editIcon}
                            data-bs-toggle="modal"
                            data-bs-target="#editTestimonial"
                          />
                        </button> */}
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div className="divider"></div>
                  </>
                );
              })}
          </div>
        </div>
      ) : (
        <EmployeeTestimonial />
      )}
    </>
  );
};
export default CompanyMyProfile;
