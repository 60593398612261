import {
  BrowserRouter,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Logo from "../assets/imgs/HireBIRD.png";
import googleIcon from "../assets/imgs/googleIcon.svg";
import canceIcon from "../assets/imgs/cancel.svg";
import approveIcon from "../assets/imgs/approved.png";
import { useEffect, useState } from "react";
import { allApiUrl } from "../api/apiRoutes";
import { apiPostMethod } from "../api/rest";
import toastMessages from "../components/toastMessage";
import queryString from "query-string";
import FullPageLoader from "../components/fullPageLoader";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";

function CreatePassword({ handleChange, form, onSubmit, navigate }) {
  const [viewPassword, setViewPassword] = useState({
    password: true,
    confirmPassword: true,
  });
  const [isAgree, setIsAgree] = useState(false);

  return (
    <>
      <section className="formSection roleSelectorMain">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="formSection__content">
                <div className="text-center mb-4">
                  <p onClick={() => navigate("/login")}>
                    <img className="logoImg" src={Logo} alt="HireBird Logo" />
                  </p>
                </div>
                <form onSubmit={onSubmit}>
                  <h2>Create password</h2>
                  <p>Sign Up and get access to all the features.</p>

                  <div className="form-group">
                    <input
                      onChange={handleChange}
                      value={form.password}
                      type={`${!viewPassword.password ? "text" : "password"}`}
                      name="password"
                      className={`form-control`}
                      placeholder="Password"
                      autocomplete="off"
                    />
                    <i
                      onClick={() =>
                        setViewPassword({
                          ...viewPassword,
                          password: !viewPassword.password,
                        })
                      }
                      className={`toggle-password fa fa-fw fa-eye${
                        viewPassword.password ? "-slash" : ""
                      }`}
                    ></i>
                    <span>
                      <i className="fa-solid fa-lock"></i>
                    </span>
                  </div>
                  {!form.password && (
                    <div>
                      <ul className="errorListingRed">
                        <li>
                          <img src={canceIcon} /> Please enter your password
                        </li>
                      </ul>
                    </div>
                  )}
                  {/* validation */}
                  {form.password && (
                    <div>
                      {(!/^(?=.*[a-z])/.test(form.password) ||
                        !/^(?=.*[A-Z])/.test(form.password) ||
                        !/^(?=.*\d)/.test(form.password) ||
                        !/^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                          form.password
                        ) ||
                        !(form.password.length >= 8)) && (
                        <ul className="errorListingRed">
                          <li
                            style={{
                              color: /^(?=.*[a-z])/.test(form.password)
                                ? "#61D180"
                                : "#DE5753",
                            }}
                          >
                            {/^(?=.*[a-z])/.test(form.password) ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Include at least 1 lowercase letter
                          </li>
                          <li
                            style={{
                              color: /^(?=.*[A-Z])/.test(form.password)
                                ? "#61D180"
                                : "#DE5753",
                            }}
                          >
                            {/^(?=.*[A-Z])/.test(form.password) ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Include at least 1 uppercase letter
                          </li>
                          <li
                            style={{
                              color: /^(?=.*\d)/.test(form.password)
                                ? "#61D180"
                                : "#DE5753",
                            }}
                          >
                            {/^(?=.*\d)/.test(form.password) ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Include at least 1 number
                          </li>
                          <li
                            style={{
                              color:
                                /^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                                  form.password
                                )
                                  ? "#61D180"
                                  : "#DE5753",
                            }}
                          >
                            {/^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                              form.password
                            ) ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Include at least 1 special character
                          </li>
                          <li
                            style={{
                              color:
                                form.password.length >= 8
                                  ? "#61D180"
                                  : "#DE5753",
                            }}
                          >
                            {form.password.length >= 8 ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Must be at least 8 characters long
                          </li>
                        </ul>
                      )}
                    </div>
                  )}
                  <div className="form-group mb-2">
                    <input
                      onChange={handleChange}
                      value={form.confirmPassword}
                      name="confirmPassword"
                      type={`${
                        !viewPassword.confirmPassword ? "text" : "password"
                      }`}
                      className="form-control"
                      placeholder="Confirm Password"
                      autocomplete="off"
                    />
                    <i
                      onClick={() =>
                        setViewPassword({
                          ...viewPassword,
                          confirmPassword: !viewPassword.confirmPassword,
                        })
                      }
                      className={`toggle-password fa fa-fw fa-eye${
                        viewPassword.confirmPassword ? "-slash" : ""
                      }`}
                    ></i>
                    <span>
                      <i className="fa-solid fa-lock"></i>
                    </span>
                  </div>
                  {!form.confirmPassword && (
                    <div>
                      <ul className="errorListingRed mt-2">
                        <li>
                          <img src={canceIcon} />
                          Please enter your confirm password
                        </li>
                      </ul>
                    </div>
                  )}
                  {form.confirmPassword &&
                    form.password !== form.confirmPassword && (
                      <div>
                        <ul className="errorListingRed mt-2">
                          <li>
                            <img src={canceIcon} /> The password you entered
                            does not match
                          </li>
                        </ul>
                      </div>
                    )}
                  {form.confirmPassword && (
                    <div>
                      {(!/^(?=.*[a-z])/.test(form.confirmPassword) ||
                        !/^(?=.*[A-Z])/.test(form.confirmPassword) ||
                        !/^(?=.*\d)/.test(form.confirmPassword) ||
                        !/^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                          form.confirmPassword
                        ) ||
                        !(form.confirmPassword.length >= 8)) && (
                        <ul className="errorListingRed">
                          {/* <li
                            style={{
                              color:
                                form.confirmPassword &&
                                form.password !== form.confirmPassword
                                  ? "green"
                                  : "red",
                            }}
                          >
                            The password you entered does not match
                          </li> */}
                          <li
                            style={{
                              color: /^(?=.*[a-z])/.test(form.confirmPassword)
                                ? "#61D180"
                                : "#DE5753",
                            }}
                          >
                            {/^(?=.*[a-z])/.test(form.confirmPassword) ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Include at least 1 lowercase letter
                          </li>
                          <li
                            style={{
                              color: /^(?=.*[A-Z])/.test(form.confirmPassword)
                                ? "#61D180"
                                : "#DE5753",
                            }}
                          >
                            {/^(?=.*[A-Z])/.test(form.confirmPassword) ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Include at least 1 uppercase letter
                          </li>
                          <li
                            style={{
                              color: /^(?=.*\d)/.test(form.confirmPassword)
                                ? "#61D180"
                                : "#DE5753",
                            }}
                          >
                            {/^(?=.*\d)/.test(form.confirmPassword) ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Include at least 1 number
                          </li>
                          <li
                            style={{
                              color:
                                /^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                                  form.confirmPassword
                                )
                                  ? "#61D180"
                                  : "#DE5753",
                            }}
                          >
                            {/^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                              form.confirmPassword
                            ) ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Include at least 1 special character
                          </li>
                          <li
                            style={{
                              color:
                                form.confirmPassword.length >= 8
                                  ? "#61D180"
                                  : "#DE5753",
                            }}
                          >
                            {form.confirmPassword.length >= 8 ? (
                              <img src={approveIcon} />
                            ) : (
                              <img src={canceIcon} />
                            )}{" "}
                            Must be at least 8 characters long
                          </li>
                        </ul>
                      )}
                    </div>
                  )}

                  <div className="passwordRemember">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isAgree}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setIsAgree(true);
                          } else {
                            setIsAgree(false);
                          }
                        }}
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        I agree to the <strong>Hirebird</strong>{" "}
                        <a href="">Terms & Conditions </a>
                      </label>
                    </div>
                  </div>

                  <button className="btn commonBtb" disabled={!isAgree}>
                    Sign up <i className="fa-solid fa-angles-right"></i>
                  </button>
                  <p className="mt-3 mb-0">
                    Already have an account? <a href="/login">Sign in</a>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
const GoogleAuth = ({ referral }) => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isGoogleApiCall, setIsGoogleApiCalled] = useState(false);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    if (isGoogleApiCall) {
      googleAuthHandler(data);
    }
  }, [isGoogleApiCall]);
  async function googleAuthHandler(data) {
    try {
      if (referral) {
        data = { ...data, referredBy: referral };
      }
      let res = await apiPostMethod(allApiUrl.googleAuth, data, "");
      if (res.status === 200) {
        navigate(`/welcome?token=${res.data.token}`);
      }
    } catch (err) {
      if (err.data.message == "Account already exists.") {
        toastMessages("error", "Account already exists.");
        navigate("/login");
      } else {
        navigate("/noreferral");
      }
    }
  }

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          setData(credentialResponse);
          setIsGoogleApiCalled(true);
        }}
        onError={() => {}}
      />
    </GoogleOAuthProvider>
  );
};
function CreateAccount() {
  // useEffect(() => {
  //   localStorage.clear();
  // }, []);
  const navigate = useNavigate();
  let isUserLoggedIn = localStorage.getItem("isLoggedIn");
  let accountType = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    if (isUserLoggedIn) {
      if (accountType.accountType == "recruiter") {
        navigate("/profile");
      } else if (accountType.accountType == "jobseeker") {
        navigate("/my-profile");
      } else {
        navigate("/login");
      }
    }
  }, [isUserLoggedIn]);
  const location = useLocation();
  let { referral, email } = queryString.parse(location.search);
  const [form, setForm] = useState({
    email: email,
    password: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
    phoneNumber: "",
    referredBy: referral,
  });

  const [isLoading, setIsLoading] = useState(false);
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      googleAuthHandler(codeResponse.access_token);
    }, // flow: 'auth-code',
  });
  async function googleAuthHandler(data) {
    setIsLoading(true);
    try {
      let res = await apiPostMethod(
        allApiUrl.googleAuth,
        {
          access_token: data,
          referredBy: form.referredBy ? form.referredBy : "",
        },
        ""
      );
      if (res.status === 200) {
        setIsLoading(false);
        navigate(`/welcome?token=${res.data.token}`);
      }
    } catch (err) {
      setIsLoading(false);
      if (err.data.message == "Account already exists.") {
        toastMessages("error", "Account already exists.");
        navigate("/login");
      } else {
        navigate("/noreferral");
      }
    }
  }
  async function onSubmit(e) {
    setIsLoading(true);
    e.preventDefault();
    if (!form.password && !form.confirmPassword) {
      setIsLoading(false);
      toastMessages("error", "Please enter passwords");
    } else {
      if (form.password !== form.confirmPassword) {
        toastMessages("error", "Password and confirm password don't match");
        setIsLoading(false);
      } else if (form.password.length <= 6) {
        toastMessages("error", "Password should be greater than 6 characters");
        setIsLoading(false);
      } else if (
        !/[A-Z]/.test(form.password) ||
        !/[a-z]/.test(form.password) ||
        !/[!@#$%^&*(),.?":{}|<>]/.test(form.password)
      ) {
        toastMessages(
          "error",
          "Password should have at least 1 uppercase, 1 lowercase, 1 digit and 1 special character"
        );
        setIsLoading(false);
      } else {
        try {
          const response = await apiPostMethod(allApiUrl.signUp, form, "");
          if (response.status === 200) {
            toastMessages("success", "Account created successfully");
            setIsLoading(false);
            navigate(`/welcome?token=${response.data.token}`);
          }
        } catch (err) {
          setIsLoading(false);
          toastMessages("error", err.data.message);
          console.log("err", err);
        }
      }
    }
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  };
  const [nextStep, setNextStep] = useState(false);
  const [isError, setIsError] = useState({
    password: false,
    confirmPassword: false,
  });
  const props = {
    form,
    handleChange,
    onSubmit,
    navigate,
    setIsError,
    isError,
  };
  const [isValidEmail, setIsValidEmail] = useState(true);

  const emailValidation = (event) => {
    const inputValue = event.target.value;
    // Use a regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  };
  if (isLoading) return <FullPageLoader />;
  return (
    <>
      {!nextStep ? (
        <section className="formSection roleSelectorMain">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="formSection__content">
                  <div className="text-center mb-4">
                    <p
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      <img className="logoImg" src={Logo} alt="HireBird Logo" />
                    </p>
                  </div>
                  <div className="accountInner commonRadius8">
                    <h2>Create an Account</h2>
                    <p>Make a profile to start matching today!</p>
                    {/* <GoogleAuth referral={referral} /> */}
                    <div className="googleLogin">
                      <button
                        className="btn btn-default accountGoogleBtn"
                        onClick={() => login()}
                        // onClick={() => {
                        //   // window.open(
                        //   //   // `${process.env.REACT_APP_API_BASE_URL}/auth/google/callback`,
                        //   //   `${
                        //   //     process.env.REACT_APP_API_BASE_URL
                        //   //   }/auth/google?referredBy=${
                        //   //     referral ? referral : ""
                        //   //   }`,
                        //   //   "_self"
                        //   // );
                        // }}
                      >
                        <img src={googleIcon} /> Sign up with Google
                      </button>
                    </div>
                    <form>
                      <div className="divider">
                        <p> Sign up with Email</p>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="firstName"
                          autocomplete="off"
                          className="form-control"
                          placeholder="First Name"
                          onChange={handleChange}
                          value={form.firstName}
                          required
                        />
                        <span>
                          <i className="fa-solid fa-user-tie"></i>
                        </span>
                      </div>

                      <div className="form-group">
                        <input
                          name="lastName"
                          autocomplete="off"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          onChange={handleChange}
                          value={form.lastName}
                          required
                        />
                        <span>
                          <i className="fa-solid fa-user-tie"></i>
                        </span>
                      </div>

                      <div className="form-group">
                        <input
                          type="email"
                          autocomplete="off"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          onChange={(e) => {
                            handleChange(e);
                            emailValidation(e);
                          }}
                          value={form.email}
                          required
                        />
                        <span>
                          <i className="fa-solid fa-envelope"></i>
                        </span>
                      </div>
                      {!isValidEmail && (
                        <ul className="errorListingRed">
                          <li>
                            <img src={canceIcon} />
                            Please enter a valid email address,e.g. joe@mail.com
                          </li>
                        </ul>
                      )}

                      <div className="form-group">
                        <input
                          name="phoneNumber"
                          type="text"
                          autocomplete="off"
                          className="form-control"
                          placeholder="Phone Number"
                          onChange={(e) => {
                            setForm((prev) => {
                              const value = e.target.value.replace(/\D/g, "");
                              prev.phoneNumber = value;
                              return { ...prev };
                            });
                          }}
                          value={form.phoneNumber}
                          required
                        />
                        <span>
                          <i className="fa-solid fa-mobile-button"></i>
                        </span>
                      </div>
                      <button
                        disabled={
                          form.firstName &&
                          form.lastName &&
                          form.email &&
                          form.phoneNumber
                            ? false
                            : true
                        }
                        className="btn commonBtb"
                        onClick={() => setNextStep(true)}
                      >
                        Next step <i className="fa-solid fa-angles-right"></i>
                      </button>
                      <p className="mt-3 mb-0">
                        Already have an account?{" "}
                        <Link to="/login">Sign in</Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <CreatePassword {...props} />
      )}
    </>
  );
}

export default CreateAccount;
