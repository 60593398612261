import Sidebar from "../components/sidebar";
import Topbar from "../components/topBar";
import Footer from "../components/footer";
import searchIcon from "../assets/imgs/searchIcon.svg";
import jobImg from "../assets/imgs/jobImgNew.png";
import LocationIcon from "../assets/imgs/locationBlack.svg";
import timerIcon from "../assets/imgs/timerBlack.svg";
import calenderIcon from "../assets/imgs/calender.svg";
import chartIcon from "../assets/imgs/chart.svg";
import heartGrey from "../assets/imgs/heartGrey.svg";
import rejectIcon from "../assets/imgs/rejectIcon.svg";
import reportIcon from "../assets/imgs/reportIcon.svg";
import { useEffect, useState } from "react";
import { apiGetMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import { useLocation } from "react-router-dom";
import FullPageLoader from "../components/fullPageLoader";
import TimeAgo from "../components/daysAgoComponent";
import DOMPurify from "dompurify";

function ViewJobDetail() {
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [jobData, setJobData] = useState({});
  useEffect(() => {
    getJobDetails();
  }, []);
  async function getJobDetails() {
    setIsLoading(true);
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await apiGetMethod(
        allApiUrl.specificJob + "/" + location.pathname.split("/")[2],
        "",
        header
      );
      if (response.status === 200) {
        let resultString;
        let formattedData = parseFloat(
          response.data.post.salary.replace(/[^\d.-]/g, "")
        ).toFixed(2);
        resultString = `$${formattedData.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}`;
        //salary upto
        let resultStringUpto;
        if (
          response.data.post.salaryType == "WithinRange" &&
          response.data.post.salaryUpto
        ) {
          // const numericValueUpto = parseFloat(
          //   response.data.post.salaryUpto.replace("$", "")
          // );
          // const formattedValueUpto = numericValueUpto.toFixed(2);
          // resultStringUpto = `$${formattedValueUpto}`;
          let formattedDataUpto = parseFloat(
            response.data.post.salaryUpto.replace(/[^\d.-]/g, "")
          ).toFixed(2);
          resultStringUpto = `$${formattedDataUpto.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}`;

          resultString = resultString + "-" + resultStringUpto;
        }
        const sanitizedHTML = DOMPurify.sanitize(
          response.data.post.description
        );
        setJobData({
          ...response.data.post,
          updatedSalary: resultString,
          sanitizedHTML: sanitizedHTML,
        });
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }
  if (isLoading) return <FullPageLoader />;
  return (
    <>
      <Sidebar activePage="dashboard" />
      <div className="mainWrapper">
        <Topbar />

        <section className="jobListing">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12">
                <div className="jobListing__form"></div>
                <div className="jobDetails">
                  <div className="jobDetailsInner">
                    <div className="row">
                      <div className="col-xl-9">
                        <div className="jobDetails__Header">
                          {jobData &&
                            jobData.firmId &&
                            jobData.firmId.firmLogo && (
                              <div className="jobImg">
                                <img src={jobData.firmId.firmLogo} />
                              </div>
                            )}
                          <div className="heading">
                            <h2>{jobData.title}</h2>
                            <p className="preference">
                              <span>
                                <img src={LocationIcon} /> {jobData.location}
                              </span>{" "}
                              <span>
                                <img src={timerIcon} /> {jobData.positionType}
                              </span>{" "}
                              <span>
                                <img src={calenderIcon} />{" "}
                                <TimeAgo timestamp={jobData.createdAt} />
                              </span>{" "}
                            </p>
                            <h4>
                              {jobData.updatedSalary}/{" "}
                              <span>{jobData.timeperiod}</span>
                            </h4>
                            <div
                              className="detailText detailsContent"
                              dangerouslySetInnerHTML={{
                                __html: jobData.sanitizedHTML,
                              }}
                            />
                          </div>
                        </div>
                        <div className="btns mt-5">
                          <button className="btn">Hide Job</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default ViewJobDetail;
