import { BrowserRouter, Link } from "react-router-dom";
import Logo from "../assets/imgs/HireBIRD.png";
import welcomePatteren from "../assets/imgs/welcomePatteren.svg";
import { useEffect, useState } from "react";
import { gsap } from "gsap";

function WelcomeLanding() {
  return (
    <>
      {/* <div class="bg-slide-accent"></div>
    <div class="bg-slide"></div> */}
      {/* <div className="patterenSection">
            <img src={welcomePatteren} />
        </div> */}
      <section className={`welcome `}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-12">
              <div className="welcome__Logo">
                <img
                  className="wow flip"
                  data-wow-duration="2s"
                  data-wow-delay="0.8s"
                  src={Logo}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WelcomeLanding;
