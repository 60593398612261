import QRCode from "qrcode.react";
import { allApiUrl } from "../api/apiRoutes";
import Logo from "../assets/imgs/HireBIRD.png";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function PublicQr() {
  let location = useLocation();
  let { referral } = queryString.parse(location.search);
  let url;
  if (process.env.REACT_APP_ENVIRONMENT == "development") {
    url = `${process.env.REACT_APP_WEBSITE}/create-account?referral=${referral}`;
  } else if (process.env.REACT_APP_ENVIRONMENT == "staging") {
    url = `${process.env.REACT_APP_WEBSITE}/create-account?referral=${referral}`;
  } else {
    url = `${process.env.REACT_APP_WEBSITE}/create-account?referral=${referral}`;
  }

  // if (process.env.REACT_APP_ENVIRONMENT == "development") {
  //   url = `${process.env.REACT_APP_WEBSITE}/create-account?referral=${referral}`;
  // } else {
  //   url = `https://stage-hirebird.softuvo.click/create-account?referral=${referral}`;
  // }

  return (
    <>
      <div className="mainWrapper ps-0">
        <div className="publicQr">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="publicQr__Content">
                  <h3>Scan QR Code to join</h3>
                  <img src={Logo} />
                </div>
                <div className="publicQr__Img">
                  <QRCode value={url} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicQr;
