import {
  BrowserRouter,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/navbar";
import jobImg from "../assets/imgs/jobPositionImg.svg";
import LocationIcon from "../assets/imgs/locationIconGreen.svg";
import calenderBlck from "../assets/imgs/calenderBlck.svg";
import timerBlack from "../assets/imgs/timerBlack.svg";
import locationBlack from "../assets/imgs/locationBlack.svg";
import Modals from "../components/modals/dashboardModal";
import FullPageLoader from "../components/fullPageLoader";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../api/rest";
import DOMPurify from "dompurify";
import { allApiUrl } from "../api/apiRoutes";
import TimeAgo from "../components/daysAgoComponent";
import rocketIconSmall from "../assets/imgs/rocketIconSmall.svg";
import linkedInBtn from "../assets/imgs/linkedInBtn.svg";
import googleIcon from "../assets/imgs/googleIcon.svg";
import easyapplyBtn from "../assets/imgs/easyapplyBtn.png";
import StepFormProgress from "./stepForm-progress";
import { useGoogleLogin } from "@react-oauth/google";
import Logo from "../assets/imgs/HireBIRD.png";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import ImageUpload from "../components/customImageEditor";
import whiteCross from "../assets/imgs/whiteCross.svg";
import uploadIcon from "../assets/imgs/uploadIcon.svg";
import DatePicker from "react-datepicker";
import { CustomDatePickerInput } from "./modal";
import toastMessages from "../components/toastMessage";
import Topbar from "../components/topBar";
const ShareJobModal = (props) => {
  //constants
  let navigate = useNavigate();
  const libraries = ["places"];
  const inputRef = useRef();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  //States
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [form, setForm] = useState({
    firstName: "",
    location: "",
    qualification: [
      {
        university: "",
        degreeType: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ],
    workExperience: [
      {
        companyName: "",
        designation: "",
        startDate: "",
        endDate: "",
        description: "",
      },
    ],
    skills: [
      {
        skillSet: "",
      },
    ],
  });
  const [page, setPage] = useState("apply");
  // ---------------------Image Upload States--------------------------------
  const [isHeadshotImageDeleted, setIsHeadshotImageDeleted] = useState(false);
  const [previewImage, setPreviewImage] = useState({
    headshotImage: "",
  });
  const [profilePicture, setProfilePicture] = useState("");
  //------------------University states----------------------------
  const [currEditUniversitiesIndex, setCurrEditUniversitiesIndex] =
    useState(null);
  const [universityList, setUniversityList] = useState([]);
  const [isUniverSelected, setIsUniversitySelected] = useState(false);
  const [qualificationCount, setQualificationCount] = useState(1);
  // ------------------Company states ---------------------
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [workExperienceCount, setWorkExperienceCount] = useState(1);
  const [currEditCompanyIndex, setCurrEditCompanyIndex] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  // ----------------skill state--------------
  const [skillsCount, setSkillsCount] = useState(1);
  const [email, setEmail] = useState("");
  // Functions
  async function onUniversitySearchHandler(value) {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
      };
      const response = await apiPostMethod(
        allApiUrl.search,
        { text: value },
        header
      );
      if (response.status === 200) {
        setUniversityList(response.data.universities);
      }
    } catch (err) {
      console.log("err", err.data.message);
    }
  }
  const oneTimeLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      googleAuthHandler(codeResponse.access_token);
    },
  });
  async function googleAuthHandler(data) {
    setIsLoading(true);
    try {
      let res = await apiPostMethod(
        allApiUrl.googleAuth,
        { access_token: data },
        ""
      );
      if (res.status === 200) {
        setToken(res.data.token);
        fetchData(res.data.token, res.data.token);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      navigate("/noreferral");
      console.log("Error", err);
    }
  }
  const updateProfile = async (accountType, accountId, profileToken) => {
    let data = {
      accountType,
      accountId: accountId,
    };
    try {
      let header = {
        Authorization: `Bearer ${profileToken}`,
      };
      let response = await apiPutMethod(allApiUrl.updateprofile, data, header);
      if (response.status == 200) {
        props.setShowtopbar(true);
        // localStorage.setItem(
        //   "userInfo",
        //   JSON.stringify({ ...userInfo, accountType: "jobseeker" })
        // );
      }
    } catch (err) {
      console.log("err", err.data.message);
    }
  };
  const fetchData = async (token) => {
    try {
      let header = {
        Authorization: `Bearer ${token}`,
      };
      let response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status == 200) {
        if (
          response.data &&
          response.data.data &&
          response.data.data.candidateProfileId
        ) {
          let updatedData = response.data.data.candidateProfileId;
          let qualification = [];
          let workExperience = [];
          let skills = [];
          if (updatedData.qualification.length == 0) {
            qualification = [
              {
                university: "",
                degreeType: "",
                startDate: "",
                endDate: "",
                description: "",
              },
            ];
          } else {
            qualification = updatedData.qualification;
          }
          if (updatedData.workExperience.length == 0) {
            workExperience = [
              {
                companyName: "",
                designation: "",
                startDate: "",
                endDate: "",
                description: "",
              },
            ];
          } else {
            workExperience = updatedData.workExperience;
          }
          if (updatedData.skills.length == 0) {
            skills = [
              {
                skillSet: "",
              },
            ];
          } else {
            skills = updatedData.skills;
          }
          localStorage.setItem("userInfo", JSON.stringify(response.data.data));
          localStorage.setItem("accessToken", token);
          localStorage.setItem("isLoggedIn", true);
          setForm(
            JSON.parse(
              JSON.stringify({
                _id: response.data.data._id,
                profileImage: response.data.data.profileImage,
                firstName: response.data.data.firstName,
                location: updatedData.location,
                qualification,
                workExperience,
                skills,
              })
            )
          );
          props.setShowtopbar(true);
          setPreviewImage({
            ...previewImage,
            headshotImage: response.data.data.profileImage,
          });
          setQualificationCount(
            updatedData.qualification.length === 0
              ? 1
              : updatedData.qualification.length
          );
          setWorkExperienceCount(
            updatedData.workExperience.length == 0
              ? 1
              : updatedData.workExperience.length
          );
          setSkillsCount(
            updatedData.skills.length == 0 ? 1 : updatedData.skills.length
          );
          const locationModal = new window.bootstrap.Modal(
            document.getElementById("locationModal")
          );
          locationModal.show();
        } else {
          //if email is already registrated as employer.
          if (
            response.data &&
            response.data.data &&
            response.data.data.employerProfileId
          ) {
            toastMessages(
              "error",
              "This email is registered as Recruiter .please try with different email."
            );
          } else {
            //if candidate is not regisrated then sign up the candidate with google id
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...response.data.data,
                accountType: "jobseeker",
              })
            );

            localStorage.setItem("accessToken", token);
            localStorage.setItem("isLoggedIn", true);
            const locationModal = new window.bootstrap.Modal(
              document.getElementById("locationModal")
            );
            locationModal.show();
            updateProfile("jobseeker", response.data.data._id, token);
          }
        }
      }
    } catch (err) {
      console.log("error", err.data.message);
    }
  };
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setForm((prev) => {
        prev.location = place.formatted_address;
        return { ...prev };
      });
    }
  };
  async function oneTimeSubmitHandler(e, type, field) {
    e.preventDefault();
    const header = {
      Authorization: `Bearer ${token}`,
    };
    try {
      if (type) {
        if (isHeadshotImageDeleted) {
          let data = {
            accountId: form._id,
            profileImage: "",
          };
          await apiPutMethod(allApiUrl.updateprofile, data, header);
        } else {
          let formData = new FormData();
          formData.append("file", profilePicture);
          formData.append("accountId", form._id);
          await apiPutMethod(allApiUrl.updateprofile, formData, header);
        }
      } else {
        let updatedData = {
          accountId: form._id,
          [field]: form[field],
        };
        await apiPutMethod(allApiUrl.updateprofile, updatedData, header);
      }
    } catch (err) {
      console.log("err", err.message);
    }
  }
  const increaseQualificationCount = (event) => {
    event.preventDefault();
    setQualificationCount((prev) => prev + 1);
    setForm({
      ...form,
      qualification: [
        ...form.qualification,
        {
          university: "",
          degreeType: "",
          startDate: "",
          endDate: "",
          description: "",
        },
      ],
    });
  };
  const increaseWorkCount = (event) => {
    event.preventDefault();
    setWorkExperienceCount((prev) => prev + 1);
    setForm({
      ...form,
      workExperience: [
        ...form.workExperience,
        {
          companyName: "",
          designation: "",
          startDate: "",
          endDate: "",
          description: "",
        },
      ],
    });
  };
  const increaseSkillCount = (event) => {
    event.preventDefault();
    setSkillsCount((prev) => prev + 1);
    setForm({
      ...form,
      skills: [
        ...form.skills,
        {
          skillSet: "",
        },
      ],
    });
  };
  async function onCompanyHandler(value) {
    try {
      const header = {
        Authorization: `Bearer ${token}`,
      };
      const response = await apiPostMethod(
        allApiUrl.searchFirm,
        { text: value },
        header
      );
      if (response.status === 200) {
        let data = [];
        response.data.data.map((item) => {
          data.push({ firmName: item.firmName, firmId: item._id });
        });
        setCompanyOptions(data);
      }
    } catch (err) {}
  }
  const LINKEDIN_CLIENT_ID = "862r923hyyd6je";
  const LINKEDIN_CALLBACK_URL = "http://localhost:3000/welcome";
  const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    LINKEDIN_CALLBACK_URL
  )}&scope=email,profile,openid`;
  const JobApplyHandler = async () => {
    let data = {
      jobId: props.jobData._id,
    };
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      let response = await apiPostMethod(allApiUrl.applyJob, data, header);
      if (response.status == 200) {
        setPage("success");
        setForm({
          firstName: "",
          location: "",
          qualification: [],
          workExperience: [],
          skills: [],
        });
      }
    } catch (err) {
      toastMessages("error", err.data.message);
      console.log("err", err.data.message);
    }
  };
  return (
    <div className="mainWrapper">
      {/* Apply Job Modal start*/}
      <div
        class="modal fade commonModal editEducationModal candidateOnboarding"
        id="applyJobModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0"></div>
            <div class="modal-body pt-0 px-0">
              <div className="jobProgress">
                <StepFormProgress value={"100%"} className="mt-0" />
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="commonModalContent px-4">
                <h3 className="ModalHeading">Sign up for Hirebird to apply!</h3>
                {/* {steps == "33.33" ? <ConfirmContent /> : ""} */}
                <div className="jobPosted__List text-start">
                  <div className="postHeader">
                    {props.jobData &&
                      props.jobData.firmId &&
                      props.jobData.firmId.firmLogo && (
                        <div className="postImg">
                          <img src={props.jobData.firmId.firmLogo} />
                          {/* <span className="img">
                        <img src={rocketIconSmall} />
                      </span> */}
                        </div>
                      )}
                    <div className="postText">
                      <h4 className="white">
                        {props.jobData.title}
                        <span className="green">
                          {" "}
                          <img src={LocationIcon} /> {props.jobData.location}
                        </span>
                      </h4>
                      <span class="postTime d-block text-start">
                        {/* 2 days ago */}
                        <TimeAgo timestamp={props.jobData.createdAt} />
                      </span>
                    </div>
                  </div>

                  <div className="postBodyContent">
                    <h4>
                      {props.jobData.updatedSalary}/{" "}
                      <span>{props.jobData.timeperiod}</span>
                    </h4>
                    {/* <p>
                      Responsible for overseeing all store operations and
                      ensuring the highest quality of customer service. You must
                      have strong leadership and communication skills to
                      effectively manage a team of employees, control inventory
                      and costs, and exceed sales goals.
                    </p> */}
                    <div
                      className="detailText"
                      dangerouslySetInnerHTML={{
                        __html: props.jobData.sanitizedHTML,
                      }}
                    />
                  </div>
                  <div className="postFooter">
                    <div
                      className="tags"
                      style={{ textTransform: "capitalize" }}
                    >
                      <span>{props.jobData.timeperiod}</span>
                      <span>{props.jobData.jobType}</span>
                      {props.jobData.location ? (
                        <span>
                          {props.jobData.location &&
                            props.jobData.location.split(",")[0]}
                        </span>
                      ) : (
                        ""
                      )}
                      <span>{props.jobData.positionType}</span>
                    </div>
                  </div>
                </div>

                <div className="postSignup">
                  <div className="btns">
                    <button
                      className="btn googleBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => oneTimeLogin()}
                    >
                      <img src={googleIcon} /> Sign up with Google
                    </button>
                    <a className="linkedApplyBtn" href={linkedinOAuthURL}>
                      {/* Sign in with LinkedIn */}
                      <img
                        src={easyapplyBtn}
                        onClick={() => {
                          localStorage.setItem("jobId", props.jobData._id);
                          localStorage.setItem(
                            "sharePageUrl",
                            props.location.pathname
                          );
                        }}
                      />
                    </a>

                    {/* <a className="btn linkedBtn" onClick={linkedinOAuthURL}>
                      <img src={linkedInBtn} />
                    </a> */}
                  </div>
                  <p>
                    <span>Or Sign up with Email!</span>
                  </p>
                  <div className="form-group signinEmail">
                    <input
                      required
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      autocomplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <span>
                      <i className="fa-solid fa-envelope"></i>
                    </span>

                    <button
                      className="btn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => navigate(`/create-account?email=${email}`)}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Apply Job Modal end*/}
      {/* Location start */}
      <div
        class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
        id="locationModal"
        data-bs-backdrop="static"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0">
              <img className="logo" src={Logo} />
            </div>
            <div class="modal-body pt-0 px-0">
              <div className="commonModalContent px-0">
                <StepFormProgress value={"20%"} />
                <h3 className="ModalHeading">
                  Nice to meet you {form.firstName}! Are you still located in{" "}
                  {form.location}
                </h3>
                <form>
                  <div className="row">
                    <div className="form-group">
                      {isLoaded && (
                        <StandaloneSearchBox
                          onLoad={(ref) => (inputRef.current = ref)}
                          onPlacesChanged={handlePlaceChanged}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Location"
                            value={form.location}
                            onChange={(e) => {
                              setForm((prev) => {
                                prev.location = e.target.value;
                                return { ...prev };
                              });
                            }}
                          />
                        </StandaloneSearchBox>
                      )}
                    </div>
                  </div>
                </form>
                <div className="formBtns mt-5 mb-4">
                  <button
                    className="btn cancelBtn"
                    data-bs-target="#oneTimeuploadImageModal"
                    data-bs-toggle="modal"
                  >
                    Skip for now.
                  </button>
                  <button
                    disabled={!form.location}
                    className="btn inviteBtn"
                    data-bs-target="#oneTimeuploadImageModal"
                    data-bs-toggle="modal"
                    onClick={(e) => oneTimeSubmitHandler(e, false, "location")}
                  >
                    Done, next step!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Location end */}
      {/* HeaderImage start */}
      <div
        class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
        id="oneTimeuploadImageModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        data-bs-backdrop="static"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0">
              <img className="logo" src={Logo} />
            </div>
            <div class="modal-body pt-0 px-0">
              <div className="commonModalContent px-0">
                <StepFormProgress value={"40%"} />
                <h3 className="ModalHeading">
                  Do you like this picture ? Change it now if you'd like
                </h3>

                <form>
                  <div
                    className="dragDrop"
                    data-bs-target="#imageUploadModalStarted"
                    data-bs-toggle="modal"
                  >
                    <img src={uploadIcon} />
                    <p>
                      Drop your file here{" "}
                      <span className="green">or Browser</span>
                    </p>
                    <p>
                      We Support <span className="white">JPG, PNG</span> files.
                      Make sure that your file is no more than 1 mb.
                    </p>
                  </div>
                  {previewImage && previewImage.headshotImage && (
                    <span className="previewUpload">
                      {previewImage && previewImage.headshotImage && (
                        <img
                          src={previewImage.headshotImage}
                          alt="headhot-image"
                        />
                      )}
                      {previewImage && previewImage.headshotImage && (
                        <span className="crossIcon">
                          <img
                            src={whiteCross}
                            onClick={() => {
                              setIsHeadshotImageDeleted(true);
                              setForm({ ...form, profileImage: "" });
                              setPreviewImage({
                                previewImage,
                                headshotImage: "",
                              });
                            }}
                          />
                        </span>
                      )}
                    </span>
                  )}
                </form>
                <div className="formBtns mt-5 mb-4">
                  <button
                    className="btn cancelBtn"
                    data-bs-target="#oneTimeEducationhistoryModal"
                    data-bs-toggle="modal"
                  >
                    Skip for now.
                  </button>
                  <button
                    className="btn inviteBtn"
                    data-bs-target="#oneTimeEducationhistoryModal"
                    data-bs-toggle="modal"
                    onClick={(e) =>
                      oneTimeSubmitHandler(e, true, "profileImage")
                    }
                  >
                    Done, next step!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* HeaderImage end */}
      {/* education start */}
      <div
        class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
        id="oneTimeEducationhistoryModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          onClick={() => setIsUniversitySelected(false)}
        >
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0">
              {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
              <img className="logo" src={Logo} />
            </div>
            <div class="modal-body pt-0 px-0">
              {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
              <div className="commonModalContent px-0">
                <StepFormProgress value={"60%"} />
                <h3 className="ModalHeading">
                  Does this seem accurate? Add anything we missed.
                </h3>
                <form>
                  {[...Array(qualificationCount)].map((value, index) => {
                    return (
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              onChange={(e) => {
                                setCurrEditUniversitiesIndex(index);
                                setIsUniversitySelected(true);
                                setForm((prevData) => {
                                  let qualification = [
                                    ...prevData.qualification,
                                  ];
                                  qualification[index].university =
                                    e.target.value;
                                  return {
                                    ...prevData,
                                    qualification,
                                  };
                                });
                                onUniversitySearchHandler(e.target.value);
                              }}
                              value={form.qualification[index]?.university}
                              autocomplete="off"
                              type="text"
                              className="form-control"
                              placeholder="College/University *"
                            />
                            {isUniverSelected &&
                              currEditUniversitiesIndex == index && (
                                <ul>
                                  {universityList &&
                                    universityList.length > 0 &&
                                    universityList.map((item, index) => {
                                      return (
                                        <li
                                          style={{ cursor: "pointer" }}
                                          key={index}
                                          onClick={() => {
                                            setIsUniversitySelected(false);
                                            setForm((prevData) => {
                                              let qualification = [
                                                ...(prevData.qualification ||
                                                  []),
                                              ];
                                              if (
                                                !qualification[
                                                  qualificationCount - 1
                                                ]
                                              ) {
                                                qualification[
                                                  qualificationCount - 1
                                                ] = {
                                                  university: "",
                                                  degreeType: "",
                                                  startDate: "",
                                                  isPresent: false,
                                                  endDate: "",
                                                  description: "",
                                                };
                                              }
                                              qualification[
                                                currEditUniversitiesIndex
                                              ].university = item.name;
                                              qualification[
                                                currEditUniversitiesIndex
                                              ].universityLogo = item.logo;

                                              return {
                                                ...prevData,
                                                qualification,
                                              };
                                            });
                                          }}
                                        >
                                          <img
                                            src={item.logo}
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              borderRadius: "15px",
                                              marginRight: "13px",
                                            }}
                                          />
                                          {item.name}
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              onChange={(e) => {
                                setForm((prevData) => {
                                  let qualification = [
                                    ...prevData.qualification,
                                  ];
                                  qualification[index].degreeType =
                                    e.target.value;
                                  return {
                                    ...prevData,
                                    qualification,
                                  };
                                });
                              }}
                              value={form.qualification[index]?.degreeType}
                              autocomplete="off"
                              type="text"
                              className="form-control"
                              placeholder="Degree Type *"
                            />
                          </div>
                        </div>
                        {qualificationCount > 1 && (
                          <button
                            className="deleteEduBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              setQualificationCount((prev) => prev - 1);
                              setForm((prev) => {
                                let qualification = [...prev.qualification];
                                qualification.splice(index, 1);
                                return { ...prev, qualification };
                              });
                            }}
                          >
                            <i className="fa-solid fa-trash-can"></i>
                          </button>
                        )}
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <DatePicker
                              showYearDropdown
                              showMonthYearPicker
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={15}
                              placeholderText="Start Date*"
                              dateFormat="MM/yyyy"
                              customInput={
                                <CustomDatePickerInput placeholder="Start Date*" />
                              }
                              // scrollableYearDropdown
                              selected={parseInt(
                                form.qualification[index]?.startDate
                              )}
                              onChange={(data) => {
                                setForm((prevData) => {
                                  let qualification = [
                                    ...prevData.qualification,
                                  ];
                                  qualification[index].startDate = data
                                    .getTime()
                                    .toString();
                                  return {
                                    ...prevData,
                                    qualification,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <DatePicker
                              showYearDropdown
                              showMonthYearPicker
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={15}
                              placeholderText="End Date*"
                              dateFormat="MM/yyyy"
                              // scrollableYearDropdown
                              customInput={
                                <CustomDatePickerInput placeholder="End Date*" />
                              }
                              selected={parseInt(
                                form.qualification[index]?.endDate
                              )}
                              onChange={(data) => {
                                setForm((prevData) => {
                                  let qualification = [
                                    ...prevData.qualification,
                                  ];
                                  qualification[index].endDate = data
                                    .getTime()
                                    .toString();
                                  if (qualification[index].isPresent) {
                                    qualification[index].isPresent = false;
                                  }
                                  return {
                                    ...prevData,
                                    qualification,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-check mb-4 text-end">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkbox"
                              checked={form.qualification[index]?.isPresent}
                              onChange={(event) => {
                                let alreadyPresent = form.qualification.some(
                                  (item) => item.isPresent
                                );
                                if (
                                  !alreadyPresent ||
                                  (alreadyPresent && !event.target.checked)
                                ) {
                                  setForm((prevData) => {
                                    let qualification = [
                                      ...prevData.qualification,
                                    ];
                                    qualification[index].isPresent =
                                      !prevData.qualification[index].isPresent;
                                    if (
                                      prevData.qualification[index].isPresent
                                    ) {
                                      prevData.qualification[index].endDate =
                                        "";
                                    }
                                    return {
                                      ...prevData,
                                      qualification,
                                    };
                                  });
                                } else {
                                  toastMessages(
                                    "error",
                                    "Current occupation already selected."
                                  );
                                  event.target.checked = false;
                                }
                              }}
                            />
                            Present
                          </label>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              onChange={(e) => {
                                setForm((prevData) => {
                                  let qualification = [
                                    ...prevData.qualification,
                                  ];
                                  qualification[index].description =
                                    e.target.value;
                                  return {
                                    ...prevData,
                                    qualification,
                                  };
                                });
                              }}
                              value={form.qualification[index]?.description}
                              className="form-control"
                              placeholder="Description"
                            ></textarea>
                            {index == qualificationCount - 1 && (
                              <div className="text-start">
                                <button
                                  className="btn appentBtn"
                                  onClick={(event) => {
                                    increaseQualificationCount(event);
                                  }}
                                >
                                  Add Education History
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </form>
                <div className="formBtns mt-5 mb-4">
                  <button
                    className="btn cancelBtn"
                    data-bs-target="#experiencehistoryModal"
                    data-bs-toggle="modal"
                  >
                    Skip for now.
                  </button>
                  <button
                    disabled={
                      form &&
                      form.qualification &&
                      form.qualification.length > 0 &&
                      form.qualification.some(
                        (item) =>
                          !item.university ||
                          !item.degreeType ||
                          !item.startDate ||
                          ((!item.isPresent || item.endDate) &&
                            (item.isPresent || !item.endDate))
                        // ||!item.description
                      )
                    }
                    className="btn inviteBtn"
                    data-bs-target="#experiencehistoryModal"
                    data-bs-toggle="modal"
                    onClick={(e) =>
                      oneTimeSubmitHandler(e, false, "qualification")
                    }
                  >
                    Done, next step!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* education end */}

      {/* workExperience  start */}
      <div
        class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
        id="experiencehistoryModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          onClick={() => setIsCompanySelected(false)}
        >
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0">
              <img className="logo" src={Logo} />
            </div>
            <div class="modal-body pt-0 px-0">
              <div className="commonModalContent px-0">
                <StepFormProgress value={"80%"} />
                <h3 className="ModalHeading mb-2">
                  How about work experience? Anything we missed?
                </h3>
                <p className="experienceText">
                  We suggest adding work/internship experience that is relevant
                  to positions <br /> you may end up applying for.
                </p>
                <form>
                  {[...Array(workExperienceCount)].map((value, index) => {
                    return (
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              onChange={(e) => {
                                setCurrEditCompanyIndex(index);
                                setIsCompanySelected(true);
                                setForm((prevData) => {
                                  let workExperience = [
                                    ...prevData.workExperience,
                                  ];
                                  workExperience[index].companyName =
                                    e.target.value;
                                  return {
                                    ...prevData,
                                    workExperience,
                                  };
                                });
                                onCompanyHandler(e.target.value);
                              }}
                              value={form.workExperience[index]?.companyName}
                              autocomplete="off"
                              type="text"
                              className="form-control"
                              placeholder="Company Name *"
                            />
                            {isCompanySelected &&
                              currEditCompanyIndex == index && (
                                <ul>
                                  {companyOptions &&
                                    companyOptions.length > 0 &&
                                    companyOptions.map((item, index) => {
                                      return (
                                        <li
                                          style={{ cursor: "pointer" }}
                                          key={index}
                                          onClick={() => {
                                            setIsCompanySelected(false);
                                            setForm((prevData) => {
                                              let workExperience = [
                                                ...(prevData.workExperience ||
                                                  []),
                                              ];
                                              if (
                                                !workExperience[
                                                  workExperienceCount - 1
                                                ]
                                              ) {
                                                workExperience[
                                                  workExperienceCount - 1
                                                ] = {
                                                  companyName: "",
                                                  designation: "",
                                                  startDate: "",
                                                  endDate: "",
                                                  description: "",
                                                };
                                              }
                                              workExperience[
                                                currEditCompanyIndex
                                              ].companyName = item.firmName;
                                              return {
                                                ...prevData,
                                                workExperience,
                                              };
                                            });
                                          }}
                                        >
                                          {item.firmName}
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              onChange={(e) => {
                                setForm((prevData) => {
                                  let workExperience = [
                                    ...prevData.workExperience,
                                  ];
                                  workExperience[index].designation =
                                    e.target.value;
                                  return {
                                    ...prevData,
                                    workExperience,
                                  };
                                });
                              }}
                              value={form.workExperience[index]?.designation}
                              autocomplete="off"
                              type="text"
                              className="form-control"
                              placeholder="Position *"
                            />
                          </div>
                        </div>
                        {workExperienceCount > 1 && (
                          <button className="deleteEduBtn">
                            <i
                              className="fa-solid fa-trash-can"
                              onClick={(e) => {
                                e.preventDefault();
                                setWorkExperienceCount((prev) => prev - 1);
                                setForm((prev) => {
                                  let workExperience = [...prev.workExperience];
                                  workExperience.splice(index, 1);
                                  return { ...prev, workExperience };
                                });
                              }}
                            ></i>
                          </button>
                        )}
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <DatePicker
                              showYearDropdown
                              showMonthYearPicker
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={15}
                              placeholderText="Start Date*"
                              dateFormat="MM/yyyy"
                              customInput={
                                <CustomDatePickerInput placeholder="Start Date*" />
                              }
                              // scrollableYearDropdown
                              selected={parseInt(
                                form.workExperience[index]?.startDate
                              )}
                              onChange={(data) => {
                                setForm((prevData) => {
                                  let workExperience = [
                                    ...prevData.workExperience,
                                  ];
                                  workExperience[index].startDate = data
                                    .getTime()
                                    .toString();
                                  return {
                                    ...prevData,
                                    workExperience,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <DatePicker
                              showYearDropdown
                              showMonthYearPicker
                              dateFormatCalendar="MMMM"
                              yearDropdownItemNumber={15}
                              placeholderText="End Date*"
                              dateFormat="MM/yyyy"
                              customInput={
                                <CustomDatePickerInput placeholder="End Date*" />
                              }
                              // scrollableYearDropdown
                              selected={parseInt(
                                form.workExperience[index]?.endDate
                              )}
                              onChange={(data) => {
                                setForm((prevData) => {
                                  let workExperience = [
                                    ...prevData.workExperience,
                                  ];
                                  workExperience[index].endDate = data
                                    .getTime()
                                    .toString();
                                  return {
                                    ...prevData,
                                    workExperience,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-check mb-4 text-end">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkbox"
                              checked={form.workExperience[index]?.isPresent}
                              onChange={(event) => {
                                let alreadyPresent = form.workExperience.some(
                                  (item) => item.isPresent
                                );
                                if (
                                  !alreadyPresent ||
                                  (alreadyPresent && !event.target.checked)
                                ) {
                                  setForm((prevData) => {
                                    let workExperience = [
                                      ...prevData.workExperience,
                                    ];
                                    workExperience[index].isPresent =
                                      !prevData.workExperience[index].isPresent;
                                    if (
                                      prevData.workExperience[index].isPresent
                                    ) {
                                      prevData.workExperience[index].endDate =
                                        "";
                                    }
                                    //  data
                                    //   .getTime()
                                    //   .toString();
                                    return {
                                      ...prevData,
                                      workExperience,
                                    };
                                  });
                                } else {
                                  toastMessages(
                                    "error",
                                    "Can't select more than one current at a time"
                                  );
                                  event.target.checked = false;
                                }
                              }}
                            />
                            Present
                          </label>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              onChange={(e) => {
                                setForm((prevData) => {
                                  let workExperience = [
                                    ...prevData.workExperience,
                                  ];
                                  workExperience[index].description =
                                    e.target.value;
                                  return {
                                    ...prevData,
                                    workExperience,
                                  };
                                });
                              }}
                              value={form.workExperience[index]?.description}
                              autocomplete="off"
                              className="form-control"
                              placeholder="Description"
                            ></textarea>
                            {index == workExperienceCount - 1 && (
                              <div className="text-start">
                                <button
                                  className="btn appentBtn"
                                  onClick={(event) => {
                                    setIsCompanySelected(false);
                                    increaseWorkCount(event);
                                  }}
                                >
                                  Add Experience History
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </form>
                <div className="formBtns mt-5 mb-4">
                  <button
                    className="btn cancelBtn"
                    data-bs-target="#additionalSkillModal"
                    data-bs-toggle="modal"
                  >
                    Skip for now.
                  </button>
                  <button
                    disabled={
                      form.workExperience &&
                      form.workExperience.some(
                        (item) =>
                          !item.companyName ||
                          // !item.description ||
                          !item.designation ||
                          !item.startDate ||
                          ((!item.isPresent || item.endDate) &&
                            (item.isPresent || !item.endDate))
                        // (!item.endDate && item.isPresent )|| (item.endDate && !item.isPresent )||
                      )
                    }
                    className="btn inviteBtn"
                    data-bs-target="#additionalSkillModal"
                    data-bs-toggle="modal"
                    onClick={(e) => {
                      oneTimeSubmitHandler(e, false, "workExperience");
                    }}
                  >
                    Done, next step!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* workExperience  end */}

      {/* Skill section start */}
      <div
        class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
        id="additionalSkillModal"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0">
              <img className="logo" src={Logo} />
            </div>
            <div class="modal-body pt-0 px-0">
              <div className="commonModalContent px-0">
                <StepFormProgress value={"100%"} />
                <h3 className="ModalHeading">
                  Add some of your top skills to standout to employers!
                </h3>
                <form>
                  {[...Array(skillsCount)].map((value, index) => {
                    return (
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              onChange={(e) => {
                                setForm((prevData) => {
                                  let skills = [...prevData.skills];
                                  skills[index].skillSet = e.target.value;
                                  return {
                                    ...prevData,
                                    skills,
                                  };
                                });
                              }}
                              value={form.skills[index]?.skillSet}
                              type="text"
                              className="form-control"
                              placeholder="Add Skill"
                            />
                          </div>
                        </div>
                        {index == skillsCount - 1 && (
                          <div className="col-lg-12">
                            <div className="form-group">
                              <div className="text-start">
                                <button
                                  disabled={
                                    form &&
                                    form.skills.some(
                                      (item) => !item.skillSet
                                      // ||
                                      // !item.proficiencyGrade
                                    )
                                  }
                                  className="btn appentBtn"
                                  onClick={(event) => {
                                    increaseSkillCount(event);
                                  }}
                                >
                                  Add Additional Skills
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </form>
                <div className="formBtns mt-5 mb-4">
                  <button
                    className="btn cancelBtn"
                    data-bs-target="#confirmModalOneTime"
                    data-bs-toggle="modal"
                    // onClick={(e) =>
                    //   onSubmit(e, "confirmModalOneTime", false, "skills")
                    // }
                  >
                    Skip for now.
                  </button>
                  <button
                    disabled={
                      form && form.skills.some((item) => !item.skillSet)
                    }
                    className="btn inviteBtn"
                    data-bs-target="#confirmModalOneTime"
                    data-bs-toggle="modal"
                    onClick={(e) => oneTimeSubmitHandler(e, false, "skills")}
                  >
                    Complete my profile!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Skill section end */}
      {/* Nice Work Modal start */}
      <div
        class="modal fade commonModal editEducationModal confirmModal candidateOnboarding"
        id="confirmModalOneTime"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header d-block pt-0 px-0">
              <img className="logo" src={Logo} />
            </div>

            <div class="modal-body pt-0 px-0 pb-0 pt-3">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              {page == "apply" ? (
                <div className="commonModalContent px-4">
                  <h5 className="mb-2">Nice Work ! That was easy.</h5>
                  <p className="confirmText">
                    You’re all set to apply to your original position now! You
                    can see view your application status on your Hirebird
                    account.
                  </p>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn inviteBtn w-100"
                      // data-bs-dismiss="modal"
                      // aria-label="Close"
                      onClick={() => {
                        JobApplyHandler();
                        // setPage("success");
                        // setForm({
                        //   firstName: "",
                        //   location: "",
                        //   qualification: [],
                        //   workExperience: [],
                        //   skills: [],
                        // });
                        // navigate("/profile");
                        // window.location.reload();
                      }}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              ) : (
                <div className="commonModalContent">
                  <h5 className="mb-2">Success!</h5>
                  <p className="confirmText">
                    You just applied to your first job on Hirebird! You can view
                    your application status on your profile or view more jobs
                    like the one you just applied for.
                  </p>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setForm({
                          firstName: "",
                          location: "",
                          qualification: [],
                          workExperience: [],
                          skills: [],
                        });
                      }}
                    >
                      Skip for now.
                    </button>
                    <button
                      className="btn inviteBtn AppStatusBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setForm({
                          firstName: "",
                          location: "",
                          qualification: [],
                          workExperience: [],
                          skills: [],
                        });
                        setPage("success");
                      }}
                    >
                      View my Application Status
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Nice work Modal end */}
      {/* Image upload Modal start */}
      <div
        class="modal fade commonModal editExperienceModalSingle"
        id="imageUploadModalStarted"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header"></div>
            <div class="modal-body pt-0">
              <div className="commonModalContent px-0">
                <h3 className="ModalHeading">Upload Image</h3>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="imageUploaderSection">
                    <ImageUpload
                      type="file"
                      page="oneTimeuploadImageModal"
                      onBoardingRecruiter={true}
                      setPreview={setPreviewImage}
                      setImage={setProfilePicture}
                      preview={previewImage}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Image Upload Modal end */}
    </div>
  );
};
function ShareJob() {
  let navigate = useNavigate();
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [jobData, setJobData] = useState({});
  const [showTopbar, setShowtopbar] = useState(false);
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    getJobDetails();
  }, []);
  // useEffect(() => {
  //   if (userInfo.accountType == "recruiter") {
  //     navigate("/profile");
  //   } else {
  //     navigate("/my-profile");
  //   }
  // }, [userInfo]);
  async function getJobDetails() {
    setIsLoading(true);
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      let data = {
        shareUrl: location.pathname,
      };
      const response = await apiPostMethod(allApiUrl.jobUrl, data, header);
      if (response.status === 200) {
        let resultString;
        let formattedData = parseFloat(
          response.data.post.salary.replace(/[^\d.-]/g, "")
        ).toFixed(2);
        resultString = `$${formattedData.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}`;
        //salary upto
        let resultStringUpto;
        if (
          response.data.post.salaryType == "WithinRange" &&
          response.data.post?.salaryUpto
        ) {
          // const numericValueUpto = parseFloat(
          //   response.data.post.salaryUpto.replace("$", "")
          // );
          // const formattedValueUpto = numericValueUpto.toFixed(2);
          // resultStringUpto = `$${formattedValueUpto}`;
          let formattedDataUpto = parseFloat(
            response.data.post.salaryUpto.replace(/[^\d.-]/g, "")
          ).toFixed(2);
          resultStringUpto = `$${formattedDataUpto.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}`;

          resultString = resultString + "-" + resultStringUpto;
        }
        const sanitizedHTML = DOMPurify.sanitize(
          response.data.post.description
        );
        setJobData({
          ...response.data.post,
          updatedSalary: resultString,
          sanitizedHTML: sanitizedHTML,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  }
  if (isLoading) return <FullPageLoader />;
  // if (userInfo && userInfo.accountType == "recruiter") {
  //   return <h1>Please logout and apply with login</h1>;
  // }
  return (
    <>
      <Modals />
      <ShareJobModal
        jobData={jobData}
        setShowtopbar={setShowtopbar}
        location={location}
      />
      <div className="landingOuter">
        <>
          {showTopbar ||
          (userInfo &&
            (userInfo.accountType == "jobseeker" ||
              userInfo.accountType == "recruiter")) ? (
            <Topbar />
          ) : (
            <Navbar />
          )}
          {/* <Navbar /> */}
          <div className="sectionShareJob">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="jobPosted__List">
                    <div className="postHeader">
                      {jobData && jobData.firmId && jobData.firmId.firmLogo && (
                        <div className="postImg">
                          <img src={jobData.firmId.firmLogo} />
                        </div>
                      )}
                      <div className="postText">
                        <h4 className="white">
                          <h2>{jobData.title}</h2>
                          <span className="green">
                            {" "}
                            <img src={LocationIcon} /> {jobData.location}
                          </span>
                        </h4>
                        <div className="jobLoc">
                          <span class="postTime text-start">
                            <img src={locationBlack} />
                            {jobData.location}
                          </span>
                          <span class="postTime text-start">
                            <img src={timerBlack} />
                            {jobData.positionType}
                          </span>
                          <span class="postTime text-start">
                            <img src={calenderBlck} />
                            {/* 10 min. ago */}
                            <TimeAgo timestamp={jobData.createdAt} />
                          </span>
                        </div>
                      </div>

                      <div className="applyBtns">
                        <button
                          className="btn btn-apply"
                          data-bs-toggle="modal"
                          data-bs-target={`#${
                            userInfo && userInfo.accountType
                              ? "confirmModalOneTime"
                              : "applyJobModal"
                          }`}
                        >
                          Apply now
                        </button>
                        <button className="btn btn-save">
                          <svg
                            width="24"
                            height="22"
                            viewBox="0 0 24 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.27855 2.70434C3.54304 1.44029 5.21739 0.669373 6.99994 0.530491C8.78249 0.391609 10.556 0.893892 12.0011 1.94684C13.5276 0.842376 15.4138 0.35274 17.2849 0.575259C19.1559 0.797778 20.8746 1.71616 22.0996 3.14786C23.3245 4.57956 23.9659 6.41975 23.8962 8.30266C23.8265 10.1856 23.0509 11.9733 21.7235 13.3106L14.6523 20.3818C13.9491 21.0849 12.9954 21.4798 12.0011 21.4798C11.0067 21.4798 10.053 21.0849 9.3498 20.3818L2.27855 13.3118C0.872139 11.9053 0.0820312 9.99776 0.0820312 8.00872C0.0820312 6.01968 0.872139 4.11085 2.27855 2.70434Z"
                              fill="white"
                              fill-opacity="0.3"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="postBodyContent">
                      {/* <h4>
                        $2,423,432.00-$234,234,234.00/ <span>Hourly</span>
                      </h4> */}
                      <h4>
                        {jobData.updatedSalary}/{" "}
                        <span>{jobData.timeperiod}</span>
                      </h4>
                      <div
                        className="detailText"
                        dangerouslySetInnerHTML={{
                          __html: jobData.sanitizedHTML,
                        }}
                      />
                      <div className="postFooter">
                        <div
                          className="tags"
                          style={{ textTransform: "capitalize" }}
                        >
                          <span>{jobData.timeperiod}</span>
                          <span>{jobData.jobType}</span>
                          {jobData.location ? (
                            <span>
                              {jobData.location &&
                                jobData.location.split(",")[0]}
                            </span>
                          ) : (
                            ""
                          )}
                          <span>{jobData.positionType}</span>
                        </div>
                      </div>
                    </div>

                    {/* <p>Crumbl Cookies is hiring for a General Manager! Our ideal candidate is someone who strives to provide an excellent customer experience and gourmet cookies. Attention to detail is required, critical time management, quality checks and cleaning as you go are crucial.</p>

                                <hr />
                                <div className="detailText">
                                    <h2>Job Details</h2>
                                    <p>Being coachable and adaptable to constantly changing environment is a must! See if you fit the bill below.</p>
                                    <ul>
                                        <li>Greeting customers, answering questions, making recommendations, accepting orders and payment, and providing exceptional customer service</li>
                                        <li>Keeping records relating to deliveries, inventory, and production levels</li>
                                        <li>Taking customer information and ensuring that deliveries are fulfilled accurately and on time</li>
                                    </ul>
                                </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="newFooter wow fadeInUp">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="newFooter__Content">
                    <p>Hirebird.me © 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}

export default ShareJob;
