import React, { useState } from "react";
import { StarRatingDiv } from "./StarRatingStyles";

export default function StarRating({
    testimonialObj,
    setTestimonialObj,
    readonly = false
}) {

    // const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);
    return (
        <>
            {
                readonly ?
                    <StarRatingDiv>
                        {[...Array(5)].map((Star, i) => {
                            const ratingValue = i + 1;
                            return (
                                <label>
                                    <input
                                        type="radio"
                                        name="rating"
                                        value={ratingValue}
                                    />
                                    <star
                                        size={50}
                                        className={
                                            ratingValue <= (hover || testimonialObj.rating) ? "activeStar" : "star"
                                        }
                                    ></star>
                                </label>
                            );
                        })}
                    </StarRatingDiv> :
                    <StarRatingDiv>
                        {[...Array(5)].map((Star, i) => {
                            const ratingValue = i + 1;
                            return (
                                <label>
                                    <input
                                        type="radio"
                                        name="rating"
                                        value={ratingValue}
                                        onClick={() => setTestimonialObj({ ...testimonialObj, rating: ratingValue })}
                                    />
                                    <star
                                        size={50}
                                        onMouseEnter={() => setHover(ratingValue)}
                                        onMouseLeave={() => setHover(null)}
                                        className={
                                            ratingValue <= (hover || testimonialObj.rating) ? "activeStar" : "star"
                                        }
                                    ></star>
                                </label>
                            );
                        })}
                    </StarRatingDiv>

            }

        </>

    );
}
