import { useEffect, useState } from "react";
import Modals from "./modal";
import { apiGetMethod, apiPostMethod, apiPutMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import toastMessages from "../components/toastMessage";
import { removeModalBackground } from "../utils/helper";

const OnBoardingProcess = () => {
  //Constant
  let location = useLocation();
  let { linkedin, jobId } = queryString.parse(location.search);
  const navigate = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let defaultForm = {
    location: "",
    accountId: userInfo._id,
    locations: {
      state: "",
      city: "",
    },
    qualification: [
      {
        university: "",
        degreeType: "",
        startDate: "",
        isPresent: false,
        endDate: "",
        description: "",
      },
    ],
    workExperience: [
      {
        companyName: "",
        designation: "",
        startDate: "",
        isPresent: false,
        endDate: "",
        description: "",
      },
    ],
    skills: [
      {
        skillSet: "",
        // proficiencyGrade: "",
      },
    ],
  };
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  //useState
  const [form, setForm] = useState(defaultForm);
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [previewImage, setPreviewImage] = useState({
    headshotImage: "",
  });
  const [headshotImage, setHeadshotImage] = useState("");
  const [isHeadshotImageDeleted, setIsHeadshotImageDeleted] = useState(false);
  const [qualificationCount, setQualificationCount] = useState(1);
  const [workExperienceCount, setWorkExperienceCount] = useState(1);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [skillsCount, setSkillsCount] = useState(1);
  const [currEditCompanyIndex, setCurrEditCompanyIndex] = useState(null);
  const [onboardingDetails, setOnboardingDetails] = useState([]);
  //university--------------------------------------------
  const [currEditUniversitiesIndex, setCurrEditUniversitiesIndex] =
    useState(null);
  const [universityList, setUniversityList] = useState([]);
  const [isUniverSelected, setIsUniversitySelected] = useState(false);
  const [page, setPage] = useState("apply");
  // const [source, setSource] = useState(linkedin || null);
  //-------------------------------------------------------
  //function
  useEffect(() => {
    getAccountDetail();
    getUniversityLogo();
  }, []);

  async function getUniversityLogo() {
    try {
      const response = await apiGetMethod(allApiUrl.universities, "", header);
      if (response.status === 200) {
        setUniversityList(response.data.universities);
      }
    } catch (err) {
      console.log("err", err.data.message);
    }
  }
  async function onUniversitySearchHandler(value) {
    try {
      const response = await apiPostMethod(
        allApiUrl.search,
        { text: value },
        header
      );
      if (response.status === 200) {
        setUniversityList(response.data.universities);
      }
    } catch (err) {
      console.log("err", err.data.message);
    }
  }
  async function getAccountDetail() {
    try {
      const response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status === 200) {
        const locationModal = new window.bootstrap.Modal(
          document.getElementById("locationModal")
        );
        locationModal.show();
        // Autofill the linkedin data while onboarding -----------------
        let updatedData = response.data.data.candidateProfileId;
        let qualification = [];
        let workExperience = [];
        let skills = [];
        if (updatedData.qualification.length == 0) {
          qualification = [
            {
              university: "",
              degreeType: "",
              startDate: "",
              endDate: "",
              description: "",
            },
          ];
        } else {
          qualification = updatedData.qualification;
        }
        if (updatedData.workExperience.length == 0) {
          workExperience = [
            {
              companyName: "",
              designation: "",
              startDate: "",
              endDate: "",
              description: "",
            },
          ];
        } else {
          workExperience = updatedData.workExperience;
        }
        if (updatedData.skills.length == 0) {
          skills = [
            {
              skillSet: "",
            },
          ];
        } else {
          skills = updatedData.skills;
        }
        // localStorage.setItem("userInfo", JSON.stringify(response.data.data));
        // localStorage.setItem("accessToken", token);
        // localStorage.setItem("isLoggedIn", true);
        setForm(
          JSON.parse(
            JSON.stringify({
              // {
              _id: response.data.data._id,
              profileImage: response.data.data.profileImage,
              firstName: response.data.data.firstName,
              location: updatedData.location,
              qualification,
              workExperience,
              skills,
              // }
            })
          )
        );
        // props.setShowtopbar(true);
        setPreviewImage({
          ...previewImage,
          headshotImage: response.data.data.profileImage,
        });
        setQualificationCount(
          updatedData.qualification.length === 0
            ? 1
            : updatedData.qualification.length
        );
        setWorkExperienceCount(
          updatedData.workExperience.length == 0
            ? 1
            : updatedData.workExperience.length
        );
        setSkillsCount(
          updatedData.skills.length == 0 ? 1 : updatedData.skills.length
        );
        //--------------------------------------------------------------
        // console.log("107", response.data);
        setOnboardingDetails(response.data);
        // getAllCompanyData();
      }
    } catch (err) {
      //  toastMessage('error',err.data.message)
      console.log("err", err.data.message);
    }
  }
  const handleChange = (event, type) => {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [type]: { ...form[type], [name]: value } }));
  };
  async function onSubmit(e, nextModal, formData, field) {
    e.preventDefault();
    try {
      if (formData) {
        let formData = new FormData();
        formData.append("file", headshotImage);
        formData.append("accountId", userInfo._id);
        let res = await apiPutMethod(allApiUrl.updateprofile, formData, header);
        if (res.status == 200) {
          const uploadImageModal = new window.bootstrap.Modal(
            document.getElementById(nextModal)
          );
          uploadImageModal.show();
          // setForm(defaultForm);
          setIsProfileUpdated(!isProfileUpdated);
        }
      } else {
        let updatedData = {
          accountId: userInfo._id,
          [field]: form[field],
        };
        let res = await apiPutMethod(
          allApiUrl.updateprofile,
          updatedData,
          header
        );
        if (res.status == 200) {
          // if (nextModal !== "confirmModal") {
          // const uploadImageModal = new window.bootstrap.Modal(
          //   document.getElementById(nextModal)
          // );
          // uploadImageModal.show();
          try {
            const response = await apiGetMethod(allApiUrl.profile, "", header);
            if (response.status === 200) {
              // localStorage.setItem(
              //   "userInfo",
              //   JSON.stringify(response.data.data)
              // );
            }
          } catch (err) {
            console.log("err", err.data.message);
          }
          // setForm(defaultForm);
          setIsProfileUpdated(!isProfileUpdated);
        }
      }
    } catch (err) {
      console.log("err", err.message);
    }
  }
  const increaseQualificationCount = (event) => {
    event.preventDefault();
    setQualificationCount((prev) => prev + 1);
    setForm({
      ...form,
      qualification: [
        ...form.qualification,
        {
          university: "",
          degreeType: "",
          startDate: "",
          endDate: "",
          description: "",
        },
      ],
    });
  };
  const decreaseQualificationCount = (index) => {
    setForm({
      ...form,
      qualification: [
        ...form.qualification.slice(0, index),
        ...form.qualification.slice(index + 1),
      ],
    });
  };
  const increaseWorkCount = (event) => {
    event.preventDefault();
    setWorkExperienceCount((prev) => prev + 1);
    setForm({
      ...form,
      workExperience: [
        ...form.workExperience,
        {
          companyName: "",
          designation: "",
          startDate: "",
          endDate: "",
          description: "",
        },
      ],
    });
  };
  const increaseSkillCount = (event) => {
    event.preventDefault();
    setSkillsCount((prev) => prev + 1);
    setForm({
      ...form,
      skills: [
        ...form.skills,
        {
          skillSet: "",
          // proficiencyGrade: "",
        },
      ],
    });
  };
  async function onCompanyHandler(value) {
    try {
      const response = await apiPostMethod(
        allApiUrl.searchFirm,
        { text: value },
        header
      );
      if (response.status === 200) {
        let data = [];
        response.data.data.map((item) => {
          data.push({ firmName: item.firmName, firmId: item._id });
        });
        setCompanyOptions(data);
      }
    } catch (err) {}
  }
  async function getAllCompanyData(value) {
    try {
      const response = await apiPostMethod(
        allApiUrl.searchFirm,
        { text: "" },
        header
      );
      if (response.status === 200) {
        let data = [];
        response.data.data.map((item) => {
          data.push({ firmName: item.firmName, firmId: item._id });
        });
        setCompanyOptions(data);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  const onSuggestedSkillHandler = (e, values) => {
    e.preventDefault();
    setForm((prev) => {
      if (!prev.skills[prev.skills.length - 1].skillSet) {
        prev.skills[prev.skills.length - 1] = {
          skillSet: values,
          // proficiencyGrade: "0",
        };
      } else {
        setSkillsCount(skillsCount + 1);
        setForm({
          ...form,
          skills: [
            ...form.skills,
            {
              skillSet: values,
              // proficiencyGrade: "0",
            },
          ],
        });
      }

      return { ...prev };
    });
  };
  // let location = useLocation();
  const JobApplyHandler = async () => {
    let data = {
      jobId: jobId,
    };
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      let response = await apiPostMethod(allApiUrl.applyJob, data, header);
      if (response.status == 200) {
        setPage("success");
        setForm({
          firstName: "",
          location: "",
          qualification: [],
          workExperience: [],
          skills: [],
        });
      }
    } catch (err) {
      removeModalBackground();
      navigate(localStorage.getItem("sharePageUrl"));
      toastMessages("error", err.data.message);
      console.log("err", err.data.message);
    }
  };
  //Props To Be Passed
  let props = {
    form,
    setForm,
    handleChange,
    onSubmit,
    setIsHeadshotImageDeleted,
    setHeadshotImage,
    setPreviewImage,
    isHeadshotImageDeleted,
    headshotImage,
    previewImage,
    setQualificationCount,
    qualificationCount,
    increaseQualificationCount,
    setWorkExperienceCount,
    workExperienceCount,
    increaseWorkCount,
    onCompanyHandler,
    companyOptions,
    setIsCompanySelected,
    isCompanySelected,
    setSkillsCount,
    skillsCount,
    onSuggestedSkillHandler,
    increaseSkillCount,
    currEditCompanyIndex,
    setCurrEditCompanyIndex,
    // decreaseQualificationCount,
    onboardingDetails,
    onUniversitySearchHandler,
    universityList,
    isUniverSelected,
    setIsUniversitySelected,
    currEditUniversitiesIndex,
    setCurrEditUniversitiesIndex,
    linkedin,
    JobApplyHandler,
    setPage,
    page,
  };
  return (
    <>
      <Modals {...props} />
    </>
  );
};
export default OnBoardingProcess;
