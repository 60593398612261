import Sidebar from "../components/sidebar";
import Topbar from "../components/topBar";
import Footer from "../components/footer";

function StepFormProgress(prop) {
  return (
    <>
      <div className="stepProgressBar">
        <div
          class="progress"
          role="progressbar"
          aria-label="Example 1px high"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div class="progress-bar" style={{ width: prop.value }}></div>
        </div>

        <p>
          <span>Step 1</span>
          <span>Step 2</span>
          <span>Step 3</span>
          <span>Step 4</span>
        </p>
      </div>
    </>
  );
}

export default StepFormProgress;
