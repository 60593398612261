import { BrowserRouter, Link } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "../components/navbar";
import facebook from "../assets/imgs/facebook.svg";
import instagram from "../assets/imgs/instagram.svg";
import twitter from "../assets/imgs/twitter.svg";
import linkedIn from "../assets/imgs/linkedIn.svg";
import Topbar from "../components/topBar";

// Register ScrollTrigger with GSAP

function Eror() {
  return (
    <>
    <Topbar />
    <div className="errorPage">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="errorPage__Content">
                        <h3>Whoops.. that wasn't supposed to happen!</h3>
                        <h1>404</h1>
                        <p>Please report any bugs to admin@hirebird.me 🐣</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default Eror;
