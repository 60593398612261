import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Modals from "./modal";
import Topbar from "../components/topBar";
import Sidebar from "../components/sidebar";
import QrCode from "../assets/imgs/qrCode.png";
import shareIcon from "../assets/imgs/shareIcon.svg";
import character from "../assets/imgs/character.svg";
import xIcon from "../assets/imgs/xIcon.svg";
import instagramIcon from "../assets/imgs/instagram.svg";
import snapchatIcon from "../assets/imgs/snapchat.svg";
import messageIcon from "../assets/imgs/messagesIcon.svg";
import whatsAppicon from "../assets/imgs/whatsAppicon.svg";
import QRCode from "qrcode.react";
import { apiGetMethod } from "../api/rest";
import { allApiUrl } from "../api/apiRoutes";
import FullPageLoader from "../components/fullPageLoader";
import { ClipboardCopy } from "../components/CopyClipboard";
import toastMessages from "../components/toastMessage";

const ShareButtons = ({ url, data }) => {
  //   {First} sent you an invite to join them on Hirebird!
  // Like a dating app, but instead you'll find the perfect Job or the perfect candidate depending on what you seek.
  // Sign up below by {1 week from date} using the button/link and get early access to some of Hirebird's unique features.
  const shareUrl = url;
  // let sevenDays =''
  // const currentDate = new Date();
  // const sevenDaysLater = new Date(currentDate);
  // sevenDaysLater.setDate(currentDate.getDate() + 7);
  // const formattedDate = sevenDaysLater.toISOString().split("T")[0];
  const title = ` Testing`;
  let tweetText = ` ${
    (data.firstName ? data.firstName : "") +
    " " +
    (data.lastName ? data.lastName : "")
  } sent you an invite to join them on Hirebird!.🐣\n\nLike a dating app, but for jobs.\n\nSign up using the link below.\n\n${url}`;

  const shareOnTwitter = () => {
    // Replace [TEXT] with your actual tweet text
    // const tweetText = `Check out ${title} at ${shareUrl}`;

    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`
    );
  };

  // const shareOnInstagram = () => {
  //   // Replace [URL] and [CAPTION] with your actual URL and caption
  //   window.open(
  //     `https://www.instagram.com/?url=${encodeURIComponent(
  //       shareUrl
  //     )}&caption=${encodeURIComponent(title)}`
  //   );
  // };

  // const shareOnInstagram = () => {
  //   const caption = `Check out ${title} at ${shareUrl}`;
  //   const clipboardText = `instagram://app?text=${encodeURIComponent(caption)}`;
  //   navigator.clipboard.writeText(caption);

  //   // Prompt the user to open Instagram
  //   // alert("Caption copied to clipboard. Open Instagram and paste to share.");
  // };

  const shareOnSnapchat = () => {
    // Replace [URL] with your actual URL
    window.open(`snapchat://snap?url=${encodeURIComponent(shareUrl)}`);
  };
  const shareOnSnapchat2 = () => {
    const snapchatUrl = `snapchat://snap?url=${encodeURIComponent(shareUrl)}`;

    // Open the Snapchat app if available
    window.location.href = snapchatUrl;

    // If the Snapchat app is not installed, you can provide a fallback or alert the user
    // For example, you can open the Snapchat website with the URL pre-filled
    // window.open(`https://www.snapchat.com/add/YOUR_SNAPCHAT_USERNAME`);
  };
  const shareOnMessage = () => {
    // Replace [BODY] with your actual message body
    window.open(`sms:?body=${encodeURIComponent(`${tweetText}`)}`);
  };

  const shareOnWhatsApp = () => {
    // Replace [TEXT] with your actual message text
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(`${tweetText}`)}`
    );
  };
  const shareOnTelegram = () => {
    // Replace [TEXT] with your actual message text
    window.open(
      `https://t.me/share/url?url=${encodeURIComponent(
        url
      )}&text=${encodeURIComponent(tweetText)}`
    );
  };
  const shareOnFacebook = () => {
    // Replace [URL] with your actual URL
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;

    // Open the Facebook Share Dialog
    window.open(facebookUrl, "_blank");
  };
  return (
    <div>
      <button onClick={shareOnTwitter}>
        <img src={xIcon} alt="X" />
      </button>
      {/* <button onClick={shareOnInstagram}>
        <img src={instagramIcon} alt="Instagram" />
      </button> */}
      {/* <button onClick={shareOnSnapchat}>
        <img src={snapchatIcon} alt="Snapchat" />
      </button>
      <button onClick={shareOnSnapchat2}>
        <img src={snapchatIcon} alt="Snapchat" />
      </button> */}
      {/* <button onClick={shareOnTelegram}>Telegram</button> */}
      {/* <button onClick={shareOnFacebook}>Facebook</button> */}
      <button onClick={shareOnMessage}>
        <img src={messageIcon} alt="Message" />
      </button>
      <button onClick={shareOnWhatsApp}>
        <img src={whatsAppicon} alt="WhatsApp" />
      </button>
    </div>
  );
};

function AffilateUrl() {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [initialLoading, setinitilLoading] = useState(true);
  // const [accountsDetails, setAccountsDetails] = useState({});
  // const [accountType,setAccountType] = useState('')
  let url;
  if (process.env.REACT_APP_ENVIRONMENT == "development") {
    url = process.env.REACT_APP_WEBSITE;
  } else if (process.env.REACT_APP_ENVIRONMENT == "staging") {
    url = process.env.REACT_APP_WEBSITE;
  } else {
    url = process.env.REACT_APP_WEBSITE;
  }
  const [data, setData] = useState({});
  useEffect(() => {
    getAccountDetail();
  }, []);
  const header = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  async function getAccountDetail() {
    try {
      const response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status === 200) {
        setData(response.data);
        setinitilLoading(false);
      }
    } catch (err) {
      //()  toastMessage('error',err.data.message)
      console.log("err", err.data.message);
    }
  }
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (initialLoading) return <FullPageLoader />;
  const shareUrl = "http://github.com";
  const title = "GitHub";

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    let copyText = `${url}/public-qr?referral=${
      data && data.data && data.data._id
    }`;
    copyTextToClipboard(copyText)
      .then(() => {
        toastMessages("success", "Link copied successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Sidebar
        activePage="affilate"
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Topbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="mainWrapper">
        <section className="affilate sectionPadding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12 col-xl-12">
                <div className="commonHeading">
                  <h2>Invite your friends!</h2>
                  <hr />
                </div>
                {/* <div className="sectionSubheading">
                  <h3 className="white">Referral URL</h3>
                  <p>
                    Share your referral URL with your friends or social media
                    audience! Earn entries to our scholarship giveaway or free
                    premium features.{" "}
                  </p>
                </div> */}
                <div className="affilate__Content commonRadius8 mb-4">
                  <h4>Scan QR code</h4>
                  <p>
                    Share this invite link with your friends. With every sign-up
                    via your link, you get closer to <br /> a chance of earning
                    a <span>Fall 2024 full scholarship!</span>
                  </p>

                  <div className="qrCode__Outer">
                    <div className="qrCode">
                      <QRCode
                        value={`${url}/create-account?referral=${
                          data && data.data && data.data._id
                        }`}
                        // style={{ height: 200, width: 200 }}
                      />
                      {/* <img src={QrCode} /> */}
                    </div>
                    <div className="entryCounter__Content">
                      <svg>
                        <circle cx="90" cy="90" r="90"></circle>
                        <circle cx="90" cy="90" r="90"></circle>
                      </svg>
                      <div class="number">
                        <h2>
                          {data.data && data.referralpoints
                            ? data.referralpoints
                            : 0}{" "}
                          <br />
                          <span>Entries</span>
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="form-section">
                    <div className="form-group">
                      <ClipboardCopy
                        copyText={`${url}/create-account?referral=${
                          data && data.data && data.data._id
                        }`}
                      />
                    </div>
                  </div>
                  {/* <button
                    className="btn shareBtn"
                    onClick={() => {
                      handleCopyClick();
                      // navigate(
                      //   `/public-qr?referral=${
                      //     data && data.data && data.data._id
                      //   }`
                      // );
                    }}
                  >
                    <img src={shareIcon} />
                    Share QR code
                  </button> */}
                  <div className="shareBtns">
                    <ShareButtons
                      data={data.data}
                      url={`${url}/create-account?referral=${
                        data && data.data && data.data._id
                      }`}
                    />
                    {/* <button>
                      <img src={xIcon} />
                    </button>
                    <button>
                      <img src={instagramIcon} />
                    </button>
                    <button>
                      <img src={snapchatIcon} />
                    </button>
                    <button>
                      <img src={messageIcon} />
                    </button>
                    <button>
                      <img src={whatsAppicon} />
                    </button> */}
                  </div>

                  {/* <div className="divider"></div>
                  {data &&
                  data.data &&
                  data.data.accountType &&
                  data.data.accountType == "jobseeker" ? (
                    <h4 className="white">
                      Share this invite link with your friends. With every
                      sign-up via your link, you get closer to a chance of
                      earning a <span>full scholarship!</span>
                    </h4>
                  ) : (
                    <h4>
                      Spread the word! Hirebird is just around the corner.{" "}
                      <span>
                        Invite your friends, or team members to join your
                        organization !
                      </span>
                    </h4>
                  )} */}

                  {/* <div className="row">
                    <div className="col-xxl-5 col-lg-5">
                      <img src={character} />
                    </div>
                    <div className="col-xxl-6 col-lg-6">
                      <div className="form-section">
                        <div className="form-group">
                          <ClipboardCopy
                            copyText={`${url}/create-account?referral=${
                              data && data.data && data.data._id
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/* {data &&
                data.data &&
                data.data.accountType &&
                data.data.accountType == "jobseeker" ? (
                  <div className="affilate__Content commonRadius8">
                    <div className="entryCounter">
                      <h3 className="white">Entry Counter</h3>

                      <div className="row">
                        <div className="col-xxl-5">
                          <div className="entryCounter__Content">
                            <svg>
                              <circle cx="100" cy="100" r="90"></circle>
                              <circle cx="100" cy="100" r="90"></circle>
                            </svg>
                            <div class="number">
                              <h2>
                                {data.data && data.referralpoints
                                  ? data.referralpoints
                                  : 0}{" "}
                                <br />
                                <span>Entries</span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        
                        <div className="col-xxl-7">
                          <div className="entryCounter__Content">
                            <ul>
                              <li>
                                Jan 1st, 2024 - April 1st, 2024 each invite = 3
                                entries
                              </li>
                              <li>
                                After April 1st, 2024, each invite goes back to
                                1 entry.{" "}
                              </li>
                              <li>Winner selected September 1st, 2024.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
              </div>{" "}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AffilateUrl;
