import { readFile } from './cropImage';
import { useImageCropContext } from './ImageCropProvider';
import Button from './editorButton';
import Cropper from './cropper';
import { RotationSlider, ZoomSlider } from './Slider';
import uploadIcon from '../../assets/imgs/uploadIcon.svg';
const ImageCropModalContent = ({ handleDone, handleClose, onBoardingRecruiter, handleDoneOnboardingRecruiter, page }) => {
    const { setImage, image } = useImageCropContext();

    const handleFileChange = async ({ target: { files } }) => {
        const file = files && files[0];
        const imageDataUrl = await readFile(file);
        setImage(imageDataUrl);
    };
    return (
        <div className="text-center relative">
            <div className="">
                <div className='hh'>
                    {
                        image &&
                        <div className="row">
                            <div className="col-lg-12">
                                <div className='cropperArea'>
                                    {/* <h6>Edit profile picture</h6> */}
                                    <Cropper className="jjkkll" />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className='cropperBtns'>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <ZoomSlider />
                                        </div>
                                        <div className='col-lg-6'>
                                            <RotationSlider />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='uploaderFileSection'>
                    <p>
                        <img src={uploadIcon} /> Upload Picture
                        <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            className="hidden"
                            id="avatarInput"
                            accept="image/*"
                        />
                    </p>

                    {/* <Button variant="light" className="">
                        <label htmlFor="avatarInput">Upload Another Picture</label>
                    </Button> */}
                </div>
                {
                    onBoardingRecruiter ?
                        <div className="formBtns">
                            <Button
                                className="btn cancelBtn"
                                data-bs-target={`#${page}`}
                                data-bs-toggle="modal"
                                variant="secondary"
                                onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                data-bs-target={`#${page}`}
                                data-bs-toggle="modal"
                                variant="primary"
                                className="btn inviteBtn"
                                onClick={handleDoneOnboardingRecruiter}>
                                Done & Save
                            </Button>
                        </div> :
                        <div className="formBtns">
                            <Button
                                className="btn cancelBtn"
                                data-bs-dismiss='modal'
                                variant="secondary"
                                onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                data-bs-dismiss='modal'
                                variant="primary"
                                className="btn inviteBtn"
                                onClick={handleDone}>
                                Done & Save
                            </Button>
                        </div>
                }
            </div>
        </div>
    );
};

export default ImageCropModalContent;