import { Link } from "react-router-dom";
import Logo from "../assets/imgs/HireBIRD.png";
import facebook from "../assets/imgs/facebook.svg";
import instagram from "../assets/imgs/instagram.svg";
import twitter from "../assets/imgs/twitter.svg";
import linkedIn from "../assets/imgs/linkedIn.svg";

function Footer() {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return (
    <>
      {userInfo &&
        userInfo.accountType &&
        userInfo.accountType !== "jobseeker" && (
          <footer class="innerFooter">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-3">
                  <div class="innerFooter__Content">
                    <img className="logoImg" src={Logo} alt="hireBird Logo" />
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="innerFooter__Content">
                    <ul>
                      <li>
                        <Link to="/create-job">Create Job</Link>
                      </li>

                      <li className="disabled">
                        <Link to="">Applicants</Link>
                      </li>

                      <li className="disabled">
                        <Link to="">Messages</Link>
                      </li>

                      <li className="disabled">
                        <Link to="">Pricing Plan</Link>
                      </li>

                      <li className="disabled">
                        <Link to="">Search</Link>
                      </li>

                      <li>
                        <Link to="/profile">Settings</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-xl-3">
                  <div class="innerFooter__Content">
                    <ul class="socialIcons">
                      {/* <li>
                        <a href="#" target="_blank">
                          <img src={facebook} alt="facebook" />
                        </a>
                      </li> */}

                      <li>
                        <a
                          href="https://www.instagram.com/hirebird.app/"
                          target="_blank"
                        >
                          <img src={instagram} alt="instagram" />
                        </a>
                      </li>

                      {/* <li>
                        <a href="" target="_blank">
                          <img src={twitter} alt="twitter" />
                        </a>
                      </li> */}

                      <li>
                        <a
                          href="https://www.linkedin.com/company/hirebird-me"
                          target="_blank"
                        >
                          <img src={linkedIn} alt="linkedIn" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )}
      <div class="bottonFooter">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <p>Hirebird.me © 2023</p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Footer;
