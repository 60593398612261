import React, { useEffect, useState } from "react";

const TimeAgo = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const calculateTimeAgo = () => {
      const currentTime = new Date();
      const previousTime = new Date(timestamp);
      const timeDifference = currentTime - previousTime;

      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30);

      if (seconds < 60) {
        setTimeAgo(`${seconds} second${seconds === 1 ? "" : "s"} ago`);
      } else if (minutes === 1) {
        setTimeAgo("1 minute ago");
      } else if (minutes < 60) {
        setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
      } else if (hours === 1) {
        setTimeAgo("1 hour ago");
      } else if (hours < 24) {
        setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
      } else if (days === 1) {
        setTimeAgo("1 day ago");
      } else if (days < 7) {
        setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
      } else if (weeks === 1) {
        setTimeAgo("1 week ago");
      } else if (weeks < 4) {
        setTimeAgo(`${weeks} week${weeks === 1 ? "" : "s"} ago`);
      } else if (months === 1) {
        setTimeAgo("1 month ago");
      } else {
        setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
      }
    };

    calculateTimeAgo();

    // You can also set up a timer to update the time every minute or so if needed
    // const timer = setInterval(calculateTimeAgo, 60000);

    return () => {
      // Clean up the interval if needed
      // clearInterval(timer);
    };
  }, [timestamp]);

  return <span className="postTime">{timeAgo}</span>;
};

export default TimeAgo;
