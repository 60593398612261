import { BrowserRouter, Link } from "react-router-dom";
// import React, { useEffect, useState, useRef } from 'react';
import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import Marquee from "react-fast-marquee";
import Navbar from "../components/navbar";
import mousewheel from "../assets/imgs/mousewheel.gif";
import laptopImg from "../assets/imgs/employerLaptopImg.png";
import LongscrollImg from "../assets/imgs/LongscrollImg.png";
import hashIcon from "../assets/imgs/hashIcon.svg";
import userIcon from "../assets/imgs/userIcon.svg";
import gameIcon from "../assets/imgs/gameIcon.svg";
import checkIcon from "../assets/imgs/checkIcon.svg";
import tickGreendark from "../assets/imgs/tickGreendark.svg";
// import teslaLogo from "../assets/imgs/tesla.svg";
// import figmaLogo from "../assets/imgs/figma.svg";
// import stripeLogo from "../assets/imgs/stripe.svg";
// import bookingLogo from "../assets/imgs/booking.svg";
// import airBnbLogo from "../assets/imgs/airBnb.svg";
// import spotifyLogo from "../assets/imgs/stripe.svg";
import candidateMessages from "../assets/imgs/candidateMessages.png";
import candidateProfileImg from "../assets/imgs/candidateProfileImg.png";
import dashboardImg from "../assets/imgs/dashboardImg.png";
import candidateSearchJobImg from "../assets/imgs/candidateSearchJobImg.png";
import candidateSettingImg from "../assets/imgs/candidateSettingImg.png";
import candidiateSearchDetailImg from "../assets/imgs/candidiateSearchDetailImg.png";
import employerProfileImg from "../assets/imgs/employerProfileImg.png";
import pcIcon from "../assets/imgs/pcIcon.svg";
import profileCircleIcon from "../assets/imgs/profileCircleIcon.svg";
import codeIcon from "../assets/imgs/codeIcon.svg";
import documentIcon from "../assets/imgs/documentIcon.svg";
import forYouImg from "../assets/imgs/forYouImg.png";
import facebook from "../assets/imgs/facebook.svg";
import instagram from "../assets/imgs/instagram.svg";
import twitter from "../assets/imgs/twitter.svg";
import linkedIn from "../assets/imgs/linkedIn.svg";
import applicantsImg from "../assets/imgs/applicantsImg.png";
import applicandMessaging from "../assets/imgs/applicandMessaging.png";
import employerSetting from "../assets/imgs/employerSetting.png";
import employerAnalytics from "../assets/imgs/employerAnalytics.png";
import applicantDashboard from "../assets/imgs/applicantDashboard.png";
import hirebirdmatch from "../assets/imgs/hirebirdmatch.png";
import WelcomeLanding from "./welcome-landing";
import alysio from "../assets/imgs/alysio.png";
import stateFarmLogo from "../assets/imgs/state-farm-logo.svg";
import eqLogo from "../assets/imgs/EQ-logo.svg";
import anovaLogo from "../assets/imgs/anovaLogo.png";
import lumioLogo from "../assets/imgs/lumioLogo.png";
import panLogo from "../assets/imgs/panLogo.png";
import crumblLogo from "../assets/imgs/crumbl.png";

// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function EmployerLanding() {
  const [initalLoading, setInitalLoading] = useState(false);
  useEffect(() => {
    // Animation using GSAP
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".laptop__Img",
        start: "top center", // Trigger animation when the top of the card is at the center of the viewport
        end: "center center", // Trigger animation when the bottom of the card is at the center of the viewport
        scrub: 0.6, // Smoothly scrub through the animation as you scroll
      },
    });

    // Define your animation
    tl.to(".laptop__Img", {
      opacity: 1,
      y: 0,
      rotationX: 0,
      duration: 0.1,
      ease: "power2.inOut", // Adjust the easing function as needed
    });
  });
  const handleClick = () => {
    gsap.to(window, {
      scrollTo: { y: "#noJobSec", offsetY: 70 },
      duration: 0.8,
      ease: "power2.inOut",
    });
  };
  useEffect(() => {
    document.title = "For Employers/Recruiters";
    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.content =
        "AI-Powered Candidate Sourcing & ATS | Find the best new grads and entry-level talent without sifting and sorting through hundreds of resumes.";
    }
    setTimeout(() => {
      setInitalLoading(true);
    }, 2700);
  }, []);
  return (
    <>
    <div className="landingOuter">
      {/* {!initalLoading ? (
        <WelcomeLanding />
      ) : ( */}
      <>
        <Navbar />

        {/* hero section */}
        <section className="heroSection">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xxl-12">
                <div className="heroSection__Content">
                  <div className="text-center">
                    <span className="capsule wow zoomIn">Coming Q1 2024.</span>
                  </div>
                  <h1 className="wow fadeInDown">
                    {/* Candidate sourcing and <br /> streamlined applicant <br />{" "}
            tracking - all in one. */}
                    Similar to a dating app. <br /> Except you find the perfect{" "}
                    <br />
                    candidate. Like, fast.
                  </h1>
                  <p className="wow fadeInDown mb-0">
                    {/* Join Our Community <br /> of 14,600+ Users */}
                    Create your profile and start matching with candidates{" "}
                    <br /> today — Fill an empty position in under 3 weeks!
                  </p>
                  <small>*Results may vary</small>

                  <div className="text-center mt-5">
                    <button onClick={handleClick}>
                      <img className="mouseImg wow fadeIn" src={mousewheel} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* no job offer section */}

        <section className="noJobSec commonBg-animation" id="noJobSec">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="noJobSec__Content wow fadeInUp">
                  <h2 className="white">Tired of candidate sourcing?</h2>
                  <ul className="sourcingList">
                    <li>
                      <img src={tickGreendark} /> Low quality candidates?
                    </li>
                    <li>
                      <img src={tickGreendark} /> Left on read again?
                    </li>
                    <li>
                      <img src={tickGreendark} /> Not enough connections on
                      LinkedIn?
                    </li>
                  </ul>
                  <p>
                    Due to the expansive and competitive nature of the hiring
                    landscape, it's nearly impossible to find the perfect
                  </p>
                  <p>
                    prospect. Lucky for you. we deliver them on a silver
                    platter. right to your dashboard.
                  </p>
                  <Link
                    to="/create-account"
                    className="commonRadius10 wow zoomIn"
                  >
                    Try a 30 Day Trial!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* laptop section */}
        <section className="laptop commonBg-animation wow fadeInUp">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="laptop__Img">
                  <img src={LongscrollImg} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* features starts */}
        <section className="features commonBg-animation">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="sectionHeading wow fadeInUp">
                  <h4 className="green">Features</h4>
                  <h2 className="white">
                    Fueling Innovation, Igniting Creativity
                  </h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="features__Content commonRadius8 wow fadeInLeft">
                  <img src={hashIcon} alt="" />
                  <h5>AI Compatibility Matching</h5>
                  <p>
                    No more keyword matching, we trained an AI model that
                    provides a true candidate/employer match for you based off
                    your profile and thousands of previous queries.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="features__Content commonRadius8 wow fadeInLeft">
                  <img src={userIcon} alt="" />
                  {/* <h5>Hirebird JobReady©</h5> */}
                  <h5>Fast & Efficient</h5>
                  {/* <p>
            What schools should have taught you. Real world modules for
            candidate readiness that expand your skills and increase
            "hireability"
          </p> */}
                  <p>
                    We cut out the fluff in the hiring process, no small talk
                    messaging, no more ghosting. Chat with real employers. Land
                    your dream job in under 3 weeks, Legit.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="features__Content commonRadius8 wow fadeInRight">
                  <img src={gameIcon} alt="" />
                  <h5>Hirebird Match© </h5>
                  <p>
                    Picture your favorite dating app (we've all used one)
                    married with a job search platform. Swipe on positions and
                    employers you're interested in.
                  </p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6">
                <div className="features__Content commonRadius8 wow fadeInRight">
                  <img src={checkIcon} alt="" />
                  <h5>Application Transparency</h5>
                  <p>
                    Don't throw your resume into a black hole. Track your
                    resume/application and see real-time updates to your
                    application.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="features__Marque">
                  <h4 className="green wow fadeInUp">
                    These guys also got sick of their hiring platform.
                  </h4>
                  <Marquee>
                    <div className="marqueeImgs">
                      <span className="wow zoomIn">
                        <img src={alysio} />
                      </span>

                      <span className="wow zoomIn">
                        <img src={stateFarmLogo} />
                      </span>

                      <span className="wow zoomIn">
                        <img src={eqLogo} />
                      </span>

                      <span className="wow zoomIn">
                        <img src={anovaLogo} />
                      </span>

                      <span className="wow zoomIn">
                        <img src={lumioLogo} />
                      </span>

                      <span className="wow zoomIn">
                        <img src={panLogo} />
                      </span>

                      <span className="wow zoomIn">
                        <img src={crumblLogo} />
                      </span>
                    </div>
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="messaging commonBg-animation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="messaging__Content">
                  <div className="messageCarousel">
                    <div
                      id="carouselExample"
                      className="carousel slide carousel-fade"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <h3 className="green">Hirebird Match</h3>
                          <img
                            src={hirebirdmatch}
                            className="d-block"
                            alt="Hirebird Match"
                          />
                        </div>
                        <div className="carousel-item">
                          <h3 className="green">Applicant Dashboard</h3>
                          <img
                            src={applicantsImg}
                            className="d-block"
                            alt="Applicant Dashboard"
                          />
                        </div>
                        <div className="carousel-item">
                          <h3 className="green">Candidate Messaging</h3>
                          <img
                            src={applicandMessaging}
                            className="d-block"
                            alt="Applicant Messaging"
                          />
                        </div>
                        <div className="carousel-item">
                          <h3 className="green">Employer Settings</h3>
                          <img
                            src={employerSetting}
                            className="d-block"
                            alt="Employer Settings"
                          />
                        </div>

                        <div className="carousel-item">
                          <h3 className="green">Employer Analytics</h3>
                          <img
                            src={employerAnalytics}
                            className="d-block"
                            alt="Employer Analytics"
                          />
                        </div>

                        <div className="carousel-item">
                          <h3 className="green">Employer Settings</h3>
                          <img
                            src={applicantDashboard}
                            className="d-block"
                            alt="Employer Settings"
                          />
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>

                  <ul>
                    <li>Employer View</li>
                    <li>Free Feature</li>
                    <li>Choice+ Features</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* compatibility section starts */}
        <section className="compatibility commonBg-animation">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="compatibility__Content wow fadeInUp">
                  <p>You're still here? Haven't you seen enough already? </p>
                  <Link
                    to="/create-account"
                    className="commonRadius10 wow zoomIn"
                    data-wow-duration="2s"
                  >
                    Create an account!
                  </Link>

                  <div className="capBottom wow zoomIn">
                    <span className="outer">
                      <span className="inner">
                        <img src={pcIcon} />
                      </span>
                    </span>

                    <div>
                      <p>
                        Multiplatform Capability:{" "}
                        <span>
                          Google <br /> Play/iOS coming late 2024
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* choose section starts */}
        <section className="chooseSection commonBg-animation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="sectionHeading wow fadeInUp">
                  <h2 className="white">Choose the way you Hirebird!</h2>
                  <p className="white">
                    Take a look at some features that you can expect on our
                    platform that will help with your job search process.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="chooseSection__Content commonRadius8 wow fadeInUp">
                  <img
                    className="icons wow zoomIn"
                    data-wow-duration="2s"
                    src={profileCircleIcon}
                  />
                  <h4 className="white wow fadeInUp">
                    Personalize Your Profile
                  </h4>
                  <p className="wow fadeInUp">
                    Customize your candidate profile. Show off all your
                    accolades, achievements and who you are.
                  </p>

                  <img
                    className="bottomImg"
                    src={employerProfileImg}
                    alt="Employer Profile"
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="chooseSection__Content commonRadius8 wow fadeInUp">
                  <img className="icons wow zoomIn" src={codeIcon} />
                  <h4 className="white wow fadeInUp">
                    Candidates Catered to You
                  </h4>
                  <p className="wow fadeInUp">
                    Due to our AI functionality,you receive candidates that are
                    catered to your position specifically with insights on why
                    they are a great fit.
                  </p>
                  <img
                    className="bottomImg"
                    src={dashboardImg}
                    alt="Employer Dashboard"
                  />
                </div>
              </div>

              <div className="col-lg-4">
                <div className="chooseSection__Content commonRadius8 wow fadeInUp">
                  <img className="icons wow zoomIn" src={documentIcon} />
                  <h4 className="white wow fadeInUp">Clear Communication</h4>
                  <p className="wow fadeInUp">
                    Our invitation/application tracker allows for transparency
                    in the hiring process. Employers can save time and add
                    customization with AI messaging features.
                  </p>
                  <img
                    className="bottomImg"
                    src={candidateMessages}
                    alt="Candidate Messages"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* waitings section starts */}
        <section className="waiting wow fadeInUp">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="waiting__Content">
                  <h3 className="white">So what are you waiting for?</h3>
                  <p className="white">
                    Hirebird is an AI-powered job search platform that connects
                    students, recent grads <br /> and entry level workers to
                    compatible employers and positions.
                  </p>
                  <ul>
                    <li className="wow zoomIn">
                      Know when we <br /> launch
                    </li>
                    <li className="wow zoomIn">
                      Zero Spam <br /> guarantee
                    </li>
                    <li className="wow zoomIn">Cancel anytime</li>
                  </ul>

                  <a>Join our waitlist!</a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="newFooter wow fadeInUp">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-4">
                <div className="newFooter__Content">
                  <ul>
                    <li>
                      <Link
                        to="/home"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Candidates
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/employer-home"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        Employer
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms-of-service">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy</Link>
                    </li>
                    <li>
                      <Link to="/contact-us"> Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="newFooter__Content">
                  <p>Hirebird.me © 2023</p>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="newFooter__Content">
                  <ul className="socialIcon">
                    {/* <li className="wow zoomIn">
                      <Link to="" target="_blank">
                        <img src={facebook} />
                      </Link>
                    </li> */}

                    <li className="wow zoomIn">
                      <Link to="https://www.instagram.com/hirebird.app" target="_blank">
                        <img src={instagram} />
                      </Link>
                    </li>
                    {/* 
                    <li className="wow zoomIn">
                      <Link to="" target="_blank">
                        <img src={twitter} />
                      </Link>
                    </li> */}

                    <li className="wow zoomIn">
                      <Link to="https://www.linkedin.com/company/hirebird-me" target="_blank">
                        <img src={linkedIn} />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* )} */}
      </div>
    </>
  );
}

export default EmployerLanding;
