import { useState, useEffect } from "react";
import postingpopup from "../../assets/imgs/postingpopup.svg";
import editIcon from "../../assets/imgs/editIcon.svg";
import iconDelete from "../../assets/imgs/iconDelete.svg";
import iconModalDelete from "../../assets/imgs/iconModalDelete.svg";
import jobImg from "../../assets/imgs/jobPositionImg.svg";
import LocationIcon from "../../assets/imgs/locationIconGreen.svg";
import questionMark from "../../assets/imgs/questionMark.svg";
import attachmentIcon from "../../assets/imgs/attachmentIcon.svg";
import codeRectangleIcon from "../../assets/imgs/codeRectangleIcon.svg";
import envelopeRectangleIcon from "../../assets/imgs/envelopeRectangleIcon.svg";
import rocketIconSmall from "../../assets/imgs/rocketIconSmall.svg";
import linkedInBtn from "../../assets/imgs/linkedInBtn.svg";
import googleIcon from "../../assets/imgs/googleIcon.svg";
import uploadIcon from "../../assets/imgs/uploadIcon.svg";
import starIcon from "../../assets/imgs/starRating.svg";
// import { areAllValuesFilled } from "../../utils/helper";
import whiteCross from "../../assets/imgs/whiteCross.svg";
import userIcon from "../../assets/imgs/user.svg";
import toastMessages from "../toastMessage";
import Rating from "react-rating";
import StarRating from "../starRating/startRating";
import dummyImg from "../../assets/imgs/dummyImg.jpg";
import ImageUpload from "../customImageEditor";
import StepFormProgress from "../../view/stepForm-progress";
import Logo from "../../assets/imgs/logo.svg";
import TimeAgo from "../daysAgoComponent";
import { type } from "@testing-library/user-event/dist/type";
function DashboardModal(props) {
  let {
    onJobDeleteHandler,
    setDeleteJobId,
    isLoading,
    jobData,
    step,
    setStep,
  } = props;
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    let shareUrl = `${process.env.REACT_APP_WEBSITE}${
      jobData && jobData.shareUrl
    }`;
    copyTextToClipboard(shareUrl)
      .then(() => {
        toastMessages("success", "Link copied successfully", "Status");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="mainWrapper">
        {/* Delete Job Modal Starts */}
        <div
          class="modal fade commonModal addEducationModal"
          id="deleteTestimonialJob"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setDeleteJobId("")}
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Delete Job</h3>
                  <img className="deleteJobImg" src={iconModalDelete} />
                  <p>
                    Are you sure you want to delete your <br /> Job ?
                  </p>

                  <div className="formBtns">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setDeleteJobId("")}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={onJobDeleteHandler}
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Job Modal Ends */}
        {/* Share Job Modal Started  */}
        {!isLoading && (
          <div
            class="modal fade commonModal editEducationModal candidateOnboarding"
            id="shareJobModal"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabindex="-1"
            data-bs-backdrop="static"
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header d-block pt-0 px-0"></div>
                <div class="modal-body pt-0 px-0">
                  <div className="jobProgress">
                    <StepFormProgress
                      value={`${step && step.toString()}%`}
                      className="mt-0"
                    />
                  </div>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  {step == 33.33 ? (
                    <div className="commonModalContent px-4">
                      <h3 className="ModalHeading">
                        Confirm the job you'd like to share!
                      </h3>
                      {/* {steps == "33.33" ? <ConfirmContent /> : ""} */}
                      <div className="jobPosted__List text-start">
                        <div className="postHeader">
                          {jobData &&
                            jobData.firmId &&
                            jobData.firmId.firmLogo && (
                              <div className="postImg">
                                <img src={jobData.firmId.firmLogo} />
                              </div>
                            )}
                          <div className="postText">
                            <h4 className="white">
                              {jobData.title}{" "}
                              <span className="green">
                                {" "}
                                <img src={LocationIcon} /> {jobData.location}
                              </span>
                            </h4>
                            <span class="postTime d-block text-start">
                              {/* 2 days ago */}
                              <TimeAgo timestamp={jobData.createdAt} />
                            </span>
                          </div>
                        </div>

                        <div className="postBodyContent">
                          {/* <h4 style={{ fontSize: "15px" }}>$65,547 - $83,279/ <span>Annually</span></h4> */}
                          <h4>
                            {jobData.updatedSalary}/{" "}
                            <span>{jobData.timeperiod}</span>
                          </h4>
                          {/* <p>
                          Responsible for overseeing all store operations and
                          ensuring the highest quality of customer service. You
                          must have strong leadership and communication skills
                          to effectively manage a team of employees, control
                          inventory and costs, and exceed sales goals.
                        </p> */}
                          <div
                            className="detailText"
                            dangerouslySetInnerHTML={{
                              __html: jobData.sanitizedHTML,
                            }}
                          />
                        </div>

                        <div className="postFooter">
                          <div className="jobssectionbottom">
                            <div
                              className="tags"
                              style={{ textTransform: "capitalize" }}
                            >
                              <span>{jobData.timeperiod}</span>
                              <span>{jobData.jobType}</span>
                              {jobData.location ? (
                                <span>
                                  {jobData.location &&
                                    jobData.location.split(",")[0]}
                                </span>
                              ) : (
                                ""
                              )}
                              <span>{jobData.positionType}</span>
                            </div>
                            {/* <a>Apply Now</a> */}
                          </div>
                          {/* <div className="jobssectionbottom">
                          <div className="tags">
                            <span>Hourly</span>
                            <span>part-time</span>
                            <span>in-person</span>
                          </div>
                        </div> */}
                        </div>
                      </div>

                      <div className="btnSection my-5">
                        <button
                          className="btn btn-save"
                          // data-bs-toggle="modal"
                          // data-bs-target="#additionalSettingModal"
                          onClick={() => props.setStep((prev) => prev + 33.33)}
                        >
                          Yes, proceed!
                        </button>
                      </div>
                    </div>
                  ) : step == 66.66 ? (
                    <div className="commonModalContent px-4">
                      <h3 className="ModalHeading">Additional Settings</h3>
                      {/* {steps == "33.33" ? <ConfirmContent /> : ""} */}
                      <div className="jobPosted__List text-start">
                        <div className="postHeader">
                          {jobData &&
                            jobData.firmId &&
                            jobData.firmId.firmLogo && (
                              <div className="postImg">
                                <img src={jobData.firmId.firmLogo} />
                              </div>
                            )}
                          <div className="postText">
                            <h4 className="white">
                              {jobData.title}{" "}
                              <span className="green">
                                {" "}
                                <img src={LocationIcon} /> {jobData.location}
                              </span>
                            </h4>
                            <span class="postTime d-block text-start">
                              {/* 2 days ago */}
                              <TimeAgo timestamp={jobData.createdAt} />
                            </span>
                          </div>
                        </div>

                        <div className="postBodyContent">
                          {/* <h4>
                            $65,547 - $83,279/ <span>Annually</span>
                          </h4> */}
                          <h4>
                            {jobData.updatedSalary}/{" "}
                            <span>{jobData.timeperiod}</span>
                          </h4>
                        </div>
                      </div>

                      <div className="checkSection">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="alert"
                          />
                          <label class="form-check-label" for="alert">
                            Alert your Hirebird Network{" "}
                            <img src={questionMark} />
                          </label>
                        </div>
                      </div>

                      <div className="checkSection">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="allowNotification"
                          />
                          <label
                            class="form-check-label"
                            for="allowNotification"
                          >
                            Allow Application Notifications
                          </label>
                        </div>
                      </div>

                      <div className="btnSection my-5">
                        <button
                          className="btn btn-save"
                          // data-bs-toggle="modal"
                          // data-bs-target="#shareLinkModal"
                          onClick={() => setStep((prev) => prev + 33.34)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="commonModalContent px-4">
                      <h3 className="ModalHeading">Share your link!</h3>
                      {/* {steps == "33.33" ? <ConfirmContent /> : ""} */}
                      <div className="shareContent">
                        <div className="form-group">
                          <div class="input-group mb-3">
                            <span
                              onClick={handleCopyClick}
                              style={{ cursor: "pointer" }}
                              class="input-group-text"
                              id="basic-addon1"
                            >
                              <img src={attachmentIcon} />
                            </span>
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={`${process.env.REACT_APP_WEBSITE}${
                                jobData && jobData.shareUrl
                              }`}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="shareBtns">
                          <div>
                            <img src={codeRectangleIcon} />
                            <button className="btn">Embed in Website</button>
                          </div>
                          <div>
                            <img src={envelopeRectangleIcon} />
                            <button className="btn">Embed in Email</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Share Job Modal Ends */}
        {/* additional setting modal starts */}
        <div
          class="modal fade commonModal editEducationModal candidateOnboarding"
          id="additionalSettingModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0"></div>
              <div class="modal-body pt-0 px-0">
                <div className="jobProgress">
                  <StepFormProgress value={"33.33%"} className="mt-0" />
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading">Additional Settings</h3>
                  {/* {steps == "33.33" ? <ConfirmContent /> : ""} */}
                  <div className="jobPosted__List text-start">
                    <div className="postHeader">
                      <div className="postImg">
                        <img src={jobImg} />
                      </div>
                      <div className="postText">
                        <h4 className="white">
                          General Manager
                          <span className="green">
                            {" "}
                            <img src={LocationIcon} /> Lufkin, TX
                          </span>
                        </h4>
                        <span class="postTime d-block text-start">
                          2 days ago
                        </span>
                      </div>
                    </div>

                    <div className="postBodyContent">
                      <h4>
                        $65,547 - $83,279/ <span>Annually</span>
                      </h4>
                    </div>
                  </div>

                  <div className="checkSection">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="alert"
                      />
                      <label class="form-check-label" for="alert">
                        Alert your Hirebird Network <img src={questionMark} />
                      </label>
                    </div>
                  </div>

                  <div className="checkSection">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="allowNotification"
                      />
                      <label class="form-check-label" for="allowNotification">
                        Allow Application Notifications
                      </label>
                    </div>
                  </div>

                  <div className="btnSection my-5">
                    <button
                      className="btn btn-save"
                      // data-bs-toggle="modal"
                      // data-bs-target="#shareLinkModal"
                      onClick={() => setStep((prev) => prev + 33.33)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* additional setting modal ends */}
        {/* Share link modal starts */}
        <div
          class="modal fade commonModal editEducationModal candidateOnboarding"
          id="shareLinkModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0"></div>
              <div class="modal-body pt-0 px-0">
                <div className="jobProgress">
                  <StepFormProgress value={"33.33%"} className="mt-0" />
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading">Share your link!</h3>
                  {/* {steps == "33.33" ? <ConfirmContent /> : ""} */}
                  <div className="shareContent">
                    <div className="form-group">
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                          <img src={attachmentIcon} />
                        </span>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value="https://www.hirebird.me/share/crumblcookies/generalmanager"
                          disabled
                        />
                      </div>
                    </div>

                    <div className="shareBtns">
                      <div>
                        <img src={codeRectangleIcon} />
                        <button className="btn">Embed in Website</button>
                      </div>
                      <div>
                        <img src={envelopeRectangleIcon} />
                        <button className="btn">Embed in Email</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Share link modal ends */}
      </div>
    </>
  );
}

export default DashboardModal;
