import Sidebar from "./adminSidebar";
import Topbar from "./adminTopbar";
import dummyImg from "../../assets/imgs/dummyImg.jpg";
import topPerformerImage from "../../assets/imgs/topPerformerImage.svg";
import newUser from "../../assets/imgs/newUserIcon.svg";
import activeAcountIcon from "../../assets/imgs/activeAcountIcon.svg";
import newJobPost from "../../assets/imgs/newContractMade.svg";
import blackSearch from "../../assets/imgs/blackSearch.svg";
import penIcon from "../../assets/imgs/penIcon.svg";
import eyeIcon from "../../assets/imgs/eyeIcon.svg";
import normalSearch from "../../assets/imgs/normalSearch.svg";
import activityIcon from "../../assets/imgs/anthropology2.svg";
import greenEnvelopeIcon from "../../assets/imgs/greenEnvelope.svg";
import redDotIcon from "../../assets/imgs/redDot.svg";
import greenCashIcon from "../../assets/imgs/greenCash.svg";
import topArrowGreen from "../../assets/imgs/topArrowGreen.svg";

import { useEffect, useState } from "react";
import AdminModal from "../../components/modals/adminModal";
import { TeamMember } from "./adminDashboard";
import { allApiUrl } from "../../api/apiRoutes";
import { apiGetMethod } from "../../api/rest";
function EmployeeProduction() {
  // const [currentModule, setCurrentModule] = useState("manageUser");
  return (
    <>
      <AdminModal />
      {/* firtname,.lasrt,design,phonr,eemaile,password */}
      <div className="mainWrapper">
        <Sidebar activePage="adminTeam" />
        <Topbar />
        <section className="analytics sectionPadding teamView">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-6">
                <div className="commonHeading">
                  <h2>Production</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="invitation commonRadius8 mb-5">
                <div className="row">
                  <div className="col-xl-9">
                    <div className="invitation px-5">
                        <div className="card">
                        <div className="card-header">
                            <h3 className="white">Customers</h3>

                            <div className="dropdown">
                                <button
                                className="btn dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                >
                                Today
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                        Today
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                        Today
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                        Today
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="card-body py-4">
                            <div className="activitySection">
                                <div className="activityContent">
                                    <div className="leftContent">
                                        <img src={activityIcon} />
                                        <p>Playlearn <br /> <span>Created and sent demo to Gabriel Newhouse.</span></p>
                                    </div>
                                    <div className="rightContent">
                                        <p>2 hours ago</p>
                                    </div>
                                </div>

                                <div className="activityContent">
                                    <div className="leftContent">
                                        <img src={activityIcon} />
                                        <p>Playlearn <br /> <span>Created and sent demo to Gabriel Newhouse.</span></p>
                                    </div>
                                    <div className="rightContent">
                                        <p>2 hours ago</p>
                                    </div>
                                </div>

                                <div className="activityContent">
                                    <div className="leftContent">
                                        <img src={activityIcon} />
                                        <p>Playlearn <br /> <span>Created and sent demo to Gabriel Newhouse.</span></p>
                                    </div>
                                    <div className="rightContent">
                                        <p>2 hours ago</p>
                                    </div>
                                </div>

                                <div className="activityContent">
                                    <div className="leftContent">
                                        <img src={activityIcon} />
                                        <p>Playlearn <br /> <span>Created and sent demo to Gabriel Newhouse.</span></p>
                                    </div>
                                    <div className="rightContent">
                                        <p>2 hours ago</p>
                                    </div>
                                </div>

                                <div className="activityContent">
                                    <div className="leftContent">
                                        <img src={activityIcon} />
                                        <p>Playlearn <br /> <span>Created and sent demo to Gabriel Newhouse.</span></p>
                                    </div>
                                    <div className="rightContent">
                                        <p>2 hours ago</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-xl-3">
                    <div className="productionCard">
                        <h3><img src={greenEnvelopeIcon} /> Active Accounts</h3>
                        <h4 className="green">214</h4>
                        <p>Today <br/ > <span>2 <img src={topArrowGreen} /></span></p>
                    </div>

                    <div className="productionCard">
                        <h3><img src={redDotIcon} /> Inactive Accounts</h3>
                        <h4 className="green">65</h4>
                        <p>Today <br/ > <span>2 <img src={topArrowGreen} /></span></p>
                    </div>

                    <div className="productionCard">
                        <h3><img src={greenCashIcon} /> Commission</h3>
                        <h4 className="green">$7,658.00/mo</h4>
                        <p>Today <br/ > <span>2 <img src={topArrowGreen} /></span></p>
                    </div>
                  </div>
                  {/* <div className="col-xl-3 col-lg-6">
                    <div className="invitationCard commonRadius8">
                      <div className="inviteHeader">
                        <span>
                          <img src={newUser} />
                        </span>

                        <p>MRR</p>
                      </div>
                      <div className="inviteBody">
                        <h4 className="green">$79,640.00</h4>
                        <p>
                          Today <br />
                          <span>
                            $938.00
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1510_281)">
                                <path
                                  d="M5.73444 0.859734L1.94278 4.6514C1.74544 4.85572 1.63624 5.12937 1.63871 5.41342C1.64118 5.69747 1.75511 5.96918 1.95597 6.17004C2.15683 6.3709 2.42854 6.48483 2.71259 6.4873C2.99664 6.48977 3.27029 6.38057 3.47461 6.18323L5.41703 4.24082L5.41703 11.3757C5.41703 11.663 5.53116 11.9385 5.73433 12.1417C5.93749 12.3448 6.21304 12.459 6.50036 12.459C6.78768 12.459 7.06323 12.3448 7.26639 12.1417C7.46956 11.9385 7.58369 11.663 7.58369 11.3757L7.58369 4.24082L9.52611 6.18323C9.62604 6.2867 9.74558 6.36923 9.87775 6.42601C10.0099 6.48279 10.1521 6.51267 10.2959 6.51392C10.4398 6.51517 10.5824 6.48776 10.7156 6.43329C10.8487 6.37882 10.9697 6.29838 11.0714 6.19666C11.1731 6.09494 11.2535 5.97399 11.308 5.84085C11.3625 5.70771 11.3899 5.56506 11.3886 5.42122C11.3874 5.27737 11.3575 5.13522 11.3007 5.00305C11.2439 4.87087 11.1614 4.75133 11.0579 4.6514L7.26628 0.859734C7.06312 0.656641 6.78762 0.542549 6.50036 0.542549C6.2131 0.542549 5.9376 0.656641 5.73444 0.859734Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1510_281">
                                  <rect
                                    width="13"
                                    height="13"
                                    fill="white"
                                    transform="translate(0 13) rotate(-90)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-xl-3 col-lg-6">
                    <div className="invitationCard commonRadius8">
                      <div className="inviteHeader">
                        <span className="activeAcount">
                          <img src={activeAcountIcon} alt="" />
                        </span>

                        <p>Active Accounts</p>
                      </div>

                      <div className="inviteBody">
                        <h4 className="green">594</h4>
                        <p>
                          Today <br />
                          <span>
                            7
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_1510_289)">
                                <path
                                  d="M5.73444 12.1403L1.94278 8.3486C1.74544 8.14428 1.63624 7.87063 1.63871 7.58658C1.64118 7.30253 1.75511 7.03082 1.95597 6.82996C2.15683 6.6291 2.42854 6.51517 2.71259 6.5127C2.99664 6.51023 3.27029 6.61943 3.47461 6.81677L5.41703 8.75918L5.41703 1.62435C5.41703 1.33703 5.53116 1.06148 5.73433 0.858317C5.93749 0.655152 6.21304 0.541016 6.50036 0.541016C6.78768 0.541016 7.06323 0.655152 7.26639 0.858317C7.46956 1.06148 7.58369 1.33703 7.58369 1.62435L7.58369 8.75918L9.52611 6.81677C9.62604 6.7133 9.74558 6.63077 9.87775 6.57399C10.0099 6.51721 10.1521 6.48733 10.2959 6.48608C10.4398 6.48483 10.5824 6.51224 10.7156 6.56671C10.8487 6.62118 10.9697 6.70162 11.0714 6.80334C11.1731 6.90506 11.2535 7.02601 11.308 7.15915C11.3625 7.29229 11.3899 7.43494 11.3886 7.57878C11.3874 7.72263 11.3575 7.86478 11.3007 7.99695C11.2439 8.12913 11.1614 8.24867 11.0579 8.3486L7.26628 12.1403C7.06312 12.3434 6.78762 12.4575 6.50036 12.4575C6.2131 12.4575 5.9376 12.3434 5.73444 12.1403Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1510_289">
                                  <rect
                                    width="13"
                                    height="13"
                                    fill="white"
                                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <TeamMember /> */}
                  {/* <div className="col-xl-6 col-lg-12">
                    <div className="invitationCard commonRadius8">
                      <div className="inviteHeader">
                        <h3 style={{ color: "white" }}>Manage Team</h3>
                      </div>
                      <div className="inviteBody">
                        <img src={dummyImg} className="manageTeamImage" />
                        <img src={dummyImg} className="manageTeamImage" />
                        <img src={dummyImg} className="manageTeamImage" />
                        <img src={dummyImg} className="manageTeamImage" />
                        <img src={dummyImg} className="manageTeamImage" />
                        <img src={dummyImg} className="manageTeamImage" />
                        <img src={dummyImg} className="manageTeamImage" />
                        <img src={dummyImg} className="manageTeamImage" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            
          </div>
        </section>
      </div>
    </>
  );
}

export default EmployeeProduction;
