import { useEffect, useState } from "react";
import StepFormProgress from "../../view/stepForm-progress";
import Anthropology from "../../assets/imgs/anthropology.svg";
import experiencepeople from "../../assets/imgs/experiencepeople.svg";
import editIcon from "../../assets/imgs/editIcon.svg";
import checkMark from "../../assets/imgs/checkMark.gif";
import boostIcon from "../../assets/imgs/boostIcon.gif";
import infoIcon from "../../assets/imgs/infoIcon.svg";
import uploadIcon from "../../assets/imgs/uploadIcon.svg";
import whiteCross from "../../assets/imgs/whiteCross.svg";
import toastMessages from "../toastMessage";
import Logo from "../../assets/imgs/HireBIRD.png";
import { allApiUrl } from "../../api/apiRoutes";
import { apiPostMethod, apiPutMethod } from "../../api/rest";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../customImageEditor/index";
import googleIcon from "../../assets/imgs/googleIcon.svg";
import canceIcon from "../../assets/imgs/cancel.svg";
import approveIcon from "../../assets/imgs/approved.png";

function AdminModal(props) {
  let navigate = useNavigate();
  useEffect(() => {
    setForm({
      firstName: props.currTeam.firstName,
      lastName: props.currTeam.lastName,
      email: props.currTeam.email,
      phoneNumber: props.currTeam.phoneNumber,
      designation: props.currTeam.designation,
      isAdmin: props.currTeam.isAdmin,
      accountId: props.currTeam._id,
    });
  }, [props.currTeam]);
  const [form, setForm] = useState({});
  const [viewPassword, setViewPassword] = useState(true);
  async function addTeamMember() {
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      let res = await apiPostMethod(allApiUrl.createTeam, form, header);
      if (res.status == 200) {
        setForm({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          designation: "",
          password: "",
          isAdmin: false,
          employee: false,
        });
        document.querySelector('[data-bs-dismiss="modal"]').click();
        props.setIsPageRefreshed(!props.isPageRefreshed);
        toastMessages("success", "Team member created successfully.");
      }
    } catch (err) {
      console.log("err", err.data.message);
      toastMessages("error", err.data.message);
    }
  }

  // Edit Team Member
  async function editTeamMember() {
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      let updatedForm = {
        accountId: form.accountId,
        ...form,
      };

      let res = await apiPostMethod(
        allApiUrl.updateEmployee,
        updatedForm,
        header
      );
      if (res.status == 200) {
        setForm({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          designation: "",
          password: "",
          isAdmin: false,
          employee: false,
        });
        props.setIsPageRefreshed(!props.isPageRefreshed);
        toastMessages("success", "Team member Edited successfully.");
      }
    } catch (err) {
      toastMessages("error", err.data.message);
      console.log("err", err.data.message);
    }
  }

  const inputHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <div className="mainWrapper">
        {/* Admin Modal Start */}
        {/* Add Member*/}
        <div
          class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
          id="educationhistoryModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setForm({
                      firstName: "",
                      lastName: "",
                      email: "",
                      phoneNumber: "",
                      designation: "",
                      password: "",
                      isAdmin: false,
                      employee: false,
                    });
                  }}
                ></button>
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"100%"} />
                  <h3 className="ModalHeading">Add Team Member</h3>
                  <form>
                    <div className="row mb-0">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="firstName"
                            onChange={inputHandler}
                            value={form.firstName}
                            autocomplete="off"
                            type="text"
                            className="form-control"
                            placeholder="First Name *"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="lastName"
                            onChange={inputHandler}
                            value={form.lastName}
                            autocomplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Last Name *"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-0">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="designation"
                            onChange={inputHandler}
                            value={form.designation}
                            autocomplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Designation *"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="phoneNumber"
                            onChange={inputHandler}
                            value={form.phoneNumber}
                            autocomplete="off"
                            type="number"
                            className="form-control"
                            placeholder="Phone Number *"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="email"
                            onChange={inputHandler}
                            value={form.email}
                            // autocomplete="off"
                            autocomplete="new-password"
                            type="text"
                            className="form-control"
                            placeholder="Email *"
                          />
                        </div>
                        <div>
                          <div className="form-group">
                            <div
                              className="form-check"
                              style={{ color: "white", marginRight: " 494px" }}
                            >
                              <label>
                                <input
                                  className="form-check-input"
                                  checked={form.isAdmin}
                                  type="checkbox"
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      setForm({
                                        ...form,
                                        isAdmin: true,
                                      });
                                    } else {
                                      setForm({
                                        ...form,
                                        isAdmin: false,
                                      });
                                    }
                                  }}
                                />
                                Admin
                              </label>
                            </div>
                          </div>
                          {/* <div className="form-group">
                            <div className="form-check">
                              <label>
                                <input
                                  disabled
                                  className="form-check-input"
                                  checked={false}
                                  type="checkbox"
                                  onClick={(e) => {}}
                                />
                                Employee
                              </label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            required
                            autocomplete="new-password"
                            // autocomplete="off"
                            name="password"
                            type={`${!viewPassword ? "text" : "password"}`}
                            className="form-control"
                            id="password"
                            placeholder="Enter Password"
                            value={form.password}
                            onChange={inputHandler}
                            // autocomplete="off"
                          />
                          {/* <i
                            onClick={() => setViewPassword(!viewPassword)}
                            className={`toggle-password fa fa-fw fa-eye${
                              viewPassword ? "-slash" : ""
                            }`}
                          ></i>
                          <span>
                            <i className="fa-solid fa-lock"></i>
                          </span> */}
                        </div>
                        {/* {form.password && (
                          <div>
                            {(!/^(?=.*[a-z])/.test(form.password) ||
                              !/^(?=.*[A-Z])/.test(form.password) ||
                              !/^(?=.*\d)/.test(form.password) ||
                              !/^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                                form.password
                              ) ||
                              !(form.password.length >= 8)) && (
                              <ul className="errorListingRed">
                                <li
                                  style={{
                                    color: /^(?=.*[a-z])/.test(form.password)
                                      ? "#61D180"
                                      : "#DE5753",
                                  }}
                                >
                                  {/^(?=.*[a-z])/.test(form.password) ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Include at least 1 lowercase letter
                                </li>
                                <li
                                  style={{
                                    color: /^(?=.*[A-Z])/.test(form.password)
                                      ? "#61D180"
                                      : "#DE5753",
                                  }}
                                >
                                  {/^(?=.*[A-Z])/.test(form.password) ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Include at least 1 uppercase letter
                                </li>
                                <li
                                  style={{
                                    color: /^(?=.*\d)/.test(form.password)
                                      ? "#61D180"
                                      : "#DE5753",
                                  }}
                                >
                                  {/^(?=.*\d)/.test(form.password) ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Include at least 1 number
                                </li>
                                <li
                                  style={{
                                    color:
                                      /^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                                        form.password
                                      )
                                        ? "#61D180"
                                        : "#DE5753",
                                  }}
                                >
                                  {/^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                                    form.password
                                  ) ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Include at least 1 special character
                                </li>
                                <li
                                  style={{
                                    color:
                                      form.password.length >= 8
                                        ? "#61D180"
                                        : "#DE5753",
                                  }}
                                >
                                  {form.password.length >= 8 ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Must be at least 8 characters long
                                </li>
                              </ul>
                            )}
                          </div>
                        )} */}
                      </div>
                    </div>
                    <div className="row d-none">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div className="form-check">
                            <label>
                              <input
                                className="form-check-input"
                                checked={form.isAdmin}
                                type="checkbox"
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setForm({
                                      ...form,
                                      isAdmin: true,
                                    });
                                  } else {
                                    setForm({
                                      ...form,
                                      isAdmin: false,
                                    });
                                  }
                                }}
                              />
                              Admin
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-check">
                            <label>
                              <input
                                disabled
                                className="form-check-input"
                                checked={false}
                                type="checkbox"
                                onClick={(e) => {}}
                              />
                              Employee
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      disabled={
                        !form.firstName ||
                        !form.lastName ||
                        !form.email ||
                        !form.phoneNumber ||
                        !form.designation ||
                        !form.password
                      }
                      className="btn inviteBtn"
                      // data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => addTeamMember(e)}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Edit Member*/}
        <div
          class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
          id="editTeamMember"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setForm({
                      ...form,
                      password: "",
                    });
                  }}
                ></button>
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"100%"} />
                  <h3 className="ModalHeading">Edit Team Member</h3>
                  <form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="firstName"
                            onChange={inputHandler}
                            value={form.firstName}
                            autocomplete="off"
                            type="text"
                            className="form-control"
                            placeholder="First Name *"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="lastName"
                            onChange={inputHandler}
                            value={form.lastName}
                            autocomplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Last Name *"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="designation"
                            onChange={inputHandler}
                            value={form.designation}
                            autocomplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Designation *"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="phoneNumber"
                            onChange={inputHandler}
                            value={form.phoneNumber}
                            autocomplete="off"
                            type="number"
                            className="form-control"
                            placeholder="Phone Number *"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="email"
                            disabled
                            onChange={inputHandler}
                            value={form.email}
                            // autocomplete="off"
                            autocomplete="new-password"
                            type="text"
                            className="form-control"
                            placeholder="Email *"
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            name="password"
                            onChange={inputHandler}
                            value={form.password}
                            autocomplete="off"
                            type="text"
                            className="form-control"
                            placeholder="Password *"
                          />
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            required
                            name="password"
                            type={`${!viewPassword ? "text" : "password"}`}
                            className="form-control"
                            id="password"
                            autocomplete="new-password"
                            placeholder="Enter Password"
                            value={form.password}
                            onChange={inputHandler}
                            // autocomplete="off"
                          />
                          {/* <i
                            onClick={() => setViewPassword(!viewPassword)}
                            className={`toggle-password fa fa-fw fa-eye${
                              viewPassword ? "-slash" : ""
                            }`}
                          ></i>
                          <span>
                            <i className="fa-solid fa-lock"></i>
                          </span> */}
                        </div>
                        {/* {form.password && (
                          <div>
                            {(!/^(?=.*[a-z])/.test(form.password) ||
                              !/^(?=.*[A-Z])/.test(form.password) ||
                              !/^(?=.*\d)/.test(form.password) ||
                              !/^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                                form.password
                              ) ||
                              !(form.password.length >= 8)) && (
                              <ul className="errorListingRed">
                                <li
                                  style={{
                                    color: /^(?=.*[a-z])/.test(form.password)
                                      ? "#61D180"
                                      : "#DE5753",
                                  }}
                                >
                                  {/^(?=.*[a-z])/.test(form.password) ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Include at least 1 lowercase letter
                                </li>
                                <li
                                  style={{
                                    color: /^(?=.*[A-Z])/.test(form.password)
                                      ? "#61D180"
                                      : "#DE5753",
                                  }}
                                >
                                  {/^(?=.*[A-Z])/.test(form.password) ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Include at least 1 uppercase letter
                                </li>
                                <li
                                  style={{
                                    color: /^(?=.*\d)/.test(form.password)
                                      ? "#61D180"
                                      : "#DE5753",
                                  }}
                                >
                                  {/^(?=.*\d)/.test(form.password) ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Include at least 1 number
                                </li>
                                <li
                                  style={{
                                    color:
                                      /^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                                        form.password
                                      )
                                        ? "#61D180"
                                        : "#DE5753",
                                  }}
                                >
                                  {/^(?=.*[!@#$%^&*()_+={}[\]:;<>,.?~\\/-])/.test(
                                    form.password
                                  ) ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Include at least 1 special character
                                </li>
                                <li
                                  style={{
                                    color:
                                      form.password.length >= 8
                                        ? "#61D180"
                                        : "#DE5753",
                                  }}
                                >
                                  {form.password.length >= 8 ? (
                                    <img src={approveIcon} />
                                  ) : (
                                    <img src={canceIcon} />
                                  )}{" "}
                                  Must be at least 8 characters long
                                </li>
                              </ul>
                            )}
                          </div>
                        )} */}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <div
                            className="form-check"
                            style={{ color: "white", marginRight: " 494px" }}
                          >
                            <label>
                              <input
                                className="form-check-input"
                                checked={form.isAdmin}
                                type="checkbox"
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setForm({
                                      ...form,
                                      isAdmin: true,
                                    });
                                  } else {
                                    setForm({
                                      ...form,
                                      isAdmin: false,
                                    });
                                  }
                                }}
                              />
                              Admin
                            </label>
                          </div>
                        </div>
                        {/* <div className="form-group">
                          <div className="form-check">
                            <label>
                              <input
                                disabled
                                className="form-check-input"
                                checked={false}
                                type="checkbox"
                                onClick={(e) => {}}
                              />
                              Employee
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      disabled={
                        !form.firstName ||
                        !form.lastName ||
                        !form.phoneNumber ||
                        !form.designation
                      }
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => editTeamMember(e)}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* View */}
        {props.currTeam && (
          <div
            class="modal fade commonModal editEducationModal candidateOnboarding"
            id="viewTeamMember"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabindex="-1"
            data-bs-backdrop="static"
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header d-block pt-0 px-0"></div>
                <div class="modal-body pt-0 px-0">
                  <div className="jobProgress"></div>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <div className="commonModalContent px-4">
                    <h3 className="ModalHeading">Team Information</h3>
                    <div className="teamInfo text-start">
                      <div className="row">
                        <div className="col-lg-6">
                          <p>
                            <span>First Name:</span> {props.currTeam.firstName}
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <p>
                            <span>Last Name:</span> {props.currTeam.lastName}
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <p>
                            <span>Designation:</span>{" "}
                            {props.currTeam.designation}
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <p>
                            <span>Phone Number:</span>{" "}
                            {props.currTeam.phoneNumber}
                          </p>
                        </div>

                        <div className="col-lg-6">
                          <p>
                            <span>Email:</span> {props.currTeam.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* View */}
      </div>
      {/* Admin Modal End */}
    </>
  );
}

export default AdminModal;
