import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import searchIconGreen from "../assets/imgs/searchIconGreen.svg";
import notificationsIcon from "../assets/imgs/notificationsIcon.svg";
import userImg from "../assets/imgs/userImg.png";
import userIcon from "../assets/imgs/userIconNew.svg";
import Logo from "../assets/imgs/HireBIRD.png";
import signoutIcon from "../assets/imgs/signoutIcon.svg";
import logoutPopupImg from "../assets/imgs/logout.gif";
import dummyImg from "../assets/imgs/dummyImg.jpg";

import {
  GoogleOAuthProvider,
  googleLogout,
  useGoogleOAuth,
} from "@react-oauth/google";
import { useGoogleLogout } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { allApiUrl } from "../api/apiRoutes";
import { apiGetMethod } from "../api/rest";

function Topbar({ sidebarOpen, setSidebarOpen }) {
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    if (!userInfo) {
      localStorage.clear();
      navigate("/login");
    }
  }, []);
  async function logoutHanlder() {
    try {
      let header = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      };
      const response = await apiGetMethod(allApiUrl.logout, "", header);
      if (response.status == 200) {
        localStorage.clear();
        navigate("/login");
        googleLogout();
      }
    } catch (err) {
      navigate("/login");
      console.log("err", err);
    }
  }

  const [topbarData, setTopbarData] = useState({});
  // const [sidebarOpen,setSidebarOpen] = useState(localStorage.getItem())
  // const [account]
  async function getAccountDetail() {
    const header = {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };

    try {
      const response = await apiGetMethod(allApiUrl.profile, "", header);
      if (response.status === 200) {
        setTopbarData(response.data.data);
      }
    } catch (err) {
      // if(err.status)
      //()  toastMessage('error',err.data.message)
      console.log("err", err.data.message);
    }
  }
  useEffect(() => {
    getAccountDetail();
  }, []);
  let navigate = useNavigate();
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { signOut, isSignedIn } = useGoogleOAuth();
  const handleLogout = async () => {
    logoutHanlder();
  };
  return (
    <>
      <div className="topbarInner">
        <div className="row">
          <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4">
            <div className="topBarLogoSection">
              <span
                className="menuIcon"
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </span>
              {/* <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                />
                <span>
                  <img src={searchIconGreen} />
                </span>
              </div> */}
              <div className="text-center">
                <img className="logo" src={Logo} />
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-2 col-1">
            <div className="d-flex">
              {/* <span
                className="menuIcon"
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </span> */}
              {/* <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                />
                <span>
                  <img src={searchIconGreen} />
                </span>
              </div> */}
            </div>
          </div>
          <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-7">
            <div className="topbarInner__Content">
              {/* <a href="">
                <img src={notificationsIcon} />
              </a> */}

              <div className="dropdown userDropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      topbarData && topbarData.profileImage
                        ? topbarData.profileImage
                        : dummyImg
                    }
                  />
                  <span style={{ textTransform: "capitalize" }}>
                    {topbarData && topbarData.firstName}{" "}
                    {topbarData && topbarData.lastName} <span>My account</span>
                  </span>
                </button>
                <ul className="dropdown-menu">
                  <li>
                    {topbarData && topbarData.accountType == "jobseeker" && (
                      <Link className="dropdown-item" to="/my-profile">
                        <img
                          src={
                            topbarData && topbarData.profileImage
                              ? topbarData.profileImage
                              : dummyImg
                          }
                        />{" "}
                        Profile Settings
                      </Link>
                    )}
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      <img src={signoutIcon} /> Sign Out
                    </a>
                  </li>
                </ul>

                {/* Modal */}
                <div
                  class="modal fade logoutModal"
                  id="staticBackdrop"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <img src={logoutPopupImg} />
                        <p>
                          Are you sure <br /> you want to <span>Logout?</span>
                        </p>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Not now.
                        </button>
                        {/* <GoogleOAuthProvider clientId={clientId}> */}
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            // googleLogout();
                            handleLogout();
                          }}
                        >
                          Yes, Logout!
                        </button>
                        {/* </GoogleOAuthProvider> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Topbar;
