import { useState } from "react";
import postingpopup from "../../assets/imgs/postingpopup.svg";
// import editIcon from "../../assets/imgs/editIcon.svg";
// import { areAllValuesFilled } from "../../utils/helper";
import jobPost_full from "../../assets/imgs/jobPost_full.gif";
import checkMark from "../../assets/imgs/checkMark.gif";

function CreateJobModal({ setStepNumber, onJobCreateHandler }) {
  return (
    <>
      <div className="mainWrapper">
        {/* All set Modal Starts */}
        <div
          class="modal fade commonModal editEducationModal confirmationModal"
          id="confirmationModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <img src={checkMark} />
                  <p>
                    All Set! <br /> Ready to post ?
                  </p>
                  <div className="formBtns mt-0">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setStepNumber(100);
                      }}
                    >
                      Go Back
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      onClick={(e) => onJobCreateHandler(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* all set Modal Ends */}
        {/* full launch modal Starts */}
        <div
          class="modal fade commonModal editEducationModal confirmationModal"
          id="fullLaunchModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <img src={jobPost_full} />
                  <p>
                    More awesome features to come with the full launch!
                    <br />
                    <p> For now, let's go ahead </p>
                  </p>
                  <div className="formBtns mt-0">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setStepNumber(75);
                      }}
                    >
                      Go Back
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-target="#confirmationModal"
                      data-bs-toggle="modal"
                    >
                      Go ahead
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* full launch Modal Ends */}
      </div>
    </>
  );
}

export default CreateJobModal;
