import Logo from "../../assets/imgs/HireBIRD.png";
const FullPageLoader = ({ isLoading }) => {
    return (

        <div className="commonLoader">
            <div className="inner">
                <img src={Logo} />
            </div>
        </div>
    )
}
export default FullPageLoader