import React, { useRef, useState } from "react";
import StepFormProgress from "../../view/stepForm-progress";
import Anthropology from "../../assets/imgs/anthropology.svg";
import experiencepeople from "../../assets/imgs/experiencepeople.svg";
import editIcon from "../../assets/imgs/editIcon.svg";
import Logo from "../../assets/imgs/HireBIRD.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import checkMark from "../../assets/imgs/checkMark.gif";
import boostIcon from "../../assets/imgs/boostIcon.gif";
import infoIcon from "../../assets/imgs/infoIcon.svg";
import uploadIcon from "../../assets/imgs/uploadIcon.svg";
import iconDelete from "../../assets/imgs/iconModalDelete.svg";
import DeleteIcon from "../../assets/imgs/iconDelete.svg";
import toastMessages from "../../components/toastMessage";
import invitePopupImg from "../../assets/imgs/invitePopupImg.svg";
import { getYear } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import CustomDatePicker from "../CustomDatePicker";
import { CustomDatePickerInput } from "../../view/modal";
import ImageUpload from "../customImageEditor";
// import { areAllValuesFilled } from "../../utils/helper";

function ProfileModal(props) {
  let navigate = useNavigate();
  let suggestedSkills = ["Sales", "Marketing", "Direct Sales", "Management"];
  let { onSubmit, form, setForm, userInfo } = props;
  const [newEducationDetail, setNewEducationDetail] = useState({
    university: "",
    degreeType: "",
    startDate: "",
    endDate: "",
    isPresent: false,
    description: "",
  });
  const [editEducationIndex, setEditEducationIndex] = useState(null);
  const [newExperienceDetails, setNewExperienceDetails] = useState({
    companyName: "",
    designation: "",
    startDate: "",
    isPresent: false,
    endDate: "",
    description: "",
  });
  const [editExperienceIndex, setEditExperienceIndex] = useState(null);
  const [newSkill, setNewSkill] = useState([
    {
      skillSet: "",
    },
  ]);
  const [currentCustomValuePropmt, setCurrentCustomValuePrompt] =
    useState(null);
  const [skillsCount, setSkillsCount] = useState(1);
  let existingSkills;
  if (newSkill && newSkill.length > 0) {
    existingSkills =
      form &&
      form.candidateProfileId &&
      form.candidateProfileId.skills &&
      form.candidateProfileId.skills.length > 0 &&
      form.candidateProfileId.skills.map((skill) => {
        return skill.skillSet;
      });
    // existingSkills =
    //   newSkill &&
    //   newSkill.length > 0 &&
    //   newSkill.map((skill) => skill.skillSet);
  }
  let threePromptFilled =
    form &&
    form.customValuePrompt &&
    form.customValuePrompt.filter((item) => item.a).length;

  function handlePresentEducation(event) {
    let currentPresentStatus = false;
    let selectedQualicationData = form.candidateProfileId.qualification.filter(
      (item) => {
        return item.degreeType == newEducationDetail.degreeType;
      }
    );
    let presentExist = form.candidateProfileId.qualification.some(
      (item) => item.isPresent
    );
    if (event.target.checked) {
      newEducationDetail.endDate = "";
    }
    if (!presentExist || (presentExist && !event.target.checked)) {
      setNewEducationDetail((prevData) => {
        // const currentDate = new Date();
        if (prevData.isPresent) {
          prevData.endDate = "";
        }
        return {
          ...prevData,
          isPresent: !prevData.isPresent,
          // endDate: prevData.isPresent ? currentDate : "", // Set endDate to current date if toggling to "Present"
        };
      });

      // toastMessages("error","Already selected")
    } else {
      if (
        selectedQualicationData &&
        selectedQualicationData.length > 0 &&
        selectedQualicationData[0].isPresent
      ) {
        setNewEducationDetail((prevData) => {
          // const currentDate = new Date();
          if (prevData.isPresent) {
            prevData.endDate = "";
          }
          return {
            ...prevData,
            isPresent: !prevData.isPresent,
            // endDate: prevData.isPresent ? currentDate : "", // Set endDate to current date if toggling to "Present"
          };
        });
      } else {
        toastMessages("error", "Present education already selected");
        event.target.checked = false;
      }
    }
  }
  function handlePresentExperience(event) {
    // setNewExperienceDetails((prevData) => {
    //   const currentDate = new Date();
    //   return {
    //     ...prevData,
    //     isPresent: !prevData.isPresent,
    //     endDate: prevData.isPresent ? currentDate : "", // Set endDate to current date if toggling to "Present"
    //   };
    // });
    let currentPresentStatus = false;
    let selectedQualicationData = form.candidateProfileId.workExperience.filter(
      (item) => {
        return item.degreeType == newExperienceDetails.degreeType;
      }
    );
    let presentExist = form.candidateProfileId.workExperience.some(
      (item) => item.isPresent
    );
    if (event.target.checked) {
      newExperienceDetails.endDate = "";
    }
    if (!presentExist || (presentExist && !event.target.checked)) {
      setNewExperienceDetails((prevData) => {
        // const currentDate = new Date();
        if (prevData.isPresent) {
          prevData.endDate = "";
        }
        return {
          ...prevData,
          isPresent: !prevData.isPresent,
          // endDate: prevData.isPresent ? currentDate : "", // Set endDate to current date if toggling to "Present"
        };
      });

      // toastMessages("error","Already selected")
    } else {
      if (selectedQualicationData[0].isPresent) {
        setNewExperienceDetails((prevData) => {
          // const currentDate = new Date();
          if (prevData.isPresent) {
            prevData.endDate = "";
          }
          return {
            ...prevData,
            isPresent: !prevData.isPresent,
            // endDate: prevData.isPresent ? currentDate : "", // Set endDate to current date if toggling to "Present"
          };
        });
      } else {
        toastMessages("error", "Present work experience already selected");
        event.target.checked = false;
      }
    }
  }
  // const handleEndDateChange = (date) => {
  //   setNewEducationDetail((prevData) => {
  //     return {
  //       ...prevData,
  //       endDate: date,
  //       isPresent: false, // Uncheck "Present" if endDate is changed
  //     };
  //   });
  // };
  const libraries = ["places"];
  const inputRef = useRef();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setForm((prev) => {
        if (prev?.candidateProfileId) {
          if (prev?.candidateProfileId?.location)
            prev.candidateProfileId.location = place.formatted_address;
          return { ...prev };
        }
      });
      // setForm((prev) => {
      //   prev.location = place.formatted_address;
      //   return { ...prev };
      // });
    }
  };
  return (
    <>
      <div className="mainWrapper">
        {/* Skills Modal Starts */}
        {/* candidate location modal starts */}
        <div
          class="modal fade commonModal editEducationModal profileModal"
          id="locationModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Edit Basic Details</h3>
                  <form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            value={form.firstName}
                            onChange={(e) => {
                              setForm((prev) => {
                                prev.firstName = e.target.value;
                                return { ...prev };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="LastName"
                            name="LastName"
                            value={form.lastName}
                            onChange={(e) => {
                              setForm((prev) => {
                                prev.lastName = e.target.value;
                                return { ...prev };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            value={
                              form &&
                              form.candidateProfileId &&
                              form.candidateProfileId.locations &&
                              form.candidateProfileId.locations.city
                            }
                            onChange={(e) => {
                              setForm((prev) => {
                                if (prev?.candidateProfileId) {
                                  if (prev?.candidateProfileId?.locations)
                                    prev.candidateProfileId.locations.city =
                                      e.target.value;
                                  return { ...prev };
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            name="state"
                            value={
                              form &&
                              form.candidateProfileId &&
                              form.candidateProfileId.locations &&
                              form.candidateProfileId.locations.state
                            }
                            onChange={(e) => {
                              setForm((prev) => {
                                if (prev?.candidateProfileId) {
                                  if (prev?.candidateProfileId?.locations)
                                    prev.candidateProfileId.locations.state =
                                      e.target.value;
                                  return { ...prev };
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="form-group">
                        {isLoaded && (
                          <StandaloneSearchBox
                            // value={}
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Location"
                              value={form.candidateProfileId?.location}
                              onChange={(e) => {
                                setForm((prev) => {
                                  prev.candidateProfileId.location =
                                    e.target.value;
                                  return { ...prev };
                                });
                              }}
                            />
                          </StandaloneSearchBox>
                        )}
                      </div>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      // disabled={!form.locations.state || !form.locations.city}
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => {
                        let data = {
                          accountId: userInfo._id,
                          firstName: form.firstName,
                          lastName: form.lastName,
                          location: form.candidateProfileId.location,
                          // locations: {
                          //   state: form.candidateProfileId.locations.state,
                          //   city: form.candidateProfileId.locations.city,
                          // },
                        };
                        onSubmit(e, data, false);
                      }}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* candidate location modal ends */}
        {/* About us  */}
        <div
          class="modal fade commonModal addExperienceModal"
          id="addExperienceModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">About Me</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="form-group">
                          <textarea
                            onChange={(e) => {
                              setForm((prev) => {
                                prev.candidateProfileId.about = e.target.value;
                                return { ...prev };
                              });
                            }}
                            value={form?.candidateProfileId?.about}
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="formBtns mt-5 mb-4">
                      <button
                        className="btn cancelBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                      <button
                        className="btn inviteBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          let data = {
                            accountId: userInfo._id,
                            about: form.candidateProfileId.about,
                          };
                          onSubmit(e, data, false);
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About us */}
        {/* ---------------------------------------------------------------------------Education Modal Start ------------------------------------------------------------------------------------------------------------------------------ */}
        {/* Add Education Modal Starts */}
        <div
          class="modal fade commonModal addEducationModal"
          id="addEducationModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            onClick={() => {
              props.setIsUniversitySelected(false);
            }}
          >
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Add Education</h3>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            onBlur={(e) => {}}
                            onChange={(e) => {
                              props.setIsUniversitySelected(true);
                              setNewEducationDetail((prevData) => {
                                prevData.university = e.target.value;
                                prevData.universityLogo = "";
                                return {
                                  ...prevData,
                                };
                              });
                              props.onUniversitySearchHandler(e.target.value);
                            }}
                            value={newEducationDetail.university}
                            type="text"
                            className="form-control"
                            placeholder="College/University"
                          />
                          {
                            <ul>
                              {props.isUniverSelected &&
                                props.universityList &&
                                props.universityList.map((item, index) => {
                                  return (
                                    <li
                                      style={{ cursor: "pointer" }}
                                      key={index}
                                      onClick={() => {
                                        props.setIsUniversitySelected(false);
                                        setNewEducationDetail((prevData) => {
                                          prevData.university = item.name;
                                          prevData.universityLogo = item.logo;
                                          return {
                                            ...prevData,
                                          };
                                        });
                                      }}
                                    >
                                      <img
                                        src={item.logo}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          borderRadius: "15px",
                                          marginRight: "13px",
                                        }}
                                      />
                                      {item.name}
                                    </li>
                                  );
                                })}
                            </ul>
                          }
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              setNewEducationDetail((prevData) => {
                                prevData.degreeType = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newEducationDetail.degreeType}
                            type="text"
                            className="form-control"
                            placeholder="Degree"
                          />
                          {/* // onChange={(e) => {
                                //   setForm((prevData) => {
                                //     let qualification = [
                                //       ...prevData.qualification,
                                //     ];
                                //     qualification[index].university =
                                //       e.target.value;
                                //     return {
                                //       ...prevData,
                                //       qualification,
                                //     };
                                //   });
                                // }}
                                // value={form.qualification[index]?.university} */}
                          {/* <select className="form-control">
                            <option>Degree</option>
                            <option>Degree</option>
                            <option>Degree</option>
                            <option>Degree</option>
                          </select> */}
                        </div>
                      </div>
                      {/* 
                      <div className="col-xl-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>Field of Study</option>
                            <option>Field of Study</option>
                            <option>Field of Study</option>
                            <option>Field of Study</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>Grade</option>
                            <option>Grade</option>
                            <option>Grade</option>
                            <option>Grade</option>
                          </select>
                        </div>
                      </div> */}

                      <div className="col-xl-6">
                        <div className="form-group">
                          <DatePicker
                            showYearDropdown
                            showMonthYearPicker
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            placeholderText="Start Date*"
                            customInput={
                              <CustomDatePickerInput placeholder="Start Date*" />
                            }
                            dateFormat="MM/yyyy"
                            // showIcon
                            selected={parseInt(newEducationDetail.startDate)}
                            onChange={(date) => {
                              setNewEducationDetail((prevData) => {
                                prevData.startDate = date.getTime().toString();
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                          />
                          {/* <input
                            onChange={(e) => {
                              setNewEducationDetail((prevData) => {
                                prevData.startDate = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newEducationDetail.startDate}
                            type="date"
                            className="form-control"
                            placeholder="Start Date"
                          /> */}
                          {/* <ReactDatePicker
                            id="myDate"
                            className="form-control"
                            // onChange={(event) => {
                            //   setNewEducationDetail((prevData) => {
                            //     prevData.startDate = event.getTime();
                            //     return {
                            //       ...prevData,
                            //     };
                            //   });
                            //   // setDueDate(event.getTime());
                            // }}
                            value={moment(newEducationDetail.startDate).format(
                              "YYYY-MM-DD"
                            )}
                            selected={new Date(newEducationDetail.startDate)}
                            minDate={new Date()}
                          /> */}
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <DatePicker
                            showYearDropdown
                            showMonthYearPicker
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            placeholderText="End Date*"
                            dateFormat="MM/yyyy"
                            customInput={
                              <CustomDatePickerInput placeholder="End Date*" />
                            }
                            // showIcon
                            selected={parseInt(newEducationDetail.endDate)}
                            onChange={(date) => {
                              setNewEducationDetail((prevData) => {
                                prevData.endDate = date.getTime().toString();
                                prevData.isPresent = false;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                          />
                          {/* <input
                            onChange={(e) => {
                              setNewEducationDetail((prevData) => {
                                prevData.endDate = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newEducationDetail.endDate}
                            type="date"
                            className="form-control"
                            placeholder="End Date"
                          /> */}
                        </div>
                        <div className="form-check mb-4 text-end">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkbox"
                              checked={newEducationDetail.isPresent}
                              onChange={handlePresentEducation}
                            />
                            Present
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <textarea
                            onChange={(e) => {
                              setNewEducationDetail((prevData) => {
                                prevData.description = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newEducationDetail.description}
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="formBtns">
                      <button
                        className="btn cancelBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={
                          !newEducationDetail.degreeType ||
                          !newEducationDetail.university ||
                          !newEducationDetail.startDate ||
                          ((!newEducationDetail.isPresent ||
                            newEducationDetail.endDate) &&
                            (newEducationDetail.isPresent ||
                              !newEducationDetail.endDate))
                        }
                        className="btn inviteBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          let data = {
                            accountId: userInfo._id,
                            qualification: [
                              ...form.candidateProfileId.qualification,
                              newEducationDetail,
                            ],
                          };
                          setNewEducationDetail({
                            university: "",
                            degreeType: "",
                            startDate: "",
                            endDate: "",
                            isPresent: false,
                            description: "",
                          });
                          props.setIsUniversitySelected(false);
                          onSubmit(e, data, false);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Education Modal Ends */}
        {/* Edit All Education Modal Starts */}
        <div
          class="modal fade commonModal editEducationModal"
          id="editEducationModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Edit Education</h3>
                  <div className="candidatemidleft">
                    <div className="candidatemidabout">
                      {form &&
                        form.candidateProfileId &&
                        form.candidateProfileId.qualification &&
                        form.candidateProfileId.qualification.map(
                          (item, index) => {
                            return (
                              <>
                                <div className="eduDetail">
                                  <div className="actionBtns">
                                    <button
                                      className="btn"
                                      data-bs-target="#editEducationModalSingle"
                                      data-bs-toggle="modal"
                                      onClick={() => {
                                        setEditEducationIndex(index);
                                        setNewEducationDetail(item);
                                      }}
                                    >
                                      <img src={editIcon} />
                                    </button>
                                  </div>
                                  <div className="row">
                                    <div className="col-xxl-2 col-xl-2 col-lg-3">
                                      <img src={Anthropology} alt="" />
                                    </div>
                                    <div className="col-xxl-10 col-xl-10 col-lg-9">
                                      <h3>{item.degreeType}</h3>
                                      <p>
                                        {item.university} (
                                        {getYear(item.startDate)} -{" "}
                                        {/* {item.endDate
                                          ? getYear(item.endDate)
                                          : "Present"} */}
                                        {!item.isPresent
                                          ? getYear(item.endDate)
                                          : "Present"}
                                        )
                                      </p>
                                    </div>
                                  </div>
                                  <p>{item.description}</p>
                                </div>
                                <div className="divider"></div>
                              </>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className="formBtns">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#addEducationModal"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Edit All Education Modal Ends */}
        {/* Edit single education modal start */}
        <div
          class="modal fade commonModal editEducationModalSingle"
          id="editEducationModalSingle"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            onClick={() => {
              props.setIsUniversitySelected(false);
            }}
          >
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setNewEducationDetail({
                      university: "",
                      degreeType: "",
                      startDate: "",
                      isPresent: false,
                      endDate: "",
                      description: "",
                    });
                  }}
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Edit Education</h3>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              props.setIsUniversitySelected(true);
                              setNewEducationDetail((prevData) => {
                                prevData.university = e.target.value;
                                prevData.universityLogo = "";
                                return {
                                  ...prevData,
                                };
                              });
                              props.onUniversitySearchHandler(e.target.value);
                            }}
                            value={newEducationDetail.university}
                            type="text"
                            className="form-control"
                            placeholder="College/University"
                          />
                          {
                            <ul>
                              {props.isUniverSelected &&
                                props.universityList &&
                                props.universityList.map((item, index) => {
                                  return (
                                    <li
                                      style={{ cursor: "pointer" }}
                                      key={index}
                                      onClick={() => {
                                        props.setIsUniversitySelected(false);
                                        setNewEducationDetail((prevData) => {
                                          prevData.university = item.name;
                                          prevData.universityLogo = item.logo;
                                          return {
                                            ...prevData,
                                          };
                                        });
                                      }}
                                    >
                                      <img
                                        src={item.logo}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          borderRadius: "15px",
                                          marginRight: "13px",
                                        }}
                                      />
                                      {item.name}
                                    </li>
                                  );
                                })}
                            </ul>
                          }
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              props.setIsUniversitySelected(false);
                              setNewEducationDetail((prevData) => {
                                prevData.degreeType = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newEducationDetail.degreeType}
                            type="text"
                            className="form-control"
                            placeholder="Degree"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <DatePicker
                            showYearDropdown
                            showMonthYearPicker
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            placeholderText="Start Date*"
                            dateFormat="MM/yyyy"
                            customInput={
                              <CustomDatePickerInput placeholder="Start Date*" />
                            }
                            selected={parseInt(newEducationDetail.startDate)}
                            onChange={(date) => {
                              setNewEducationDetail((prevData) => {
                                prevData.startDate = date.getTime().toString();
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                          />
                          {/* <input
                            onChange={(e) => {
                              setNewEducationDetail((prevData) => {
                                prevData.startDate = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newEducationDetail.startDate}
                            type="date"
                            className="form-control"
                            placeholder="Start Date"
                          /> */}
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <DatePicker
                            showYearDropdown
                            showMonthYearPicker
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            placeholderText="End Date*"
                            customInput={
                              <CustomDatePickerInput placeholder="End Date*" />
                            }
                            dateFormat="MM/yyyy"
                            // showIcon
                            selected={parseInt(newEducationDetail.endDate)}
                            onChange={(date) => {
                              setNewEducationDetail((prevData) => {
                                prevData.endDate = date.getTime().toString();
                                prevData.isPresent = false;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                          />
                          {/* <input
                            onChange={(e) => {
                              setNewEducationDetail((prevData) => {
                                prevData.endDate = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newEducationDetail.endDate}
                            type="date"
                            className="form-control"
                            placeholder="End Date"
                          /> */}
                        </div>
                        <div className="form-check mb-4 text-end">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkbox"
                              checked={newEducationDetail.isPresent}
                              onChange={handlePresentEducation}
                            />
                            Present
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <textarea
                            onChange={(e) => {
                              setNewEducationDetail((prevData) => {
                                prevData.description = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newEducationDetail.description}
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="formBtns">
                      <button
                        className="btn cancelBtn"
                        data-bs-target="#deleteModal"
                        data-bs-toggle="modal"
                      >
                        Delete
                      </button>
                      <button
                        disabled={
                          !newEducationDetail.degreeType ||
                          !newEducationDetail.university ||
                          !newEducationDetail.startDate ||
                          ((!newEducationDetail.isPresent ||
                            newEducationDetail.endDate) &&
                            (newEducationDetail.isPresent ||
                              !newEducationDetail.endDate))
                        }
                        className="btn inviteBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          form.candidateProfileId.qualification[
                            editEducationIndex
                          ] = newEducationDetail;
                          let data = {
                            accountId: userInfo._id,
                            qualification:
                              form.candidateProfileId.qualification,
                          };
                          setNewEducationDetail({
                            university: "",
                            degreeType: "",
                            startDate: "",
                            isPresent: false,
                            endDate: "",
                            description: "",
                          });
                          setEditEducationIndex(null);
                          props.setIsUniversitySelected(false);
                          onSubmit(e, data, false);
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Edit single Education Modal Ends */}
        {/* Delete Education Modal Starts */}
        <div
          class="modal fade commonModal addEducationModal"
          id="deleteModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Delete Education</h3>
                  <img src={iconDelete} />
                  <p>
                    Are you sure you want to delete your <br /> education?
                  </p>

                  <div className="formBtns">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => {
                        form.candidateProfileId.qualification.splice(
                          editEducationIndex,
                          1
                        );
                        let data = {
                          accountId: userInfo._id,
                          qualification: form.candidateProfileId.qualification,
                        };

                        onSubmit(e, data, false);
                      }}
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* delete Education Modal Ends */}
        {/* ---------------------------------------------------------------------------Education Modal End ------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ---------------------------------------------------------------------------Experience Modal start ------------------------------------------------------------------------------------------------------------------------------ */}
        {/*Edit all  Education Modal Starts */}
        <div
          class="modal fade commonModal editEducationModal"
          id="editExperienceModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Edit Experience</h3>
                  <div className="candidatemidleft">
                    <div className="candidatemidabout">
                      {form &&
                        form?.candidateProfileId &&
                        form.candidateProfileId.workExperience &&
                        form.candidateProfileId.workExperience.map(
                          (item, index) => {
                            return (
                              <>
                                <div className="eduDetail">
                                  <div className="actionBtns">
                                    <button
                                      className="btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editExperienceModalSingle"
                                      onClick={() => {
                                        setNewExperienceDetails(item);
                                        setEditExperienceIndex(index);
                                      }}
                                    >
                                      <img className="mb-0" src={editIcon} />
                                    </button>
                                  </div>
                                  <p className="mb-0 experienceHead">
                                    {item.designation}
                                  </p>

                                  <p>
                                    {item.companyName} (
                                    {getYear(item.startDate)} -{" "}
                                    {!item.isPresent
                                      ? getYear(item.endDate)
                                      : "Present"}
                                    )
                                    {/* {item.endDate
                                      ? getYear(item.endDate)
                                      : "Present"} */}
                                    ){/* {getYear(item.endDate)}) */}
                                  </p>
                                  <p>{item.description}</p>
                                </div>
                                <div className="divider"></div>
                              </>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className="formBtns">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#addExperienceModalSingle"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Edit all  Education Modal Ends */}
        {/* Add Experience Modal Starts */}
        <div
          class="modal fade commonModal addExperienceModalSingle"
          id="addExperienceModalSingle"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            onClick={() => {
              props.setIsCompanySelected(false);
            }}
          >
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Add Experience</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              props.setIsCompanySelected(true);
                              setNewExperienceDetails((prevData) => {
                                prevData.companyName = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                              props.onCompanyHandler(e.target.value);
                            }}
                            value={newExperienceDetails.companyName}
                            type="text"
                            className="form-control"
                            placeholder="Company Name"
                          />
                          <ul>
                            {props.isCompanySelected &&
                              props.companyList &&
                              props.companyList.length > 0 &&
                              props.companyList.map((item, index) => {
                                return (
                                  <li
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    onClick={() => {
                                      props.setIsCompanySelected(false);
                                      setNewExperienceDetails((prevData) => {
                                        prevData.companyName = item.firmName;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                  >
                                    {item.firmName}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          {/* <select className="form-control">
                            <option>Designation</option>
                            <option>Designation</option>
                            <option>Designation</option>
                            <option>Designation</option>
                          </select> */}
                          <input
                            // onChange={(e) => {
                            //   setForm((prevData) => {
                            //     let workExperience = [
                            //       ...prevData.workExperience,
                            //     ];
                            //     workExperience[index].designation =
                            //       e.target.value;
                            //     return {
                            //       ...prevData,
                            //       workExperience,
                            //     };
                            //   });
                            // }}
                            // value={form.workExperience[index]?.designation}
                            onChange={(e) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.designation = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.designation}
                            type="text"
                            className="form-control"
                            placeholder="Position"
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          {/* <input
                            onChange={(e) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.startDate = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.startDate}
                            type="date"
                            className="form-control"
                            placeholder="Start Date"
                          /> */}
                          <DatePicker
                            showYearDropdown
                            showMonthYearPicker
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            placeholderText="Start Date*"
                            dateFormat="MM/yyyy"
                            customInput={
                              <CustomDatePickerInput placeholder="Start Date*" />
                            }
                            // showIcon
                            selected={parseInt(newExperienceDetails.startDate)}
                            onChange={(date) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.startDate = date.getTime().toString();
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          {/* <input
                            onChange={(e) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.endDate = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.endDate}
                            type="date"
                            className="form-control"
                            placeholder="End Date"
                          /> */}
                          <DatePicker
                            showYearDropdown
                            showMonthYearPicker
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            placeholderText="End Date*"
                            customInput={
                              <CustomDatePickerInput placeholder="End Date*" />
                            }
                            dateFormat="MM/yyyy"
                            // showIcon
                            selected={parseInt(newExperienceDetails.endDate)}
                            onChange={(date) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.endDate = date.getTime().toString();
                                prevData.isPresent = false;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="form-check mb-4 text-end">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkbox"
                              checked={newExperienceDetails.isPresent}
                              onChange={handlePresentExperience}
                            />
                            Present
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <textarea
                            onChange={(e) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.description = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.description}
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="formBtns">
                      <button
                        className="btn cancelBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                      <button
                        disabled={
                          !newExperienceDetails.companyName ||
                          !newExperienceDetails.designation ||
                          !newExperienceDetails.startDate ||
                          ((!newExperienceDetails.isPresent ||
                            newExperienceDetails.endDate) &&
                            (newExperienceDetails.isPresent ||
                              !newExperienceDetails.endDate))
                        }
                        className="btn inviteBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          let data = {
                            accountId: userInfo._id,
                            workExperience: [
                              ...form.candidateProfileId.workExperience,
                              newExperienceDetails,
                            ],
                          };
                          setNewExperienceDetails({
                            companyName: "",
                            designation: "",
                            isPresent: false,
                            startDate: "",
                            endDate: "",
                            description: "",
                          });
                          props.setIsCompanySelected(true);
                          onSubmit(e, data, false);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Experience Modal Ends */}
        {/* Edit Experience Modal Starts */}
        <div
          class="modal fade commonModal editExperienceModalSingle"
          id="editExperienceModalSingle"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            onClick={() => {
              props.setIsCompanySelected(false);
            }}
          >
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setNewExperienceDetails({
                      companyName: "",
                      designation: "",
                      startDate: "",
                      isPresent: false,
                      endDate: "",
                      description: "",
                    });
                  }}
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Edit Experience</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              props.setIsCompanySelected(true);
                              setNewExperienceDetails((prevData) => {
                                prevData.companyName = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.companyName}
                            type="text"
                            className="form-control"
                            placeholder="Company Name"
                          />
                          <ul>
                            {props.isCompanySelected &&
                              props.companyList &&
                              props.companyList.length > 0 &&
                              props.companyList.map((item, index) => {
                                return (
                                  <li
                                    style={{ cursor: "pointer" }}
                                    key={index}
                                    onClick={() => {
                                      props.setIsCompanySelected(false);
                                      setNewExperienceDetails((prevData) => {
                                        prevData.companyName = item.firmName;
                                        return {
                                          ...prevData,
                                        };
                                      });
                                    }}
                                  >
                                    {item.firmName}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.designation = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.designation}
                            type="text"
                            className="form-control"
                            placeholder="Position"
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          {/* <input
                            onChange={(e) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.startDate = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.startDate}
                            type="date"
                            className="form-control"
                            placeholder="Start Date"
                          /> */}
                          <DatePicker
                            showYearDropdown
                            showMonthYearPicker
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            placeholderText="Start Date*"
                            dateFormat="MM/yyyy"
                            customInput={
                              <CustomDatePickerInput placeholder="Start Date*" />
                            }
                            selected={parseInt(newExperienceDetails.startDate)}
                            onChange={(date) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.startDate = date.getTime().toString();
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          {/* <input
                            onChange={(e) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.endDate = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.endDate}
                            type="date"
                            className="form-control"
                            placeholder="End Date"
                          /> */}
                          <DatePicker
                            showYearDropdown
                            showMonthYearPicker
                            dateFormatCalendar="MMMM"
                            yearDropdownItemNumber={15}
                            placeholderText="End Date*"
                            customInput={
                              <CustomDatePickerInput placeholder="End Date*" />
                            }
                            dateFormat="MM/yyyy"
                            // scrollableYearDropdown
                            // showIcon
                            selected={parseInt(newExperienceDetails.endDate)}
                            onChange={(date) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.endDate = date.getTime().toString();
                                prevData.isPresent = false;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                          />
                        </div>
                        <div className="form-check mb-4 text-end">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="checkbox"
                              checked={newExperienceDetails.isPresent}
                              onChange={handlePresentExperience}
                            />
                            Present
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <textarea
                            onChange={(e) => {
                              setNewExperienceDetails((prevData) => {
                                prevData.description = e.target.value;
                                return {
                                  ...prevData,
                                };
                              });
                            }}
                            value={newExperienceDetails.description}
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="formBtns">
                      <button
                        className="btn cancelBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModalExperience"
                      >
                        Delete
                      </button>
                      <button
                        disabled={
                          !newExperienceDetails.companyName ||
                          !newExperienceDetails.designation ||
                          !newExperienceDetails.startDate ||
                          ((!newExperienceDetails.isPresent ||
                            newExperienceDetails.endDate) &&
                            (newExperienceDetails.isPresent ||
                              !newExperienceDetails.endDate))
                        }
                        className="btn inviteBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          form.candidateProfileId.workExperience[
                            editExperienceIndex
                          ] = newExperienceDetails;
                          let data = {
                            accountId: userInfo._id,
                            workExperience:
                              form.candidateProfileId.workExperience,
                          };
                          setNewExperienceDetails({
                            companyName: "",
                            designation: "",
                            startDate: "",
                            isPresent: false,
                            endDate: "",
                            description: "",
                          });
                          setEditExperienceIndex(null);
                          props.setIsCompanySelected(false);
                          onSubmit(e, data, false);
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Edit Experience Modal Ends */}
        {/* Delete Education Modal Starts */}
        <div
          class="modal fade commonModal addEducationModal"
          id="deleteModalExperience"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Delete Experience</h3>
                  <img src={iconDelete} style={{ width: "16%" }} />
                  <p>
                    Are you sure you want to delete your <br /> experience?
                  </p>

                  <div className="formBtns">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => {
                        form.candidateProfileId.workExperience.splice(
                          editExperienceIndex,
                          1
                        );
                        let data = {
                          accountId: userInfo._id,
                          workExperience:
                            form.candidateProfileId.workExperience,
                        };

                        onSubmit(e, data, false);
                      }}
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* delete Education Modal Ends */}
        {/* ---------------------------------------------------------------------------Experience Modal End ------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ---------------------------------------------------------------------------Skill Modal End ------------------------------------------------------------------------------------------------------------------------------ */}
        {/* Add One Skills start*/}
        <div
          class="modal fade commonModal skillsModal"
          id="skillsModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Add Skill</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    {newSkill &&
                      newSkill.length > 0 &&
                      newSkill.map((item, index) => {
                        return (
                          <div className="row">
                            <div className="col-xl-6">
                              <div className="form-group">
                                <input
                                  onChange={(e) => {
                                    //  let data=
                                    //   setNewSkill({
                                    //     ...newSkill,
                                    //     skillSet: e.target.value,
                                    //   });
                                    setNewSkill((prev) => {
                                      prev[index].skillSet = e.target.value;
                                      return [...prev];
                                    });
                                  }}
                                  value={newSkill[index].skillSet}
                                  type="text"
                                  className="form-control"
                                  placeholder="Add Skill"
                                />
                              </div>
                            </div>
                            {index == skillsCount - 1 && (
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <div className="text-start">
                                    <button
                                      disabled={
                                        newSkill &&
                                        newSkill.length > 0 &&
                                        newSkill.some(
                                          (item) => !item.skillSet
                                          //  || !item.proficiencyGrade
                                        )
                                      }
                                      className="btn appentBtn"
                                      onClick={(event) => {
                                        // const increaseSkillCount = (event) => {
                                        event.preventDefault();
                                        setSkillsCount((prev) => prev + 1);
                                        setNewSkill([
                                          ...newSkill,
                                          { skillSet: "" },
                                        ]);
                                      }}
                                    >
                                      Add Additional Skills
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}

                    {/* <div className="tagSuggesion">
                      <p>Suggested based on your profile</p>
                      {existingSkills
                        ? suggestedSkills
                            .filter(
                              (filterItem) =>
                                !existingSkills.includes(filterItem)
                            )
                            .map((item) => (
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (!newSkill[newSkill.length - 1].skillSet) {
                                    setNewSkill((prev) => {
                                      prev[prev.length - 1].skillSet = item;
                                      return [...prev];
                                    });
                                  } else {
                                    setSkillsCount((prev) => prev + 1);
                                    setNewSkill([
                                      ...newSkill,
                                      { skillSet: item },
                                    ]);
                                  }
                                }}
                              >
                                {item}
                              </span>
                            ))
                        : suggestedSkills.map((item) => (
                            <span style={{ cursor: "pointer" }}>{item}</span>
                          ))}
                    </div> */}
                    <div className="formBtns">
                      <button
                        className="btn cancelBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setSkillsCount(1);
                          setNewSkill([
                            {
                              skillSet: "",
                            },
                          ]);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={
                          newSkill &&
                          newSkill.length > 0 &&
                          newSkill.some((item) => !item.skillSet)
                        }
                        className="btn inviteBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={(e) => {
                          let data = {
                            accountId: userInfo._id,
                            // skills: newSkill,
                            skills: [
                              ...form.candidateProfileId.skills,
                              ...newSkill,
                            ],
                          };
                          setNewSkill({});
                          onSubmit(e, data, false);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add One Skills end */}
        <div
          class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
          id="additionalSkillModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-5">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Edit Skill</h3>
                  <form>
                    {form &&
                      form.candidateProfileId &&
                      form.candidateProfileId.skills &&
                      form.candidateProfileId.skills.length > 0 &&
                      form.candidateProfileId.skills.map((value, index) => {
                        return (
                          <div className="row">
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <input
                                  onChange={(e) => {
                                    setForm((prev) => {
                                      prev.candidateProfileId.skills[
                                        index
                                      ].skillSet = e.target.value;
                                      return { ...prev };
                                    });
                                  }}
                                  value={
                                    form.candidateProfileId.skills[index]
                                      .skillSet
                                  }
                                  type="text"
                                  className="form-control"
                                  placeholder="Add Skill"
                                />
                              </div>
                            </div>

                            <div className="col-lg-2 col-md-2">
                              <div className="form-group delereRow">
                                {/* <input
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/\D/g);
                                    if (
                                      value === "" ||
                                      (parseInt(value) <= 100 &&
                                        parseInt(value) >= 1)
                                    ) {
                                      setForm((prev) => {
                                        prev.candidateProfileId.skills[
                                          index
                                        ].proficiencyGrade = value;
                                        return { ...prev };
                                      });
                                    }
                                  }}
                                  value={
                                    form.candidateProfileId.skills[index]
                                      .proficiencyGrade
                                  }
                                  type="text"
                                  className="form-control"
                                  placeholder="Proficiency Grade (%)"
                                /> */}

                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setForm((prev) => {
                                      prev.candidateProfileId.skills.splice(
                                        index,
                                        1
                                      );
                                      return { ...prev };
                                    });
                                  }}
                                  src={DeleteIcon}
                                  className="iconSkillDelete"
                                />
                              </div>
                            </div>
                            {/* {index == skillsCount - 1 && (
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <div className="text-start">
                                    <button
                                      className="btn appentBtn"
                                      onClick={(event) => {
                                        increaseSkillCount(event);
                                      }}
                                    >
                                      Add Additional Skills
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )} */}
                          </div>
                        );
                      })}
                    {/* <div className="col-lg-6">
                      <div className="suggesion">
                        <h3>Suggested based on your profile</h3>
                        {suggestedSkills
                          .filter(
                            (filterItem) => !existingSkills.includes(filterItem)
                          )
                          .map((item) => (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={(e) => onSuggestedSkillHandler(e, item)}
                            >
                              {item}
                            </span>
                          ))}
                        <span>Sales</span>
                        <span>Marketing</span>
                        <span>Direct Sales</span>
                        <span>Management</span>
                      </div>
                    </div> */}
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      disabled={
                        form &&
                        form.candidateProfileId &&
                        form.candidateProfileId.skills &&
                        form.candidateProfileId.skills.length &&
                        form.candidateProfileId.skills.some(
                          (item) => !item.skillSet
                          // || !item.proficiencyGrade
                        )
                      }
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => {
                        let data = {
                          accountId: userInfo._id,
                          skills: form.candidateProfileId.skills,
                        };
                        onSubmit(e, data, false);
                      }}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Edit All Skills modal end */}
        {/* ---------------------------------------------------------------------------Skill Modal End ------------------------------------------------------------------------------------------------------------------------------ */}
        {/* custom value prompt starts */}
        <div
          class="modal fade commonModal postingModal"
          id="questionModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header pb-1">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading">Custom Value Prompt</h3>
                  <ul className="questionsList">
                    <li>
                      {form &&
                        form.customValuePrompt &&
                        form.customValuePrompt.length > 0 &&
                        form.customValuePrompt.map((item, index, arrayList) => {
                          let threePromptFilled = arrayList.filter(
                            (item) => item.a
                          ).length;
                          return (
                            <React.Fragment>
                              <h3>
                                {item.q}
                                <button
                                  data-bs-target="#valuePromptAnswer"
                                  data-bs-toggle="modal"
                                >
                                  <img
                                    src={editIcon}
                                    onClick={() => {
                                      setCurrentCustomValuePrompt(index);
                                      //  const uploadImageModal =
                                      //     new window.bootstrap.Modal(
                                      //       document.getElementById(
                                      //         "valuePromptAnswer"
                                      //       )
                                      //     );
                                      //   uploadImageModal.show();
                                    }}
                                  />
                                </button>
                              </h3>
                              <p>{item.a}</p>
                            </React.Fragment>
                          );
                        })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* custom value prompt modal ends */}
        {/* custom value prompt answer modal starts */}
        {form &&
          form.customValuePrompt &&
          form.customValuePrompt.length > 0 && (
            <div
              class="modal fade commonModal companyProfileImg"
              id="valuePromptAnswer"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabindex="-1"
            >
              <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                    {/* <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button> */}
                  </div>
                  <div class="modal-body">
                    <div className="commonModalContent px-4">
                      <h3 className="ModalHeading mb-5">{}</h3>

                      <form className="mt-5">
                        <div className="row">
                          <div className="col-xl-12">
                            <div className="form-group">
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                placeholder=""
                                value={
                                  form &&
                                  form.customValuePrompt &&
                                  form.customValuePrompt[
                                    currentCustomValuePropmt
                                  ] &&
                                  form.customValuePrompt[
                                    currentCustomValuePropmt
                                  ].q
                                }
                              />
                            </div>
                          </div>
                          <div className="col-xl-12">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                value={
                                  form &&
                                  form.customValuePrompt &&
                                  form.customValuePrompt[
                                    currentCustomValuePropmt
                                  ] &&
                                  form.customValuePrompt[
                                    currentCustomValuePropmt
                                  ].a
                                }
                                onChange={(e) => {
                                  // let threePromptFilled =
                                  //   form &&
                                  //   form.customValuePrompt &&
                                  //   form.customValuePrompt.filter(
                                  //     (item) => item.a
                                  //   ).length;
                                  // if (threePromptFilled >= 3) {
                                  setForm((prev) => {
                                    prev.customValuePrompt[
                                      currentCustomValuePropmt
                                    ].a = e.target.value;
                                    return { ...prev };
                                  });
                                  // }
                                }}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="formBtns mt-5 mb-4">
                        <button
                          className="btn cancelBtn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                        <button
                          className="btn inviteBtn"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={(e) => {
                            let data = {
                              accountId: userInfo._id,
                              customValuePrompt: form.customValuePrompt,
                            };
                            onSubmit(e, data, false);
                            setCurrentCustomValuePrompt(null);
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        {/* custom value prompt answer modal ends */}
        {/* Image upload modal start  */}
        <div
          class="modal fade commonModal editEducationModal confirmationModal"
          id="confirmationModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <img src={checkMark} />
                  <p>New Display Image Upload Successfully</p>
                  <div className="formBtns mt-0">
                    <button className="btn cancelBtn" data-bs-dismiss="modal">
                      Go Back
                    </button>
                    <button className="btn inviteBtn" data-bs-dismiss="modal">
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Image upload modal end */}
        {/* Profile image upload modal start  */}
        <div
          class="modal fade commonModal editEducationModal profileImageModal"
          id="profileImageModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <img src={checkMark} />
                  <p>New Profile Image Upload Successfully</p>
                  <div className="formBtns mt-0">
                    <button className="btn cancelBtn" data-bs-dismiss="modal">
                      Go Back
                    </button>
                    <button className="btn inviteBtn" data-bs-dismiss="modal">
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Profile Image upload modal end */}
        {/* All Data Field  modal starts */}
        <div
          class="modal fade commonModal companyProfileImg"
          id="postingModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              {/* <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div> */}
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-5 mt-4">
                    You're all done for now! 🎉
                  </h3>

                  <img
                    className="mb-5"
                    style={{ width: "40%" }}
                    src={invitePopupImg}
                  />

                  <h4 className="inviteSubText">
                    In the meantime invite your friends and{" "}
                    <span>
                      Earn entries for a full university scholarship giveaway!
                    </span>
                  </h4>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn inviteBtn w-100"
                      data-bs-dismiss="modal"
                      onClick={(e) => {
                        let data = {
                          accountId: userInfo._id,
                          profileCompleted: true,
                        };
                        onSubmit(e, data, false);
                        setTimeout(() => {
                          navigate("/affiliates");
                        }, 1000);
                      }}
                    >
                      Invite your friends to join Hirebird
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* All Data Field modal ends */}
        <div
          class="modal fade commonModal addEducationModal"
          id="deleteconfirmationModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Delete Image</h3>
                  <img src={iconDelete} />
                  <p>Your display image has been delete.</p>

                  <div className="btnSection text-center">
                    <button
                      className="btn btn-save"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      OK
                    </button>
                    {/* <button
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      Yes, Delete
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Image Upload Start*/}
        <div
          class="modal fade commonModal editExperienceModalSingle"
          id="customImageUpload"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Upload/edit your profile picture!</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row imageUploaderSection">
                      {/* if (e) {
                                  const file = e.target.files;
                                  if (
                                    file &&
                                    file[0] &&
                                    file[0].type &&
                                    file[0].type.split("/")[0] == "image"
                                  ) {
                                    // setIsLoading(true);
                                    setPreviewImage({
                                      ...previewImage,
                                      displayImage: URL.createObjectURL(
                                        file[0]
                                      ),
                                    });
                                    setDisplayImage(file[0]);
                                    setIsDisplayImageDeleted(false);
                                    try {
                                      let formData = new FormData();
                                      formData.append("displayImage", file[0]);
                                      formData.append(
                                        "accountId",
                                        userInfo._id
                                      );
                                      let res = await apiPutMethod(
                                        allApiUrl.updateProfileCandidate,
                                        formData,
                                        header
                                      );
                                      if (res.status == 200) {
                                        // setIsLoading(false);
                                        const uploadImageModal =
                                          new window.bootstrap.Modal(
                                            document.getElementById(
                                              "confirmationModal"
                                            )
                                          );
                                        uploadImageModal.show();
                                        setIsProfileUpdated(!isProfileUpdated);
                                      }
                                    } catch (err) {
                                      // setIsLoading(false);
                                    }
                                  } else {
                                    // setIsLoading(false);
                                    toastMessages(
                                      "error",
                                      "The image format is not correct"
                                    );
                                  }
                                } */}

                      <ImageUpload
                        preview={props.previewImage}
                        setPreview={props.setPreviewImage}
                        setImage={props.setDisplayImage}
                        setRefresh={props.setIsProfileUpdated}
                        refresh={props.isProfileUpdated}
                        type="displayImage"
                        dismissModal={props.dismissModal}
                        setDismissModal={props.setDismissModal}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Image Upload End */}
        {/* Image Upload for profile Start*/}
        <div
          class="modal fade commonModal editExperienceModalSingle"
          id="customImageUpload1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Upload Image</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="imageUploaderSection">
                      <ImageUpload
                        preview={props.previewImage}
                        setPreview={props.setPreviewImage}
                        setImage={props.setDisplayImage}
                        setRefresh={props.setIsProfileUpdated}
                        refresh={props.isProfileUpdated}
                        type="file"
                        dismissModal={props.dismissModal}
                        setDismissModal={props.setDismissModal}
                      />
                    </div>
                    {/* <div className="row"> */}
                    {/* if (e) {
                                  const file = e.target.files;
                                  if (
                                    file &&
                                    file[0] &&
                                    file[0].type &&
                                    file[0].type.split("/")[0] == "image"
                                  ) {
                                    // setIsLoading(true);
                                    setPreviewImage({
                                      ...previewImage,
                                      displayImage: URL.createObjectURL(
                                        file[0]
                                      ),
                                    });
                                    setDisplayImage(file[0]);
                                    setIsDisplayImageDeleted(false);
                                    try {
                                      let formData = new FormData();
                                      formData.append("displayImage", file[0]);
                                      formData.append(
                                        "accountId",
                                        userInfo._id
                                      );
                                      let res = await apiPutMethod(
                                        allApiUrl.updateProfileCandidate,
                                        formData,
                                        header
                                      );
                                      if (res.status == 200) {
                                        // setIsLoading(false);
                                        const uploadImageModal =
                                          new window.bootstrap.Modal(
                                            document.getElementById(
                                              "confirmationModal"
                                            )
                                          );
                                        uploadImageModal.show();
                                        setIsProfileUpdated(!isProfileUpdated);
                                      }
                                    } catch (err) {
                                      // setIsLoading(false);
                                    }
                                  } else {
                                    // setIsLoading(false);
                                    toastMessages(
                                      "error",
                                      "The image format is not correct"
                                    );
                                  }
                                } */}

                    {/* </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Image Upload End for profile ends*/}
      </div>
    </>
  );
}

export default ProfileModal;
