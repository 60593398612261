// ---------------------Regrex-------------------
const onlyNumber = "^[0-9]+$";
export const allRegrex = { onlyNumber };

export function getYear(value) {
  var d = new Date(parseInt(value));
  return d.getFullYear();
}
// Last Job Posted
export const calculateTimeAgo = (timestamp) => {
  let result;
  const currentTime = new Date();
  const previousTime = new Date(timestamp);
  const timeDifference = currentTime - previousTime;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);

  if (seconds < 60) {
    result = `${seconds} second${seconds === 1 ? "" : "s"} ago`;
  } else if (minutes === 1) {
    result = "1 minute ago";
  } else if (minutes < 60) {
    result = `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (hours === 1) {
    result = "1 hour ago";
  } else if (hours < 24) {
    result = `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else if (days === 1) {
    result = "1 day ago";
  } else if (days < 7) {
    result = `${days} day${days === 1 ? "" : "s"} ago`;
  } else if (weeks === 1) {
    result = "1 week ago";
  } else if (weeks < 4) {
    result = `${weeks} week${weeks === 1 ? "" : "s"} ago`;
  } else if (months === 1) {
    result = "1 month ago";
  } else {
    result = `${months} month${months === 1 ? "" : "s"} ago`;
  }
  return result;
};

export function formatDate_mm_dd_yyyy(dateString, time = false) {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 since months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  let timeDisplay;
  if (time) {
    const time = new Date(dateString);
    let hours = time.getHours();
    let minutes = time.getMinutes();
    timeDisplay = ` ${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  }
  return `${month}/${day}/${year} ${
    timeDisplay !== undefined ? timeDisplay : ""
  }`;
}
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const removeModalBackground = () => {
  document
    .getElementById("confirmModalOneTime")
    .classList.remove("show", "d-block");
  document
    .querySelectorAll(".modal-backdrop")
    .forEach((el) => el.classList.remove("modal-backdrop"));
};
