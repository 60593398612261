import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import signinImg from "../../assets/imgs/signinImg-cropped.svg";
import Logo from "../../assets/imgs/headerAdmin.svg";

import { useEffect, useState } from "react";
import { apiGetMethod, apiPostMethod } from "../../api/rest";
import { allApiUrl } from "../../api/apiRoutes";
import toastMessages from "../../components/toastMessage";
import axios from "axios";
import FullPageLoader from "../../components/fullPageLoader";
function AdminLogin() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [viewPassword, setViewPassword] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  async function onSubmit(e) {
    e.preventDefault();
    try {
      const response = await apiPostMethod(allApiUrl.adminLogin, form, "");
      if (response.status === 200) {
        let adminData = response.data.response;
        if (
          !response.data.response.isAdmin &&
          response.data.response.isEmployee
        ) {
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              ...adminData,
              userType: "employee",
            })
          );
        } else {
          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              ...adminData,
              userType: "admin",
            })
          );
          localStorage.setItem("accessToken", response.data.token);
          localStorage.setItem("isLoggedIn", true);
          navigate("/adminDashboard");
        }
      }
    } catch (err) {
      console.log("err", err);
      setisLoading(false);
      toastMessages("error", err.data.message);
    }
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((form) => ({ ...form, [name]: value }));
  };
  if (isLoading) return <FullPageLoader />;
  return (
    <>
      <section className="formSection roleSelectorMain">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="formSection__Img">
                <img src={signinImg} alt="" />
              </div>
            </div>
            <div className="col-lg-5 ms-auto">
              <div className="formSection__content">
                <div className="text-center mb-4">
                  <p
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    <img className="logoImg" src={Logo} alt="HireBird Logo" />
                  </p>
                </div>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      onSubmit(e);
                    }
                  }}
                >
                  {/* <h2>Sign in</h2> */}
                  <div className="form-group">
                    <input
                      required
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      value={form.email}
                      onChange={handleChange}
                      autocomplete="off"
                    />
                    <span>
                      <i className="fa-solid fa-envelope"></i>
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      required
                      name="password"
                      type={`${!viewPassword ? "text" : "password"}`}
                      className="form-control"
                      id="password"
                      placeholder="Enter Password"
                      value={form.password}
                      onChange={handleChange}
                      autocomplete="off"
                    />
                    <i
                      onClick={() => setViewPassword(!viewPassword)}
                      className={`toggle-password fa fa-fw fa-eye${
                        viewPassword ? "-slash" : ""
                      }`}
                    ></i>
                    <span>
                      <i className="fa-solid fa-lock"></i>
                    </span>
                  </div>

                  {/* <div className="passwordRemember">
                    <div className="form-check"></div>
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div> */}
                  <button
                    className="btn commonBtb"
                    onClick={onSubmit}
                    disabled={!form.email || !form.password ? true : false}
                  >
                    Sign in <i className="fa-solid fa-angles-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminLogin;
