import { BrowserRouter, Link, useNavigate } from "react-router-dom";

import Logo from "../assets/imgs/HireBIRD.png";
import DashboardIcon from "../assets/imgs/dashboardIcon.svg";
import jobIcon from "../assets/imgs/jobIcon.svg";
import applicantsIcon from "../assets/imgs/applicantsIcon.svg";
import messageIcon from "../assets/imgs/messageIcon.svg";
import settingIcon from "../assets/imgs/settingIcon.svg";
import whiteSearchIcon from "../assets/imgs/whiteSearchIcon.svg";
import rocketIcon from "../assets/imgs/rocketIcon.svg";
import inviteCharacterImg from "../assets/imgs/inviteCharacterImg.svg";
import employerInviteCharacter from "../assets/imgs/employerInviteCharacter.svg";
import forYouIcon from "../assets/imgs/forYouIcon.svg";
import profileIcon from "../assets/imgs/profileIcon.svg";
import match from "../assets/imgs/match.svg";
import backArrow from "../assets/imgs/backArrow.svg";
import { useState } from "react";
import Modal from "../components/modals/editProfileModlas";
import waitImg from "../assets/imgs/waitImg.svg";
const CandidateSidebar = ({
  currentPage,
  navigate,
  accountsDetail,
  referralpoints,
}) => {
  return (
    <>
      <ul>
        <li
          className="disabled"
          // style={{
          //   pointerEvents: "none",
          // }}
        >
          {/* <Link to="/"> */}
          <Link data-bs-toggle="modal" data-bs-target="#waitModal">
            <img src={match} alt="Match Icon" />
            Match
          </Link>
        </li>

        <li className={`${currentPage == "myProfile" ? "active" : ""}`}>
          <Link to="/my-profile">
            <img src={profileIcon} alt="My Profile Icon" />
            My Profile
          </Link>
        </li>

        <li
          className="disabled"
          // style={{
          //   pointerEvents: "none",
          // }}
        >
          {/* <Link to="/"> */}
          <Link data-bs-toggle="modal" data-bs-target="#waitModal">
            <img src={messageIcon} alt="Messages Icon" />
            Messages
          </Link>
        </li>

        <li
          className="disabled"
          // style={{
          //   pointerEvents: "none",
          // }}
        >
          {/* <Link to="/settings"> */}
          <Link data-bs-toggle="modal" data-bs-target="#waitModal">
            <img src={settingIcon} alt="Settings Icon" />
            Settings
          </Link>
        </li>

        <li
          className="disabled"
          // style={{
          //   pointerEvents: "none",
          // }}
        >
          {/* <Link to="/settings"> */}
          <Link data-bs-toggle="modal" data-bs-target="#waitModal">
            <img src={whiteSearchIcon} alt="Search Icon" />
            Search
          </Link>
        </li>
      </ul>
      {/* {accountsDetail && accountsDetail.referralpoints && ( */}
      {/* <div className="sidebarEntries">
        <p>{referralpoints ? referralpoints : 0} Entries</p>
      </div> */}
      {/* )} */}
      {/* <div className="upgradeSection">
        <img src={inviteCharacterImg} />
        <p>
          Invite your <span>friends, win a full scholarship</span>
        </p>
        <button className="btn" onClick={() => navigate("/affiliates")}>
          Share my link!
        </button>
      </div> */}
    </>
  );
};
const RecruiterSidebar = ({ currentPage, setCurrentPage, navigate }) => {
  return (
    <>
      <ul>
        <li
          className={`${
            process.env.REACT_APP_ENVIRONMENT == "production"
              ? "disabled"
              : currentPage == "dashboard"
              ? "active"
              : ""
          }`}
          style={{
            pointerEvents:
              process.env.REACT_APP_ENVIRONMENT == "production" ? "none" : "",
          }}
        >
          <Link
            to="/dashboard"
            // data-bs-toggle="modal" data-bs-target="#waitModal"
          >
            <img src={DashboardIcon} alt="Dashboard Icon" />
            Dashboard
          </Link>
        </li>

        <li className={`${currentPage == "createJob" ? "active" : ""}`}>
          <Link to="/create-job">
            <img src={jobIcon} alt="Job Icon" />
            Create Job
          </Link>
        </li>

        <li
          className={`${
            // process.env.REACT_APP_ENVIRONMENT == "production" ||
            // process.env.REACT_APP_ENVIRONMENT == "staging"
            // ?
            "disabled"
            // : currentPage == "applicants"
            // ? "active"
            // : ""
          }`}
          style={{
            pointerEvents: "none",
          }}
        >
          <Link to="/applicants">
            <img src={applicantsIcon} alt="Applicants Icon" />
            Applicants
          </Link>
        </li>

        <li
          className="disabled"
          // style={{
          //   pointerEvents: "none",
          // }}
        >
          <Link data-bs-toggle="modal" data-bs-target="#waitModal">
            <img src={messageIcon} alt="Messages Icon" />
            Messages
          </Link>
        </li>

        <li className={`${currentPage == "profile" ? "active" : ""}`}>
          <Link to="/profile">
            <img src={settingIcon} alt="Settings Icon" />
            Settings
          </Link>
        </li>

        <li
          className="disabled"
          // style={{
          //   pointerEvents: "none",
          // }}
        >
          <Link
            // to="/candidate-list"
            data-bs-toggle="modal"
            data-bs-target="#waitModal"
          >
            <img src={whiteSearchIcon} alt="Search Icon" />
            Search
          </Link>
        </li>
      </ul>

      <div className="sidebar__Progress">
        {/* <p>4 Credits Used</p>
        <div
          className="progress"
          role="progressbar"
          aria-label="Basic example"
          aria-valuenow="50"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div className="progress-bar" style={{ width: "50%" }}></div>
        </div>
        <p>6 Credits Remaining</p> */}
        {/* <div className="upgradeSection">
          <img src={employerInviteCharacter} />
          <p>
            Invite other <span>member of your company/team.</span>
          </p>
          <button className="btn" onClick={() => navigate("/affiliates")}>
            Share my link!
          </button>
        </div> */}
      </div>
    </>
  );
};
const AffilateSidebar = ({ userInfo }) => {
  return (
    <>
      <ul>
        <li className="active">
          <Link
            to={`${
              userInfo && userInfo.accountType == "jobseeker"
                ? "/my-profile"
                : "/profile"
            }`}
          >
            <img src={backArrow} alt="Match Icon" />
            Back To Profile
          </Link>
        </li>
      </ul>
    </>
  );
};
function Sidebar({
  activePage,
  accountsDetail,
  sidebarOpen,
  setSidebarOpen,
  referralpoints,
}) {
  const [currentPage, setCurrentPage] = useState(activePage);
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [isSidebarToggle, setIsSidebarToggle] = useState(false);
  let navigate = useNavigate();
  return (
    <>
      <div className={`sidebar ${sidebarOpen ? "active" : ""}`}>
        <Link
          to={`${
            userInfo && userInfo.accountType == "jobseeker"
              ? "/my-profile"
              : "/profile"
          }`}
        >
          <img src={Logo} />
        </Link>
        <div className="close" onClick={() => setSidebarOpen("")}></div>
        <div className="sidebar__Inner">
          {activePage == "affilate" ? (
            <AffilateSidebar userInfo={userInfo} />
          ) : userInfo && userInfo.accountType == "jobseeker" ? (
            <CandidateSidebar
              referralpoints={referralpoints}
              accountsDetail={accountsDetail}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              navigate={navigate}
            />
          ) : (
            <RecruiterSidebar
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              navigate={navigate}
            />
          )}
        </div>
        {/* <div className="sidebar__Progress">
          <p>4 Credits Used</p>
          <div
            className="progress"
            role="progressbar"
            aria-label="Basic example"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div className="progress-bar" style={{ width: "50%" }}></div>
          </div>
          <p>6 Credits Remaining</p>
        </div> */}
      </div>
      {/* <Modal /> */}

      {/* coming soon modal ends */}
      <div
        class="modal fade commonModal"
        id="waitModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="commonModalContent px-4">
                <img className="waitImg" src={waitImg} />

                <h3 className="waitText">
                  <span>Woah!</span> Slow down there. We're just as excited as
                  you are. New features are coming out <span>very soon</span>. Stay
                  in touch for further updates
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  coming soon modal ends */}
    </>
  );
}

export default Sidebar;
