import editLight from "../../../assets/imgs/editLight.svg";
import viewIcon from "../../../assets/imgs/viewIcon.svg";
const FirmTable = (props) => {
  return (
    <tbody>
      {props.userData &&
        props.userData.length > 0 &&
        props.userData.map((item, index) => {
          return (
            <tr>
              <td>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    checked={props.csvData.some(
                      (csvItem) => csvItem.id === item._id
                    )}
                    type="checkbox"
                    onClick={() => {
                      props.setCsvData((prev) => {
                        const existingIndex = prev.findIndex(
                          (csvItem) => csvItem.id === item._id
                        );
                        if (existingIndex !== -1) {
                          const updatedData = [
                            ...prev.slice(0, existingIndex),
                            ...prev.slice(existingIndex + 1),
                          ];
                          return updatedData;
                        } else {
                          let updatedItem = {
                            id: item._id,
                            "Firm Name ": item.firmName,
                            "Admin ": item.admin,
                            "Address ": item.address,
                            "Website Link": item.websiteLink,
                            "Created At": new Date(
                              item.createdAt
                            ).toLocaleDateString(),
                          };
                          return [...prev, updatedItem];
                        }
                      });
                    }}
                  />
                </div>
              </td>
              <td>{index + 1}</td>
              <td>{item.firmName}</td>
              <td>{item.admin}</td>
              <td>{item.address || " "}</td>
              <td>{item.websiteLink || ""}</td>
              <td>
                {item.createdAt
                  ? new Date(item.createdAt).toLocaleDateString()
                  : new Date(item.createdOn).toLocaleDateString()}
              </td>
              <td>
                <div className="actionBtns">
                  {/* <button className="btn">
                    <img src={editLight} />
                  </button> */}
                  <button
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#adminUserFirm"
                    onClick={() => props.setCurrModalPreviewData(item)}
                  >
                    <img src={viewIcon} />
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};
export default FirmTable;
