import { useState } from "react";
import postingpopup from "../../assets/imgs/postingpopup.svg";
import editIcon from "../../assets/imgs/editIcon.svg";
import iconDelete from "../../assets/imgs/iconDelete.svg";
import uploadIcon from "../../assets/imgs/uploadIcon.svg";
import starIcon from "../../assets/imgs/starRating.svg";
// import { areAllValuesFilled } from "../../utils/helper";
import whiteCross from "../../assets/imgs/whiteCross.svg";
import userIcon from "../../assets/imgs/user.svg";
import toastMessages from "../toastMessage";
import Rating from "react-rating";
import StarRating from "../starRating/startRating";
import dummyImg from "../../assets/imgs/dummyImg.jpg";
import ImageUpload from "../customImageEditor";

function NewModal(props) {
  let {
    form,
    setForm,
    createHiringAlertHandler,
    setPreviewHiriingAlertImage,
    previewHiringAlertImage,
    setIsHiringImageUploaded,
    testimonialObj,
    setTestimonialObj,
    previewEmployeeTestimonialImage,
    setPreviewEmployeeTestimonialImage,
    isEmployeeUploaded,
    setIsEmployeeUploaded,
    addTestimonalHandler,
    editTestimonalHandler,
    onDeleteHandler,
    profileCompleteHandler,
    onJobDeleteHandler,
    testimonialList,
    setIsNewImageUploaded,
    isNewImageUploaded,
    setPreviewImage,
  } = props;
  return (
    <>
      <div className="mainWrapper">
        {/* invite modal starts */}
        <div
          class="modal fade commonModal postingModal"
          id="recruiterCompleteModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              {/* <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div> */}
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-0 mt-4">
                    You’re all done for now! 🎉
                  </h3>
                  <p className="mt-2">
                    Our full release is set for March 2024, we’re eager and I
                    hope you are too!
                  </p>

                  <img
                    className="mb-4"
                    style={{ width: "50%" }}
                    src={postingpopup}
                  />

                  <h4 className="inviteSubText">
                    Create and a publish a job post to{" "}
                    <span>
                      earn a month of Hirebird Advanced features for free!{" "}
                      <small>($349 value)</small>
                    </span>
                  </h4>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn inviteBtn w-100"
                      data-bs-dismiss="modal"
                      onClick={(e) => profileCompleteHandler(e, true)}
                    >
                      Publish a job posting!
                    </button>
                  </div>
                  <p
                    data-bs-dismiss="modal"
                    style={{ cursor: "pointer" }}
                    className="text-end mb-0"
                    onClick={(e) => profileCompleteHandler(e, false)}
                  >
                    I will later
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* invite modal ends */}

        {/* custom value prompt starts */}
        <div
          class="modal fade commonModal postingModal"
          id="questionModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header pb-1">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading">Custom Value Prompt</h3>
                  <ul className="questionsList">
                    <li>
                      <h3>
                        Something that sets me apart from the crowd.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>

                    <li>
                      <h3>
                        A time i added measurable valuable for a client.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>

                    <li>
                      <h3>
                        A time i exceeded expectations.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>

                    <li>
                      <h3>
                        A time i elevated a company, culture or workplace.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>

                    <li>
                      <h3>
                        Here's why i have the skills to thrive in the work
                        place.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>

                    <li>
                      <h3>
                        A valuable lessons i learned from a mistakes.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>

                    <li>
                      <h3>
                        Something i'm passionate about.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>

                    <li>
                      <h3>
                        Why my profile should stand out.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>

                    <li>
                      <h3>
                        Why you shouldn't judge a book my it's cover.{" "}
                        <button
                          data-bs-target="#valuePromptAnswer"
                          data-bs-toggle="modal"
                        >
                          <img src={editIcon} />
                        </button>
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* custom value prompt modal ends */}
        {/* custom value prompt answer modal starts */}
        <div
          class="modal fade commonModal companyProfileImg"
          id="valuePromptAnswer"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-5">
                    Something that sets me apart from the crowd.
                  </h3>

                  <form className="mt-5">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <textarea className="form-control"></textarea>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button className="btn cancelBtn" data-bs-dismiss="modal">
                      Cancel
                    </button>
                    <button className="btn inviteBtn">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* custom value prompt answer modal ends */}

        {/* sent offer modal starts */}
        <div
          class="modal fade commonModal companyProfileImg"
          id="hiringAlertModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-5">{`Add/Edit Banner Image/Hiring Alert`}</h3>
                  <form className="mt-5">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            title="alertTitle"
                            value={form.hiringAlert.alertTitle}
                            onChange={(e) => {
                              setForm((prev) => {
                                prev.hiringAlert.alertTitle = e.target.value;
                                return { ...prev };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            title="alertDescription"
                            value={form.hiringAlert.alertDescription}
                            onChange={(e) => {
                              setForm((prev) => {
                                prev.hiringAlert.alertDescription =
                                  e.target.value;
                                return { ...prev };
                              });
                            }}
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-xl-12">
                        <div className="photoUploadSection">
                          <label>Hiring Call-To-Action (CTA) image</label>
                          <div className="dragDrop">
                            <input
                              type="file"
                              onChange={(e) => {
                                if (e) {
                                  const file = e.target.files;
                                  if (
                                    file &&
                                    file[0] &&
                                    file[0].type &&
                                    file[0].type.split("/")[0] == "image"
                                  ) {
                                    setIsHiringImageUploaded(true);
                                    setForm({
                                      ...form,
                                      hiringAlert: {
                                        ...form.hiringAlert,
                                        alertImage: file[0],
                                      },
                                    });
                                    setPreviewHiriingAlertImage(
                                      URL.createObjectURL(file[0])
                                    );
                                    // setIsNewImageUploaded(true);
                                  } else {
                                    toastMessages(
                                      "error",
                                      "The image format is not correct"
                                    );
                                  }
                                }
                              }}
                            />
                            <img src={uploadIcon} />
                            <p>
                              Drop your file here <br />{" "}
                              <span className="green">or Browser</span>
                            </p>
                            <p>
                              We Support <span className="white">JPG, PNG</span>{" "}
                              files. Make sure that your file is no more than
                              500 kb.{" "}
                            </p>
                          </div>
                        </div>
                        {previewHiringAlertImage && (
                          <img
                            className="uploadImg"
                            style={{
                              width: "145px",
                              borderRadius: "29px",
                              marginTop: "19px",
                              marginRight: " 20px",
                            }}
                            src={
                              previewHiringAlertImage
                                ? previewHiringAlertImage
                                : ""
                            }
                          />
                        )}
                        {previewHiringAlertImage && (
                          <span
                            onClick={() => {
                              setIsHiringImageUploaded(false);
                              setPreviewHiriingAlertImage("");
                              if (form && form.hiringAlert) {
                                setForm((prev) => {
                                  prev.hiringAlert.alertImage = "";
                                  return { ...prev };
                                });
                              }
                            }}
                          >
                            <img src={whiteCross} />
                          </span>
                        )}
                      </div>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button className="btn cancelBtn" data-bs-dismiss="modal">
                      No, Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      onClick={(e) => {
                        createHiringAlertHandler(e);
                      }}
                    >
                      {`${
                        !form.hiringAlert.alertTitle ? "Create" : "Edit"
                      } Alert`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* sent offer modal ends */}
        {/* testimonial modal starts */}
        <div
          class="modal fade commonModal"
          id="testimonial"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-5">
                    Add Employee Testimonials
                  </h3>

                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type=""
                              autoComplete="off"
                              placeholder="Name *"
                              value={testimonialObj.name}
                              onChange={(e) =>
                                setTestimonialObj({
                                  ...testimonialObj,
                                  name: e.target.value,
                                })
                              }
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="form-group">
                            <textarea
                              autoComplete="off"
                              className="form-control"
                              title="alertDescription"
                              value={testimonialObj.description}
                              onChange={(e) =>
                                setTestimonialObj({
                                  ...testimonialObj,
                                  description: e.target.value,
                                })
                              }
                              placeholder="Description *"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div id="half-stars-example">
                              <label className="mainLabel">Rating</label>
                              <div class="rating-group">
                                <StarRating
                                  testimonialObj={testimonialObj}
                                  setTestimonialObj={setTestimonialObj}
                                />
                                {/* <Rating /> */}
                                {/* <input
                                  class="rating__input rating__input--none"
                                  checked
                                  name="rating2"
                                  id="rating2-0"
                                  value="0"
                                  type="radio"
                                />
                                <label
                                  aria-label="0 stars"
                                  class="rating__label"
                                  for="rating2-0"
                                >
                                  &nbsp;
                                </label>
                                <label
                                  aria-label="0.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-05"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star-half"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-05"
                                  value="0.5"
                                  type="radio"
                                />
                                <label
                                  aria-label="1 star"
                                  class="rating__label"
                                  for="rating2-10"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-10"
                                  value="1"
                                  type="radio"
                                />
                                <label
                                  aria-label="1.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-15"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star-half"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-15"
                                  value="1.5"
                                  type="radio"
                                />
                                <label
                                  aria-label="2 stars"
                                  class="rating__label"
                                  for="rating2-20"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-20"
                                  value="2"
                                  type="radio"
                                />
                                <label
                                  aria-label="2.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-25"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star-half"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-25"
                                  value="2.5"
                                  type="radio"
                                  checked
                                />
                                <label
                                  aria-label="3 stars"
                                  class="rating__label"
                                  for="rating2-30"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-30"
                                  value="3"
                                  type="radio"
                                />
                                <label
                                  aria-label="3.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-35"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star-half"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-35"
                                  value="3.5"
                                  type="radio"
                                />
                                <label
                                  aria-label="4 stars"
                                  class="rating__label"
                                  for="rating2-40"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-40"
                                  value="4"
                                  type="radio"
                                />
                                <label
                                  aria-label="4.5 stars"
                                  class="rating__label rating__label--half"
                                  for="rating2-45"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star-half"></i>
                                </label> */}
                                {/* <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-45"
                                  value="4.5"
                                  type="radio"
                                />
                                <label
                                  aria-label="5 stars"
                                  class="rating__label"
                                  for="rating2-50"
                                >
                                  <i class="rating__icon rating__icon--star fa fa-star"></i>
                                </label>
                                <input
                                  class="rating__input"
                                  name="rating2"
                                  id="rating2-50"
                                  value="5"
                                  type="radio"
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="dragDrop">
                            <img src={uploadIcon} />
                            <input
                              id="addTestimonalImage"
                              type="file"
                              onChange={(e) => {
                                if (e) {
                                  const file = e.target.files;
                                  if (
                                    file &&
                                    file[0] &&
                                    file[0].type &&
                                    file[0].type.split("/")[0] == "image"
                                  ) {
                                    // previewEmployeeTestimonialImage,
                                    // setPreviewEmployeeTestimonialImage,
                                    // isEmployeeUploaded,
                                    // setIsEmployeeUploaded,
                                    setTestimonialObj({
                                      ...testimonialObj,
                                      testimonialImage: file[0],
                                    });
                                    setPreviewEmployeeTestimonialImage(
                                      URL.createObjectURL(file[0])
                                    );
                                    setIsEmployeeUploaded(true);
                                  } else {
                                    toastMessages(
                                      "error",
                                      "The image format is not correct"
                                    );
                                  }
                                }
                              }}
                            />
                            <p>
                              Drop your file here or{" "}
                              <span className="green">Browser</span>
                            </p>
                            <p>
                              We Support <span className="white">JPG, PNG</span>{" "}
                              files. Make sure that your file is no more than
                              500 kb.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* new changes */}
                      {/* <div className="preview">
                    {previewImage && (
                      <img
                        className="uploadImg"
                        src={previewImage ? previewImage : dummyImg}
                      />
                    )}
                    {previewImage && (
                      <span
                        onClick={() => {
                          document.getElementById("companyData").value = null;
                          setIsNewImageUploaded(false);
                          setPreviewImage("");
                          setForm((prev) => {
                            prev.firmLogo = "";
                            return { ...prev };
                          });
                        }}
                      >
                        <img src={whiteCross} />
                      </span>
                    )}
                  </div> */}

                      {/*  */}
                      <div className="preview" style={{ marginTop: "34px" }}>
                        {previewEmployeeTestimonialImage && (
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "80px",
                            }}
                            src={previewEmployeeTestimonialImage}
                            alt="headhot-image"
                          />
                        )}
                        {previewEmployeeTestimonialImage && (
                          <span
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              setIsEmployeeUploaded(false);
                              setTestimonialObj({
                                ...testimonialObj,
                                testimonialImage: "",
                              });
                              setPreviewEmployeeTestimonialImage("");
                              document.getElementById(
                                "addTestimonalImage"
                              ).value = null;
                            }}
                          >
                            <img src={whiteCross} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="formBtns mt-5 mb-4">
                      <button
                        className="btn cancelBtn"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          setTestimonialObj({
                            name: "",
                            description: "",
                            rating: null,
                            testimonialImage: "",
                          });
                          setPreviewEmployeeTestimonialImage("");
                          document.getElementById("addTestimonalImage").value =
                            null;
                        }}
                      >
                        No, Cancel
                      </button>
                      <button
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        disabled={
                          !testimonialObj.name || !testimonialObj.description
                        }
                        className="btn inviteBtn"
                        onClick={addTestimonalHandler}
                      >
                        {" "}
                        Add
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Job Modal Starts */}
        <div
          class="modal fade commonModal addEducationModal"
          id="deleteTestimonialJob"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Delete Job</h3>
                  <img src={iconDelete} />
                  <p>
                    Are you sure you want to delete your <br /> Job ?
                  </p>

                  <div className="formBtns">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={onJobDeleteHandler}
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Job Modal Ends */}
        {/* testimonial modal ends */}
        {/* Delete Testimondal Modal Starts */}
        <div
          class="modal fade commonModal addEducationModal"
          id="deleteTestimonialModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Delete Testimonal</h3>
                  <img src={iconDelete} />
                  <p>
                    Are you sure you want to delete your <br /> Testimonal ?
                  </p>

                  <div className="formBtns">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={onDeleteHandler}
                    >
                      Yes, Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Testimondal Modal Ends */}
        {/* Edit Review Modal Starts */}
        <div
          class="modal fade commonModal editEducationModal"
          id="editReviewsModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Edit Reviews</h3>
                  <div className="candidatemidleft">
                    <div className="candidatemidabout">
                      {testimonialList &&
                        testimonialList.length > 0 &&
                        testimonialList.map((item) => {
                          let testimonialObj = {
                            rating: item.rating,
                          };
                          return (
                            <>
                              <div className="eduDetail reviewDetail">
                                <div className="actionBtns">
                                  <button
                                    onClick={() => {
                                      setTestimonialObj(item);
                                      setPreviewEmployeeTestimonialImage(
                                        item.testimonialImage
                                      );
                                    }}
                                    className="btn"
                                    data-bs-target="#editTestimonial"
                                    data-bs-toggle="modal"
                                  >
                                    <img src={editIcon} />
                                  </button>
                                </div>
                                <div className="row">
                                  <div className="col-xxl-2 col-xl-2 col-lg-3">
                                    <img
                                      className="userIcon"
                                      src={
                                        item.testimonialImage
                                          ? item.testimonialImage
                                          : dummyImg
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="col-xxl-10 col-xl-10 col-lg-9">
                                    <h3>{item.name}</h3>
                                    <div className="starRating">
                                      <StarRating
                                        readonly={true}
                                        testimonialObj={testimonialObj}
                                        // setTestimonialObj={setTestimonialObj}
                                      />
                                    </div>
                                    {/* <img className="rating" src={starIcon} /> */}
                                    <p>{item.description}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="divider"></div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                  <div className="formBtns">
                    <button
                      className="btn cancelBtn"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-toggle="modal"
                      data-bs-target="#testimonial"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Edit Review Modal Ends */}
        {/* Edit Single testimonial modal starts */}
        <div
          class="modal fade commonModal"
          id="editTestimonial"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setTestimonialObj({})}
                ></button>
              </div>
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-5">
                    Edit Employee Testimonials
                  </h3>

                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type=""
                              autoComplete="off"
                              placeholder="Name *"
                              value={testimonialObj.name}
                              onChange={(e) =>
                                setTestimonialObj({
                                  ...testimonialObj,
                                  name: e.target.value,
                                })
                              }
                              name="name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-xl-12">
                          <div className="form-group">
                            <textarea
                              autoComplete="off"
                              className="form-control"
                              title="alertDescription"
                              value={testimonialObj.description}
                              onChange={(e) =>
                                setTestimonialObj({
                                  ...testimonialObj,
                                  description: e.target.value,
                                })
                              }
                              placeholder="Description *"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div id="half-stars-example">
                              <label className="mainLabel">Rating</label>
                              <div class="rating-group">
                                <StarRating
                                  testimonialObj={testimonialObj}
                                  setTestimonialObj={setTestimonialObj}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="dragDrop">
                            <img src={uploadIcon} />
                            <input
                              id="editTestimonalImage"
                              type="file"
                              onChange={(e) => {
                                if (e) {
                                  const file = e.target.files;
                                  if (
                                    file &&
                                    file[0] &&
                                    file[0].type &&
                                    file[0].type.split("/")[0] == "image"
                                  ) {
                                    setTestimonialObj({
                                      ...testimonialObj,
                                      testimonialImage: file[0],
                                    });
                                    setPreviewEmployeeTestimonialImage(
                                      URL.createObjectURL(file[0])
                                    );
                                    setIsEmployeeUploaded(true);
                                  } else {
                                    toastMessages(
                                      "error",
                                      "The image format is not correct"
                                    );
                                  }
                                }
                              }}
                            />
                            <p>
                              Drop your file here or{" "}
                              <span className="green">Browser</span>
                            </p>
                            <p>
                              We Support <span className="white">JPG, PNG</span>{" "}
                              files. Make sure that your file is no more than
                              500 kb.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* {previewEmployeeTestimonialImage && (
                        <img
                          style={{ width: "100px", height: "100px" }}
                          src={previewEmployeeTestimonialImage}
                          alt="headhot-image"
                        />
                      )}
                      {previewEmployeeTestimonialImage && (
                        <i
                          style={{ cursor: "pointer" }}
                          className="fa-solid fa-trash-can"
                          onClick={() => {
                            setIsEmployeeUploaded(false);
                            setTestimonialObj({
                              ...testimonialObj,
                              testimonialImage: "",
                            });
                            setPreviewEmployeeTestimonialImage("");
                          }}
                        ></i>
                      )} */}
                      <div className="preview" style={{ marginTop: "34px" }}>
                        {previewEmployeeTestimonialImage && (
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "80px",
                            }}
                            src={previewEmployeeTestimonialImage}
                            alt="headhot-image"
                          />
                        )}
                        {previewEmployeeTestimonialImage && (
                          <span
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              setIsEmployeeUploaded(false);
                              setTestimonialObj({
                                ...testimonialObj,
                                testimonialImage: "",
                              });
                              setPreviewEmployeeTestimonialImage("");
                              document.getElementById(
                                "editTestimonalImage"
                              ).value = null;
                            }}
                          >
                            <img src={whiteCross} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="formBtns mt-5 mb-4">
                      <button
                        className="btn cancelBtn"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteTestimonialModal"
                      >
                        Delete
                      </button>
                      <button
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        disabled={
                          !testimonialObj.name || !testimonialObj.description
                        }
                        className="btn inviteBtn"
                        onClick={editTestimonalHandler}
                      >
                        {" "}
                        Save Changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  Edit Single testimonial modal ends */}
        {/* Image Upload for profile Start*/}
        <div
          class="modal fade commonModal editExperienceModalSingle"
          id="customImageUpload2"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Upload Image</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="imageUploaderSection">
                      <ImageUpload
                        type="firmLogo"
                        recruiter={true}
                        form={form}
                        setForm={setForm}
                        setIsNewImageUploaded={setIsNewImageUploaded}
                        isNewImageUploaded={isNewImageUploaded}
                        setPreview={setPreviewImage}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Image Upload End for profile ends*/}
        {/* create testimonal upload start*/}
        <div
          class="modal fade commonModal editExperienceModalSingle"
          id="customImageUploadAddTestimonal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Upload Image</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <ImageUpload
                        type="file"
                        recruiter={true}
                        form={form}
                        setForm={setForm}
                        setIsNewImageUploaded={setIsNewImageUploaded}
                        isNewImageUploaded={isNewImageUploaded}
                        setPreview={setPreviewImage}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* create testimonal upload start*/}
      </div>
    </>
  );
}

export default NewModal;
