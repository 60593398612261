import ImageCrop from "./ImageCrop";
import ImageCropProvider from "./ImageCropProvider";
const ImageUpload = ({
    preview,
    setPreview,
    setImage,
    setRefresh,
    refresh,
    type,
    setDismissModal,
    dismissModal,
    recruiter,
    form,
    setForm,
    setIsNewImageUploaded,
    isNewImageUploaded,
    onBoardingRecruiter = false,
    previewImage,
    setPreviewImage,
    headshotImage,
    setHeadshotImage,
    isHeadshotImageDeleted,
    setIsHeadshotImageDeleted,
    page
}) => {
    let props = {
        preview,
        setPreview,
        setImage,
        setRefresh,
        refresh,
        type,
        setDismissModal,
        dismissModal,
        recruiter,
        setIsNewImageUploaded,
        isNewImageUploaded,
        form,
        setForm,
        onBoardingRecruiter,
        previewImage,
        setPreviewImage,
        headshotImage,
        setHeadshotImage,
        isHeadshotImageDeleted,
        setIsHeadshotImageDeleted,
        page
    }
    return (

        <ImageCropProvider>
            <ImageCrop  {...props} />
        </ImageCropProvider>
    )
}
export default ImageUpload