import StepFormProgress from "./stepForm-progress";
import Anthropology from "../assets/imgs/anthropology.svg";
import experiencepeople from "../assets/imgs/experiencepeople.svg";
import editIcon from "../assets/imgs/editIcon.svg";
import checkMark from "../assets/imgs/checkMark.gif";
import boostIcon from "../assets/imgs/boostIcon.gif";
import infoIcon from "../assets/imgs/infoIcon.svg";
import uploadIcon from "../assets/imgs/uploadIcon.svg";
import iconDelete from "../assets/imgs/iconModalDelete.svg";
import invitePopupImg from "../assets/imgs/invitePopupImg.svg";
import whiteCross from "../assets/imgs/whiteCross.svg";
import Logo from "../assets/imgs/HireBIRD.png";
import toastMessages from "../components/toastMessage";
import Select from "react-select";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CustomDatePicker from "../components/CustomDatePicker";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import ImageUpload from "../components/customImageEditor/index";
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
let suggestedSkills = ["Sales", "Marketing", "Direct Sales", "Management"];
export const CustomDatePickerInput = ({ value, onClick, placeholder }) => (
  <input
    type="text"
    value={value}
    onClick={onClick}
    readOnly
    style={{ backgroundColor: "#1D1D1D", cursor: "pointer" }} // Optionally, change the background color
    placeholder={placeholder}
  />
);
function Modals({
  form,
  handleChange,
  onSubmit,
  setIsHeadshotImageDeleted,
  setHeadshotImage,
  setPreviewImage,
  isHeadshotImageDeleted,
  headshotImage,
  previewImage,
  setQualificationCount,
  qualificationCount,
  increaseQualificationCount,
  setForm,
  onCompanyHandler,
  workExperienceCount,
  setWorkExperienceCount,
  companyOptions,
  increaseWorkCount,
  setIsCompanySelected,
  isCompanySelected,
  setSkillsCount,
  skillsCount,
  increaseSkillCount,
  onSuggestedSkillHandler,
  currEditCompanyIndex,
  setCurrEditCompanyIndex,
  // decreaseQualificationCount,
  onboardingDetails,
  onUniversitySearchHandler,
  universityList,
  isUniverSelected,
  setIsUniversitySelected,
  currEditUniversitiesIndex,
  setCurrEditUniversitiesIndex,
  linkedin,
  JobApplyHandler,
  setPage,
  page,
}) {
  let existingSkills =
    form &&
    form.skills.length > 0 &&
    form.skills.map((skill) => skill.skillSet);
  let navigate = useNavigate();
  const libraries = ["places"];
  const inputRef = useRef();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setForm((prev) => {
        prev.location = place.formatted_address;
        return { ...prev };
      });
    }
  };
  const [newEducationDetail, setNewEducationDetail] = useState({
    university: "",
    degreeType: "",
    startDate: "",
    endDate: "",
    isPresent: false,
    description: "",
  });
  return (
    <>
      <div className="mainWrapper">
        {/* Add Education Modal Starts */}
        <div
          class="modal fade commonModal addEducationModal"
          id="addEducationModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Add Education</h3>
                  <form>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>University</option>
                            <option>University</option>
                            <option>University</option>
                            <option>University</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>Degree</option>
                            <option>Degree</option>
                            <option>Degree</option>
                            <option>Degree</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>Field of Study</option>
                            <option>Field of Study</option>
                            <option>Field of Study</option>
                            <option>Field of Study</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>Grade</option>
                            <option>Grade</option>
                            <option>Grade</option>
                            <option>Grade</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Start Date"
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="End Date"
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="formBtns">
                      <button className="btn cancelBtn">Cancel</button>
                      <button className="btn inviteBtn">Add</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Education Modal Ends */}

        {/* delete Education Modal Starts */}
        <div
          class="modal fade commonModal addEducationModal"
          id="deleteModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Delete Education</h3>
                  <img src={iconDelete} style={{ width: "16%" }} />
                  <p>
                    Are you sure you want to delete your <br /> education?
                  </p>

                  <div className="formBtns">
                    <button className="btn cancelBtn">Cancel</button>
                    <button className="btn inviteBtn">Yes, Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* delete Education Modal Ends */}

        {/* Add Education Modal Starts */}
        <div
          class="modal fade commonModal editEducationModal"
          id="editEducationModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Add Education</h3>
                  <div className="candidatemidleft">
                    <div className="candidatemidabout">
                      <div className="eduDetail">
                        <div className="actionBtns">
                          <button
                            className="btn"
                            data-bs-target="#addEducationModal"
                            data-bs-toggle="modal"
                          >
                            <img src={editIcon} />
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-xxl-2 col-xl-2 col-lg-3">
                            <img src={Anthropology} alt="" />
                          </div>
                          <div className="col-xxl-10 col-xl-10 col-lg-9">
                            <h3>Bachelor of Science, Marketing</h3>
                            <p>Utah Valley University - (2017 - 2021)</p>
                          </div>
                        </div>
                        <p>
                          I am proud to boast a Bachelor of Science in Marketing
                          from Utah Valley University. With a 3.79 GPA, I have
                          proven myself to be a dedicated and persistent student
                          with a 3.79 GPA, I have proven myself to be a
                          dedicated.....
                        </p>
                      </div>

                      <div className="divider"></div>

                      <div className="eduDetail">
                        <div className="actionBtns">
                          <button
                            className="btn"
                            data-bs-target="#addEducationModal"
                            data-bs-toggle="modal"
                          >
                            <img src={editIcon} />
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-xxl-2 col-xl-2 col-lg-3">
                            <img src={Anthropology} alt="" />
                          </div>
                          <div className="col-xxl-10 col-xl-10 col-lg-9">
                            <h3>Bachelor of Science, Marketing</h3>
                            <p>Utah Valley University - (2017 - 2021)</p>
                          </div>
                        </div>
                        <p>
                          I am proud to boast a Bachelor of Science in Marketing
                          from Utah Valley University. With a 3.79 GPA, I have
                          proven myself to be a dedicated and persistent student
                          with a 3.79 GPA, I have proven myself to be a
                          dedicated.....
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="formBtns">
                    <button className="btn cancelBtn">Cancel</button>
                    <button className="btn inviteBtn">Add</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Add Education Modal Ends */}

        {/* boost Modal Starts */}
        <div
          class="modal fade commonModal editEducationModal boostModal"
          id="boostModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <div className="boostContent">
                    <img src={boostIcon} />
                    <p>
                      Boost your post to reach out to a <br /> bigger candidate
                      base
                    </p>
                    <div className="formBtns mt-0">
                      <button className="btn cancelBtn">No thanks</button>
                      <button className="btn inviteBtn">
                        Yes, boost to reach a larger audience!
                      </button>
                      <span className="tooltipOuter">
                        <img src={infoIcon} />

                        <span className="tooltipText">
                          Job listings that are boosted receive 4.6x the amount
                          of applicants, and find a successful hire 3.2x faster.
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* boost Modal Ends */}

        {/* approve Modal Starts */}
        <div
          class="modal fade commonModal editEducationModal confirmationModal"
          id="approveModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <img src={checkMark} />
                  <p className="mb-0">
                    Congrats! <strong>Lululemon Athletica</strong> thinks you'd
                    make <br /> a great fit as their new{" "}
                    <strong>General Store Manager</strong>!
                  </p>
                  <p className="mt-0">Send them a message now?</p>
                  <div className="formBtns mt-0">
                    <button className="btn cancelBtn" data-bs-dismiss="modal">
                      Not now.
                    </button>
                    <button className="btn inviteBtn">Go to Messages!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* approve Modal Ends */}
        {/* candidate location modal starts */}
        <div
          class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
          id="locationModal"
          data-bs-backdrop="static"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"25%"} />
                  <h3 className="ModalHeading">
                    Let's add your location to find open <br /> positions near
                    you.
                  </h3>
                  <form>
                    <div className="row">
                      <div className="form-group">
                        {isLoaded && (
                          <StandaloneSearchBox
                            // value={}
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Location"
                              value={form.location}
                              onChange={(e) => {
                                setForm((prev) => {
                                  prev.location = e.target.value;
                                  return { ...prev };
                                });
                              }}
                            />
                          </StandaloneSearchBox>
                        )}
                      </div>
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>City</option>
                            <option>City</option>
                            <option>City</option>
                          </select>
                        </div>
                      </div>
                       */}
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            autocomplete="off"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            // value={form.location.state}
                            onChange={(e) => handleChange(e, "locations")}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>State</option>
                            <option>State</option>
                            <option>State</option>
                          </select>
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            autocomplete="off"
                            className="form-control"
                            placeholder="State"
                            name="state"
                            // value={form.location.state}
                            onChange={(e) => handleChange(e, "locations")}
                          />
                        </div>
                      </div> */}
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    {/* <button
                      className="btn cancelBtn"
                      data-bs-target="#uploadImageModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button> */}
                    {/* <button
                      className="btn cancelBtn"
                      data-bs-target="#confirmModal"
                      data-bs-toggle="modal"
                    >
                    </button> */}
                    <button
                      disabled={!form.location}
                      className="btn inviteBtn"
                      data-bs-target="#uploadImageModal"
                      data-bs-toggle="modal"
                      onClick={(e) =>
                        onSubmit(e, "uploadImageModal", false, "location")
                      }
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
              {/* <div class="modal-footer">
                    <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open second modal</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* candidate location modal ends */}
        {/* uploadImage maodal starts */}
        <div
          class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
          id="uploadImageModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          data-bs-backdrop="static"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"50%"} />
                  <h3 className="ModalHeading">
                    Add a headshot to liven up your profile!
                  </h3>

                  <form>
                    <div
                      className="dragDrop"
                      // temporary changes
                      data-bs-target="#imageUploadModalStarted"
                      data-bs-toggle="modal"
                    >
                      {/* <input
                        type="file"
                        onChange={(e) => {
                          if (e) {
                            const file = e.target.files;
                            if (
                              file &&
                              file[0] &&
                              file[0].type &&
                              file[0].type.split("/")[0] == "image"
                            ) {
                              setPreviewImage({
                                ...previewImage,
                                headshotImage: URL.createObjectURL(file[0]),
                              });
                              setHeadshotImage(file[0]);
                              setIsHeadshotImageDeleted(false);
                            } else {
                              toastMessages(
                                "error",
                                "The image format is not correct"
                              );
                            }
                          }
                        }}
                      /> */}
                      <img src={uploadIcon} />
                      <p>
                        Drop your file here{" "}
                        <span className="green">or Browser</span>
                      </p>
                      <p>
                        We Support <span className="white">JPG, PNG</span> files.
                        Make sure that your file is no more than 1 mb.
                      </p>
                    </div>
                    {previewImage && previewImage.headshotImage && (
                      <span className="previewUpload">
                        {previewImage && previewImage.headshotImage && (
                          <img
                            // style={{
                            //   width: "100px",
                            //   height: "100px",
                            //   borderRadius: "22px",
                            // }}
                            src={previewImage.headshotImage}
                            alt="headhot-image"
                          />
                        )}
                        {previewImage && previewImage.headshotImage && (
                          <span className="crossIcon">
                            <img
                              src={whiteCross}
                              onClick={() => {
                                setIsHeadshotImageDeleted(true);
                                setHeadshotImage("");
                                setPreviewImage({
                                  previewImage,
                                  headshotImage: "",
                                });
                              }}
                            />
                          </span>
                        )}
                      </span>
                    )}
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn cancelBtn"
                      data-bs-target="#educationhistoryModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button>
                    <button
                      // disabled={
                      //   !form.qualification[index].university ||
                      //   !form.qualification[index].degreeType ||
                      //   !form.qualification[index].startDate ||
                      //   !form.qualification[index].endDate ||
                      //   !form.qualification[index].description
                      // }
                      className="btn inviteBtn"
                      data-bs-target="#educationhistoryModal"
                      data-bs-toggle="modal"
                      onClick={(e) =>
                        onSubmit(e, "educationhistoryModal", true, "")
                      }
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
              {/* <div class="modal-footer">
                    <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open second modal</button>
                </div> */}
            </div>
          </div>
        </div>
        {/* uploadImage modal ends */}
        {/* educationhistory modal starts */}
        <div
          class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
          id="educationhistoryModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            onClick={() => setIsUniversitySelected(false)}
          >
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"50%"} />
                  <h3 className="ModalHeading">
                    Tell us about your education history!
                  </h3>
                  <form>
                    {[...Array(qualificationCount)].map((value, index) => {
                      return (
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                onChange={(e) => {
                                  setCurrEditUniversitiesIndex(index);
                                  setIsUniversitySelected(true);
                                  setForm((prevData) => {
                                    let qualification = [
                                      ...prevData.qualification,
                                    ];
                                    qualification[index].university =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      qualification,
                                    };
                                  });
                                  onUniversitySearchHandler(e.target.value);
                                }}
                                value={form.qualification[index]?.university}
                                autocomplete="off"
                                type="text"
                                className="form-control"
                                placeholder="College/University *"
                              />
                              {isUniverSelected &&
                                currEditUniversitiesIndex == index && (
                                  <ul>
                                    {universityList &&
                                      universityList.length > 0 &&
                                      universityList.map((item, index) => {
                                        return (
                                          <li
                                            style={{ cursor: "pointer" }}
                                            key={index}
                                            onClick={() => {
                                              setIsUniversitySelected(false);
                                              setForm((prevData) => {
                                                let qualification = [
                                                  ...(prevData.qualification ||
                                                    []),
                                                ];
                                                if (
                                                  !qualification[
                                                    qualificationCount - 1
                                                  ]
                                                ) {
                                                  qualification[
                                                    qualificationCount - 1
                                                  ] = {
                                                    university: "",
                                                    degreeType: "",
                                                    startDate: "",
                                                    isPresent: false,
                                                    endDate: "",
                                                    description: "",
                                                  };
                                                }
                                                qualification[
                                                  currEditUniversitiesIndex
                                                ].university = item.name;
                                                qualification[
                                                  currEditUniversitiesIndex
                                                ].universityLogo = item.logo;

                                                return {
                                                  ...prevData,
                                                  qualification,
                                                };
                                              });
                                            }}
                                          >
                                            <img
                                              src={item.logo}
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "15px",
                                                marginRight: "13px",
                                              }}
                                            />
                                            {item.name}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let qualification = [
                                      ...prevData.qualification,
                                    ];
                                    qualification[index].degreeType =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      qualification,
                                    };
                                  });
                                }}
                                value={form.qualification[index]?.degreeType}
                                autocomplete="off"
                                type="text"
                                className="form-control"
                                placeholder="Degree Type *"
                              />
                            </div>
                          </div>
                          {qualificationCount > 1 && (
                            <button
                              className="deleteEduBtn"
                              onClick={(e) => {
                                e.preventDefault();
                                setQualificationCount((prev) => prev - 1);
                                setForm((prev) => {
                                  let qualification = [...prev.qualification];
                                  qualification.splice(index, 1);
                                  return { ...prev, qualification };
                                });
                              }}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          )}
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              {/* <CustomDatePicker
                                form={form}
                                setForm={setForm}
                                index={index}
                                type="qualification"
                                field="startDate"
                              /> */}
                              <DatePicker
                                showYearDropdown
                                showMonthYearPicker
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                placeholderText="Start Date*"
                                dateFormat="MM/yyyy"
                                customInput={
                                  <CustomDatePickerInput placeholder="Start Date*" />
                                }
                                // scrollableYearDropdown
                                selected={parseInt(
                                  form.qualification[index]?.startDate
                                )}
                                onChange={(data) => {
                                  setForm((prevData) => {
                                    let qualification = [
                                      ...prevData.qualification,
                                    ];
                                    qualification[index].startDate = data
                                      .getTime()
                                      .toString();
                                    return {
                                      ...prevData,
                                      qualification,
                                    };
                                  });
                                }}
                                // onChange={(date) => {
                                //   setNewEducationDetail((prevData) => {
                                //     prevData.endDate = date.getTime().toString();
                                //     return {
                                //       ...prevData,
                                //     };
                                //   });
                                // }}
                              />
                              {/* <input
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let qualification = [
                                      ...prevData.qualification,
                                    ];
                                    qualification[index].startDate =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      qualification,
                                    };
                                  });
                                }}
                                value={form.qualification[index]?.startDate}
                                type="date"
                                className="form-control"
                                placeholder="Start Date *"
                              /> */}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              {/* <CustomDatePicker
                                form={form}
                                setForm={setForm}
                                index={index}
                                type="qualification"
                                field="endDate"
                              /> */}
                              {/* <input
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let qualification = [
                                      ...prevData.qualification,
                                    ];
                                    qualification[index].endDate =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      qualification,
                                    };
                                  });
                                }}
                                value={form.qualification[index]?.endDate}
                                type="date"
                                className="form-control"
                                placeholder="Start Date *"
                              /> */}
                              <DatePicker
                                showYearDropdown
                                showMonthYearPicker
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                placeholderText="End Date*"
                                dateFormat="MM/yyyy"
                                // scrollableYearDropdown
                                customInput={
                                  <CustomDatePickerInput placeholder="End Date*" />
                                }
                                selected={parseInt(
                                  form.qualification[index]?.endDate
                                )}
                                onChange={(data) => {
                                  setForm((prevData) => {
                                    let qualification = [
                                      ...prevData.qualification,
                                    ];
                                    qualification[index].endDate = data
                                      .getTime()
                                      .toString();
                                    if (qualification[index].isPresent) {
                                      qualification[index].isPresent = false;
                                    }
                                    return {
                                      ...prevData,
                                      qualification,
                                    };
                                  });
                                }}
                                // onChange={(date) => {
                                //   setNewEducationDetail((prevData) => {
                                //     prevData.endDate = date.getTime().toString();
                                //     return {
                                //       ...prevData,
                                //     };
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                          <div className="form-check mb-4 text-end">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="checkbox"
                                checked={form.qualification[index]?.isPresent}
                                onChange={(event) => {
                                  let alreadyPresent = form.qualification.some(
                                    (item) => item.isPresent
                                  );
                                  if (
                                    !alreadyPresent ||
                                    (alreadyPresent && !event.target.checked)
                                  ) {
                                    setForm((prevData) => {
                                      let qualification = [
                                        ...prevData.qualification,
                                      ];
                                      // let isPresentCOunt=  prevData.qualification.
                                      qualification[index].isPresent =
                                        !prevData.qualification[index]
                                          .isPresent;
                                      if (
                                        prevData.qualification[index].isPresent
                                      ) {
                                        prevData.qualification[index].endDate =
                                          "";
                                      }
                                      //  data
                                      //   .getTime()
                                      //   .toString();
                                      return {
                                        ...prevData,
                                        qualification,
                                      };
                                    });
                                  } else {
                                    toastMessages(
                                      "error",
                                      "Current occupation already selected."
                                    );
                                    event.target.checked = false;
                                  }
                                }}
                              />
                              Present
                            </label>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <textarea
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let qualification = [
                                      ...prevData.qualification,
                                    ];
                                    qualification[index].description =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      qualification,
                                    };
                                  });
                                }}
                                value={form.qualification[index]?.description}
                                className="form-control"
                                placeholder="Description"
                              ></textarea>
                              {index == qualificationCount - 1 && (
                                <div className="text-start">
                                  <button
                                    className="btn appentBtn"
                                    onClick={(event) => {
                                      increaseQualificationCount(event);
                                    }}
                                  >
                                    Add Education History
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn cancelBtn"
                      data-bs-target="#experiencehistoryModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button>
                    <button
                      disabled={
                        form &&
                        form.qualification &&
                        form.qualification.length > 0 &&
                        form.qualification.some(
                          (item) =>
                            !item.university ||
                            !item.degreeType ||
                            !item.startDate ||
                            ((!item.isPresent || item.endDate) &&
                              (item.isPresent || !item.endDate))
                          // ||!item.description
                        )
                      }
                      className="btn inviteBtn"
                      data-bs-target="#experiencehistoryModal"
                      data-bs-toggle="modal"
                      onClick={(e) =>
                        onSubmit(
                          e,
                          "experiencehistoryModal",
                          false,
                          "qualification"
                        )
                      }
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* educationhistory modal ends */}

        {/* educationhistory modal starts */}
        <div
          class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
          id="experiencehistoryModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            onClick={() => setIsCompanySelected(false)}
          >
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"75%"} />
                  <h3 className="ModalHeading mb-2">
                    Add relevant work experience!
                  </h3>
                  <p className="experienceText">
                    We suggest adding work/internship experience that is
                    relevant to positions <br /> you may end up applying for.
                  </p>
                  <form>
                    {[...Array(workExperienceCount)].map((value, index) => {
                      return (
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                onChange={(e) => {
                                  setCurrEditCompanyIndex(index);
                                  setIsCompanySelected(true);
                                  setForm((prevData) => {
                                    let workExperience = [
                                      ...prevData.workExperience,
                                    ];
                                    workExperience[index].companyName =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      workExperience,
                                    };
                                  });
                                  onCompanyHandler(e.target.value);
                                }}
                                value={form.workExperience[index]?.companyName}
                                autocomplete="off"
                                type="text"
                                className="form-control"
                                placeholder="Company Name *"
                              />
                              {isCompanySelected &&
                                currEditCompanyIndex == index && (
                                  <ul>
                                    {companyOptions &&
                                      companyOptions.length > 0 &&
                                      companyOptions.map((item, index) => {
                                        return (
                                          <li
                                            style={{ cursor: "pointer" }}
                                            key={index}
                                            onClick={() => {
                                              setIsCompanySelected(false);
                                              setForm((prevData) => {
                                                let workExperience = [
                                                  ...(prevData.workExperience ||
                                                    []),
                                                ];
                                                if (
                                                  !workExperience[
                                                    workExperienceCount - 1
                                                  ]
                                                ) {
                                                  workExperience[
                                                    workExperienceCount - 1
                                                  ] = {
                                                    companyName: "",
                                                    designation: "",
                                                    startDate: "",
                                                    endDate: "",
                                                    description: "",
                                                  };
                                                }
                                                workExperience[
                                                  currEditCompanyIndex
                                                ].companyName = item.firmName;
                                                return {
                                                  ...prevData,
                                                  workExperience,
                                                };
                                              });
                                            }}
                                          >
                                            {item.firmName}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                )}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let workExperience = [
                                      ...prevData.workExperience,
                                    ];
                                    workExperience[index].designation =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      workExperience,
                                    };
                                  });
                                }}
                                value={form.workExperience[index]?.designation}
                                autocomplete="off"
                                type="text"
                                className="form-control"
                                placeholder="Position *"
                              />
                            </div>
                          </div>
                          {workExperienceCount > 1 && (
                            <button className="deleteEduBtn">
                              <i
                                className="fa-solid fa-trash-can"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setWorkExperienceCount((prev) => prev - 1);
                                  setForm((prev) => {
                                    let workExperience = [
                                      ...prev.workExperience,
                                    ];
                                    workExperience.splice(index, 1);
                                    return { ...prev, workExperience };
                                  });
                                }}
                              ></i>
                            </button>
                          )}
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              {/* <CustomDatePicker
                                form={form}
                                setForm={setForm}
                                index={index}
                                type="workExperience"
                                field="startDate"
                              /> */}
                              {/* <input
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let workExperience = [
                                      ...prevData.workExperience,
                                    ];
                                    workExperience[index].startDate =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      workExperience,
                                    };
                                  });
                                }}
                                value={form.workExperience[index]?.startDate}
                                type="date"
                                className="form-control"
                                placeholder="Start Date *"
                              /> */}
                              <DatePicker
                                showYearDropdown
                                showMonthYearPicker
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                placeholderText="Start Date*"
                                dateFormat="MM/yyyy"
                                customInput={
                                  <CustomDatePickerInput placeholder="Start Date*" />
                                }
                                // scrollableYearDropdown
                                selected={parseInt(
                                  form.workExperience[index]?.startDate
                                )}
                                onChange={(data) => {
                                  setForm((prevData) => {
                                    let workExperience = [
                                      ...prevData.workExperience,
                                    ];
                                    workExperience[index].startDate = data
                                      .getTime()
                                      .toString();
                                    return {
                                      ...prevData,
                                      workExperience,
                                    };
                                  });
                                }}
                                // onChange={(date) => {
                                //   setNewEducationDetail((prevData) => {
                                //     prevData.endDate = date.getTime().toString();
                                //     return {
                                //       ...prevData,
                                //     };
                                //   });
                                // }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              {/* <CustomDatePicker
                                form={form}
                                setForm={setForm}
                                index={index}
                                type="workExperience"
                                field="endDate"
                              /> */}
                              {/* <input
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let workExperience = [
                                      ...prevData.workExperience,
                                    ];
                                    workExperience[index].endDate =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      workExperience,
                                    };
                                  });
                                }}
                                value={form.workExperience[index]?.endDate}
                                type="date"
                                className="form-control"
                                placeholder="End Date *"
                              /> */}
                              <DatePicker
                                showYearDropdown
                                showMonthYearPicker
                                dateFormatCalendar="MMMM"
                                yearDropdownItemNumber={15}
                                placeholderText="End Date*"
                                dateFormat="MM/yyyy"
                                customInput={
                                  <CustomDatePickerInput placeholder="End Date*" />
                                }
                                // scrollableYearDropdown
                                selected={parseInt(
                                  form.workExperience[index]?.endDate
                                )}
                                onChange={(data) => {
                                  setForm((prevData) => {
                                    let workExperience = [
                                      ...prevData.workExperience,
                                    ];
                                    workExperience[index].endDate = data
                                      .getTime()
                                      .toString();
                                    return {
                                      ...prevData,
                                      workExperience,
                                    };
                                  });
                                }}
                                // onChange={(date) => {
                                //   setNewEducationDetail((prevData) => {
                                //     prevData.endDate = date.getTime().toString();
                                //     return {
                                //       ...prevData,
                                //     };
                                //   });
                                // }}
                              />
                            </div>
                          </div>
                          <div className="form-check mb-4 text-end">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="checkbox"
                                checked={form.workExperience[index]?.isPresent}
                                onChange={(event) => {
                                  let alreadyPresent = form.workExperience.some(
                                    (item) => item.isPresent
                                  );
                                  if (
                                    !alreadyPresent ||
                                    (alreadyPresent && !event.target.checked)
                                  ) {
                                    setForm((prevData) => {
                                      let workExperience = [
                                        ...prevData.workExperience,
                                      ];
                                      workExperience[index].isPresent =
                                        !prevData.workExperience[index]
                                          .isPresent;
                                      if (
                                        prevData.workExperience[index].isPresent
                                      ) {
                                        prevData.workExperience[index].endDate =
                                          "";
                                      }
                                      //  data
                                      //   .getTime()
                                      //   .toString();
                                      return {
                                        ...prevData,
                                        workExperience,
                                      };
                                    });
                                  } else {
                                    toastMessages(
                                      "error",
                                      "Can't select more than one current at a time"
                                    );
                                    event.target.checked = false;
                                  }
                                }}
                              />
                              Present
                            </label>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <textarea
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let workExperience = [
                                      ...prevData.workExperience,
                                    ];
                                    workExperience[index].description =
                                      e.target.value;
                                    return {
                                      ...prevData,
                                      workExperience,
                                    };
                                  });
                                }}
                                value={form.workExperience[index]?.description}
                                autocomplete="off"
                                className="form-control"
                                placeholder="Description"
                              ></textarea>
                              {index == workExperienceCount - 1 && (
                                <div className="text-start">
                                  <button
                                    className="btn appentBtn"
                                    onClick={(event) => {
                                      setIsCompanySelected(false);
                                      increaseWorkCount(event);
                                    }}
                                  >
                                    Add Experience History
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn cancelBtn"
                      data-bs-target="#additionalSkillModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button>
                    <button
                      disabled={
                        form.workExperience &&
                        form.workExperience.some(
                          (item) =>
                            !item.companyName ||
                            // !item.description ||
                            !item.designation ||
                            !item.startDate ||
                            ((!item.isPresent || item.endDate) &&
                              (item.isPresent || !item.endDate))
                          // (!item.endDate && item.isPresent )|| (item.endDate && !item.isPresent )||
                        )
                      }
                      className="btn inviteBtn"
                      data-bs-target="#additionalSkillModal"
                      data-bs-toggle="modal"
                      onClick={(e) => {
                        onSubmit(
                          e,
                          "additionalSkillModal",
                          false,
                          "workExperience"
                        );
                      }}
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* educationhistory modal ends */}

        {/* educationhistory modal starts */}
        <div
          class="modal fade commonModal editEducationModal profileModal candidateOnboarding"
          id="additionalSkillModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"100%"} />
                  <h3 className="ModalHeading">
                    Add some of your top skills to standout to employers!
                  </h3>
                  <form>
                    {[...Array(skillsCount)].map((value, index) => {
                      return (
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                onChange={(e) => {
                                  setForm((prevData) => {
                                    let skills = [...prevData.skills];
                                    skills[index].skillSet = e.target.value;
                                    return {
                                      ...prevData,
                                      skills,
                                    };
                                  });
                                }}
                                value={form.skills[index]?.skillSet}
                                type="text"
                                className="form-control"
                                placeholder="Add Skill"
                              />
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <input
                                onChange={(e) => {
                                  const value = e.target.value.replace(/\D/g);
                                  if (
                                    value === "" ||
                                    (parseInt(value) <= 100 &&
                                      parseInt(value) >= 1)
                                  ) {
                                    setForm((prevData) => {
                                      let skills = [...prevData.skills];
                                      skills[index].proficiencyGrade = value;
                                      return {
                                        ...prevData,
                                        skills,
                                      };
                                    });
                                  }
                                }}
                                value={form.skills[index]?.proficiencyGrade}
                                type="text"
                                className="form-control"
                                placeholder="Proficiency Grade (%)"
                              />
                            </div>
                          </div> */}
                          {index == skillsCount - 1 && (
                            <div className="col-lg-12">
                              <div className="form-group">
                                <div className="text-start">
                                  <button
                                    disabled={
                                      form &&
                                      form.skills.some(
                                        (item) => !item.skillSet
                                        // ||
                                        // !item.proficiencyGrade
                                      )
                                    }
                                    className="btn appentBtn"
                                    onClick={(event) => {
                                      increaseSkillCount(event);
                                    }}
                                  >
                                    Add Additional Skills
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {/* <div className="col-lg-6">
                      <div className="suggesion">
                        <h3>Suggested based on your profile</h3>
                        {suggestedSkills
                          .filter(
                            (filterItem) => !existingSkills.includes(filterItem)
                          )
                          .map((item) => (
                            <span
                              style={{ cursor: "pointer", marginBottom: "5px" }}
                              onClick={(e) => onSuggestedSkillHandler(e, item)}
                            >
                              {item}
                            </span>
                          ))}
                        <span>Sales</span>
                        <span>Marketing</span>
                        <span>Direct Sales</span>
                        <span>Management</span>
                      </div>
                    </div> */}
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    {/* <button
                      className="btn cancelBtn"
                      data-bs-target="#confirmModal"
                      data-bs-toggle="modal"
                      onClick={(e) =>
                        onSubmit(e, "confirmModal", false, "skills")
                      }
                    >
                      Skip for now.
                    </button> */}
                    <button
                      // disabled={
                      //   form &&
                      //   form.skills.some(
                      //     (item) => !item.skillSet
                      //     // || !item.proficiencyGrade
                      //   )
                      // }
                      className="btn inviteBtn"
                      data-bs-target={`${
                        linkedin ? "#confirmModalOneTime" : "#confirmModal"
                      }`}
                      data-bs-toggle="modal"
                      onClick={(e) =>
                        onSubmit(e, "confirmModal", false, "skills")
                      }
                    >
                      Complete my profile!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* educationhistory modal ends */}

        {/* confirm modal starts */}
        <div
          class="modal fade commonModal editEducationModal confirmModal candidateOnboarding"
          id="confirmModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header d-block pt-3 px-0 pb-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-4">
                  <h5 className="mb-2 mt-3">Welcome to Hirebird Beta</h5>
                  <p className="confirmText">
                    You're all set for now! However, there's a couple extra
                    details you can add to your profile before our full launch
                    in March 2024
                  </p>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn inviteBtn w-100"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        navigate("/my-profile");
                        window.location.reload();
                      }}
                    >
                      Sounds good!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* confirm modal ends */}

        {/* sent offer modal starts */}
        <div
          class="modal fade commonModal companyProfileImg"
          id="jobOfferModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-5">Send Job Offer</h3>

                  <form className="mt-5">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Job Title"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>Job Type</option>
                            <option>Job Type</option>
                            <option>Job Type</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <select className="form-control">
                            <option>Salary</option>
                            <option>Salary</option>
                            <option>Salary</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Location"
                          />
                        </div>
                      </div>

                      <div className="col-xl-6">
                        <div className="form-group">
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Start Date"
                          />
                        </div>
                      </div>

                      <div className="col-xl-12">
                        <div className="form-group mb-0">
                          <textarea
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button className="btn cancelBtn" data-bs-dismiss="modal">
                      No, Cancel
                    </button>
                    <button className="btn inviteBtn">Send job Offer</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* sent offer modal ends */}

        {/* invite modal starts */}
        <div
          class="modal fade commonModal companyProfileImg"
          id="inviteModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              {/* <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div> */}
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-5">
                    You're all done for now! 🎉
                  </h3>

                  <img src={invitePopupImg} />

                  <h4 className="inviteSubText">
                    In the meantime invite your friends and{" "}
                    <span>
                      Earn entries for a full university scholarship giveaway!
                    </span>
                  </h4>
                  <div className="formBtns mt-5 mb-4">
                    <button className="btn inviteBtn w-100">
                      Invite your friends to join Hirebird
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* invite modal ends */}

        {/* invite modal starts */}
        <div
          class="modal fade commonModal companyProfileImg"
          id="postingModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              {/* <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div> */}
              <div class="modal-body">
                <div className="commonModalContent px-4">
                  <h3 className="ModalHeading mb-5 mt-4">
                    You're all done for now! 🎉
                  </h3>

                  <img
                    className="mb-5"
                    style={{ width: "40%" }}
                    src={invitePopupImg}
                  />

                  <h4 className="inviteSubText">
                    In the meantime invite your friends and{" "}
                    <span>
                      Earn entries for a full university scholarship giveaway!
                    </span>
                  </h4>
                  <div className="formBtns mt-5 mb-4">
                    <button className="btn inviteBtn w-100">
                      Invite your friends to join Hirebird
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* invite modal ends */}
        <div
          class="modal fade commonModal editExperienceModalSingle"
          id="imageUploadModalStarted"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header"></div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Upload Image</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="imageUploaderSection">
                      <ImageUpload
                        type="file"
                        page="uploadImageModal"
                        onBoardingRecruiter={true}
                        setPreview={setPreviewImage}
                        setImage={setHeadshotImage}
                        preview={previewImage}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --------------Linkedin--Modal-------------------------- */}
        {/* Nice Work Modal start */}
        <div
          class="modal fade commonModal editEducationModal confirmModal candidateOnboarding"
          id="confirmModalOneTime"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                <img className="logo" src={Logo} />
              </div>

              <div class="modal-body pt-0 px-0 pb-0 pt-3">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                {page == "apply" ? (
                  <div className="commonModalContent px-4">
                    <h5 className="mb-2">Nice Work ! That was easy.</h5>
                    <p className="confirmText">
                      You’re all set to apply to your original position now! You
                      can see view your application status on your Hirebird
                      account.
                    </p>
                    <div className="formBtns mt-5 mb-4">
                      <button
                        className="btn inviteBtn w-100"
                        onClick={() => {
                          JobApplyHandler();
                        }}
                      >
                        Apply Now
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="commonModalContent">
                    <h5 className="mb-2">Success!</h5>
                    <p className="confirmText">
                      You just applied to your first job on Hirebird! You can
                      view your application status on your profile or view more
                      jobs like the one you just applied for.
                    </p>
                    <div className="formBtns mt-5 mb-4">
                      <button
                        className="btn cancelBtn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          navigate(localStorage.getItem("sharePageUrl"));
                          setForm({
                            firstName: "",
                            location: "",
                            qualification: [],
                            workExperience: [],
                            skills: [],
                          });
                        }}
                      >
                        Skip for now.
                      </button>
                      <button
                        className="btn inviteBtn w-100"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          // setForm({
                          //   firstName: "",
                          //   location: "",
                          //   qualification: [],
                          //   workExperience: [],
                          //   skills: [],
                          // });
                          navigate(localStorage.getItem("sharePageUrl"));
                          setPage("success");
                        }}
                      >
                        View my Application Status
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Nice work Modal end */}
        {/* ------------------------------------------------------- */}
      </div>
    </>
  );
}

export default Modals;
