import React, { useState } from "react";
import StepFormProgress from "../../view/stepForm-progress";
import Anthropology from "../../assets/imgs/anthropology.svg";
import experiencepeople from "../../assets/imgs/experiencepeople.svg";
import editIcon from "../../assets/imgs/editIcon.svg";
import checkMark from "../../assets/imgs/checkMark.gif";
import boostIcon from "../../assets/imgs/boostIcon.gif";
import infoIcon from "../../assets/imgs/infoIcon.svg";
import uploadIcon from "../../assets/imgs/uploadIcon.svg";
import whiteCross from "../../assets/imgs/whiteCross.svg";
import toastMessages from "../toastMessage";
import Logo from "../../assets/imgs/HireBIRD.png";
import { allApiUrl } from "../../api/apiRoutes";
import { apiPutMethod } from "../../api/rest";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../customImageEditor/index";
import canceIcon from "../../assets/imgs/cancelIcon.svg";
// import proceedIcon from "../../assets/imgs/proceed.png";

function RecruiterModal(props) {
  let navigate = useNavigate();
  const [isCompanyDropdownClosed, setIsCompanyDropdownClosed] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsCompanyDropdownClosed(true);
      // console.log("do validate");
    }
  };
  return (
    <>
      <div className="mainWrapper">
        {/* Recruiter Modal Start */}
        {/* set company modal starts */}
        <div
          class="modal fade commonModal editEducationModal candidateOnboarding"
          id="setCompanyModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"25%"} />
                  <h3 className="ModalHeading mb-5">
                    Please select or enter the company you represent!
                  </h3>

                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                      {/* <button
                        onClick={() => setIsCompanyDropdownClosed(true)}
                        style={{
                          background: "transparent",
                          border: "none",
                          position: "absolute",
                          top: "6px",
                          right: "3px",
                        }}
                        // onClick={() => {
                        //   if (newSkill) {
                        //     setForm((prev) => {
                        //       if (prev.keySkills) {
                        //         prev.keySkills = [...prev.keySkills, newSkill];
                        //       } else {
                        //         prev.keySkills = [newSkill];
                        //       }
                        //       return { ...prev };
                        //     });
                        //     setNewSkill("");
                        //   }
                        // }}
                      >
                        <img src={proceedIcon} height={40} width={40} />
                      </button> */}
                      <input
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          setIsCompanyDropdownClosed(false);
                          props.setIsCompanySelected(false);
                          props.setForm({
                            ...props.form,
                            firmName: e.target.value,
                          });
                          props.onCompanyHandler(e.target.value);
                        }}
                        value={props.form.firmName}
                        type="text"
                        className="form-control"
                        placeholder="Ex. General Mills"
                      />

                      {!isCompanyDropdownClosed &&
                        !props.isCompanySelected &&
                        props &&
                        props.form &&
                        props.form.firmName &&
                        props.form.firmName.trim() !== "" && (
                          <ul
                            className="companySuggest"
                            // style={{ display: "none" }}
                          >
                            {props.companyOptions.map((item, index) => {
                              return (
                                <li
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                  onClick={() => {
                                    props.setIsCompanySelected(true);
                                    props.setForm((prevData) => {
                                      prevData.firmName = item.firmName;
                                      prevData.employerProfileId = item.firmId;
                                      return {
                                        ...prevData,
                                      };
                                    });
                                  }}
                                >
                                  {item.firmName}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    {/* <button
                      className="btn cancelBtn"
                      data-bs-target="#companyProfileImgModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button> */}
                    {props.form &&
                      props.form.firmName &&
                      props.form.firmName.toLowerCase() == "hirebird" && (
                        <div>
                          <ul
                            className="errorListingRed"
                            style={{
                              margin: "-44px -12px 2px 33px",
                            }}
                          >
                            <li>
                              <img src={canceIcon} /> Contact your admin or
                              manager to make an account.
                            </li>
                          </ul>
                        </div>
                      )}
                    <button
                      disabled={
                        !props.form.firmName ||
                        (props.form &&
                          props.form.firmName &&
                          props.form.firmName.toLowerCase() == "hirebird")
                        // props.form.firmName == "Hirebird"
                      }
                      className="btn inviteBtn"
                      data-bs-target="#companyProfileImgModal"
                      data-bs-toggle="modal"
                      onClick={(e) =>
                        props.onSubmit(
                          e,
                          "companyProfileImgModal",
                          false,
                          "firmName"
                        )
                      }
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* set company modal ends */}
        {/* company headShot modal start*/}
        <div
          class="modal fade commonModal companyProfileImg candidateOnboarding"
          id="companyProfileImgModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"40%"} />
                  <h3 className="ModalHeading mb-5">
                    Add a headshot to liven up your profile!
                  </h3>
                  {/* <ImageUpload /> */}
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div
                      className="dragDrop mt-5"
                      style={{ marginBottom: "12px" }}
                      //temporary changes
                      data-bs-target="#imageUploadModalStarted"
                      data-bs-toggle="modal"
                    >
                      {/* <input
                        id="profileImageRecruiter"
                        type="file"
                        onChange={(e) => {
                          if (e) {
                            const file = e.target.files;
                            if (
                              file &&
                              file[0] &&
                              file[0].type &&
                              file[0].type.split("/")[0] == "image"
                            ) {
                              props.setPreviewImage({
                                ...props.previewImage,
                                headshotImage: URL.createObjectURL(file[0]),
                              });
                              props.setHeadshotImage(file[0]);
                              props.setIsHeadshotImageDeleted(false);
                            } else {
                              toastMessages(
                                "error",
                                "The image format is not correct"
                              );
                            }
                          }
                        }}
                      /> */}
                      <img src={uploadIcon} />
                      <p className="my-0">
                        Drop your file here{" "}
                        <span className="green">or Browser</span>
                      </p>
                      <p className="my-0">
                        We Support <span className="white">JPG, PNG</span>{" "}
                        files. Make sure that your file is no more than 1 mb.
                      </p>
                    </div>
                    {props.previewImage && props.previewImage.headshotImage && (
                      <span className="previewUpload">
                        {props.previewImage &&
                          props.previewImage.headshotImage && (
                            <img
                              // style={{
                              //   width: "100px",
                              //   height: "100px",
                              //   borderRadius: "22px",
                              // }}
                              // style={{ width: "100px", height: "100px" }}
                              src={props.previewImage.headshotImage}
                              alt="headhot-image"
                            />
                          )}
                        {props.previewImage &&
                          props.previewImage.headshotImage && (
                            <span className="crossIcon">
                              <img
                                src={whiteCross}
                                onClick={() => {
                                  // temporary changes
                                  // document.getElementById(
                                  //   "profileImageRecruiter"
                                  // ).value = null;
                                  props.setIsHeadshotImageDeleted(true);
                                  props.setHeadshotImage("");
                                  props.setPreviewImage({
                                    headshotImage: "",
                                  });
                                }}
                              />
                            </span>
                          )}
                      </span>
                    )}
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn cancelBtn"
                      data-bs-target={`${
                        props.isCompanySelected
                          ? "#confirmModal"
                          : "#jobTitleModal"
                      }`}
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-target={`${
                        props.isCompanySelected
                          ? "#confirmModal"
                          : "#jobTitleModal"
                      }`}
                      data-bs-toggle="modal"
                      onClick={async (e) => {
                        // companyProfileImgModal;
                        // const uploadImageModal = new window.bootstrap.Modal(
                        //   document.getElementById("companyProfileImgModal")
                        // );
                        try {
                          let formData = new FormData();
                          formData.append("file", props.headshotImage);
                          formData.append("accountId", props.userInfo._id);
                          await apiPutMethod(
                            allApiUrl.updateprofile,
                            formData,
                            props.header
                          );
                        } catch (err) {
                          // uploadImageModal.show();
                          // toastMessages("error", err.data.message);
                        }
                      }}
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* company headshot modal end */}
        {/* company position modal start */}
        <div
          class="modal fade commonModal companyProfileImg candidateOnboarding"
          id="jobTitleModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"40%"} />
                  <h3 className="ModalHeading mb-5">
                    What is your position within{" "}
                    {props.form && props.form.firmName}
                  </h3>

                  <form className="mt-5">
                    <div className="row">
                      <div className="col-xl-6 mx-auto">
                        <div className="form-group">
                          <input
                            onChange={(e) => {
                              props.setForm((prev) => {
                                prev.designation = e.target.value;
                                return { ...prev };
                              });
                            }}
                            value={props.form.designation}
                            type="text"
                            className="form-control"
                            placeholder="Job Title *"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    {/* <button
                      className="btn cancelBtn"
                      data-bs-target="#companyDoModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button> */}
                    <button
                      disabled={!props.form.designation}
                      className="btn inviteBtn"
                      data-bs-target="#companyDoModal"
                      data-bs-toggle="modal"
                      onClick={(e) => {
                        props.onSubmit(
                          e,
                          "companyDoModal",
                          false,
                          "designation"
                        );
                      }}
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* company position modal end */}
        {/* company about us modal starts */}
        <div
          class="modal fade commonModal companyProfileImg candidateOnboarding"
          id="companyDoModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"65%"} />
                  <h3 className="ModalHeading mb-5">
                    In 1-2 sentences, what does your company Does?
                  </h3>
                  <form className="mt-5">
                    <div className="form-group">
                      <textarea
                        onChange={(e) => {
                          props.setForm((prev) => {
                            prev.aboutus = e.target.value;
                            return { ...prev };
                          });
                        }}
                        value={props.form.aboutus}
                        className="form-control"
                        placeholder="*This will be your subheader!"
                      ></textarea>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    {/* <button
                      className="btn cancelBtn"
                      // data-bs-dismiss="modal"
                      data-bs-target="#companyDescriptionModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button> */}
                    <button
                      disabled={!props.form.aboutus}
                      className="btn inviteBtn"
                      data-bs-target="#companyDescriptionModal"
                      data-bs-toggle="modal"
                      onClick={(e) => {
                        props.onSubmit(e, "", false, "aboutus");
                      }}
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* company about use modal ends */}
        {/* set aboutus starts */}
        <div
          class="modal fade commonModal companyProfileImg candidateOnboarding"
          id="companyDescriptionModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"80%"} />
                  <h3 className="ModalHeading mb-5">
                    Briefly tell us about {props.form && props.form.firmName}
                  </h3>

                  <form className="mt-5">
                    <div className="form-group">
                      <textarea
                        onChange={(e) => {
                          props.setForm((prev) => {
                            prev.description = e.target.value;
                            return { ...prev };
                          });
                        }}
                        value={props.form.description}
                        className="form-control"
                        placeholder="Company Description (280 characters) *"
                      ></textarea>
                    </div>
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    {/* <button
                      className="btn cancelBtn"
                      data-bs-target="#companyLogoModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button> */}
                    <button
                      disabled={!props.form.description}
                      className="btn inviteBtn"
                      data-bs-target="#companyLogoModal"
                      data-bs-toggle="modal"
                      onClick={(e) => {
                        props.onSubmit(e, "", false, "description");
                      }}
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* set aboutus ends */}
        {/* set company logo modal starts */}
        <div
          class="modal fade commonModal companyProfileImg candidateOnboarding"
          id="companyLogoModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header d-block px-0 pt-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-0">
                  <StepFormProgress value={"100%"} />
                  <h3 className="ModalHeading mb-5">
                    Please add company logo image
                  </h3>

                  <form className="mt-5">
                    <div
                      className="dragDrop mt-5 mb-5"
                      //temporary changes
                      data-bs-target="#companyImageModal"
                      data-bs-toggle="modal"
                    >
                      {/* <input
                        id="profileImage"
                        type="file"
                        onChange={(e) => {
                          if (e) {
                            const file = e.target.files;
                            if (
                              file &&
                              file[0] &&
                              file[0].type &&
                              file[0].type.split("/")[0] == "image"
                            ) {
                              props.setPreviewImageCompany({
                                ...props.previewImageCompany,
                                companyImage: URL.createObjectURL(file[0]),
                              });
                              props.setCompanyImage(file[0]);
                              props.setIsCompanyImageDeleted(false);
                            } else {
                              toastMessages(
                                "error",
                                "The image format is not correct"
                              );
                            }
                          }
                        }}
                      /> */}
                      <img src={uploadIcon} />
                      <p className="my-0">
                        Drop your file here{" "}
                        <span className="green">or Browser</span>
                      </p>
                      <p className="my-0">
                        We Support <span className="white">JPG, PNG</span>{" "}
                        files. Make sure that your file is no more than 1 mb.
                      </p>
                    </div>
                    {props.previewImageCompany &&
                      props.previewImageCompany.companyImage && (
                        <span className="previewUpload">
                          {props.previewImageCompany &&
                            props.previewImageCompany.companyImage && (
                              <img
                                src={props.previewImageCompany.companyImage}
                                alt="headhot-image"
                              />
                            )}
                          {props.previewImageCompany &&
                            props.previewImageCompany.companyImage && (
                              <span className="crossIcon">
                                <img
                                  src={whiteCross}
                                  onClick={() => {
                                    //temporary Chnages
                                    // document.getElementById(
                                    //   "profileImage"
                                    // ).value = null;
                                    props.setIsCompanyImageDeleted(true);
                                    props.setCompanyImage("");
                                    props.setPreviewImageCompany({
                                      companyImage: "",
                                    });
                                  }}
                                />
                              </span>
                            )}
                        </span>
                      )}
                    {/* {props.previewImageCompany &&
                      props.previewImageCompany.companyImage && (
                        <img
                          src={props.previewImageCompany.companyImage}
                          alt="headhot-image"
                        />
                      )} */}
                    {/* {props.previewImageCompany &&
                      props.previewImageCompany.companyImage && (
                        <i
                          style={{
                            cursor: "pointer",
                            marginLeft: "12px",
                            color: "red",
                          }}
                          className="fa-solid fa-trash-can"
                          onClick={() => {
                            document.getElementById("profileImage").value =
                              null;
                            props.setIsCompanyImageDeleted(true);
                            props.setCompanyImage("");
                            props.setPreviewImageCompany({
                              companyImage: "",
                            });
                          }}
                        ></i>
                      )} */}
                  </form>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn cancelBtn"
                      data-bs-target="#confirmModal"
                      data-bs-toggle="modal"
                    >
                      Skip for now.
                    </button>
                    <button
                      className="btn inviteBtn"
                      data-bs-target="#confirmModal"
                      data-bs-toggle="modal"
                      onClick={async (e) => {
                        let formData = new FormData();
                        formData.append("firmLogo", props.companyImage);
                        formData.append("accountId", props.userInfo._id);
                        await apiPutMethod(
                          allApiUrl.updateprofile,
                          formData,
                          props.header
                        );
                      }}
                    >
                      Done, next step!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* set company logo modal ends */}
        {/* nice work modal starts */}
        {/* <div
          class="modal fade commonModal editEducationModal confirmModal candidateOnboarding"
          id="confirmModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header d-block pt-0 px-0">
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0 pb-0 pt-3">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <div className="commonModalContent px-4">
                  <h5 className="mb-2">Nice Work</h5>
                  <p className="confirmText">
                    You're all set for now! However, there's a couple extra
                    things you can add to your profile to increase compatibility
                    with employers.
                  </p>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn inviteBtn w-100"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        navigate("/profile");
                        window.location.reload();
                      }}
                    >
                      Sounds good!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* nice work modal ends */}
        {/* confirm modal starts */}
        <div
          class="modal fade commonModal editEducationModal confirmModal candidateOnboarding"
          id="confirmModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header d-block pt-3 px-0 pb-0">
                {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">Modal 1</h1> */}
                <img className="logo" src={Logo} />
              </div>
              <div class="modal-body pt-0 px-0">
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
                <div className="commonModalContent px-4">
                  <h5 className="mb-2 mt-3">Welcome to Hirebird Beta</h5>
                  <p className="confirmText">
                    You're all set for now! However, there's a couple extra
                    details you can add to your profile before our full launch
                    in March 2024
                  </p>
                  <div className="formBtns mt-5 mb-4">
                    <button
                      className="btn inviteBtn w-100"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        navigate("/profile");
                        window.location.reload();
                      }}
                    >
                      Sounds good!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade commonModal editExperienceModalSingle"
          id="imageUploadModalStarted"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header"></div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Upload Image</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="imageUploaderSection">
                      <ImageUpload
                        type="file"
                        page="companyProfileImgModal"
                        onBoardingRecruiter={true}
                        setPreview={props.setPreviewImage}
                        setImage={props.setHeadshotImage}
                        preview={props.previewImage}
                        // isHeadshotImageDeleted={},
                        // setIsHeadshotImageDeleted,
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* companyLogoModal start */}
        <div
          class="modal fade commonModal editExperienceModalSingle"
          id="companyImageModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header"></div>
              <div class="modal-body pt-0">
                <div className="commonModalContent px-0">
                  <h3 className="ModalHeading">Upload Image</h3>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="imageUploaderSection">
                      <ImageUpload
                        page="companyLogoModal"
                        type="firmLogo"
                        onBoardingRecruiter={true}
                        setPreview={props.setPreviewImageCompany}
                        setImage={props.setCompanyImage}
                        preview={props.companyImage}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* companyLogoModal end*/}
      </div>
    </>
  );
}

export default RecruiterModal;
